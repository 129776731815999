import React, { useEffect, useState } from 'react';
import './PrintMaintenanceForm.scss';
import { Icon, Image, Table } from 'semantic-ui-react';
import whiteLogo from './../../../assets/images/logo-white.jpg';
import { maintenanceFormApi } from '../../../api/api';
import moment from 'moment';

export default function PrintMaintenanceForm() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  const [formData, setFormData] = useState({});

  useEffect(() => {
    (async () => {
      const res = await maintenanceFormApi.getOneForm(id);
      setFormData(res.data);
    })();
  }, [id]);

  return (
    <div className='print-container'>
      <div className='header'>
        <div className='header-logo'>
          <Image src={whiteLogo} alt='logo' size='tiny' centered />
        </div>
        <div className='header-title'>الشركة العامة للحاسبات والالكترونيات</div>
      </div>

      <div className='contents'>
        <h3>تقرير الصيانة رقم #{formData.id}</h3>

        <div className='segment'>
          <h4>
            <Icon name='book' /> &nbsp; معلومات التقرير
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>تاريخ اجراء الصيانة</Table.HeaderCell>
                <Table.HeaderCell width={2}>تاريخ انشاء التقرير</Table.HeaderCell>
                <Table.HeaderCell width={2}>منشيء التقرير</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell className='ltr'>{`${moment(formData.maintenanceDate).format('YYYY-MM-DD')}, ${moment
                  .utc(moment.duration(formData.maintenanceHour, 'hours').asMilliseconds())
                  .format('hh:mm A')}`}</Table.Cell>
                <Table.Cell className='ltr'>{moment(formData.createdAt).format('YYYY-MM-DD, hh:mm A')}</Table.Cell>
                <Table.Cell>{formData.createdByName}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='sticky note' /> &nbsp; معلومات الجهاز
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>العميل</Table.HeaderCell>
                <Table.HeaderCell width={2}>رقم لوحة المركبة</Table.HeaderCell>
                <Table.HeaderCell width={2}>IMEI جهاز التتبع</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.customerName}</Table.Cell>
                <Table.Cell>{formData.plateNumber}</Table.Cell>
                <Table.Cell>{formData.imei}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='cogs' /> &nbsp; معلومات الصيانة
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>الفني المسؤول</Table.HeaderCell>
                <Table.HeaderCell>حالة جهاز التتبع</Table.HeaderCell>
                <Table.HeaderCell>حالة المركبة</Table.HeaderCell>
                <Table.HeaderCell>فصل مزود الطاقة الرئيسي</Table.HeaderCell>
                <Table.HeaderCell>الاجراء</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.technicianName}</Table.Cell>
                <Table.Cell>{formData.issueTypeStr}</Table.Cell>
                <Table.Cell>{formData.vehicleIssueTypeStr}</Table.Cell>
                <Table.Cell>{formData.mainPowerAlerts === false ? 'نعم' : 'لا'}</Table.Cell>
                <Table.Cell>{formData.actionTypeStr}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {formData.issueDesc && (
          <div className='segment'>
            <h4>
              <Icon name='pencil' /> &nbsp; وصف المشكلة
            </h4>
            <div className='desc'>{formData.issueDesc}</div>
          </div>
        )}
        {formData.actionDesc && (
          <div className='segment'>
            <h4>
              <Icon name='pencil' /> &nbsp; وصف الاجراء
            </h4>
            <div className='desc'>{formData.actionDesc}</div>
          </div>
        )}
        {formData.note && (
          <div className='segment'>
            <h4>
              <Icon name='pencil' /> &nbsp; ملاحظات
            </h4>
            <div className='desc'>{formData.note}</div>
          </div>
        )}
        {formData.image && (
          <div className='segment'>
            <h4>
              <Icon name='picture' /> &nbsp; صورة المشكلة
            </h4>
            <div className='image-margin image-preview'>
              <div className='image-cropper'>
                <Image src={formData.image} className='img' centered />
              </div>
            </div>
          </div>
        )}
        {formData.representativeName && (
          <div className='segment'>
            <h4>
              <Icon name='user circle' /> &nbsp; اسم مندوب العميل
            </h4>
            <div>{formData.representativeName}</div>
          </div>
        )}
        {formData.representativeSignature && (
          <div className='segment'>
            <h4>
              <Icon name='signup' /> &nbsp; توقيع مندوب العميل
            </h4>
            <div className='image-margin image-preview'>
              <div className='image-cropper'>
                <Image src={formData.representativeSignature} className='img' centered />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

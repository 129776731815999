import React, { useState } from 'react';
import { Modal, Transition, Dropdown, Button } from 'semantic-ui-react';
import { scheduleVisits } from '../../../api/api';

const AddVisitsCalendar = ({
  setIsAddModalOpen,
  isAddModalOpen,
  setModalError,
  clients,
  addedEventFormData,
  setAddedEventFormData,
  getCustomerBranches,
  formData,
  clientbranches,
  setClientBranches,
  setRerender,
  rerender,
}) => {
  const [addError, setAddError] = useState('');

  const validateAdd = () => {
    if (!addedEventFormData.customerId || !addedEventFormData.branchId) {
      setAddError('يرجى اختيار العميل و الفرع');
      return false;
    }
    return true;
  };

  const clearValues = () => {
    setAddedEventFormData({
      scheduleMaintenanceId: '',
      date: new Date(),
      branchId: '',
    });
    setClientBranches([]);
  };

  const handleAddVisit = async () => {
    try {
      if (validateAdd()) {
        await scheduleVisits.createVisit(addedEventFormData);
        clearValues();
        setRerender(!rerender);
        setIsAddModalOpen(false);
      }
    } catch (err) {
      setModalError(true);
    }
  };

  const CustomerchangeHandler = async (e, v) => {
    setAddedEventFormData({ ...addedEventFormData, [v.name]: v.value });
    if (v.name == 'customerId') {
      await getCustomerBranches(v.value);
    }
  };

  return (
    <Transition visible={isAddModalOpen} animation='scale' duration={250}>
      <Modal size='small' open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <Modal.Header>اضافة زيارة جديدة</Modal.Header>
        <Modal.Content>
          <div>
            <div className='title-container' style={{ marginBottom: '1rem' }}>
              <h5 className='title'>العميل</h5>
            </div>
            <Dropdown
              placeholder='اختر العميل'
              fluid
              selection
              search
              name='customerId'
              value={addedEventFormData['customerId']}
              options={clients}
              disabled={formData.customerId ? true : false}
              onChange={CustomerchangeHandler}
              className='table-dropdown'
            />
          </div>
          {clientbranches.length > 0 && (
            <div>
              <div className='title-container' style={{ marginBottom: '1rem' }}>
                <h5 className='title'>الفروع</h5>
              </div>
              <Dropdown
                fluid
                selection
                search
                name='branchId'
                value={addedEventFormData.branchId}
                options={clientbranches}
                onChange={(e, v) => setAddedEventFormData({ ...addedEventFormData, [v.name]: v.value })}
                className='table-dropdown'
              />
              <label style={{ color: '#9f3a38' }}>{addError}</label>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setIsAddModalOpen(false)}>
            إلغاء
          </Button>
          <Button positive onClick={handleAddVisit}>
            اضافة
          </Button>
        </Modal.Actions>
      </Modal>
    </Transition>
  );
};

export default AddVisitsCalendar;

import React, { useEffect, useState } from 'react';
import { assetsApi } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import PageCardContainer from '../../Layout/PageCardContainer';
import { Icon, Table, Loader } from 'semantic-ui-react';
import './../Users/Users.scss';

export default function ViewAssetDetails() {
  const location = useLocation();
  const { id } = location.state;

  const [asset, setAsset] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await assetsApi.getViewOneAsset(id);
      setAsset(res.data);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div className='user-details'>
            <div className='user-card'>
              <div className='contents'>
                <h2>معلومات الجهاز</h2>
                <hr />

                <Table basic='very' collapsing unstackable>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='user' /> العميل:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{asset.customerName}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='list ul' /> نوع الجهاز:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{asset.assetType}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='list ul' /> موديل الجهاز:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{asset.assetModel}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='barcode' /> رقم التسلسل:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{asset.serialNumber ? asset.serialNumber : 'لم يتم الادخال'}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='qrcode' /> IMEI:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{asset.imei}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='table' /> SIM:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{asset.sim}</b>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
}

import React from 'react';
import { Card } from 'semantic-ui-react';
import SettingsCard from '../Settings/SettingsList/SettingsCard';
import { motion } from 'framer-motion';
import permissions from './../../helpers/permissions';
import PermissionCheck from '../Reusable/PermissionCheck';

const ScheduleVisitsService = () => {
  return (
    <div className='financial-services-cards-container'>
      <Card.Group className='settings-card-group'>
        <PermissionCheck
          permissions={[
            permissions.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
            permissions.VIEW_CUSTOMER_VISITS_SCHEDULE,
            permissions.ADD_SCHEDULE_VISIT,
            permissions.DELETE_SCHEDULE_VISIT,
            permissions.ADD_VISIT,
            permissions.EDIT_VISIT_DATE,
            permissions.CLOSE_VISIT_WITHOUT_REPORT,
            permissions.ADD_VISIT_REPORT,
            permissions.DELETE_VISIT,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='الصيانات الدورية و الزيارات' iconName='clipboard' url='/schedule/client-visits' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            permissions.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
            permissions.ADD_VISIT_REPORT,
            permissions.VIEW_VISIT_REPORT,
            permissions.DELETE_VISIT_REPORT,
            permissions.EDIT_VISIT_REPORT,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='تقارير الزيارات' iconName='pen square' url='/schedule/view-reports' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            permissions.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
            permissions.VIEW_CUSTOMER_VISITS_SCHEDULE,
            permissions.EDIT_VISIT_DATE,
            permissions.DELETE_VISIT,
            permissions.ADD_VISIT_REPORT,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='جدول الزيارات'
              iconName='calendar alternate outline'
              url='/schedule/all-clients-visits'
            />
          </motion.div>
        </PermissionCheck>
      </Card.Group>
    </div>
  );
};

export default ScheduleVisitsService;

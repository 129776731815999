import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './drop-file-upload.scss';
import { Dropdown, Icon } from 'semantic-ui-react';
import { lookupsApi } from '../../api/api';

const DropFileUpload = ({ onFileChange, attachmentType = 0, maxFiles = 3}) => {
  const [files, setFiles] = useState([]);
  const [attachmentTypesDropDown, setAttachmentTypesDropDown] = useState([]);

  useEffect(() => typeof onFileChange === 'function' && onFileChange(files), [files]);

  useEffect(() => {
    const fetchLookUpTypes = async () => {
      const { data: lookups } = await lookupsApi.getGroup(attachmentType);
      let assuranceTypesOpts = [];
      for (let item of lookups) {
        assuranceTypesOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
      }
      setAttachmentTypesDropDown(assuranceTypesOpts);
    };
    if (attachmentType != 0) fetchLookUpTypes();
  }, []);

  function removeFile(index) {
    const f = [...files];
    f.splice(index, 1);
    setFiles(f);
  }

  function nameLengthValidator(file) {
    if (file.name.length > 50) {
      return {
        code: 'name-too-large',
        message: `Name is larger than 50 characters`,
      };
    }
    return null;
  }

  const selectionChangeHandler = (index, e, data) => {
    const f = [...files];
    f[index].attachmentType = data.value;
    setFiles(f);
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: 'image/jpeg, image/png, .pdf, .xlsx, .xls, .csv, text/plain',
    maxFiles: maxFiles,
    maxSize: 5242880 /* 5MB */,
    validator: nameLengthValidator,
    onDrop: (acceptedFiles) => setFiles(acceptedFiles),
  });

  return (
    <section>
      <div {...getRootProps()} className='drop-file-upload-container'>
        <input {...getInputProps()} />
        {isDragActive ? <p>قم برمي الملفات هنا...</p> : <p>اسحب الملفات هنا، أو اضغط لاختيار الملفات من الجهاز</p>}
      </div>
      <aside>
        <ul className='drop-file-upload-files'>
          {files.map((file, index) => (
            <li key={file.path}>
              {attachmentType != 0 ? (
                <div className='flex-gap'>
                  <div>
                    <Icon name='delete' color='red' onClick={() => removeFile(index)} /> {file.path}
                  </div>
                  <Dropdown
                    selection
                    search
                    name={file.path}
                    value={file.attachmentType ? file.attachmentType : ''}
                    options={attachmentTypesDropDown}
                    onChange={(e, data) => selectionChangeHandler(index, e, data)}
                    className='table-dropdown'
                  />
                </div>
              ) : (
                <div>
                  <Icon name='delete' color='red' onClick={() => removeFile(index)} /> {file.path} -{' '}
                  {(file.size / 1024).toFixed(2)} kb
                </div>
              )}
            </li>
          ))}
        </ul>
        {fileRejections?.length > 0 && <h5>الملفات المرفوضة</h5>}
        <ul>
          {fileRejections.map(({ file, errors }) => (
            <li key={file.path}>
              {file.path} - {file.size} bytes
              <ul>
                {errors.map((e) => (
                  <li key={e.code}>{e.message}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </aside>
    </section>
  );
};

export default DropFileUpload;

import React, { useEffect, useState } from 'react';
import DataTable from '../../Layout/Table/DataTable';
import { lookupsApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission } from '../../../helpers/permissions';

export default function LookupsDetails() {
  const location = useLocation();
  const router = useHistory();
  const { id } = location.state;
  const [parentName, setParentName] = useState('');

  useEffect(() => {
    // api call to fetch the name of the parent
    const fetchData = async () => {
      try {
        const res = await lookupsApi.getOne(id);
        setParentName(res.data.name);
      } catch (err) {
        console.log("Error fetching lookup parent's name...", err);
      }
    };

    fetchData();
  }, []);

  const tableHeaders = [
    {
      // a field that is used for display only (in arabic)
      displayName: 'اسم الرمز',
      // a field that is used as a key (in english camelCase)
      fieldName: 'name',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
      searchValues: '',
      dropdownArMap: '',
    },
  ];

  const tableActions = [
    ...(hasPermission(permissions.EDIT_LOOKUP)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/lookups/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_LOOKUP)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: lookupsApi.delete,
          },
        ]
      : []),
  ];

  // parentId = int (get children of that parent)
  // no parentId (get parents only)
  const optionalParams = { parentId: id };

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <PermissionCheck permissions={permissions.CREATE_LOOKUP}>
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              onClick={() => router.push('/lookups/add', { id })}
            >
              <Icon name='plus' className='blue-buton-icon' />
              اضافة رمز جديد في مجموعة {parentName}
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='رموز_النظام_الفرعية'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={lookupsApi.getAll}
          optionalQsParams={optionalParams}
          reloadTrigger={[]}
        />
      </PageCardContainer>
    </div>
  );
}

export const loadMaintenanceFormStorage = () => {
  try {
    const serializedState = sessionStorage.getItem('maintenanceForm');

    if (serializedState === null) {
      return {
        form: null,
      };
    }

    return JSON.parse(serializedState);
  } catch (error) {
    console.log('Load maintenance form storage error...', error);
    return undefined;
  }
};

export const saveMaintenanceFormStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('maintenanceForm', serializedState);
  } catch (error) {
    console.log('Save maintenance form storage error...', error);
  }
};

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { motion } from 'framer-motion';

const Star = ({ rateState, setRateState, hoverIndex, setHoverIndex, value }) => {
  const glowVariants = {
    initial: {
      scale: 1,
      rotate: 0,
    },
    hover: {
      scale: 1.2,
      rotate: 90,
    },
  };

  return (
    <motion.div
      onClick={() => setRateState(value)}
      onHoverStart={() => {
        setHoverIndex(value);
      }}
      initial='initial'
      whileHover='hover'
      variants={glowVariants}
    >
      <Icon name={`star${rateState >= value ? '' : ' outline'}`} size='big' />
    </motion.div>
  );
};

export default Star;

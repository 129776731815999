import React, { useState, useEffect } from 'react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Dropdown, Icon, Responsive, Message } from 'semantic-ui-react';
import DropFileUpload from '../../Reusable/DropFileUpload';
import moment from 'moment';
import { stampReceiptFormApi, customersApi, filesApi, departmentsApi } from '../../../api/api';
import AnimatedModal from '../../Reusable/AnimatedModal';

const EditStampReceiptForm = () => {
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();
  const location = useLocation();
  const { id } = location.state;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [customersIdOptions, setCustomersIdOptions] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [deleteFileName, setDeleteFileName] = useState(null);
  const [userDepartments, setUserDepartments] = useState([]);
  const [currancyOptions, setCurrancyOptions] = useState([
    {
      key: 'JOD',
      text: 'دينار اردني',
      value: 'JOD',
    },
    {
      key: 'EUR',
      text: 'يورو',
      value: 'EUR',
    },
    {
      key: 'USD',
      text: 'دولار امريكي',
      value: 'USD',
    },
  ]);

  const [formData, setFormData] = useState({
    createdAt: new Date(),
    assuranceNumber: '',
    customerId: 0,
    assuranceAmount: 0.0,
    stampAmount: 0.0,
    createdBy: Number(currentUser.id),
    actualAssuranceDate: '',
    departmentId: 0,
    currancy: '',
  });

  const [touched, setTouched] = useState({
    assuranceNumber: false,
    customerId: false,
    assuranceAmount: false,
    stampAmount: false,
    actualAssuranceDate: false,
    departmentId: false,
    currancy: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    assuranceNumber: null,
    customerId: null,
    assuranceAmount: null,
    stampAmount: null,
    actualAssuranceDate: null,
    departmentId: null,
    currancy: null,
    assuranceAttachment: null,
  });

  const onDropFilesChanged = (files) => {
    setValidationErrors({ ...validationErrors, assuranceAttachment: null });
    setFiles(files);
  };

  const uploadFiles = async (stampReceiptId) => {
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const formData = new FormData();
        formData.append('file', file);
        await filesApi.uploadStampReceiptAttachment(stampReceiptId, file.attachmentType, formData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  const deleteExistingFile = async () => {
    const id = location.state.id;
    const fileName = deleteFileName;
    setDeleteFileName(null);
    try {
      setIsSubmitting(true);
      await filesApi.deleteStampReceiptAttachment(id, fileName);
      setPreviousFiles(previousFiles.filter((item) => item.name !== fileName));
      setIsSubmitting(false);
    } catch (e) {
      console.log(e);
      alert('حدث خطأ أثناء الحذف');
    }
  };

  const filesHasAttachmentType = () => {
    let assignmentAttachmentTypeExist = false;
    files.forEach((file) => {
      if (!file.attachmentType) assignmentAttachmentTypeExist = true;
    });
    return assignmentAttachmentTypeExist;
  };

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  useEffect(() => {
    (async () => {
      try {
        console.log(currentUser);
        const { data: customers } = await customersApi.getCustomersDropDown();
        const { data: form } = await stampReceiptFormApi.getOne(id);
        const { data: previousFilesResponse } = await stampReceiptFormApi.getAttachmentPaths(id);
        const { data: departments } = await departmentsApi.getDepartmentsByUserIdDropDown(currentUser.id);

        const departmentsOptions = departments.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setUserDepartments(departmentsOptions);

        if (!!previousFilesResponse.length) setPreviousFiles(previousFilesResponse);

        setFormData({ ...form });

        const customersOptions = customers.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCustomersIdOptions(customersOptions);
      } catch (err) {
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching assruance form dropdowns...', err);
        router.goBack();
      }
      setLoading(false);
    })();
  }, []);

  const validation = () => {
    const errors = {
      assuranceNumber: null,
      customerId: null,
      assuranceAmount: null,
      stampAmount: null,
      actualAssuranceDate: null,
      departmentId: null,
      currancy: null,
    };

    if (!formData.actualAssuranceDate) {
      errors.actualAssuranceDate = 'الرجاء ادخال تاريخ الاحالة الفعلي';
    }
    if (!formData.assuranceAmount) {
      errors.assuranceAmount = 'الرجاء ادخال قيمة الاحالة';
    }
    if (!formData.assuranceNumber) {
      errors.assuranceNumber = 'الرجاء ادخال رقم العطاء';
    }
    if (!formData.customerId) {
      errors.customerId = 'الرجاء اختيار عميل';
    }
    if (!formData.stampAmount) {
      errors.stampAmount = 'الرجاء ادخال قيمة الطوابع';
    }
    if (!formData.departmentId) {
      errors.departmentId = 'الرجاء اختيار قسم';
    }
    if (!formData.currancy) {
      errors.currancy = 'الرجاء اختيار العملة';
    }

    setValidationErrors(errors);

    if (
      validationErrors.actualAssuranceDate ||
      validationErrors.assuranceAmount ||
      validationErrors.assuranceNumber ||
      validationErrors.customerId ||
      validationErrors.stampAmount ||
      validationErrors.departmentId ||
      validationErrors.currancy
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      assuranceNumber: true,
      customerId: true,
      assuranceAmount: true,
      stampAmount: true,
      actualAssuranceDate: true,
      departmentId: true,
      currancy: true,
    });

    if (validation()) {
      if (filesHasAttachmentType()) {
        setValidationErrors({
          ...validationErrors,
          assuranceAttachment: 'الرجاء تحديد انواع المرفقات',
        });
        setIsSubmitting(false);
        return;
      }
      try {
        await stampReceiptFormApi.edit(id, {
          ...formData,
        });
        if (files.length > 0) uploadFiles(id);
        router.goBack();
      } catch (e) {
        console.log('Error posting contract...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const onBlurHandler = () => {
    const stampAmount = formData.assuranceAmount * 0.006;
    setFormData({ ...formData, stampAmount: stampAmount.toFixed(3) });
  };

  return (
    <PageCardContainer>
      <AnimatedModal
        open={!!deleteFileName}
        content='تأكيد عملية حذف الملف؟'
        footer={
          <>
            <Button color='red' onClick={deleteExistingFile}>
              تأكيد
            </Button>
            <Button onClick={() => setDeleteFileName(null)}>إلغاء</Button>
          </>
        }
      />
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>نموذج طلب دفع طوابع</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>المندوب</label>
              <Form.Input className='rtl-input' fluid value={currentUser.FullName} readOnly />
            </Form.Field>
            <Form.Field>
              <label>القسم</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  search
                  name='departmentId'
                  value={formData.departmentId}
                  options={userDepartments}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.departmentId && validationErrors.departmentId}</label>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>تاريخ تقديم الطلب</label>
            <DatePicker
              popperPlacement='top-end'
              placeholderText='تاريخ تقديم الطلب'
              value={moment(formData.createdAt).format('YYYY/MM/DD hh:mm A')}
              readOnly
            />
          </Form.Field>
          <Form.Field>
            <label>رقم العطاء/ احالة/ توريد/ شراء</label>
            <Form.Input
              className='rtl-input'
              fluid
              name='assuranceNumber'
              onChange={onChangeHandler}
              value={formData.assuranceNumber}
              error={touched.assuranceNumber && validationErrors.assuranceNumber}
            />
          </Form.Field>
          <Form.Field>
            <label>المستفيد</label>
            <Dropdown
              fluid
              selection
              search
              value={formData['customerId']}
              name='customerId'
              options={customersIdOptions}
              onChange={selectionChangeHandler}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
            <label style={{ color: '#9f3a38' }}>{touched.customerId && validationErrors.customerId}</label>
          </Form.Field>
          <Form.Field>
            <label>تاريخ الاحالة الفعلي</label>
            <DatePicker
              popperPlacement='bottom-end'
              placeholderText='تاريخ'
              value={moment(formData.actualAssuranceDate).format('YYYY/MM/DD hh:mm A')}
              onChange={(date) => setFormData({ ...formData, actualAssuranceDate: moment(date).format('YYYY/MM/DD') })}
            />
            <label style={{ color: '#9f3a38' }}>
              {touched.actualAssuranceDate && validationErrors.actualAssuranceDate}
            </label>
          </Form.Field>
          <Form.Group widths={3}>
            <Form.Field>
              <label>قيمة الاحالة رقما</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceAmount'
                onChange={onChangeHandler}
                value={formData.assuranceAmount}
                error={touched.assuranceAmount && validationErrors.assuranceAmount}
                onBlur={onBlurHandler}
              />
            </Form.Field>
            <Form.Field>
              <label>قيمة الطوابع رقما</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='stampAmount'
                onChange={onChangeHandler}
                value={formData.stampAmount}
                error={touched.stampAmount && validationErrors.stampAmount}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>العملة</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  clearable
                  value={formData.currancy}
                  name='currancy'
                  options={currancyOptions}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.currancy && validationErrors.currancy}</label>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>المرفقات</label>
            <div className='attachments-container'>
              <DropFileUpload onFileChange={onDropFilesChanged} attachmentType={154} />
              <label style={{ color: '#9f3a38' }}>{validationErrors.assuranceAttachment}</label>
              {Boolean(previousFiles.length) && (
                <>
                  <h5>المرفقات السابقة</h5>
                  <ul className='drop-file-upload-files'>
                    {previousFiles.map((item, index) => (
                      <li key={index}>
                        <Icon name='delete' color='red' onClick={() => setDeleteFileName(item.name)} />
                        <label style={{ direction: 'ltr' }}>
                          {item.name}-{item.attachmentType}
                        </label>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            {uploadProgress > -1 && (
              <h4 style={{ textAlign: 'center' }}>
                جاري رفع المرفقات {uploadProgress}/{files.length}
              </h4>
            )}
          </Form.Field>
          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default EditStampReceiptForm;

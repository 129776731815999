import React, { useState, useEffect } from 'react';
import './Playback.scss';
import { useLocation } from 'react-router-dom';
import Map from '../Map/Map';
import DatePicker from 'react-datepicker';
import { Divider, Button, Dimmer, Loader, TransitionablePortal, Message } from 'semantic-ui-react';
import { AutoSizer, CellMeasurer, List, CellMeasurerCache } from 'react-virtualized';
import techLocationAddressImg from '../../assets/images/techLocationAddressImg.svg';
import { playbackApi } from '../../api/api';
import moment from 'moment';
import AddressingServiceApi from '../../api/addressing-service.api';
import { Slider } from 'react-semantic-ui-range';

import { useRef } from 'react';
import { cleanMap, drawRoute, moveTechSelectedLocation } from '../../helpers/playbackMapHelper';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const emptySelectedLocationInfo = {
  locationDate: null,
  locationHour: null,
  lnglat: {
    lng: null,
    lat: null,
  },
  locationId: null,
  index: 0,
};

const Playback = () => {
  const [searchDateFrom, setSearchDateFrom] = useState(today);
  const [searchDateTo, setSearchDateTo] = useState(tomorrow);
  const [futureDate, setFutureDate] = useState(false);

  const [map, setMap] = useState(null);

  const [techMovePointsLocations, setTechMovePointsLocations] = useState([]);
  const [selectedLocationInfo, setSelectedLocationInfo] = useState(emptySelectedLocationInfo);
  const [techLocationAddress, setTechLocationAddress] = useState(null);
  const locationSelectedGeometry = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [locationId, setLocationId] = useState(0);
  const location = useLocation();

  const { id, phone, name } = location.state;

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const getLocations = async () => {
    try {
      setIsLoading(true);
      if (!searchDateFrom || !searchDateTo || id == 0) {
        setIsError(true);
        setErrorMessage('يرجى تعبأة جميع الحقول');
        cleanMap(map);
        setTechMovePointsLocations([]);
        setIsLoading(false);
        return;
      }
      setIsError(false);
      const res = await playbackApi.getPlaybackLocations(
        id,
        moment(searchDateFrom).format('YYYY/MM/DD'),
        moment(searchDateTo).format('YYYY/MM/DD')
      );
      if (res && res.data && res.data.length > 0) {
        const currPath = res.data.map((value) => {
          return [value.longitude, value.latitude];
        });
        if (drawRoute(currPath, locationSelectedGeometry, map) === -1) {
          setIsError(true);
          setErrorMessage('حدث خطأ يرجى المحاولة لاحقا');
          setTechMovePointsLocations([]);
          setIsLoading(false);
          return;
        }

        setTechMovePointsLocations(
          res.data.map((point) => {
            return {
              lng: point.longitude,
              lat: point.latitude,
              locationDate: moment.utc(point.gps_date).format('DD/MM/YYYY'),
              locationHour: moment.utc(point.gps_date).format('HH:mm:ss'),
              locationID: +point.id,
            };
          })
        );
        setLocationId(res.data[0].id);
        setIsLoading(false);
      } else {
        setIsError(true);
        setErrorMessage('لا يوجد نقاط لهذا المستخدم');
        cleanMap(map);
        setTechMovePointsLocations([]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsError(true);
      setErrorMessage('حدث خطأ يرجى المحاولة لاحقا');
      cleanMap(map);
      setTechMovePointsLocations([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedLocationInfo.lnglat && selectedLocationInfo.lnglat.lat && selectedLocationInfo.lnglat.lng)
      moveTechSelectedLocation(
        locationSelectedGeometry.current,
        [selectedLocationInfo.lnglat.lng, selectedLocationInfo.lnglat.lat],
        map
      );
  }, [selectedLocationInfo]);

  const locationsListCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
    })
  );

  const getTechLocaionAddress = async (lng, lat) => {
    const techAddress = await AddressingServiceApi.getAddress(lng, lat);
    if (techAddress) {
      setTechLocationAddress(techAddress);
    } else {
      setIsError(true);
      setErrorMessage('عذراً، ولكن لا توجد معلومات لهذا العنوان.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (locationId) {
      let index = 0;
      const result = techMovePointsLocations.find((t, i) => {
        if (t.locationID === locationId) {
          index = i;
          return true;
        }
      });

      if (result) {
        setSelectedLocationInfo({
          locationDate: result.locationDate,
          locationHour: result.locationHour,
          lnglat: {
            lng: result.lng,
            lat: result.lat,
          },
          locationId: locationId,
          index: index,
        });
      }
    } else setSelectedLocationInfo(emptySelectedLocationInfo);
  }, [locationId]);

  return (
    <div className='playback-page-container'>
      <Dimmer active={isLoading}>
        <Loader content='جاري تحميل نقاط الفني' />
      </Dimmer>
      <div className='right-panel-container'>
        <h2>عرض النقاط</h2>
        <div className='options-container'>
          <div className='date-pickers-container'>
            <div className='date-picker-lbl-container'>
              <label> من تاريخ</label>
              <DatePicker
                popperPlacement='top-end'
                selected={searchDateFrom}
                onChange={(date) => setSearchDateFrom(date)}
                selectsStart
                minDate={futureDate ? today : null}
                maxDate={futureDate ? null : today}
              />
            </div>
            <div className='date-picker-lbl-container'>
              <label> الى تاريخ</label>
              <DatePicker
                popperPlacement='top-end'
                selected={searchDateTo}
                onChange={(date) => setSearchDateTo(date)}
                selectsEnd
                minDate={futureDate ? today : null}
                maxDate={futureDate ? null : today}
              />
            </div>
          </div>
          <Divider />
          <div className='user-info-container'>
            <h3> معلومات الفني</h3>
            <label>اسم الفني: {name}</label>
            <label>رقم الفني: {phone}</label>
          </div>
          {!isError && techMovePointsLocations && Boolean(techMovePointsLocations.length > 0) && (
            <>
              <Divider />
              <h3 className='location-slider-lbl'> نقاط التحرك </h3>
              <Slider
                color='blue'
                inverted={false}
                settings={{
                  start: locationId,
                  min: techMovePointsLocations[0].locationID,
                  max: techMovePointsLocations[techMovePointsLocations.length - 1].locationID,
                  step: 1,
                  onChange: (e) => {
                    setLocationId(+e);
                  },
                }}
              />
              <div className='location-time-container'>
                <label>{`التاريخ ${
                  Boolean(selectedLocationInfo.locationDate)
                    ? selectedLocationInfo.locationDate
                    : techMovePointsLocations[0].locationDate
                } `}</label>
                <label>،</label>
                <label>{`الوقت ${
                  Boolean(selectedLocationInfo.locationHour)
                    ? selectedLocationInfo.locationHour
                    : techMovePointsLocations[0].locationHour
                } `}</label>
              </div>
            </>
          )}
          <Divider />
        </div>
        <Button
          className='execute-btn'
          onClick={getLocations}
          style={{ backgroundColor: '#00295d', color: 'white', display: 'flex', alignSelf: 'flex-start' }}
        >
          تنفيذ
        </Button>
        {isError && <Message error compact content={errorMessage} />}
      </div>
      <div className='map-points-container'>
        <div className='map-container'>
          <Map center={[36.200359, 31.563158]} mapWidth='100%' returnMap={setMap} />
        </div>
        <div>
          <div className='technician-locations-info-container'>
            <div className='technician-locations-info-header'>معلومات نقاط التحرك الخاصة بالفني</div>
            <div className='technician-locations-info-box-container'>
              <div className='locations-info-box-container'>
                <div className='locations-info-box-headers-container'>
                  <label className='locations-info-box-headers-time'>الوقت</label>
                  <label className='locations-info-box-headers-address'>العنوان</label>
                </div>
                <div className='locations-info-box'>
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        rowHeight={locationsListCache.current.rowHeight}
                        deferredMeasurementCache={locationsListCache.current}
                        width={width}
                        height={height}
                        rowCount={techMovePointsLocations.length}
                        scrollToIndex={selectedLocationInfo.index}
                        scrollToAlignment='start'
                        rowRenderer={({ key, index, style, parent }) => {
                          const techMovePointInfo = techMovePointsLocations[index];
                          return (
                            <CellMeasurer
                              key={key}
                              cache={locationsListCache.current}
                              parent={parent}
                              rowIndex={index}
                              columnIndex={0}
                            >
                              <div
                                className={`location-info-row-container ${
                                  Boolean(techMovePointInfo.locationID === selectedLocationInfo.locationId)
                                    ? 'yellow-back-ground'
                                    : ''
                                }`}
                                style={style}
                              >
                                <div className='location-info-row-address'>
                                  <TransitionablePortal
                                    closeOnTriggerClick
                                    openOnTriggerClick
                                    onOpen={() => getTechLocaionAddress(techMovePointInfo.lng, techMovePointInfo.lat)}
                                    trigger={<img src={techLocationAddressImg} className='img' />}
                                  >
                                    <div className='address-text'>
                                      {techLocationAddress ? techLocationAddress : <Loader active inline='centered' />}
                                    </div>
                                  </TransitionablePortal>
                                </div>
                                <div className='location-info-row-time'>
                                  <label>{techMovePointInfo.locationDate}</label>
                                  <label>{techMovePointInfo.locationHour}</label>
                                </div>
                              </div>
                            </CellMeasurer>
                          );
                        }}
                      ></List>
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playback;

import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Transition, Modal, Dropdown, Form, Message } from 'semantic-ui-react';
import { merchandiseDeliveryRequestsApi } from '../../../api/api';

export default function StatusUpdatePopup({ visible, row, statusOptions, setVisible, toggleRerenderFlag }) {
  const [filteredStatusOptions, setFilteredStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [error, setError] = useState(null);
  const currentUser = useSelector((st) => st.user).user;

  useEffect(() => {
    if (typeof row === 'object' && row.status && statusOptions && statusOptions.length) {
      let tempOptions = statusOptions.filter((item) => item.key !== row.status);
      setSelectedStatus(tempOptions[0].key);
      setFilteredStatusOptions(tempOptions);
    }
  }, [statusOptions, row]);

  const clearStates = () => {
    if (typeof setVisible === 'function') setVisible(false);
    setError(null);
    setSelectedStatus(null);
    setFilteredStatusOptions([]);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await merchandiseDeliveryRequestsApi.updateMerchandiseDeliveryRequestStatus(row.id, {
        status: selectedStatus,
        changedBy: Number(currentUser.id),
      });
      if (typeof toggleRerenderFlag === 'function') toggleRerenderFlag(true);
      clearStates();
    } catch (err) {
      setError('حدث خطأ اثناء تعديل حالة الطلب');
      console.log('Error updating delivery request status...', err.response ?? err);
    }
  };

  return (
    <div>
      <Transition visible={visible} animation='scale' duration={1000}>
        <Modal size='small' open={visible}>
          <Modal.Header>تغيير حالة طلب توصيل بضاعة</Modal.Header>
          <Modal.Content>
            <div>
              <div style={{ margin: '1rem' }} className='form-margin'>
                <Form style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
                  <Form.Field>
                    <label>المستخدم</label>
                    <Dropdown
                      placeholder='اختر الحالة'
                      fluid
                      selection
                      search
                      name='status'
                      value={selectedStatus}
                      options={filteredStatusOptions}
                      onChange={(e, data) => setSelectedStatus(data.value)}
                      className='table-dropdown'
                      style={{ marginLeft: '0.5rem' }}
                      disabled={!filteredStatusOptions || !filteredStatusOptions.length}
                      error={Boolean(error)}
                    />
                  </Form.Field>

                  {Boolean(error) && <Message negative content={error} />}
                </Form>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='blue-button' onClick={(e) => onSubmitHandler(e)}>
              تغيير حالة الطلب
            </Button>
            <Button onClick={clearStates}>اغلاق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Icon} from 'semantic-ui-react';
import {humanizeIsoString} from '../../../helpers/isoDurationHumanize';
import TicketAssignForm from './TicketAssignForm';
import {customerTicketsApi, filesApi} from "../../../api/api";

const DataBox = ({title, icon = null, data, ltr = false}) => {
  return (<div className='data-box'>
    <div className='title-container'>
      {icon && <Icon name={icon} size='small'/>}
      <h5 className='title'>{title}</h5>
    </div>
    <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data}</h5>
  </div>);
};

const SysTicketInfoPanel = ({ticket, onTicketAssigned}) => {
  const [attachmentPaths, setAttachmentPaths] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const loadTicketAttachments = async (id) => {
    const paths = await customerTicketsApi.getAttachmentPaths(id);
    setAttachmentPaths(paths.data);
  }

  useEffect(() => {
    loadTicketAttachments(ticket.id);
  }, []);

  const downloadFile = async (fileName) => {
    if (downloading) return;
    setDownloading(true);
    await filesApi.downloadFile(fileName);
    setDownloading(false);
  }

  return (<>
    <DataBox title='العميل' icon='building' data={ticket.fullName}/>
    {ticket.branchLocation && <DataBox title='الفرع' icon='building' data={ticket.branchLocation}/>}
    <DataBox title='نوع التذكره' icon='ticket' data={ticket.typeName}/>
    <DataBox title='مستوى الأهمية' icon='ticket' data={ticket.urgencyLevelName}/>
    <DataBox title='منشئ التذكرة' icon='user circle' data={ticket.createdBy}/>
    <DataBox title='بتاريخ' ltr icon='calendar' data={moment(ticket.createdAt).format('YYYY/MM/DD, hh:mm A')}/>
    {!Boolean(ticket.closedBy) ? (<DataBox
      title='وقت التذكرة'
      icon='clock'
      data={humanizeIsoString(moment.duration(moment(new Date()).diff(moment(ticket.createdAt), 'minutes'), 'minutes').toISOString())}
    />) : (<DataBox
      title='وقت التذكرة'
      icon='clock'
      data={humanizeIsoString(moment.duration(moment(ticket.closedAt).diff(moment(ticket.createdAt), 'minutes'), 'minutes').toISOString())}
    />)}
    {ticket.closedBy && (<>
      <DataBox title='مغلق التذكرة' icon='user circle' data={ticket.closedBy}/>
      <DataBox
        title='بتاريخ'
        ltr
        icon='calendar check'
        data={moment(ticket.closedAt).format('YYYY/MM/DD, hh:mm A')}
      />
    </>)}
    {!!ticket.ticketOpenerName && <DataBox title='اسم المتصل' icon='user circle' data={ticket.ticketOpenerName}/>}

    {!!ticket.ticketOpenerPhone && <DataBox title='هاتف المتصل' icon='phone' ltr data={ticket.ticketOpenerPhone}/>}

    {attachmentPaths.length > 0 && <DataBox title='المرفقات' icon='attach' data={<div style={{direction: 'ltr'}}>
      {attachmentPaths.map(fileName => (
        <div style={downloading ? {color: 'lightgray'} : {}} className="attachment-path"
             onClick={() => downloadFile(fileName)}>
          {fileName}
        </div>
      ))}
    </div>}/>}

    <TicketAssignForm ticket={ticket} onTicketAssigned={onTicketAssigned}/>
  </>);
};

export default React.memo(SysTicketInfoPanel);

import React from 'react';
import { motion } from 'framer-motion';
import { Card } from 'semantic-ui-react';
import SettingsCard from '../Settings/SettingsList/SettingsCard';
import PermissionCheck from '../Reusable/PermissionCheck';
import p, { hasPermission, hasPermissions } from '../../helpers/permissions';

const TicketsMenu = () => {
  return (
    <div className='settings-list'>
      <Card.Group className='settings-card-group max-width-1200'>
        <PermissionCheck
          permissions={[
            p.VIEW_MAINTENANCE_TICKETS,
            p.VIEW_ALL_MAINTENANCE_TICKETS,
            p.CREATE_SYSTEM_TICKET,
            p.CREATE_TRACKING_VEHICLE_TICKET,
            p.VIEW_ALL_ASSET_MAINTENANCE_TICKETS,
            p.VIEW_ASSET_MAINTENANCE_TICKETS,
            p.CREATE_ASSET_MAINTENANCE_TICKETS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='عرض جميع التذاكر' iconName='ticket' url='/sys-tickets' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={p.CREATE_SYSTEM_TICKET}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='إضافة تذكرة جديدة' iconName='plus square' url='/sys-tickets/add' />
          </motion.div>
        </PermissionCheck>

        <PermissionCheck permissions={p.CREATE_TRACKING_VEHICLE_TICKET}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='إضافة تذكرة صيانة أجهزة تتبع' iconName='plus square' url='/maintenanceTicket/add' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.CREATE_REPORT_WITHOUT_TICKET,
            p.EDIT_MAINTENANCE_REPORTS,
            p.VIEW_MAINTENANCE_REPORTS,
            p.VIEW_ALL_MAINTENANCE_REPORTS,
            p.DELETE_MAINTENANCE_REPORT,
            p.CREATE_TRACKING_VEHICLE_TICKET,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='عرض تقارير صيانة أجهزة التتبع' iconName='hdd' url='/maintenanceForm' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={p.CREATE_ASSET_MAINTENANCE_TICKETS}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='إضافة تذكرة صيانة جهاز لوحي' iconName='plus square' url='/assetTickets/add' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_ALL_MERCHANDISE_DELIVERY_REQUESTS,
            p.VIEW_MERCHANDISE_DELIVERY_REQUESTS,
            p.CREATE_MERCHANDISE_DELIVERY_REQUEST,
            p.EDIT_MERCHANDISE_DELIVERY_REQUEST,
            p.DELETE_MERCHANDISE_DELIVERY_REQUESTS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='طلبات تسليم البضائع' iconName='shipping' url='/merchandiseDeliveryRequests' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={p.VIEW_EVALUATIONS}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='تقييم العملاء على للتذاكر' iconName='star' url='/evaluation/view' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_ASSURANCE_FORMS,
            p.ADD_EDIT_ASSURANCE_FORMS,
            p.CHANGE_ASSURANCE_FORMS_STATUS,
            p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
            p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
            p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
            p.VIEW_STAMP_RECEIPT_FORMS,
            p.ADD_EDIT_STAMP_RECEIPT_FORMS,
            p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
            p.ADD_EXPENSES_FORM,
            p.ADD_TRANSPORT_EXPENSES_FORM,
            p.PAYMENT_OF_FINANCIAL_DUES,
            p.REJECT_APPROVE_EXPENSES_FORM,
            p.REJECT_APPROVE_TRANSPORT_EXPENSES_FORM,
            p.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL,
            p.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='الخدمات المالية' iconName='dollar sign' url='/FinancialServices' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
            p.VIEW_CUSTOMER_VISITS_SCHEDULE,
            p.ADD_SCHEDULE_VISIT,
            p.DELETE_SCHEDULE_VISIT,
            p.ADD_VISIT,
            p.EDIT_VISIT_DATE,
            p.CLOSE_VISIT_WITHOUT_REPORT,
            p.DELETE_VISIT,
            p.ADD_VISIT_REPORT,
            p.VIEW_VISIT_REPORT,
            p.DELETE_VISIT_REPORT,
            p.EDIT_VISIT_REPORT,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='الزيارات' iconName='clipboard list' url='/schedule' />
          </motion.div>
        </PermissionCheck>
      </Card.Group>
    </div>
  );
};

export default TicketsMenu;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Dropdown, Transition, Modal } from 'semantic-ui-react';
import { hasPermission, hasPermissions } from '../../../helpers/permissions';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi } from './../../../api/api';
import permissions from './../../../helpers/permissions';

const Branches = () => {
  const router = useHistory();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(location.state?.id);
  const [currentBranchId, setCurrentBranchId] = useState(null);
  const [deleteBranchErrPopup, setDeleteBranchErrPopup] = useState(false);
  const [deleteBranchPopup, setDeleteBranchPopup] = useState(false);
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const handleDeleteCustomer = async (id) => {
    setCurrentBranchId(id);
    setDeleteBranchPopup(true);
  };
  const handleDeleteBranchConfirm = async () => {
    try {
      await customersApi.deleteBranch(currentBranchId);
      setRerenderFlag(!rerenderFlag);
      setDeleteBranchPopup(false);
    } catch (e) {
      setDeleteBranchErrPopup(true);
      console.log('Error deleting customer...', e);
    }
  };

  const tableHeaders = [
    {
      displayName: 'اسم الفرع',
      fieldName: 'branchName',
      dbFieldName: 'branch_name',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'موقع الفرع',
      fieldName: 'location',
      dbFieldName: 'location',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'اسم العميل',
      fieldName: 'fullName',
      dbFieldName: 'full_name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
  ];
  const tableActions = [
    ...(hasPermissions([
      permissions.VIEW_CUSTOMER_BRANCHES,
      permissions.EDIT_CUSTOMER_BRANCH,
      permissions.DELETE_CUSTOMER_BRANCH,
      permissions.ADD_CUSTOMER_BRANCH,
    ])
      ? [
          {
            actionType: 'view',
            actionUrl: `/customers/branches/view-branch`,
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_CUSTOMER_BRANCH)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/customers/branches/edit-branch',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_CUSTOMER_BRANCH)
      ? [
          {
            actionType: 'custom',
            onClick: handleDeleteCustomer,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];
  useEffect(async () => {
    const customers = await customersApi.getCustomersDropDown();
    const customersOptions = customers.data.map((item) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
    setClients(customersOptions);
  }, []);

  return (
    <>
      <PageCardContainer>
        <div className='table-title-container merchandise-table-title-container'>
          <div>
            <h3>الفروع</h3>
            {/* <PermissionCheck permissions={permissions.CREATE_MERCHANDISE_DELIVERY_REQUEST}> */}
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              onClick={() => {
                if (selectedClient)
                  router.push({
                    pathname: '/customers/add-branches',
                    state: { customerName: clients.filter((cus) => cus.value === selectedClient) },
                  });
                else {
                  console.log('يرجى اختيار العميل');
                }
              }}
            >
              <Icon name='plus' className='blue-buton-icon' />
              اضافة فرع للعميل
            </Button>
            {/* </PermissionCheck> */}
          </div>
        </div>
        <div className='title-container' style={{ margin: '1rem', backgroundColor: 'white', zIndex: '100' }}>
          <Dropdown
            placeholder='اختر العميل'
            fluid
            selection
            search
            name='status'
            value={selectedClient}
            options={clients}
            onChange={(e, data) => setSelectedClient(data.value)}
            className='table-dropdown'
            style={{ marginLeft: '0.5rem', zIndex: 100 }}
          />
        </div>
        <DataTable
          tableTitle='فروع العميل'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={customersApi.getCustomerBranches}
          reloadTrigger={[selectedClient, rerenderFlag]}
          optionalQsParams={{ id: selectedClient }}
        />
      </PageCardContainer>
      <Transition visible={deleteBranchPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteBranchPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <p>هل انت متأكد من الحذف</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => handleDeleteBranchConfirm()}>
              نعم
            </Button>
            <Button color='green' onClick={() => setDeleteBranchPopup(false)}>
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
      <Transition visible={deleteBranchErrPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteBranchErrPopup}>
          <Modal.Header>حدث خطأ</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>حدث خطأ اثناء حذف الفرع</p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setDeleteBranchErrPopup(false)}>موافق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  );
};

export default Branches;

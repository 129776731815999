import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LookupsGroups from './components/Settings/Lookups/LookupsGroups';
import LookupsDetails from './components/Settings/Lookups/LookupsDetails';
import Roles from './components/Settings/Roles/Roles';
import SidebarPage from './components/Layout/SidebarPage';
import SettingsList from './components/Settings/SettingsList/SettingsList';
import AddLookupForm from './components/Settings/Lookups/AddLookupForm';
import EditLookupForm from './components/Settings/Lookups/EditLookupForm';
import AddRoleForm from './components/Settings/Roles/AddRoleForm';
import EditRoleForm from './components/Settings/Roles/EditRoleForm';
import ViewRoleDetails from './components/Settings/Roles/ViewRoleDetails';
import Login from './components/UserAuth/Login';
import Signup from './components/UserAuth/Signup';
import Users from './components/Settings/Users/Users';
import AddUserForm from './components/Settings/Users/AddUserForm';
import EditUserForm from './components/Settings/Users/EditUserForm';
import ViewUserDetails from './components/Settings/Users/ViewUserDetails';
import EditPasswordForm from './components/Settings/Users/EditPasswordForm';
import Customers from './components/Settings/Customers/Customers';
import AddCustomer from './components/Settings/Customers/AddCustomer';
import EditCustomer from './components/Settings/Customers/EditCustomer';
import ViewCustomerDetails from './components/Settings/Customers/ViewCustomerDetails';
import AddMaintenanceForm from './components/Tickets/MaintenanceForm/AddMaintenanceForm';
import EditMaintenanceForm from './components/Tickets/MaintenanceForm/EditMaintenanceForm';
import Vehicles from './components/Settings/Vehicles/Vehicles';
import AddVehicle from './components/Settings/Vehicles/AddVehicle';
import EditVehicle from './components/Settings/Vehicles/EditVehicle';
import TrackingUnits from './components/Settings/TrackingUnits/TrackingUnits';
import AddTrackingUnit from './components/Settings/TrackingUnits/AddTrackingUnit';
import EditTrackingUnit from './components/Settings/TrackingUnits/EditTrackingUnit';
import ViewTrackingUnitDetails from './components/Settings/TrackingUnits/ViewTrackingUnitDetails';
import TicketsMenu from './components/Tickets/TicketsMenu';
import SysTicketAddForm from './components/Tickets/SystemMaintenanceTickets/SysTicketAddForm/SysTicketAddForm';
import MaintenanceForm from './components/Tickets/MaintenanceForm/MaintenanceForm';
import ViewMaintenanceFormDetails from './components/Tickets/MaintenanceForm/ViewMaintenanceFormDetails';
import PrintMaintenanceForm from './components/Tickets/MaintenanceForm/PrintMaintenanceForm';
import AddMaintenanceTicket from './components/Tickets/SystemMaintenanceTickets/MaintenanceTicket/AddMaintenanceTicket';
import EditMaintenanceTicket from './components/Tickets/SystemMaintenanceTickets/MaintenanceTicket/EditMaintenanceTicket';
import NotFound from './components/NotFound/NotFound';
import PermissionCheck from './components/Reusable/PermissionCheck';
import p from './helpers/permissions';
import CustomerTicketsContainer from './components/Tickets/CustomerTicketsContainer';
import Dashboard from './components/Dashboard/Dashboard';
import Departments from './components/Settings/Departments/Departments';
import { useSelector } from 'react-redux';
import AddDepartmentForm from './components/Settings/Departments/AddDepartmentForm';
import EditDepartmentsForm from './components/Settings/Departments/EditDepartmentsForm';
import Teams from './components/Settings/Teams/Teams';
import EditTeamForm from './components/Settings/Teams/EditTeamForm';
import AddTeamForm from './components/Settings/Teams/AddTeamForm';
import AssignTeamMembers from './components/Settings/Teams/AssignTeamMembers';
import Contracts from './components/Settings/Contracts/Contracts';
import AddContractsForm from './components/Settings/Contracts/AddContractsForm';
import EditContractsForm from './components/Settings/Contracts/EditContractsForm';
import ViewContract from './components/Settings/Contracts/ViewContract';
import AssignTeamCustomers from './components/Settings/Teams/AssignTeamCustomers';
import Assets from './components/Settings/Assets/Assets';
import AddAsset from './components/Settings/Assets/AddAsset';
import ViewAssetDetails from './components/Settings/Assets/ViewAssetDetails';
import EditAsset from './components/Settings/Assets/EditAsset';
import AssetTicketForm from './components/Tickets/SystemMaintenanceTickets/AssetTicket/AssetTicketForm';
import ContractAssets from './components/Settings/Contracts/ContractAssets';
import TeamAssignment from './components/Settings/TeamAssignment/TeamAssignment';
import TicketEvaluation from './components/TicketEvaluation/TicketEvaluation';
import EvaluationTable from './components/TicketEvaluation/EvaluationTable';
import UsersTrackingAssets from './components/Settings/UserTrackingAssets/UsersTrackingAssets';
import AddUserTrackingAsset from './components/Settings/UserTrackingAssets/AddUserTrackingAsset';
import EditUserTrackingAsset from './components/Settings/UserTrackingAssets/EditUserTrackingAsset';
import UsersMap from './components/UsersMap/UsersMap';
import MerchandiseDeliveryRequests from './components/Tickets/MerchandiseDeliveryRequests/MerchandiseDeliveryRequests';
import AddMerchandiseDeliveryRequest from './components/Tickets/MerchandiseDeliveryRequests/AddMerchandiseDeliveryRequest';
import EditMerchandiseDeliveryRequest from './components/Tickets/MerchandiseDeliveryRequests/EditMerchandiseDeliveryRequest';
import ViewMerchandiseDeliveryRequestDetails from './components/Tickets/MerchandiseDeliveryRequests/ViewMerchandiseDeliveryRequestDetails';
import PrintMerchandiseDeliveryRequest from './components/Tickets/MerchandiseDeliveryRequests/PrintMerchandiseDeliveryRequest';
import Playback from './components/Playback/Playback';
import ViewOtherMaintenanceItems from './components/Settings/OtherMaintenanceItems/ViewOtherMaintenanceItems';
import AddOtherMaintenanceItemsForm from './components/Settings/OtherMaintenanceItems/AddOtherMaintenanceItemsForm';
import EditOtherMaintenanceItemsForm from './components/Settings/OtherMaintenanceItems/EditOtherMaintenanceItemsForm';
import FinancialServicesCards from './components/FinancialServices/FinancialServicesCards';
import AssuranceForm from './components/FinancialServices/AssuranceForm/AssuranceForm';
import AssuranceFormList from './components/FinancialServices/AssuranceForm/AssuranceFormList';
import EditAssuranceForm from './components/FinancialServices/AssuranceForm/EditAssuranceForm';
import PrintAssuranceForm from './components/FinancialServices/AssuranceForm/PrintAssuranceForm';
import AssuranceFormView from './components/FinancialServices/AssuranceForm/AssuranceFormView';
import AssuranceEditFormList from './components/FinancialServices/AssuranceEditForm/AssuranceEditFormList';
import AssuranceEditRequestForm from './components/FinancialServices/AssuranceEditForm/AssuranceEditRequestForm';
import EditAssuranceEditRequest from './components/FinancialServices/AssuranceEditForm/EditAssuranceEditRequest';
import AssuranceEditPrint from './components/FinancialServices/AssuranceEditForm/AssuranceEditPrint';
import ViewAssuranceEditRequest from './components/FinancialServices/AssuranceEditForm/ViewAssuranceEditRequest';
import StampReceiptList from './components/FinancialServices/StampReceiptForm/StampReceipList';
import StampReceiptForm from './components/FinancialServices/StampReceiptForm/StampReceiptForm';
import EditStampReceiptForm from './components/FinancialServices/StampReceiptForm/EditStampReceiptForm';
import StampReceiptPrint from './components/FinancialServices/StampReceiptForm/StampReceiptPrint';
import ViewStampReceipt from './components/FinancialServices/StampReceiptForm/ViewStampReceipt';
import FinancialSettings from './components/FinancialServices/FinancialSettings/FinancialSettings';
import ScheduleVisits from './components/CustomerVisits/ScheduleVisits';
import AddMaintenanceContract from './components/CustomerVisits/MaintenanceContract/AddMaintenanceContract';
import Branches from './components/Settings/Customers/Branches';
import AddBranches from './components/Settings/Customers/AddBranches';
import ViewBranch from './components/Settings/Customers/ViewBranch';
import EditBranch from './components/Settings/Customers/EditBranch';
import ViewCustomerMaintenanceVisitCalendar from './components/CustomerVisits/CalendarVisits/ViewCustomerMaintenanceVisitCalendar';
import GenerateVisitReport from './components/CustomerVisits/VisitMaintenanceReport/GenerateVisitReport';
import ViewMaintenanceReport from './components/CustomerVisits/VisitMaintenanceReport/ViewMaintenanceReport';
import ScheduleVisitsService from './components/CustomerVisits/ScheduleVisitsService';
import ViewAllMaintenanceVisitCalendar from './components/CustomerVisits/CalendarVisits/ViewAllMaintenanceVisitCalendar';
import ViewAllVisitsReports from './components/CustomerVisits/VisitMaintenanceReport/ViewAllVisitsReports';
import EditVisitReport from './components/CustomerVisits/VisitMaintenanceReport/EditVisitReport';
import PrintVisitReport from './components/CustomerVisits/VisitMaintenanceReport/PrintVisitReport';
import ExpensesFormsList from './components/FinancialServices/ExpensesForms/Expenses/ExpensesFormsList';
import AddExpensesForm from './components/FinancialServices/ExpensesForms/Expenses/AddExpensesForm.js';
import ExpensesForm from './components/FinancialServices/ExpensesForms/Expenses/ExpensesForm.js';
import EditExpensesForm from './components/FinancialServices/ExpensesForms/Expenses/EditExpensesForm';
import TransportExpensesFormsList from './components/FinancialServices/ExpensesForms/TransportExpenses/TransportExpensesFormsList';
import AddTransportExpensesForm from './components/FinancialServices/ExpensesForms/TransportExpenses/AddTransportExpensesForm.js';
import TransportExpensesForm from './components/FinancialServices/ExpensesForms/TransportExpenses/TransportExpensesForm.js';
import EditTransportExpensesForm from './components/FinancialServices/ExpensesForms/TransportExpenses/EditTransportExpensesForm';
function App() {
  const reduxState = useSelector((x) => x.user).user;
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/signup' component={Signup} />
      <Route exact path='/evaluation'>
        <TicketEvaluation />
      </Route>
      {!(reduxState.isLoggedIn || sessionStorage.getItem('user')) && window.location.pathname !== '/login' && (
        <Redirect to='/login' />
      )}
      <Route exact path='/maintenanceForm/print' component={PrintMaintenanceForm} />
      <Route exact path='/merchandiseDeliveryRequests/print' component={PrintMerchandiseDeliveryRequest} />
      <Route exact path='/FinancialServices/printAssuranceForm' component={PrintAssuranceForm} />
      <Route exact path='/FinancialServices/printAssuranceEditForm' component={AssuranceEditPrint} />
      <Route exact path='/FinancialServices/printStampReceipt' component={StampReceiptPrint} />
      <Route exact path='/client-visits/printVisitReport' component={PrintVisitReport} />
      <Route exact path='/FinancialServices/printExpenseForm' component={ExpensesForm} />
      <Route exact path='/FinancialServices/printTransportExpenseForm' component={TransportExpensesForm} />

      <SidebarPage>
        <Switch>
          <Route exact path={['/', '/dashboard']}>
            <PermissionCheck permissions={p.VIEW_DASHBOARD}>
              <Dashboard />
            </PermissionCheck>
          </Route>
          <Route exact path={'/settings'} component={SettingsList} />
          <Route exact path='/lookupsGroups'>
            <PermissionCheck permissions={[p.VIEW_LOOKUP, p.EDIT_LOOKUP, p.DELETE_LOOKUP, p.CREATE_LOOKUP]}>
              <LookupsGroups />
            </PermissionCheck>
          </Route>
          <Route exact path='/lookupsDetails'>
            <PermissionCheck permissions={p.VIEW_LOOKUP}>
              <LookupsDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/lookups/add'>
            <PermissionCheck permissions={p.CREATE_LOOKUP}>
              <AddLookupForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/lookups/edit'>
            <PermissionCheck permissions={p.EDIT_LOOKUP}>
              <EditLookupForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles'>
            <PermissionCheck permissions={[p.VIEW_ROLES, p.CREATE_ROLE, p.EDIT_ROLE, p.DELETE_ROLE]}>
              <Roles />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles/view'>
            <PermissionCheck permissions={p.VIEW_ROLES}>
              <ViewRoleDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles/add'>
            <PermissionCheck permissions={p.CREATE_ROLE}>
              <AddRoleForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/roles/edit'>
            <PermissionCheck permissions={p.EDIT_ROLE}>
              <EditRoleForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users'>
            <PermissionCheck
              permissions={[
                p.VIEW_USERS,
                p.VIEW_ALL_USERS,
                p.CHANGE_USER_PASSWORD,
                p.CREATE_USER,
                p.EDIT_USER,
                p.DELETE_USER,
              ]}
            >
              <Users />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/add'>
            <PermissionCheck permissions={p.CREATE_USER}>
              <AddUserForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/edit'>
            <PermissionCheck permissions={p.EDIT_USER}>
              <EditUserForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/users/editPassword'>
            <EditPasswordForm />
          </Route>
          <Route exact path='/users/view'>
            <PermissionCheck permissions={[p.VIEW_USERS, p.VIEW_ALL_USERS]}>
              <ViewUserDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers'>
            <PermissionCheck
              permissions={[p.CREATE_CLIENT, p.VIEW_ALL_CLIENTS, p.VIEW_CLIENTS, p.DELETE_CLIENT, p.EDIT_CLIENT]}
            >
              <Customers />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/add'>
            <PermissionCheck permissions={p.CREATE_CLIENT}>
              <AddCustomer />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/edit'>
            <PermissionCheck permissions={p.EDIT_CLIENT}>
              <EditCustomer />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/view'>
            <PermissionCheck permissions={[p.VIEW_ALL_CLIENTS, p.VIEW_CLIENTS]}>
              <ViewCustomerDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/vehicles'>
            <PermissionCheck
              permissions={[p.VIEW_VEHICLES, p.VIEW_ALL_VEHICLES, p.EDIT_VEHICLE, p.DELETE_VEHICLE, p.CREATE_VEHICLE]}
            >
              <Vehicles />
            </PermissionCheck>
          </Route>
          <Route exact path='/vehicles/add'>
            <PermissionCheck permissions={p.CREATE_VEHICLE}>
              <AddVehicle />
            </PermissionCheck>
          </Route>
          <Route exact path='/vehicles/edit'>
            <PermissionCheck permissions={p.EDIT_VEHICLE}>
              <EditVehicle />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits'>
            <PermissionCheck
              permissions={[
                p.VIEW_TRACKING_UNITS,
                p.VIEW_ALL_TRACKING_UNITS,
                p.CREATE_TRACKING_UNIT,
                p.EDIT_TRACKING_UNIT,
              ]}
            >
              <TrackingUnits />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits/add'>
            <PermissionCheck permissions={p.CREATE_TRACKING_UNIT}>
              <AddTrackingUnit />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits/edit'>
            <PermissionCheck permissions={p.EDIT_TRACKING_UNIT}>
              <EditTrackingUnit />
            </PermissionCheck>
          </Route>
          <Route exact path='/trackingUnits/view'>
            <PermissionCheck permissions={[p.VIEW_TRACKING_UNITS, p.VIEW_ALL_TRACKING_UNITS]}>
              <ViewTrackingUnitDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/home'>
            <TicketsMenu />
          </Route>
          <Route exact path='/sys-tickets'>
            <PermissionCheck
              permissions={[
                p.VIEW_MAINTENANCE_REPORTS,
                p.VIEW_MAINTENANCE_TICKETS,
                p.VIEW_ALL_MAINTENANCE_REPORTS,
                p.VIEW_ALL_MAINTENANCE_TICKETS,
                p.CREATE_REPORT_FROM_TICKET,
                p.CREATE_SYSTEM_TICKET,
                p.EDIT_MAINTENANCE_TICKETS,
                p.CREATE_TRACKING_VEHICLE_TICKET,
                p.DELETE_TICKET,
                p.VIEW_ASSET_MAINTENANCE_TICKETS,
                p.CREATE_ASSET_MAINTENANCE_TICKETS,
              ]}
            >
              <CustomerTicketsContainer />
            </PermissionCheck>
          </Route>
          <Route exact path='/sys-tickets/add'>
            <PermissionCheck permissions={[p.CREATE_SYSTEM_TICKET, p.EDIT_MAINTENANCE_TICKETS]}>
              <SysTicketAddForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm'>
            <PermissionCheck
              permissions={[
                p.VIEW_MAINTENANCE_REPORTS,
                p.CREATE_REPORT_WITHOUT_TICKET,
                p.DELETE_MAINTENANCE_REPORT,
                p.EDIT_MAINTENANCE_REPORTS,
                p.VIEW_ALL_MAINTENANCE_REPORTS,
              ]}
            >
              <MaintenanceForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm/add'>
            <PermissionCheck permissions={[p.CREATE_REPORT_WITHOUT_TICKET, p.CREATE_REPORT_FROM_TICKET]}>
              <AddMaintenanceForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm/edit'>
            <PermissionCheck permissions={p.EDIT_MAINTENANCE_REPORTS}>
              <EditMaintenanceForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceForm/view'>
            <PermissionCheck permissions={[p.VIEW_ALL_MAINTENANCE_REPORTS, p.VIEW_MAINTENANCE_REPORTS]}>
              <ViewMaintenanceFormDetails />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceTicket/add'>
            <PermissionCheck permissions={p.CREATE_TRACKING_VEHICLE_TICKET}>
              <AddMaintenanceTicket />
            </PermissionCheck>
          </Route>
          <Route exact path='/maintenanceTicket/edit'>
            <PermissionCheck permissions={p.EDIT_MAINTENANCE_TICKETS}>
              <EditMaintenanceTicket />
            </PermissionCheck>
          </Route>
          <Route exact path='/departments'>
            <PermissionCheck permissions={p.VIEW_DEPARTMENTS}>
              <Departments />
            </PermissionCheck>
          </Route>
          <Route exact path='/departments/add'>
            <PermissionCheck permissions={p.CREATE_DEPARTMENT}>
              <AddDepartmentForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/departments/edit'>
            <PermissionCheck permissions={p.EDIT_DEPARTMENT}>
              <EditDepartmentsForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/teams'>
            <PermissionCheck permissions={p.VIEW_TEAMS}>
              <Teams />
            </PermissionCheck>
          </Route>
          <Route exact path='/teams/add'>
            <PermissionCheck permissions={p.CREATE_TEAM}>
              <AddTeamForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/teams/edit'>
            <PermissionCheck permissions={p.EDIT_TEAM}>
              <EditTeamForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/teams/teamMembers'>
            <PermissionCheck permissions={p.EDIT_TEAM}>
              <AssignTeamMembers />
            </PermissionCheck>
          </Route>
          <Route exact path='/teams/teamCustomers'>
            <PermissionCheck permissions={p.EDIT_TEAM}>
              <AssignTeamCustomers />
            </PermissionCheck>
          </Route>
          <Route exact path='/contracts'>
            <PermissionCheck permissions={[p.VIEW_CONTRACTS, p.VIEW_ALL_CONTRACTS]}>
              <Contracts />
            </PermissionCheck>
          </Route>
          <Route exact path='/contracts/add'>
            <PermissionCheck permissions={p.CREATE_CONTRACT}>
              <AddContractsForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/contracts/edit'>
            <PermissionCheck permissions={p.EDIT_CONTRACT}>
              <EditContractsForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/contracts/view'>
            <PermissionCheck permissions={p.VIEW_CONTRACTS}>
              <ViewContract />
            </PermissionCheck>
          </Route>
          <Route exact path='/contracts/linkContractAssets'>
            <PermissionCheck permissions={[p.CREATE_CONTRACT, p.EDIT_CONTRACT]}>
              <ContractAssets />
            </PermissionCheck>
          </Route>
          <Route exact path='/Assets'>
            <PermissionCheck permissions={[p.VIEW_ASSETS, p.VIEW_ALL_ASSETS]}>
              <Assets />
            </PermissionCheck>
          </Route>
          <Route exact path='/Assets/add'>
            <PermissionCheck permissions={p.CREATE_ASSET}>
              <AddAsset />
            </PermissionCheck>
          </Route>
          <Route exact path='/Assets/edit'>
            <PermissionCheck permissions={p.EDIT_ASSET}>
              <EditAsset />
            </PermissionCheck>
          </Route>
          <Route exact path='/Assets/view'>
            <PermissionCheck permissions={[p.VIEW_ASSETS, p.VIEW_ALL_ASSETS]}>
              <ViewAssetDetails />
            </PermissionCheck>
          </Route>
          <Route path='/assetTickets/add'>
            <PermissionCheck permissions={p.CREATE_ASSET_MAINTENANCE_TICKETS}>
              <AssetTicketForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/teamAssignment'>
            <PermissionCheck permissions={[p.EDIT_TEAM_ASSIGNMENT_DEFAULTS]}>
              <TeamAssignment />
            </PermissionCheck>
          </Route>
          <Route exact path='/evaluation/view'>
            <PermissionCheck permissions={p.VIEW_EVALUATIONS}>
              <EvaluationTable />
            </PermissionCheck>
          </Route>
          <Route exact path='/usersTrackingAssets'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_USERS_TRACKING_ASSETS,
                p.VIEW_USERS_TRACKING_ASSETS_BASED_ON_USER_DEPARTMENTS,
                p.EDIT_USERS_TRACKING_ASSET,
                p.CREATE_USERS_TRACKING_ASSET,
                p.DELETE_USERS_TRACKING_ASSET,
                p.LINK_TRACKING_ASSET_WITH_USER,
                p.UNLINK_TRACKING_ASSET_FROM_USER,
              ]}
            >
              <UsersTrackingAssets />
            </PermissionCheck>
          </Route>
          <Route exact path='/usersTrackingAssets/add'>
            <PermissionCheck permissions={p.CREATE_USERS_TRACKING_ASSET}>
              <AddUserTrackingAsset />
            </PermissionCheck>
          </Route>
          <Route exact path='/usersTrackingAssets/edit'>
            <PermissionCheck permissions={p.EDIT_USERS_TRACKING_ASSET}>
              <EditUserTrackingAsset />
            </PermissionCheck>
          </Route>
          <Route exact path='/usersMap'>
            <PermissionCheck permissions={p.VIEW_USERS_TRACKING_MAP}>
              <UsersMap />
            </PermissionCheck>
          </Route>
          <Route exact path='/merchandiseDeliveryRequests'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_MERCHANDISE_DELIVERY_REQUESTS,
                p.VIEW_MERCHANDISE_DELIVERY_REQUESTS,
                p.CREATE_MERCHANDISE_DELIVERY_REQUEST,
                p.EDIT_MERCHANDISE_DELIVERY_REQUEST,
                p.DELETE_MERCHANDISE_DELIVERY_REQUESTS,
              ]}
            >
              <MerchandiseDeliveryRequests />
            </PermissionCheck>
          </Route>
          <Route exact path='/merchandiseDeliveryRequests/add'>
            <PermissionCheck permissions={p.CREATE_MERCHANDISE_DELIVERY_REQUEST}>
              <AddMerchandiseDeliveryRequest />
            </PermissionCheck>
          </Route>
          <Route exact path='/merchandiseDeliveryRequests/edit'>
            <PermissionCheck permissions={p.EDIT_MERCHANDISE_DELIVERY_REQUEST}>
              <EditMerchandiseDeliveryRequest />
            </PermissionCheck>
          </Route>
          <Route exact path='/merchandiseDeliveryRequests/view'>
            <PermissionCheck
              permissions={[p.VIEW_ALL_MERCHANDISE_DELIVERY_REQUESTS, p.VIEW_MERCHANDISE_DELIVERY_REQUESTS]}
            >
              <ViewMerchandiseDeliveryRequestDetails />
            </PermissionCheck>
          </Route>
          <Route path='/playback'>
            <PermissionCheck permissions={(p.VIEW_PLAYBACK, p.VIEW_USERS_TRACKING_MAP)}>
              <Playback />
            </PermissionCheck>
          </Route>
          <Route path='/viewOtherMaintenanceItems'>
            <PermissionCheck permissions={p.VIEW_MAINTENANCE_TYPES}>
              <ViewOtherMaintenanceItems />
            </PermissionCheck>
          </Route>
          <Route path='/addOtherMaintenanceItemsForm'>
            <PermissionCheck permissions={p.VIEW_MAINTENANCE_TYPES}>
              <AddOtherMaintenanceItemsForm />
            </PermissionCheck>
          </Route>
          <Route path='/editOtherMaintenanceItems'>
            <PermissionCheck permissions={p.EDIT_MAINTENANCE_TYPES}>
              <EditOtherMaintenanceItemsForm />
            </PermissionCheck>
          </Route>
          <Route path='/assuranceForm/edit'>
            <PermissionCheck permissions={p.ADD_EDIT_ASSURANCE_FORMS}>
              <EditAssuranceForm />
            </PermissionCheck>
          </Route>
          <Route path='/assuranceEditRequest/edit'>
            <PermissionCheck permissions={p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS}>
              <EditAssuranceEditRequest />
            </PermissionCheck>
          </Route>
          <Route path='/stampReceiptForm/edit'>
            <PermissionCheck permissions={p.ADD_EDIT_STAMP_RECEIPT_FORMS}>
              <EditStampReceiptForm />
            </PermissionCheck>
          </Route>
          <Route path='/assuranceForm/view'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_ASSURANCE_FORMS,
                p.VIEW_ASSURANCE_FORMS,
                p.ADD_EDIT_ASSURANCE_FORMS,
                p.CHANGE_ASSURANCE_FORMS_STATUS,
              ]}
            >
              <AssuranceFormView />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/settings'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_STAMP_RECEIPT_FORMS,
                p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
                p.VIEW_STAMP_RECEIPT_FORMS,
                p.ADD_EDIT_STAMP_RECEIPT_FORMS,
              ]}
            >
              <FinancialSettings />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/AddAssuranceForm'>
            <PermissionCheck permissions={p.ADD_EDIT_ASSURANCE_FORMS}>
              <AssuranceForm />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/AddAssuranceEditRequest'>
            <PermissionCheck permissions={p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS}>
              <AssuranceEditRequestForm />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/AddStampReceipt'>
            <PermissionCheck permissions={p.ADD_EDIT_STAMP_RECEIPT_FORMS}>
              <StampReceiptForm />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/ViewAssuranceFormsList'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_ASSURANCE_FORMS,
                p.VIEW_ASSURANCE_FORMS,
                p.ADD_EDIT_ASSURANCE_FORMS,
                p.CHANGE_ASSURANCE_FORMS_STATUS,
              ]}
            >
              <AssuranceFormList />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/AssuranceEditFormList'>
            <PermissionCheck
              permissions={[
                p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
                p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
                p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
                p.VIEW_ALL_ASSURANCE_EDIT_FORMS,
              ]}
            >
              <AssuranceEditFormList />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices/stampReceiptList'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_STAMP_RECEIPT_FORMS,
                p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
                p.VIEW_STAMP_RECEIPT_FORMS,
                p.ADD_EDIT_STAMP_RECEIPT_FORMS,
              ]}
            >
              <StampReceiptList />
            </PermissionCheck>
          </Route>
          <Route path='/assuranceEditRequest/view'>
            <PermissionCheck
              permissions={[
                p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
                p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
                p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
                p.VIEW_ALL_ASSURANCE_EDIT_FORMS,
              ]}
            >
              <ViewAssuranceEditRequest />
            </PermissionCheck>
          </Route>
          <Route path='/stampReceiptForm/view'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_STAMP_RECEIPT_FORMS,
                p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
                p.VIEW_STAMP_RECEIPT_FORMS,
                p.ADD_EDIT_STAMP_RECEIPT_FORMS,
              ]}
            >
              <ViewStampReceipt />
            </PermissionCheck>
          </Route>
          <Route exact path='/FinancialServices/ExpensesFormsList'>
            <PermissionCheck
              permissions={[p.ADD_EXPENSES_FORM, p.CLOSE_EXPENSES_FORM, p.EDIT_EXPENSE_FORM_AFTER_APPROVE]}
            >
              <ExpensesFormsList />
            </PermissionCheck>
          </Route>
          <Route exact path='/FinancialServices/AddExpensesForm'>
            <PermissionCheck permissions={[p.ADD_EXPENSES_FORM]}>
              <AddExpensesForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/FinancialServices/EditExpensesForm'>
            <PermissionCheck permissions={[p.ADD_EXPENSES_FORM, p.EDIT_EXPENSE_FORM_AFTER_APPROVE]}>
              <EditExpensesForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/FinancialServices/TransportExpensesFormsList'>
            <PermissionCheck
              permissions={[
                p.ADD_TRANSPORT_EXPENSES_FORM,
                p.CLOSE_TRANSPORT_EXPENSES_FORM,
                p.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE,
              ]}
            >
              <TransportExpensesFormsList />
            </PermissionCheck>
          </Route>
          <Route exact path='/FinancialServices/AddTransportExpensesForm'>
            <PermissionCheck permissions={[p.ADD_TRANSPORT_EXPENSES_FORM]}>
              <AddTransportExpensesForm />
            </PermissionCheck>
          </Route>
          <Route exact path='/FinancialServices/EditTransportExpensesForm'>
            <PermissionCheck permissions={[p.ADD_TRANSPORT_EXPENSES_FORM, p.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE]}>
              <EditTransportExpensesForm />
            </PermissionCheck>
          </Route>
          <Route path='/FinancialServices'>
            <PermissionCheck
              permissions={[
                p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
                p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
                p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
                p.VIEW_ALL_ASSURANCE_EDIT_FORMS,
                p.VIEW_ALL_STAMP_RECEIPT_FORMS,
                p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
                p.VIEW_STAMP_RECEIPT_FORMS,
                p.ADD_EDIT_STAMP_RECEIPT_FORMS,
                p.VIEW_ALL_ASSURANCE_FORMS,
                p.VIEW_ASSURANCE_FORMS,
                p.ADD_EDIT_ASSURANCE_FORMS,
                p.CHANGE_ASSURANCE_FORMS_STATUS,
                p.ADD_EXPENSES_FORM,
                p.EDIT_EXPENSE_FORM_AFTER_APPROVE,
                p.ADD_TRANSPORT_EXPENSES_FORM,
                p.PAYMENT_OF_FINANCIAL_DUES,
                p.REJECT_APPROVE_EXPENSES_FORM,
                p.REJECT_APPROVE_TRANSPORT_EXPENSES_FORM,
                p.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL,
                p.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE,
              ]}
            >
              <FinancialServicesCards />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/branches'>
            <PermissionCheck
              permissions={[
                p.VIEW_CUSTOMER_BRANCHES,
                p.EDIT_CUSTOMER_BRANCH,
                p.DELETE_CUSTOMER_BRANCH,
                p.ADD_CUSTOMER_BRANCH,
              ]}
            >
              <Branches />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/add-branches'>
            <PermissionCheck permissions={p.ADD_CUSTOMER_BRANCH}>
              <AddBranches />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/branches/edit-branch'>
            <PermissionCheck permissions={p.EDIT_CUSTOMER_BRANCH}>
              <EditBranch />
            </PermissionCheck>
          </Route>
          <Route exact path='/customers/branches/view-branch'>
            <PermissionCheck
              permissions={[
                p.VIEW_CUSTOMER_BRANCHES,
                p.EDIT_CUSTOMER_BRANCH,
                p.DELETE_CUSTOMER_BRANCH,
                p.ADD_CUSTOMER_BRANCH,
              ]}
            >
              <ViewBranch />
            </PermissionCheck>
          </Route>
          <Route exact path='/schedule'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                p.VIEW_CUSTOMER_VISITS_SCHEDULE,
                p.ADD_SCHEDULE_VISIT,
                p.DELETE_SCHEDULE_VISIT,
                p.ADD_VISIT,
                p.EDIT_VISIT_DATE,
                p.DELETE_VISIT,
                p.ADD_VISIT_REPORT,
                p.VIEW_VISIT_REPORT,
                p.DELETE_VISIT_REPORT,
                p.EDIT_VISIT_REPORT,
              ]}
            >
              <ScheduleVisitsService />
            </PermissionCheck>
          </Route>
          <Route exact path='/schedule/client-visits'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                p.VIEW_CUSTOMER_VISITS_SCHEDULE,
                p.ADD_SCHEDULE_VISIT,
                p.DELETE_SCHEDULE_VISIT,
                p.ADD_VISIT,
                p.EDIT_VISIT_DATE,
                p.ADD_VISIT_REPORT,
                p.DELETE_VISIT,
              ]}
            >
              <ScheduleVisits />
            </PermissionCheck>
          </Route>
          <Route exact path='/schedule/view-reports'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                p.ADD_VISIT_REPORT,
                p.VIEW_VISIT_REPORT,
                p.DELETE_VISIT_REPORT,
                p.EDIT_VISIT_REPORT,
              ]}
            >
              <ViewAllVisitsReports />
            </PermissionCheck>
          </Route>
          <Route exact path='/schedule/all-clients-visits'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                p.VIEW_CUSTOMER_VISITS_SCHEDULE,
                p.EDIT_VISIT_DATE,
                p.DELETE_VISIT,
                p.ADD_VISIT_REPORT,
              ]}
            >
              <ViewAllMaintenanceVisitCalendar />
            </PermissionCheck>
          </Route>
          <Route exact path='/client-visits/add-schedule-visit'>
            <PermissionCheck permissions={p.ADD_SCHEDULE_VISIT}>
              <AddMaintenanceContract />
            </PermissionCheck>
          </Route>
          <Route exact path='/client-visits/ViewReport'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                p.VIEW_VISIT_REPORT,
                p.ADD_VISIT_REPORT,
              ]}
            >
              <ViewMaintenanceReport />
            </PermissionCheck>
          </Route>
          <Route exact path='/client-visits/view-contract'>
            <PermissionCheck
              permissions={[
                p.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                p.VIEW_CUSTOMER_VISITS_SCHEDULE,
                p.ADD_VISIT,
                p.ADD_SCHEDULE_VISIT,
                p.DELETE_SCHEDULE_VISIT,
                p.EDIT_VISIT_DATE,
                p.ADD_VISIT_REPORT,
                p.DELETE_VISIT,
              ]}
            >
              <ViewCustomerMaintenanceVisitCalendar />
            </PermissionCheck>
          </Route>
          <Route exact path='/client-visits/generateReport'>
            <PermissionCheck permissions={p.ADD_VISIT_REPORT}>
              <GenerateVisitReport />
            </PermissionCheck>
          </Route>
          <Route exact path='/client-visits/edit-report'>
            <PermissionCheck permissions={p.EDIT_VISIT_REPORT}>
              <EditVisitReport />
            </PermissionCheck>
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </SidebarPage>
    </Switch>
  );
}

export default App;

import { head } from 'lodash';
import React from 'react';
import { Table, Responsive, Card, Label } from 'semantic-ui-react';

export default function TableSummary({ headers, engHeaders, data, counter = false, oneCountColor = null }) {
  return (
    <div>
      <Responsive minWidth={768}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              {headers.map((item, index) => {
                return <Table.HeaderCell key={index}>{item}</Table.HeaderCell>;
              })}
              {counter && <Table.HeaderCell>التكرار</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map((item) => {
              return (
                <Table.Row key={item.id}>
                  {engHeaders.map((header, index) => {
                    return <Table.Cell key={index}>{item[header] === '' ? '-' : item[header]}</Table.Cell>;
                  })}
                  {counter && (
                    <>
                      <Table.Cell>
                        <Label
                          circular
                          color={
                            oneCountColor
                              ? oneCountColor
                              : item.count <= 1
                              ? 'green'
                              : item.count > 1 && item.count <= 3
                              ? 'orange'
                              : 'red'
                          }
                        >
                          {item.count}
                        </Label>
                      </Table.Cell>
                    </>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Responsive>

      <Responsive maxWidth={767}>
        <div className='responsive-cards'>
          <Card.Group compact>
            {data.map((item, index) => (
              <Card fluid key={index}>
                <Card.Content>
                  <Card.Description>
                    <div className='cards-table'>
                      <Table basic='very' unstackable compact>
                        <Table.Header>
                          <Table.Row></Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {headers.map((header, header_index) => {
                            return (
                              <Table.Row key={header_index}>
                                <Table.Cell width={5}>
                                  <span>{header}:</span>
                                </Table.Cell>
                                <Table.Cell>
                                  <b>{item[engHeaders[header_index]] === '' ? '-' : item[engHeaders[header_index]]}</b>
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}

                          {counter && (
                            <Table.Row>
                              <Table.Cell width={5}>
                                <span>التكرار: </span>
                              </Table.Cell>
                              <Table.Cell>
                                <Label
                                  circular
                                  color={
                                    oneCountColor
                                      ? oneCountColor
                                      : item.count <= 1
                                      ? 'green'
                                      : item.count > 1 && item.count <= 3
                                      ? 'orange'
                                      : 'red'
                                  }
                                >
                                  {item.count}
                                </Label>
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </div>
      </Responsive>
    </div>
  );
}

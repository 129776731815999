import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';

export default function SettingsCard({ iconName, title, url }) {
  const router = useHistory();
  return (
    <Card link color='grey' onClick={() => router.push(url)} className='settings-card'>
      <div className='card-icon'>
        <Icon name={iconName} />
      </div>
      <Card.Header className='card-header'>{title}</Card.Header>
      <Card.Meta className='card-meta'></Card.Meta>
    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import { lookupsApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';

export default function EditLookupForm() {
  //the id prop is the lookup's id in the edit form
  const location = useLocation();
  const { id } = location.state;
  const user = useSelector((st) => st.user).user;
  const router = useHistory();

  const [formData, setFormData] = useState({
    name: '',
    createdBy: Number(user.id),
  });

  const [validationErrors, setValidationErrors] = useState({
    name: null,
  });

  const [touched, setTouched] = useState({
    name: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        const res = await lookupsApi.getOne(id);
        if (mounted) {
          setFormData(res.data);
        }
      } catch (err) {
        console.log('Error fetching data...', err);
      }
    }
    fetchData();

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    validation();
  }, [formData]);

  const validation = () => {
    const errors = {
      name: null,
    };

    //NAME VALIDATION
    if (!formData.name) {
      errors.name = 'الرجاء ادخال اسم الرمز';
    }

    setValidationErrors(errors);

    if (validationErrors.name) return false;
    else return true;
  };

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTouched({
      name: true,
    });

    if (validation()) {
      try {
        await lookupsApi.edit(id, formData);
        router.goBack();
      } catch (e) {
        console.log('Error editing lookup...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2>تعديل الرمز</h2>
        <div style={{ marginTop: '3rem' }}>
          <Form error onSubmit={onSubmitHandler}>
            <Form.Field className='field-wrapper' style={{ marginLeft: '1rem' }}>
              <label>اسم الرمز</label>
              <Form.Input
                icon='star'
                value={formData['name']}
                name='name'
                onChange={onChangeHandler}
                error={touched.name && validationErrors.name}
              />
            </Form.Field>

            <Message error key={errors.key} content={errors.message} />

            <div style={{ marginTop: '2rem' }}>
              <Button icon type='submit' loading={isSubmitting} className='blue-button' labelPosition='right'>
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Form>
        </div>
      </div>
    </PageCardContainer>
  );
}

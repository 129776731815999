import XLSX from 'xlsx';
import moment from 'moment';

export const excelExport = (data, title) => {
  // remove editable field that exists in lookups & roles
  for (let i in data) {
    delete data[i].editable;
  }
  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet([...data]);
  XLSX.utils.book_append_sheet(workbook, ws, 'Results');
  XLSX.writeFile(workbook, `${title}-${moment(moment().toDate()).format('YYYY-MM-DD-HH-mm')}.xlsx`, { type: 'file' });
};

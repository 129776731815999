const { tickets } = require('../actions');

const initialState = {
  selectedTicket: null,
};

export const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case tickets.SET_SELECTED_TICKET:
      return { selectedTicket: action.payload };
    case tickets.CLEAR_SELECTED_TICKET:
      return initialState;
    default:
      return state;
  }
};

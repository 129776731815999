import React, { useEffect, useState } from 'react';
import { Icon, Label, Loader, Popup, Segment, Table, Responsive, Card } from 'semantic-ui-react';
import './MaintenanceTicketContent.scss';
import { maintenanceTicketsApi } from '../../../../api/api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import permissions, { hasPermissions } from './../../../../helpers/permissions';

export default function MaintenanceTicketContent({
  maintenanceTicketId,
  customerId,
  customerTicketId,
  ticketStatus,
  maintenanceTicketTV,
}) {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changeTicketStatusAfterForm, setChangeTicketStatusAfterForm] = useState(false);

  useEffect(() => {
    if (maintenanceTicketId) fetchData(maintenanceTicketId);
  }, [maintenanceTicketId]);

  const fetchData = async (id) => {
    setLoading(true);
    const { data: ticket } = await maintenanceTicketsApi.getOneMaintenanceTicket(id);
    maintenanceTicketTV(ticket.trackingVehiclesList);

    if (ticket.trackingVehiclesList.filter((tv) => tv.status !== 18).length === 0) {
      setChangeTicketStatusAfterForm(true);
    }
    setTicket(ticket);
    setLoading(false);
  };

  const router = useHistory();

  const iconClickHandler = (trackingVehicleId, maintenanceTicketVehicleId, tvDisplayName, path) => {
    router.push(`/maintenanceForm/${path}`, {
      trackingVehicleId,
      maintenanceTicketVehicleId,
      customerTicketId,
      customerId,
      changeTicketStatusAfterForm,
      tvDisplayName,
    });
  };

  return (
    <div className='maintenance-ticket-container'>
      <h4>+ تفاصيل التذكرة</h4>
      {!loading ? (
        ticket && (
          <>
            <div className='wrapper'>
              <div>
                <h5 className='title'>العنوان</h5>
                <p className='content'>{ticket.address}</p>
              </div>
              <div>
                <h5 className='title'>تاريخ الزيارة</h5>
                <p className='content-date'>{moment(ticket.visitDate).format('YYYY/MM/DD')}</p>
              </div>
            </div>
            <h5 className='title'>ملاحظات</h5>
            <p className='content'>{ticket.note ? ticket.note : 'لا توجد ملاحظات'}</p>
            <h5 className='title'>اجهزة التتبع</h5>
            {!ticket.trackingVehiclesList && !ticket.trackingVehiclesList.length ? (
              <Segment>لا توجد بيانات</Segment>
            ) : (
              <>
                <Responsive maxWidth={767}>
                  <div className='responsive-cards'>
                    <Card.Group compact>
                      {ticket.trackingVehiclesList.map((item, index) => (
                        <Card fluid key={index}>
                          <Card.Content>
                            <Card.Description>
                              <div className='cards-table'>
                                <Table basic='very' unstackable compact>
                                  <Table.Body>
                                    <Table.Row>
                                      <Table.Cell width={5}>
                                        <span>IMEI:</span>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <b>{item.imei}</b>
                                      </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                      <Table.Cell width={5}>
                                        <span>رقم لوحة المركبة:</span>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <b>{item.plateNumber}</b>
                                      </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                      <Table.Cell width={5}>
                                        <span>الحالة:</span>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <b>
                                          {item.status === 18 ? (
                                            <Label color='orange' size='small'>
                                              <Icon name='clock outline' /> مفتوحة
                                            </Label>
                                          ) : (
                                            <Label color='green'>
                                              <Icon name='check circle outline' /> مغلقة
                                            </Label>
                                          )}
                                        </b>
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                              </div>
                            </Card.Description>
                          </Card.Content>
                          <Card.Content extra>
                            {ticketStatus !== 20 && (
                              <>
                                {item.status === 18 ? (
                                  hasPermissions([permissions.CREATE_REPORT_FROM_TICKET]) && (
                                    <div
                                      className='responsive-actions'
                                      onClick={() =>
                                        iconClickHandler(
                                          item.trackingVehicleId,
                                          item.maintenanceTicketVehicleId,
                                          item.imei + ' - ' + item.plateNumber,
                                          'add'
                                        )
                                      }
                                    >
                                      <Popup
                                        position='top right'
                                        content='اضافة تقرير صيانة'
                                        trigger={<Icon name='plus' className='blue-icon' />}
                                      />
                                    </div>
                                  )
                                ) : (
                                  <>
                                    {hasPermissions([permissions.VIEW_MAINTENANCE_REPORTS]) && (
                                      <div
                                        className='responsive-actions'
                                        onClick={() =>
                                          iconClickHandler(
                                            item.trackingVehicleId,
                                            item.maintenanceTicketVehicleId,
                                            '',
                                            'view'
                                          )
                                        }
                                      >
                                        <Popup
                                          position='top right'
                                          content='عرض تقرير صيانة'
                                          trigger={<Icon name='eye' className='blue-icon' />}
                                        />
                                      </div>
                                    )}
                                    {hasPermissions([permissions.EDIT_MAINTENANCE_REPORTS]) && (
                                      <div
                                        className='responsive-actions'
                                        onClick={() =>
                                          iconClickHandler(
                                            item.trackingVehicleId,
                                            item.maintenanceTicketVehicleId,
                                            item.imei + ' - ' + item.plateNumber,
                                            'edit'
                                          )
                                        }
                                      >
                                        <Popup
                                          position='top right'
                                          content='تعديل تقرير الصيانة'
                                          trigger={<Icon name='pencil' className='blue-icon' />}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                  </div>
                </Responsive>

                <Responsive minWidth={768}>
                  <Table celled size='small' compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>IMEI</Table.HeaderCell>
                        <Table.HeaderCell>رقم لوحة المركبة</Table.HeaderCell>
                        <Table.HeaderCell>الحالة</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {ticket.trackingVehiclesList.map((item) => {
                        return (
                          <Table.Row key={item.trackingVehicleId}>
                            <Table.Cell>{item.imei}</Table.Cell>
                            <Table.Cell>{item.plateNumber}</Table.Cell>
                            <Table.Cell>
                              {item.status === 18 ? (
                                <Label color='orange' size='small'>
                                  <Icon name='clock outline' /> مفتوحة
                                </Label>
                              ) : (
                                <Label color='green'>
                                  <Icon name='check circle outline' /> مغلقة
                                </Label>
                              )}
                            </Table.Cell>
                            {/* 18: مفتوحة 
                              20: مغلقة */}
                            <Table.Cell>
                              {ticketStatus === 20 ? (
                                <>
                                  {hasPermissions([permissions.VIEW_MAINTENANCE_REPORTS]) && (
                                    <span
                                      onClick={() =>
                                        iconClickHandler(
                                          item.trackingVehicleId,
                                          item.maintenanceTicketVehicleId,
                                          '',
                                          'view'
                                        )
                                      }
                                    >
                                      <Popup
                                        content='عرض تقرير صيانة'
                                        trigger={<Icon name='eye' className='blue-icon' />}
                                      />
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.status === 18 ? (
                                    hasPermissions([permissions.CREATE_REPORT_FROM_TICKET]) && (
                                      <span
                                        onClick={() =>
                                          iconClickHandler(
                                            item.trackingVehicleId,
                                            item.maintenanceTicketVehicleId,
                                            item.imei + ' - ' + item.plateNumber,
                                            'add'
                                          )
                                        }
                                      >
                                        <Popup
                                          content='اضافة تقرير صيانة'
                                          trigger={<Icon name='plus' className='blue-icon' />}
                                        />
                                      </span>
                                    )
                                  ) : (
                                    <>
                                      {hasPermissions([permissions.VIEW_MAINTENANCE_REPORTS]) && (
                                        <span
                                          onClick={() =>
                                            iconClickHandler(
                                              item.trackingVehicleId,
                                              item.maintenanceTicketVehicleId,
                                              '',
                                              'view'
                                            )
                                          }
                                        >
                                          <Popup
                                            content='عرض تقرير صيانة'
                                            trigger={<Icon name='eye' className='blue-icon' />}
                                          />
                                        </span>
                                      )}

                                      {hasPermissions([permissions.EDIT_MAINTENANCE_REPORTS]) && (
                                        <span
                                          onClick={() =>
                                            iconClickHandler(
                                              item.trackingVehicleId,
                                              item.maintenanceTicketVehicleId,
                                              item.imei + ' - ' + item.plateNumber,
                                              'edit'
                                            )
                                          }
                                        >
                                          <Popup
                                            content='تعديل تقرير الصيانة'
                                            trigger={<Icon name='pencil' className='blue-icon' />}
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Responsive>
              </>
            )}
          </>
        )
      ) : (
        <Loader active inline size='large' />
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Responsive } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import qs from 'qs';
import { customerTicketsApi } from '../../api/api';
import { excelExport } from '../../helpers/excelExport';
import { ticketsActions } from '../../redux/actions/actionCreator';
import CustomerTickets from './CustomerTickets/CustomerTickets';
import CustomerTicketsMobile from './CustomerTicketsMobile/CustomerTicketsMobile';
import { multipleSheetsExcelExport } from './../../helpers/multipleSheetsExcelExport';

// TODO: Find a way to keep these lookups consistant across deployments
const statusNames = {
  18: { name: 'مفتوحة' }, // Open
  19: { name: 'قيد التنفيذ' }, // In Progress
  20: { name: 'مغلقة' }, // Closed
};

let queryString = '';

const CustomerTicketsContainer = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [dateFilter, setDateFilter] = useState({});

  const defaultPagination = {
    offset: 0,
    limit: 50,
  };

  const dispatch = useDispatch();
  const selectedTicket = useSelector((state) => state.tickets).selectedTicket;
  const user = useSelector((st) => st.user);

  const fetchData = async (filters = { status: 18 }, pagination = defaultPagination) => {
    // TODO: Handle multiple calls (one from the reload effect + one from the onSearch prop sent to the search component)
    try {
      setLoading(true);
      for (const key in filters) if (!filters[key]) delete filters[key];
      queryString = qs.stringify({ ...filters, ...pagination, userId: user.user.id }, { addQueryPrefix: true });
      const res = await customerTicketsApi.getAll(queryString);
      if (res.data.data) {
        setData(res.data.data);
        setTotal(res.data.total);
      }
      setLoading(false);
    } catch (error) {
      // TODO: handle errors
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reload]);

  const onTicketSelected = (ticket) => {
    dispatch(ticketsActions.setSelectedTicket(ticket));
  };

  const onTicketStatusChanged = (statusId) => {
    const updatedTickets = [...data];
    const ticket = updatedTickets.find((ticket) => ticket.id === selectedTicket.id);
    if (ticket.status === statusId) return;

    ticket.status = statusId;
    ticket.statusName = statusNames[statusId].name;
    if (statusId === 20) {
      ticket.closedBy = user.user.FullName;
      ticket.closedAt = new Date();
    } else {
      ticket.closedBy = null;
      ticket.closedAt = null;
    }
    setData(updatedTickets);
    dispatch(ticketsActions.setSelectedTicket({ ...ticket }));
  };

  const onTicketAssigned = ({ teamId, team, memberId, member }) => {
    const updatedTickets = [...data];
    let ticket = updatedTickets.find((ticket) => ticket.id === selectedTicket.id);
    ticket.teamMemberId = memberId;
    ticket.teamId = teamId;
    ticket.teamName = team;
    ticket.teamMember = member;

    setData(updatedTickets);
    dispatch(ticketsActions.setSelectedTicket({ ...ticket }));
  };

  const handleDeleteTicket = () => {
    setReload(!reload);
    dispatch(ticketsActions.clearSelectedTicket());
  };
  const exportFileHandler = async () => {
    try {
      setExcelLoading(true);
      const res = await customerTicketsApi.getExcelData(queryString);
      let data = [];
      if (res.data) {
        data = res.data.map((item) => ({
          'Ticket Id': item.id,
          'Customer Name': item.fullName,
          'Branch Location': item.branchLocation || '-',
          'Ticket Type': item.typeName,
          Status: item.statusName,
          'Urgency Level': item.urgencyLevelName,
          'Created At': moment(item.createdAt).format('YYYY-MM-DD, hh:mm A'),
          'Closed At': item.closedAt ? moment(item.closedAt).format('YYYY-MM-DD, hh:mm A') : '-',
          'Created By': item.createdBy ? item.createdBy : '-',
          'Closed By': item.closedBy ? item.closedBy : '-',
          'Assigned to team': item.teamName ? item.teamName : 'no',
          'Assigned to team member': item.teamMember ? item.teamMember : 'no',
          'Caller Name': item.ticketOpenerName || '-',
          'Caller Phone': item.ticketOpenerPhone || '-',
          IMEI: item.imei || '-',
          'Receipt No': item.receiptNumber || '-',
          'Close Note': item.closeNote || '-',
          Description: item.description || '-',
          MTM: item.machineTypeModel || '-',
          'Serial Number': item.serialNumber || '-',
          'Duration In Hours': item.duration || '-',
        }));
      }
      excelExport(data, 'تذاكر_الصيانة');
      setExcelLoading(false);
    } catch (error) {
      setExcelLoading(false);
      console.log(error);
    }
  };
  const exportFileStatisticsHandler = async () => {
    try {
      setExcelLoading(true);
      console.log();
      queryString = dateFilter.from != null ? qs.stringify({ ...dateFilter }, { addQueryPrefix: true }) : '';
      const { data: statistics } = await customerTicketsApi.getAllStatistics(queryString);
      const { data: ticketStatistics } = await customerTicketsApi.getAllTicketsStatistics(queryString);
      console.log(ticketStatistics);
      if (statistics) {
        multipleSheetsExcelExport(
          [
            [...statistics.createdBy],
            [...statistics.closedBy],
            [...statistics.teamMemberDetails],
            [...ticketStatistics.ticketsDailyCreations],
            [...ticketStatistics.ticketClosedIn24Hours],
            [...ticketStatistics.ticketClosedInMoreThan24Hour],
            [...ticketStatistics.urgencyLevelTickets],
          ],
          [
            'Created By Tickets',
            'Closed By Tickets',
            'Team Members Details',
            'Tickets daily creations',
            'Ticket Closed < 24',
            'Ticket Closed > 24',
            'Urgency Level Tickets',
          ],
          'احصائيات التذاكر'
        );
      }
      setExcelLoading(false);
    } catch (error) {
      setExcelLoading(false);
    }
  };
  return (
    <>
      <Responsive maxWidth={767} style={{ height: '100%' }}>
        <CustomerTicketsMobile
          data={data}
          total={total}
          fetchData={fetchData}
          loading={loading}
          onTicketSelected={onTicketSelected}
          onTicketStatusChanged={onTicketStatusChanged}
          exportFileStatisticsHandler={exportFileStatisticsHandler}
          setDateFilter={setDateFilter}
          exportFileHandler={exportFileHandler}
          selectedTicket={selectedTicket}
          handleDeleteTicket={handleDeleteTicket}
          onTicketAssigned={onTicketAssigned}
          excelLoading={excelLoading}
        />
      </Responsive>
      <Responsive minWidth={768} style={{ height: '100%' }}>
        <CustomerTickets
          data={data}
          total={total}
          fetchData={fetchData}
          loading={loading}
          onTicketSelected={onTicketSelected}
          onTicketStatusChanged={onTicketStatusChanged}
          exportFileStatisticsHandler={exportFileStatisticsHandler}
          setDateFilter={setDateFilter}
          exportFileHandler={exportFileHandler}
          selectedTicket={selectedTicket}
          handleDeleteTicket={handleDeleteTicket}
          onTicketAssigned={onTicketAssigned}
          excelLoading={excelLoading}
        />
      </Responsive>
    </>
  );
};

export default CustomerTicketsContainer;

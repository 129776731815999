import React, { useEffect, useState } from 'react';
import { Accordion, Icon, Segment, Table, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import './../CustomerVisits.scss';
import DatePicker from 'react-datepicker';

const AccordionBranchVisits = ({
  activeAccordionIndex,
  onAccordionClick,
  formData,
  validateDate,
  validation,
  branches,
  generatedVisits,
  setGeneratedVisits,
  viewOnly = false,
}) => {
  const [totalVisitsPerBranch, setTotalVisitsPerBranch] = useState(null);

  const generateBranchVisits = () => {
    if (!viewOnly) {
      if (validation()) {
        const dateDiffInDays = Math.floor((formData.visitsTo - formData.visitsFrom) / (24 * 3600 * 1000));
        if (validateDate(dateDiffInDays)) {
          let dateType = formData.visitsDateType === 140 ? 7 : formData.visitsDateType === 141 ? 30 : 365;
          let dateDiff = Math.round(dateDiffInDays / dateType) * formData.visitsCount;
          setTotalVisitsPerBranch(dateDiff);
          let generatedBranches = [];
          let gVisits = [];
          let startDate = formData.visitsFrom;
          for (let i = 0; i < (formData.branches.length === 0 ? 1 : formData.branches.length); i++) {
            for (let j = 0; j < dateDiff; j++) {
              const copiedDate = new Date(startDate.getTime());
              const suggestedDate = copiedDate.setDate(
                copiedDate.getDate() + Math.round(dateDiffInDays / dateDiff) * j
              );
              gVisits.push({
                date:
                  moment(suggestedDate).day() === 5
                    ? moment(suggestedDate).add(-1, 'days')
                    : moment(suggestedDate).day() === 6
                    ? moment(suggestedDate).add(1, 'days')
                    : moment(suggestedDate),
                active: moment(suggestedDate).isAfter(moment()),
                validDate: null,
              });
            }
            generatedBranches.push({
              branchId: formData.branches.length === 0 ? 0 : branches[i].key,
              branchName: formData.branches.length === 0 ? 'العميل نفسه' : branches[i].text,
              visits: gVisits,
            });
            gVisits = [];
            startDate = formData.visitsFrom;
          }
          console.log(generatedBranches);
          setGeneratedVisits(generatedBranches);
          generatedBranches = [];
        }
      }
    }
  };

  const checkDateVisitsValidationAfter = (date, gIdx, vIdx) => {
    if (
      moment(date).isAfter(moment(generatedVisits[gIdx].visits[vIdx + 1].date)) ||
      moment(date).isSame(moment(generatedVisits[gIdx].visits[vIdx + 1].date))
    )
      return false;
    return true;
  };

  const checkDateVisitsValidationBefore = (date, gIdx, vIdx) => {
    if (
      moment(date).isBefore(moment(generatedVisits[gIdx].visits[vIdx - 1].date)) ||
      moment(date).isSame(moment(generatedVisits[gIdx].visits[vIdx - 1].date))
    )
      return false;
    return true;
  };

  const DateChangeHandler = (date, gIdx, vIdx) => {
    let checkValidation = true;
    if (generatedVisits[gIdx].visits.length === 1) {
      checkValidation = true;
    } else if (vIdx === 0) {
      checkValidation = checkDateVisitsValidationAfter(date, gIdx, vIdx);
    } else if (vIdx === generatedVisits[gIdx].visits.length - 1) {
      checkValidation = checkDateVisitsValidationBefore(date, gIdx, vIdx);
    } else {
      checkValidation =
        checkDateVisitsValidationAfter(date, gIdx, vIdx) && checkDateVisitsValidationBefore(date, gIdx, vIdx);
    }
    if (!checkValidation) {
      let gVisits = [...generatedVisits];
      let currentGVisit = { ...gVisits[gIdx] };
      currentGVisit.visits[vIdx].validDate = 'التاريخ الذي تم اختياره غير صحيح';
      gVisits[gIdx] = currentGVisit;
      setGeneratedVisits(gVisits);
      return;
    }
    let gVisits = [...generatedVisits];
    let currentGVisit = { ...gVisits[gIdx] };
    currentGVisit.visits[vIdx].date = date;
    currentGVisit.visits[vIdx].validDate = null;
    currentGVisit.visits[vIdx].active = moment(date).isAfter(moment());
    gVisits[gIdx] = currentGVisit;
    setGeneratedVisits(gVisits);
  };

  useEffect(() => {
    generateBranchVisits();
  }, [formData]);
  return (
    <>
      <Segment>
        {generatedVisits.length > 0 && (
          <>
            <h5 className='title'>عدد الزيارات {totalVisitsPerBranch}</h5>

            <Accordion styled fluid exclusive={false}>
              {generatedVisits.map((branch, idx) => (
                <React.Fragment key={branch.branchId}>
                  <Accordion.Title
                    active={activeAccordionIndex.includes(branch.branchId)}
                    index={branch.branchId}
                    onClick={() => onAccordionClick(branch.branchId)}
                    style={{ userSelect: 'none' }}
                  >
                    {activeAccordionIndex.includes(branch.branchId) ? (
                      <Icon name='chevron down' style={{ marginLeft: 12 }} />
                    ) : (
                      <Icon name='chevron left' style={{ marginLeft: 12 }} />
                    )}
                    {branch.branchName}
                  </Accordion.Title>
                  <Accordion.Content active={activeAccordionIndex.includes(branch.branchId)}>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>التاريخ</Table.HeaderCell>
                          <Table.HeaderCell>الحالة</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {/* Times = branches * (visits * period) */}
                        {branch.visits.map((date, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>
                              <div className='appointment' style={{ flexDirection: 'column' }}>
                                <DatePicker
                                  popperPlacement='top-end'
                                  placeholderText='تاريخ الزيارة'
                                  value={moment(date.date).format('YYYY/MM/DD')}
                                  onChange={(date) => DateChangeHandler(date, idx, index)}
                                  minDate={formData.visitsFrom}
                                  maxDate={formData.visitsTo}
                                  disabled={viewOnly}
                                />
                                <label style={{ color: '#9f3a38' }}>{date.validDate}</label>
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <div className='appointment'>
                                <Checkbox
                                  checked={date.active ? true : false}
                                  disabled={viewOnly}
                                  onClick={(e, v) => {
                                    let gVisits = [...generatedVisits];
                                    let currentGVisit = { ...gVisits[idx] };
                                    currentGVisit.visits[index].active = v.checked;
                                    gVisits[idx] = currentGVisit;
                                    setGeneratedVisits(gVisits);
                                  }}
                                />
                                <Icon name='check circle' color={date.active ? 'green' : 'red'} />
                                <span> {date.active ? 'فعال' : 'غير فعال'}</span>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </Accordion>
          </>
        )}
      </Segment>
    </>
  );
};

export default AccordionBranchVisits;

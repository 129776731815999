export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('user');

    if (serializedState === null) {
      return {
        isLoggedIn: false,
        err: 'User state is not valid',
        token: null,
      };
    }

    return JSON.parse(serializedState);
  } catch (error) {
    console.log('Load state error...', error);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    if (!state) {
      return sessionStorage.clear();
    }
    sessionStorage.clear();
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('user', serializedState);
  } catch (error) {
    console.log('Save state error...', error);
  }
};

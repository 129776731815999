import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { customersApi, vehiclesApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';

export default function AddUserForm() {
  const currentUser = useSelector((st) => st.user).user;

  const [formData, setFormData] = useState({
    plateNumber: '',
    customerId: '',
    createdBy: Number(currentUser.id),
  });

  const [validationErrors, setValidationErrors] = useState({
    plateNumber: null,
  });

  const [touched, setTouched] = useState({
    plateNumber: false,
  });

  const [customerIdOptions, setCustomerIdOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const router = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      //get customers
      const customers = await customersApi.getCustomersDropDown();
      const options3 = customers.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setCustomerIdOptions(options3);

      setFormData({
        ...formData,
        customerId: options3[0].key,
      });
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      plateNumber: null,
    };

    if (!formData.plateNumber) {
      errors.plateNumber = 'الرجاء ادخال رقم المركبة';
    }

    setValidationErrors(errors);

    if (validationErrors.plateNumber) return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const customerSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, customerId: data.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      plateNumber: true,
    });

    if (validation()) {
      try {
        await vehiclesApi.postVehicle(formData);
        router.goBack();
      } catch (e) {
        console.log('Error posting vehicle...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>اضافة مركبة جديدة</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
          <Form.Field>
            <label>رقم المركبة</label>
            <Form.Input
              icon='car'
              value={formData['plateNumber']}
              name='plateNumber'
              onChange={onChangeHandler}
              error={touched.plateNumber && validationErrors.plateNumber}
            />
          </Form.Field>

          <Form.Field>
            <label>العميل</label>
            <Dropdown
              placeholder='اختر العميل'
              fluid
              selection
              search
              value={formData['customerId']}
              options={customerIdOptions}
              onChange={customerSelectionChangeHandler}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
          </Form.Field>

          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}

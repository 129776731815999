import { take, race, call, delay, put } from 'redux-saga/effects';
import { usersLocations as actions } from '../actions';
import { usersLocationsActionsCreator } from '../actions/actionCreator';
import { reduxStore } from '../store/store';
import { userTrackingAssetLocationsApi } from '../../api/api';

function* fetchUsersLocations() {
  while (true) {
    try {
      const firstLoad = reduxStore?.getState()?.usersLocations?.firstLoad;
      const { data: res } = yield userTrackingAssetLocationsApi.getAllAssetsLastLocations();

      if (!firstLoad) {
        yield put(usersLocationsActionsCreator.usersFirstLoadSet(true));
      }

      yield put(usersLocationsActionsCreator.usersLocationsSet(res));
    } catch (err) {
      console.log('Error fetching users locations...', err.response ?? err);
      yield put(usersLocationsActionsCreator.usersLocationsFail('حدث خطأ اثناء تحميل مواقع المستخدمين'));
    }
    yield delay(15000);
  }
}

export function* createUsersLocationsStart() {
  while (true) {
    const action = yield take(actions.FETCH_USERS_LOCATIONS_REQUEST);
    yield race({ loop: call(fetchUsersLocations), stop: take(actions.FETCH_USERS_LOCATIONS_STOP) });
  }
}

import React, { useEffect, useState } from 'react';
import { Button, Loader, Icon, Segment, Modal, Transition, Form, Checkbox, Popup, Dropdown } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useHistory } from 'react-router-dom';
import { departmentsApi, scheduleVisits, usersApi } from '../../../api/api';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import qs from 'qs';
import DatePicker from 'react-datepicker';
import ar from '@fullcalendar/core/locales/ar';
import { customersApi } from './../../../api/api';
import { hasPermission, hasPermissions } from '../../../helpers/permissions';
import permissions from './../../../helpers/permissions';
import PermissionCheck from '../../Reusable/PermissionCheck';
import { excelExport } from '../../../helpers/excelExport';
import ErrorModal from '../../Reusable/ErrorModal';

const ViewAllMaintenanceVisitCalendar = () => {
  const router = useHistory();
  const [clickedBranch, setClickedBranch] = useState({
    branchId: '',
    visitId: '',
    date: '',
    title: '',
    updateVisitedDate: '',
    assignedToId: -1,
    currentAssignedToId: -1,
    customerName: '',
    departmentName: '',
    contractName: '',
    isVisited: false,
    deleted: '',
    reportId: '',
  });
  const [excelVisitsDate, setExcelVisitsDate] = useState({
    visitsFrom: new Date(),
    visitsTo: new Date(),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [visits, setVisits] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(-1);
  const [users, setUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState(null);
  const [excelExportLoading, setExcelExportLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  const [calenderLoading, setCalenderLoading] = useState(false);
  const [alreadyVisited, setAlreadyVisited] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setCalenderLoading(true);
      try {
        const { data } = await scheduleVisits.getAllRecurringMaintenanceVisits(
          qs.stringify(queryString, { addQueryPrefix: true })
        );
        const events = data.map((c) => {
          return {
            id: c.visitId,
            branchId: c.branchId === 0 ? -1 : c.branchId,
            title: c.branchId === 0 ? `${c.customerName} (العميل نفسه)` : `${c.customerName} (${c.branchName})`,
            date: moment(c.visitDate).format('YYYY-MM-DD'),
            deleted: c.deleted,
            updateVisitedDate: c.updateVisitedDate,
            customerName: c.customerName,
            departmentName: c.departmentName,
            assignedToId: c.assignedToId ? c.assignedToId : -1,
            currentAssignedToId: c.assignedToId ? c.assignedToId : -1,
            contractName: c.contractName === null ? 'بدون عقد' : c.contractName,
            isVisited: c.isVisited,
            reportId: c.reportId,
            editable: !c.isVisited && !c.deleted && hasPermission(permissions.EDIT_VISIT_DATE),
            color: c.deleted ? '#dc143c' : c.reportId !== 0 ? '#2e8b57' : c.isVisited ? '#ffa500' : '#003A71',
          };
        });
        setVisits(events);
        setCalenderLoading(false);
      } catch (err) {
        setModalError(true);
        setCalenderLoading(false);
        console.log('Error getting data...', err.response ?? err);
      }
    };
    if (queryString) {
      fetchData();
    }
  }, [rerender, queryString]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const customers = await customersApi.getCustomersDropDownForScheduleVisits();
        const customersOptions = customers.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setClients([
          {
            key: -1,
            text: 'الكل',
            value: -1,
          },
          ...customersOptions,
        ]);
        const departments = await departmentsApi.getDepartmentsDropDown();
        const departmentsOptions = departments.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setDepartments([
          {
            key: -1,
            text: 'الكل',
            value: -1,
          },
          ...departmentsOptions,
        ]);
        const usersData = await usersApi.getAllExceptCustomersDropdown();
        const usersOptions = usersData.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setUsers([
          {
            key: -1,
            text: 'دون اسناد',
            value: -1,
          },
          ...usersOptions,
        ]);
        setLoading(false);
      } catch (err) {
        setModalError(true);
        setLoading(false);
        console.log('Error getting data...', err.response ?? err);
      }
    };
    fetchData();
  }, []);

  const onEventDrop = async (info) => {
    setCalenderLoading(true);
    const { start: date } = info.event._instance.range;
    const { publicId: id } = info.event._def;

    try {
      const queryString = qs.stringify(
        {
          date: date,
          id: id,
          departmentId: selectedDepartment,
        },
        { addQueryPrefix: true }
      );
      await scheduleVisits.updateVisitDate(queryString);
      setCalenderLoading(false);
    } catch (err) {
      setModalError(true);
      setCalenderLoading(false);
      setVisits([...visits]);
      console.log('Error getting data...', err.response ?? err);
    }
  };

  const onEventClick = (info) => {
    const {
      branchId,
      isVisited,
      reportId,
      deleted,
      assignedToId,
      currentAssignedToId,
      updateVisitedDate,
      customerName,
      departmentName,
      contractName,
    } = info.event._def.extendedProps;
    const { publicId: visitId } = info.event._def;
    const { start: date } = info.event._instance.range;
    const { title } = info.event._def;

    setClickedBranch({
      branchId: branchId,
      visitId: visitId,
      date: date,
      title: title,
      updateVisitedDate: updateVisitedDate,
      customerName: customerName,
      assignedToId: assignedToId,
      currentAssignedToId: currentAssignedToId,
      departmentName: departmentName,
      contractName: contractName,
      isVisited: isVisited,
      reportId: reportId,
      deleted: deleted,
    });
    setAlreadyVisited(isVisited);
    setIsModalOpen(true);
  };

  const DeleteCurrentClickedVisit = async () => {
    try {
      setLoading(true);
      const { data } = await scheduleVisits.deleteVisit(clickedBranch.visitId);
      if (data == -1) alert('لا يمكن حذف زيارة مكتملة');
      setRerender(!rerender);
      setIsConfirmModalOpen(false);
      setLoading(false);
    } catch (err) {
      setModalError(true);
      setIsConfirmModalOpen(false);
      setLoading(false);
      console.log('Error getting data...', err.response ?? err);
    }
  };

  const EditScheduleVisit = async () => {
    setCalenderLoading(true);
    setIsModalOpen(false);
    try {
      const queryString = qs.stringify(
        {
          date: clickedBranch.date,
          id: clickedBranch.visitId,
          isVisited: clickedBranch.isVisited,
          assignedToId: clickedBranch.assignedToId,
          assignmentChanged: clickedBranch.assignedToId !== clickedBranch.currentAssignedToId,
        },
        { addQueryPrefix: true }
      );
      await scheduleVisits.updateVisitDate(queryString);
      setRerender(!rerender);
      setCalenderLoading(false);
    } catch (err) {
      setModalError(true);
      setCalenderLoading(false);
      setIsModalOpen(false);
      setVisits([...visits]);
      console.log('Error getting data...', err.response ?? err);
    }
  };

  const CustomerChangeHandler = (e, v) => {
    setSelectedClient(v.value);
    setQueryString({
      ...queryString,
      id: v.value,
    });
  };

  const DepartmentChangeHandler = (e, v) => {
    setSelectedDepartment(v.value);
    setQueryString({
      ...queryString,
      departmentId: v.value,
    });
  };

  const handleMonthChange = async (info) => {
    setQueryString({
      visitsFrom: moment(info.start).utc().format('YYYY/MM/DD'),
      visitsTo: moment(info.end).utc().format('YYYY/MM/DD'),
      id: selectedClient,
      departmentId: selectedDepartment,
    });
  };

  const exportFileStatisticsHandler = async () => {
    try {
      setExcelExportLoading(true);
      const queryString = qs.stringify(
        {
          id: selectedClient,
          visitsFrom: excelVisitsDate.visitsFrom,
          visitsTo: excelVisitsDate.visitsTo,
          departmentId: selectedDepartment,
        },
        { addQueryPrefix: true }
      );
      const { data } = await scheduleVisits.getAllExcelStatistics(queryString);
      const visits = data.map((c) => {
        return {
          visitDate: moment(c.visitDate).format('YYYY/MM/DD'),
          customerName: c.customerName,
          branchName: c.branchName === null ? 'العميل نفسه' : c.branchName,
          departmentName: c.departmentName,
          assignedTo: c.assignedTo ? c.assignedTo : 'دون اسناد',
          isVisited: c.isVisited ? 'تمت الزيارة' : 'لم تتم الزيارة بعد',
          updateVisitedDate:
            c.updateVisitedDate == null ? 'لم تتم الزيارة بعد' : moment(c.updateVisitedDate).format('YYYY/MM/DD'),
          contractName: c.contractName === null ? 'بدون عقد' : c.contractName,
          reportCreatedBy: c.createdBy === null ? 'لم يتم اصدار تقرير' : c.createdBy,
          reportCreatedAt: c.reportCreatedAt === null ? '' : moment(c.reportCreatedAt).format('YYYY/MM/DD'),
          status: c.deleted ? 'زيارة محذوفة' : 'فعالة',
        };
      });
      excelExport(visits, 'زيارات العميل');
      setExcelExportLoading(false);
    } catch (error) {
      setExcelExportLoading(false);
    }
  };

  const userAssignedHandler = (e, v) => {
    setClickedBranch({ ...clickedBranch, assignedToId: v.value });
  };

  return (
    <div className='maintenance-schedule-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <>
            <div style={{ margin: '1rem 0.7rem' }}>
              <div className='schedule-content-header'>
                <h2>سجل زيارات الزبائن</h2>
                <div className='excel-export-wrapper'>
                  <div>
                    <DatePicker
                      popperPlacement='top-end'
                      placeholderText='من تاريخ'
                      selected={excelVisitsDate.visitsFrom}
                      onChange={(date) => setExcelVisitsDate({ ...excelVisitsDate, visitsFrom: date })}
                    />
                  </div>
                  <div>
                    <DatePicker
                      popperPlacement='top-start'
                      placeholderText='إلى تاريخ'
                      selected={excelVisitsDate.visitsTo}
                      onChange={(date) => setExcelVisitsDate({ ...excelVisitsDate, visitsTo: date })}
                    />
                  </div>
                  <PermissionCheck permissions={permissions.EXPORT_SCHEDULE_STATISTICS}>
                    <Popup
                      content='تصدير الزيارات الى ملف اكسل'
                      trigger={
                        <Button icon basic onClick={() => exportFileStatisticsHandler()} loading={excelExportLoading}>
                          <Icon name='file excel' />
                        </Button>
                      }
                    />
                  </PermissionCheck>
                </div>
              </div>
              <div className='customer-dropdown'>
                <div>
                  <label>العميل</label>
                  <Dropdown
                    placeholder='اختر العميل'
                    fluid
                    selection
                    search
                    name='customerId'
                    value={selectedClient}
                    options={clients}
                    onChange={CustomerChangeHandler}
                    className='table-dropdown'
                  />
                </div>
                <div>
                  <label>الدائرة</label>
                  <Dropdown
                    placeholder='اختر الدائرة'
                    fluid
                    selection
                    search
                    name='departmentId'
                    value={selectedDepartment}
                    options={departments}
                    onChange={DepartmentChangeHandler}
                    className='table-dropdown'
                  />
                </div>
              </div>
              <Segment loading={calenderLoading}>
                <FullCalendar
                  events={visits}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  contentHeight={700}
                  locale={ar}
                  dayMaxEventRows={3}
                  initialView='dayGridMonth'
                  droppable
                  editable
                  eventDrop={onEventDrop}
                  eventClick={onEventClick}
                  datesSet={handleMonthChange}
                />
              </Segment>
            </div>
            <div>
              <Transition visible={isModalOpen} animation='scale' duration={250}>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} size='small'>
                  <Modal.Header>
                    <div className='flex-space'>
                      <div>
                        {clickedBranch.branchId === -1 ? (
                          <div>
                            زيارة العميل {clickedBranch.customerName} بتاريخ{' '}
                            {moment(clickedBranch.date).format('YYYY/MM/DD')} (الدائرة: {clickedBranch.departmentName})
                          </div>
                        ) : (
                          <div>
                            زيارة العميل {clickedBranch.customerName} لفرع {clickedBranch.title} بتاريخ{' '}
                            {moment(clickedBranch.date).format('YYYY/MM/DD')} (الدائرة: {clickedBranch.departmentName})
                          </div>
                        )}
                      </div>
                      <Icon name='close' size='small' onClick={() => setIsModalOpen(false)} />
                    </div>
                  </Modal.Header>
                  <Modal.Content>
                    <Form style={{ margin: '1rem' }}>
                      <Form.Field>
                        <label>تاريخ الزيارة</label>
                        <DatePicker
                          popperPlacement='top-end'
                          placeholderText='تاريخ الزيارة'
                          value={moment(clickedBranch.date).format('YYYY/MM/DD')}
                          onChange={(date) => setClickedBranch({ ...clickedBranch, date: date })}
                          disabled={
                            alreadyVisited || clickedBranch.deleted || !hasPermission(permissions.EDIT_VISIT_DATE)
                          }
                          maxDate={new Date(clickedBranch.visitsTo)}
                          minDate={new Date(clickedBranch.visitsFrom)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>تم اسناد الزيارة الى</label>
                        <Dropdown
                          disabled={alreadyVisited || clickedBranch.deleted || !hasPermission(permissions.ASSIGN_VISIT)}
                          fluid
                          selection
                          search
                          name='userId'
                          value={clickedBranch.assignedToId}
                          options={users}
                          onChange={userAssignedHandler}
                          className='table-dropdown'
                        />
                      </Form.Field>

                      <Form.Field>
                        <div className='title-container'>
                          <div className='edit-visit-container'>
                            <div className='flex-row'>
                              <Checkbox
                                checked={clickedBranch.isVisited}
                                disabled={
                                  alreadyVisited ||
                                  clickedBranch.deleted ||
                                  !hasPermission(permissions.CLOSE_VISIT_WITHOUT_REPORT)
                                }
                                onChange={(e, v) => setClickedBranch({ ...clickedBranch, isVisited: v.checked })}
                              />
                              <div className='title'>
                                {clickedBranch.isVisited && clickedBranch.updateVisitedDate
                                  ? 'زيارة منتهية'
                                  : 'انهاء الزيارة بدون تقرير'}
                              </div>
                            </div>
                            <div>
                              {clickedBranch.isVisited && clickedBranch.updateVisitedDate && (
                                <span>
                                  <Popup
                                    content={`تم تحديث انه تم زيارة هذا الفرع بتاريخ ${moment(
                                      clickedBranch.updateVisitedDate
                                    ).format('YYYY/MM/DD')}`}
                                    trigger={<Icon name='info' size='small' circular />}
                                  />
                                </span>
                              )}
                              {!clickedBranch.deleted &&
                                clickedBranch.reportId === 0 &&
                                hasPermission(permissions.ADD_VISIT_REPORT) && (
                                  <span
                                    onClick={() =>
                                      router.push('/client-visits/generateReport', {
                                        ...clickedBranch,
                                      })
                                    }
                                  >
                                    <Popup content={'إنشاء تقرير'} trigger={<Icon name='newspaper' size='large' />} />
                                  </span>
                                )}
                              {clickedBranch.reportId !== 0 &&
                                hasPermissions([
                                  permissions.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                                  permissions.VIEW_VISIT_REPORT,
                                  permissions.ADD_VISIT_REPORT,
                                ]) && (
                                  <span
                                    onClick={() =>
                                      router.push('/client-visits/ViewReport', {
                                        id: clickedBranch.visitId,
                                      })
                                    }
                                  >
                                    <Popup
                                      content={'عرض التقرير'}
                                      trigger={<Icon name='envelope open' size='large' />}
                                    />
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </Form.Field>
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    {!clickedBranch.deleted && !clickedBranch.isVisited && hasPermission(permissions.DELETE_VISIT) && (
                      <Button
                        style={{ backgroundColor: '#dc143c', color: 'white' }}
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                          setIsModalOpen(false);
                        }}
                      >
                        حذف
                        <Icon name='trash' />
                      </Button>
                    )}
                    {!clickedBranch.deleted &&
                      !alreadyVisited &&
                      (hasPermission(permissions.EDIT_VISIT_DATE) ||
                        hasPermission(permissions.ASSIGN_VISIT) ||
                        hasPermission(permissions.CLOSE_VISIT_WITHOUT_REPORT)) && (
                        <Button style={{ backgroundColor: '#003A71', color: 'white' }} onClick={EditScheduleVisit}>
                          تعديل
                          <Icon name='pencil' />
                        </Button>
                      )}
                  </Modal.Actions>
                </Modal>
              </Transition>
              <Transition visible={isConfirmModalOpen} animation='scale' duration={250}>
                <Modal size='tiny' open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
                  <Modal.Header>حذف موعد زيارة</Modal.Header>
                  <Modal.Content>
                    <p>هل انت متأكد من حذف موعد الزيارة؟</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='grey' onClick={() => setIsConfirmModalOpen(false)}>
                      لا
                    </Button>
                    <Button color='blue' onClick={DeleteCurrentClickedVisit}>
                      نعم
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Transition>
            </div>
          </>
        )}
      </PageCardContainer>
      {modalError && <ErrorModal />}
    </div>
  );
};

export default ViewAllMaintenanceVisitCalendar;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Form, Table, Icon, Responsive, Card, Button, Checkbox } from 'semantic-ui-react';
import PageCardContainer from '../../../Layout/PageCardContainer';
import { departmentsApi, expensesApi, lookupsApi, filesApi } from '../../../../api/api';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import '../Expenses.scss';
import DropFileUpload from '../../../Reusable/DropFileUpload';

const AddTransportExpensesForm = () => {
  const otherDestinationId = 33;
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();
  const [loading, setLoading] = useState(true);
  const [userDepartments, setUserDepartments] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [destinationDetails, setDestinationDetails] = useState([]);
  const [transportOptions, setTransportOptions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    selectedDepartment: 0,
    formDate: moment().format('YYYY/MM/DD hh:mm A'),
    userId: currentUser.id,
    fromDate: moment().format('YYYY/MM/DD'),
    toDate: moment().format('YYYY/MM/DD'),
  });
  const [departmentManager, setDepartmentManager] = useState('');
  const [error, setError] = useState('');

  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    (async () => {
      const { data: departments } = await departmentsApi.getUserDepartmentsDropDown(currentUser.id);
      const { data: destinations } = await expensesApi.getDestinations();
      const { data: transports } = await lookupsApi.getGroup(165);

      const destinationsOptArr = destinations?.map((item) => ({ key: item.id, text: item.name, value: item.id }));
      const transportsOptArr = transports?.map((item) => ({ key: item.id, text: item.name, value: item.id }));
      const departmentsOptions = departments.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));

      departmentsOptions.length != 0 && setFormData({ ...formData, selectedDepartment: departmentsOptions[0]?.key });
      setUserDepartments(departmentsOptions);
      setDestinationOptions(destinationsOptArr);
      setTransportOptions(transportsOptArr);
      setDestinationDetails(destinations);
      let defaultExpense = {
        date: moment().format('YYYY/MM/DD'),
        jod: 0,
        fils: 0,
        transportId: 170,
        distance: 0,
        note: '',
        destinationId: 0,
        id: 1,
      };
      setExpenses([...expenses, defaultExpense]);
      await getDepartmentManager(departmentsOptions[0]?.key);
    })();
    setLoading(false);
  }, []);

  const getDepartmentManager = async (depId) => {
    if (!depId) return;
    const data = await departmentsApi.getDepartmentManager(depId);
    setDepartmentManager(data.data);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (validation()) {
      try {
        const transportExpenseId = await expensesApi.postTransportExpensesForm({
          departmentId: formData.selectedDepartment,
          fromDate: formData.fromDate,
          toDate: formData.toDate,
          formDate: moment().format('YYYY/MM/DD hh:mm A'),
          expenses: expenses,
        });
        if (files.length > 0) uploadFiles(transportExpenseId.data);
        router.goBack();
      } catch (e) {
        console.log('Error posting expenses...', e);
        setIsSubmitting(false);
        setError('حدث خطأ اثناء الحفظ');
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const validation = () => {
    let error = '';
    if (userDepartments.length === 0) {
      error = 'لا يمكنك اضافة فاتورة دون ان تكون تابع لدائرة';
      setError(error);
      return false;
    }

    const diffInDays = moment(formData.toDate).diff(formData.formDate, 'days');
    if (diffInDays < 0) {
      error = 'لا يمكن لتاريخ البداية أن يكون أكبر من تاريخ النهاية';
      setError(error);
      return false;
    }

    if (diffInDays > 40) {
      error = 'يجب ألا تزيد الفترة عن 40 يوم';
      setError(error);
      return false;
    }

    if (expenses.length === 0) {
      error = 'الرجاء تعبئة معلومات الفاتورة';
      setError(error);
      return false;
    }

    expenses.map((expense) => {
      if (expense.destination === otherDestinationId && (expense.distance === '' || expense.distance === 0)) {
        error = 'يرجى التأكد من تعبئة المسافة لكل السطور';
        setError(error);
        return false;
      } else if (!expense.date || !expense.jod || !expense.transport) {
        error = 'يرجى التأكد من تعبئة جميع معلومات الفاتورة بشكل صحيح';
        setError(error);
        return false;
      } else if (+expense.fils > 999) {
        error = 'الحد الاعلى 999 فلس يرجى ادخال البيانات بشكل صحيح';
        setError(error);
        return false;
      }
    });
    if (error) return false;
    return true;
  };

  const DepartmentChangeHandler = async (e, data) => {
    setFormData({ ...formData, selectedDepartment: data.value });
    await getDepartmentManager(data.value);
  };

  const handleNoteChange = (e, index) => {
    const clonedExpenses = [...expenses];
    const currentExpense = { ...clonedExpenses[index] };
    currentExpense.note = e.target.value;
    clonedExpenses[index] = currentExpense;
    setExpenses(clonedExpenses);
  };

  const DateChangeHandler = (date, index) => {
    const clonedExpenses = [...expenses];
    const currentExpense = { ...clonedExpenses[index] };
    currentExpense.date = moment(date).format('YYYY/MM/DD');
    clonedExpenses[index] = currentExpense;
    setExpenses(clonedExpenses);
  };

  const handleExpenseChange = (e, data, index) => {
    let clonedExpenses = [...expenses];

    if (data.name === 'destination') {
      const selectedDestinationDetails = destinationDetails.find((des) => des.id === data.value);
      clonedExpenses[index]['jod'] = selectedDestinationDetails?.jod;
      clonedExpenses[index]['fils'] = selectedDestinationDetails?.fils;
      clonedExpenses[index]['distance'] = 0;
      clonedExpenses[index]['destinationId'] = data.value;
    } else if (data.name === 'distance') {
      const selectedDestinationDetails = destinationDetails.find((des) => des.id === otherDestinationId);
      const totalInFils = parseInt(data.value) * selectedDestinationDetails?.fils * 10;
      clonedExpenses[index]['distance'] = data.value;
      clonedExpenses[index]['jod'] = isNaN(totalInFils) ? '' : (totalInFils / 1000) | 0;
      clonedExpenses[index]['fils'] = isNaN(totalInFils) ? 0 : totalInFils % 1000;
      setExpenses(clonedExpenses);
      return;
    } else if (data.name === 'transport') {
      clonedExpenses[index]['transportId'] = data.value;
    }
    clonedExpenses[index][data.name] = data.value;
    setExpenses(clonedExpenses);
  };

  const DeleteExpenseRow = (index) => {
    let clonedExpenses = [...expenses];
    clonedExpenses.splice(index, 1);
    setExpenses(clonedExpenses);
  };

  const addNewRow = () => {
    setExpenses([
      ...expenses,
      {
        date: moment().format('YYYY/MM/DD'),
        jod: 0,
        fils: 0,
        transportId: 170,
        distance: 0,
        destinationId: 0,
        note: '',
        id: parseInt(Math.random() * 100),
      },
    ]);
  };

  const onDropFilesChanged = (files) => {
    setFiles(files);
  };

  const uploadFiles = async (expenseFormId) => {
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const formData = new FormData();
        formData.append('file', file);
        await filesApi.uploadTransportExpensesFormAttachments(expenseFormId, formData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  return (
    <PageCardContainer className='expenses-container'>
      <div className='expenses-wrapper'>
        <h2 className='headerMargin'>مستند دفع بدل مواصلات </h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>الاسم</label>
              <Form.Input className='rtl-input' fluid value={currentUser.FullName} readOnly />
            </Form.Field>
            <Form.Field>
              <label>الدائرة</label>
              <Dropdown
                fluid
                selection
                search
                name='departmentId'
                value={formData.selectedDepartment}
                options={userDepartments}
                onChange={DepartmentChangeHandler}
                className='table-dropdown'
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>الفترة من</label>
              <DatePicker
                placeholderText='من'
                name='fromDate'
                value={formData.fromDate}
                onChange={(date) => setFormData({ ...formData, fromDate: moment(date).format('YYYY/MM/DD') })}
              />
            </Form.Field>
            <Form.Field>
              <label>الى</label>
              <DatePicker
                name='toDate'
                placeholderText='الى'
                value={formData.toDate}
                onChange={(date) => setFormData({ ...formData, toDate: moment(date).format('YYYY/MM/DD') })}
                minDate={moment(formData.fromDate, 'YYYY/MM/DD').toDate()}
                maxDate={moment(formData.fromDate, 'YYYY/MM/DD').add(40, 'days').toDate()}
              />
            </Form.Field>
          </Form.Group>
          <Button
            icon
            labelPosition='right'
            className='blue-button'
            type='button'
            style={{ marginBottom: 15 }}
            onClick={addNewRow}
          >
            <Icon name='plus' className='blue-buton-icon' />
            إضافة سطر جديد
          </Button>
          <Responsive maxWidth={767}>
            <Card.Group>
              {expenses.map((expense, index) => (
                <Card fluid key={expense.id}>
                  <Card.Content>
                    <Icon name='close' size='large' onClick={() => DeleteExpenseRow(index)} className='card-icon' />
                    <Card.Description>
                      <div className='cards-table'>
                        <Table basic='very' celled structured>
                          <Table.Body>
                            <Table.Row>
                              <>
                                <Table.Cell>
                                  <div className='flex-center'>
                                    <span>التاريخ:</span>
                                    <DatePicker
                                      popperPlacement='top-end'
                                      placeholderText='تاريخ الزيارة'
                                      value={expense.date}
                                      onChange={(date) => DateChangeHandler(date, index)}
                                    />
                                  </div>
                                </Table.Cell>
                              </>
                            </Table.Row>
                            <Table.Row>
                              <>
                                <Table.Cell width={5}>
                                  <div className='cash-wrapper'>
                                    <div className='flex-input'>
                                      <span>المبلغ-دينار : </span>
                                      <Form.Input
                                        className='rtl-input'
                                        value={expense.jod}
                                        name='jod'
                                        type='number'
                                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                                      />
                                    </div>
                                    <div className='flex-input'>
                                      <span>المبلغ-فلس : </span>
                                      <Form.Input
                                        className='rtl-input'
                                        name='fils'
                                        type='number'
                                        value={expense.fils}
                                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                                      />
                                    </div>
                                    <div className='flex-input'>
                                      <span>المسافة/كم</span>
                                      <Form.Input
                                        className='rtl-input'
                                        name='distance'
                                        type='number'
                                        value={expense.distance}
                                        readOnly={expense.destination !== otherDestinationId}
                                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                                      />
                                    </div>
                                    <div className='flex-input'>
                                      <span>الوسيلة</span>
                                      <Dropdown
                                        fluid
                                        selection
                                        search
                                        name='transport'
                                        value={expense.transportId}
                                        options={transportOptions}
                                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                                        className='table-dropdown'
                                      />
                                    </div>
                                  </div>
                                </Table.Cell>
                              </>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={5}>
                                <div className='flex-center'>
                                  <span>الملاحظات</span>
                                  <Form.Input
                                    className='rtl-input flex-1'
                                    name='note'
                                    value={expense.note}
                                    onChange={(e, data) => handleNoteChange(e, index)}
                                  />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={5}>
                                <div className='flex-center'>
                                  <span>التفاصيل : </span>
                                  <Dropdown
                                    fluid
                                    selection
                                    search
                                    name='destination'
                                    value={expense.destination}
                                    options={destinationOptions}
                                    onChange={(e, data) => handleExpenseChange(e, data, index)}
                                    className='table-dropdown'
                                  />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Responsive>
          <Responsive minWidth={768}>
            <Table celled structured>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell rowSpan='2'>التاريخ</Table.HeaderCell>
                  <Table.HeaderCell colSpan='2' textAlign='center'>
                    المبلغ
                  </Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2'>المسافة/كم</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2'>الوسيلة</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2'>الملاحظات</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2'>التفاصيل</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' />
                </Table.Row>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell>فلس</Table.HeaderCell>
                  <Table.HeaderCell>دينار</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {expenses.map((expense, index) => (
                  <Table.Row key={expense.id}>
                    <Table.Cell width={3}>
                      <DatePicker
                        popperPlacement='top-end'
                        value={expense.date}
                        onChange={(date) => DateChangeHandler(date, index)}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign='center' width={2}>
                      <label>{expense.fils}</label>
                    </Table.Cell>
                    <Table.Cell textAlign='center' width={2}>
                      <label>{expense.jod}</label>
                    </Table.Cell>
                    <Table.Cell textAlign='right' width={2}>
                      <Form.Input
                        className='rtl-input'
                        fluid
                        name='distance'
                        type='number'
                        value={expense.distance}
                        readOnly={expense.destination !== otherDestinationId}
                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign='right' width={2}>
                      <Dropdown
                        fluid
                        selection
                        search
                        name='transport'
                        value={expense.transportId}
                        options={transportOptions}
                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                        className='table-dropdown'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Input
                        className='rtl-input'
                        fluid
                        name='note'
                        value={expense.note}
                        onChange={(e, data) => handleNoteChange(e, index)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        fluid
                        selection
                        search
                        name='destination'
                        value={expense.destination}
                        options={destinationOptions}
                        onChange={(e, data) => handleExpenseChange(e, data, index)}
                        className='table-dropdown'
                      />
                    </Table.Cell>
                    <Table.Cell style={{ width: 50 }}>
                      <Icon name='close' size='large' onClick={() => DeleteExpenseRow(index)} className='card-icon' />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Responsive>
          <Form.Group widths={2} style={{ marginTop: 15 }}>
            <Form.Field>
              <label>توقيع الموظف</label>
              <Form.Input className='rtl-input input-as-label' fluid value={currentUser.FullName} readOnly />
            </Form.Field>
            <Form.Field>
              <label>تاريخ تقديم الطلب</label>
              <Form.Input className='rtl-input input-as-label' fluid value={moment().format('YYYY/MM/DD')} readOnly />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>مدير الدائرة</label>
              <Form.Input className='rtl-input input-as-label' fluid value={departmentManager} readOnly />
            </Form.Field>
            <Form.Field>
              <label>حالة الطلب</label>
              <Form.Input className='rtl-input input-as-label' fluid value='' readOnly />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>توقيع مدير الدائرة</label>
              <Form.Input className='rtl-input input-as-label' fluid value='' readOnly />
            </Form.Field>
            <Form.Field>
              <label>تاريخ الموافقة/الرفض</label>
              <Form.Input className='rtl-input input-as-label' fluid value='' readOnly />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>تمت المصادقة من قبل</label>
              <Form.Input className='rtl-input input-as-label' fluid value='' readOnly />
            </Form.Field>
            <Form.Field>
              <label>تاريخ المصادقة/الرفض</label>
              <Form.Input className='rtl-input input-as-label' fluid value='' readOnly />
            </Form.Field>
          </Form.Group>
          <Form.Field style={{ marginTop: 15 }}>
            <div className='attachments-container'>
              <DropFileUpload onFileChange={onDropFilesChanged}  maxFiles={10}/>
            </div>
            {uploadProgress > -1 && (
              <h4 style={{ textAlign: 'center' }}>
                جاري رفع المرفقات {uploadProgress}/{files.length}
              </h4>
            )}
          </Form.Field>
          <label style={{ color: '#9f3a38' }}>{error}</label>
          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default AddTransportExpensesForm;

import React from 'react';
import { useSelector } from 'react-redux';

/**
 * @param {object} props
 * @param {React.ReactElement} props.children
 * @param {'all'|'any'} [props.mode]
 * @param {number[]|number} props.permissions
 * @param {React.ReactElement} [props.showWarning]
 */
const PermissionCheck = ({ mode = 'any', permissions, children, showWarning = null }) => {
  const user = useSelector((state) => state.user).user;

  if (!permissions || !user) return null;
  let permitted = mode === 'all';
  if (Array.isArray(permissions)) {
    for (const permission of permissions) {
      if (mode === 'any') {
        if (user.p.has(permission)) {
          permitted = true;
          break;
        }
      } else {
        if (!user.p.has(permission)) {
          permitted = false;
          break;
        }
      }
    }
  } else {
    permitted = user.p.has(permissions);
  }
  return permitted ? children : Boolean(showWarning) ? showWarning : <></>;
};

export default PermissionCheck;

import React from 'react';
import './Settings.scss';
import { Card } from 'semantic-ui-react';
import SettingsCard from './SettingsCard';
import { motion } from 'framer-motion';
import PermissionCheck from '../../Reusable/PermissionCheck';
import p from '../../../helpers/permissions';

export default function SettingsList() {
  return (
    <div className='settings-list'>
      <Card.Group className='settings-card-group'>
        <PermissionCheck permissions={[p.CREATE_CLIENT, p.VIEW_CLIENTS, p.EDIT_CLIENT, p.DELETE_CLIENT]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='العملاء' iconName='address book' url='/customers' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[p.ACTIVATE_NEW_USER, p.CREATE_USER, p.DELETE_USER, p.EDIT_USER, p.CHANGE_USER_PASSWORD]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='المستخدمين' iconName='user circle' url='/users' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[p.VIEW_VEHICLES, p.EDIT_VEHICLE, p.CREATE_VEHICLE, p.DELETE_VEHICLE, p.VIEW_ALL_VEHICLES]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='المركبات' iconName='car' url='/vehicles' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.CREATE_TRACKING_UNIT,
            p.EDIT_TRACKING_UNIT,
            p.VIEW_TRACKING_UNITS,
            p.DELETE_TRACKING_UNIT,
            p.VIEW_ALL_TRACKING_UNITS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='اجهزة التتبع' iconName='map pin' url='/trackingUnits' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_ROLES, p.EDIT_ROLE, p.CREATE_ROLE, p.DELETE_ROLE]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='الصلاحيات' iconName='list alternate' url='/roles' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_LOOKUP, p.EDIT_LOOKUP, p.CREATE_LOOKUP, p.DELETE_LOOKUP]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='رموز النظام' iconName='star' url='/lookupsGroups' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[p.VIEW_DEPARTMENTS, p.EDIT_DEPARTMENT, p.CREATE_DEPARTMENT, p.DELETE_DEPARTMENT]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='الإدارات والأقسام' iconName='building' url='/departments' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_TEAMS, p.EDIT_TEAM, p.CREATE_TEAM, p.DELETE_TEAM]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='الفرق والمجموعات' iconName='users' url='/teams' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[p.VIEW_CONTRACTS, p.VIEW_ALL_CONTRACTS, p.EDIT_CONTRACT, p.CREATE_CONTRACT, p.DELETE_CONTRACT]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='العقود' iconName='pencil' url='/contracts' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_ALL_ASSETS, p.VIEW_ASSETS]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: -100 }}
          >
            <SettingsCard title='الاجهزة والقطع' iconName='boxes' url='/assets' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.EDIT_TEAM_ASSIGNMENT_DEFAULTS]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='اسناد الفرق' iconName='tasks' url='/teamAssignment' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_ALL_USERS_TRACKING_ASSETS,
            p.VIEW_USERS_TRACKING_ASSETS_BASED_ON_USER_DEPARTMENTS,
            p.EDIT_USERS_TRACKING_ASSET,
            p.CREATE_USERS_TRACKING_ASSET,
            p.DELETE_USERS_TRACKING_ASSET,
            p.LINK_TRACKING_ASSET_WITH_USER,
            p.UNLINK_TRACKING_ASSET_FROM_USER,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='اجهزة تتبع المستخدمين' iconName='paper plane' url='/usersTrackingAssets' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck permissions={[p.VIEW_MAINTENANCE_TYPES]}>
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='رموز انواع الصيانة' iconName='star' url='/viewOtherMaintenanceItems' />
          </motion.div>
        </PermissionCheck>
      </Card.Group>
    </div>
  );
}

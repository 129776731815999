import { usersLocations as actions } from '../actions';

const initialState = {
  locations: [],
  firstLoad: false,
  error: null,
  selectedUser: null,
  usersImages: {},
};

export const usersLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_USERS_LOCATIONS_SUCCESS:
      return { ...state, locations: action.payload, error: null };
    case actions.FETCH_USERS_LOCATIONS_ERROR:
      return { ...state, error: action.payload };
    case actions.SELECT_USER_LOCATION:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case actions.CLEAR_ALL_SELECTED_USERS:
      return {
        ...initialState,
      };
    case actions.SET_FIRST_LOAD:
      return { ...state, firstLoad: action.payload };
    case actions.LOAD_USERS_IMAGES:
      return {
        ...state,
        usersImages: action.payload,
      };
    default:
      return state;
  }
};

import { userAuth, tickets, usersLocations } from './index';

export const userAuthActions = {
  //saga listens to LOGIN_START
  createLoginRequest: (payload) => ({
    type: userAuth.LOGIN_START,
    payload: payload,
  }),
  //dispatches if saga was successful
  login: (payload) => ({
    type: userAuth.LOGIN_SUCCESS,
    payload: payload,
  }),
  logout: () => ({
    type: userAuth.LOGOUT,
  }),
  //dispatched if saga failed
  loginFailed: (err) => ({
    type: userAuth.LOGIN_FAILED,
    payload: err,
  }),
  //update data in redux store
  updateData: (payload) => ({
    type: userAuth.UPDATE_DATA,
    payload: payload,
  }),
};

export const ticketsActions = {
  setSelectedTicket: (ticket) => ({
    type: tickets.SET_SELECTED_TICKET,
    payload: ticket,
  }),
  clearSelectedTicket: () => ({
    type: tickets.CLEAR_SELECTED_TICKET,
  }),
};

export const usersLocationsActionsCreator = {
  usersLocationsRequest: () => ({
    type: usersLocations.FETCH_USERS_LOCATIONS_REQUEST,
  }),
  usersLocationsRequestStop: () => ({
    type: usersLocations.FETCH_USERS_LOCATIONS_STOP,
  }),
  usersLocationsSet: (data) => ({
    type: usersLocations.FETCH_USERS_LOCATIONS_SUCCESS,
    payload: data,
  }),
  usersLocationsFail: (Error) => ({
    type: usersLocations.FETCH_USERS_LOCATIONS_ERROR,
    payload: Error,
  }),
  usersLocationsSelection: (data) => ({
    type: usersLocations.SELECT_USER_LOCATION,
    payload: data,
  }),
  usersLocationsClearAllSelections: () => ({
    type: usersLocations.CLEAR_ALL_SELECTED_USERS,
  }),
  usersFirstLoadSet: (data) => ({
    type: usersLocations.SET_FIRST_LOAD,
    payload: data,
  }),
  loadUsersImages: (data) => ({
    type: usersLocations.LOAD_USERS_IMAGES,
    payload: data,
  }),
};

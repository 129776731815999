import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { customersApi, lookupsApi, assetsApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';

export default function AddAsset() {
  const currentUser = useSelector((st) => st.user).user;

  const [modelOpts, setModelOpts] = useState([]);
  const [typeOpts, setTypeOpts] = useState([]);

  const [formData, setFormData] = useState({
    imei: '',
    customerId: '',
    sim: '',
    assetModel: '',
    serialNumber: '',
    assetType: '',
    createdBy: Number(currentUser.id),
    simSerialNumber: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    imei: null,
    customerId: null,
    sim: null,
    assetModel: null,
    serialNumber: null,
    assetType: null,
    simSerialNumber: null,
  });

  const [touched, setTouched] = useState({
    imei: false,
    customerId: false,
    sim: false,
    assetModel: false,
    serialNumber: false,
    assetType: false,
    simSerialNumber: false,
  });

  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const router = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        //get lookups for types
        const { data: types } = await lookupsApi.getGroup(77);
        const options0 = types.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        setTypeOpts(options0);

        //get lookups for models
        const { data: models } = await lookupsApi.getGroup(83);
        const options1 = models.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        setModelOpts(options1);

        //get customers
        const { data: customers } = await customersApi.getCustomersDropDown();
        const options2 = customers.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        setCustomerIdOptions(options2);

        setFormData({
          ...formData,
          customerId: Boolean(options2) ? options2[0].key : '',
          assetModel: Boolean(options1) ? options1[0].key : '',
          assetType: Boolean(options0) ? options0[0].key : '',
        });

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching asset form data...', err);
        router.goBack();
      }
    };

    fetchData();
  }, []);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const ddlSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  const validation = () => {
    const errors = {};

    if (!formData.customerId) {
      errors.customerId = 'يرجى إختيار العميل';
    }
    if (!formData.assetType) {
      errors.assetType = 'يرجى إختيار نوع الجهاز';
    }
    if (!formData.assetModel) {
      errors.assetModel = 'يرجى إختيار موديل الجهاز';
    }
    if (!formData.imei) {
      errors.imei = 'الرجاء ادخال الIMEI';
    }
    if (!formData.sim) {
      errors.sim = 'الرجاء ادخال الSIM';
    }
    if (!formData.serialNumber) {
      errors.serialNumber = 'الرجاء ادخال رقم التسلسل الخاص بالجهاز';
    }
    if (!formData.simSerialNumber) {
      errors.simSerialNumber = 'الرجاء ادخال رقم التسلسل الخاص بالSIM';
    }

    setValidationErrors(errors);

    if (
      validationErrors.customerId ||
      validationErrors.assetType ||
      validationErrors.assetModel ||
      validationErrors.imei ||
      validationErrors.sim ||
      validationErrors.serialNumber ||
      validationErrors.simSerialNumber
    )
      return false;
    else return true;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      imei: true,
      customerId: true,
      sim: true,
      assetModel: true,
      serialNumber: true,
      assetType: true,
      simSerialNumber: true,
    });

    if (validation()) {
      try {
        await assetsApi.postAsset(formData);
        router.goBack();
      } catch (e) {
        console.log('Error posting tracking unit...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    validation();
  }, [formData]);

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}> اضافة جهاز جديد</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={isLoading}>
          <Form.Field>
            <label>العميل</label>
            <Dropdown
              placeholder='اختر العميل'
              fluid
              selection
              search
              value={formData['customerId']}
              error={touched.customerId && validationErrors.customerId}
              options={customerIdOptions}
              onChange={ddlSelectionChangeHandler}
              name='customerId'
              noResultsMessage='لا يوجد عملاء'
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
          </Form.Field>

          <Form.Group widths={2}>
            <Form.Field>
              <label>نوع الجهاز</label>
              <Dropdown
                placeholder='اختر نوع الجهاز'
                fluid
                selection
                search
                error={touched.assetType && validationErrors.assetType}
                value={formData['assetType']}
                options={typeOpts}
                onChange={ddlSelectionChangeHandler}
                name='assetType'
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
            <Form.Field>
              <label>موديل الجهاز</label>
              <Dropdown
                placeholder='اختر موديل الجهاز'
                fluid
                selection
                search
                error={touched.assetModel && validationErrors.assetModel}
                value={formData['assetModel']}
                options={modelOpts}
                onChange={ddlSelectionChangeHandler}
                name='assetModel'
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>IMEI</label>
              <Form.Input
                icon='qrcode'
                error={touched.imei && validationErrors.imei}
                value={formData['imei']}
                name='imei'
                onChange={onChangeHandler}
              />
            </Form.Field>

            <Form.Field>
              <label>SIM</label>
              <Form.Input
                icon='table'
                error={touched.sim && validationErrors.sim}
                value={formData['sim']}
                name='sim'
                onChange={onChangeHandler}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>رقم التسلسل</label>
              <Form.Input
                icon='barcode'
                error={touched.serialNumber && validationErrors.serialNumber}
                value={formData['serialNumber']}
                name='serialNumber'
                onChange={onChangeHandler}
              />
            </Form.Field>

            <Form.Field>
              <label>رقم التسلسل الخاص بالSIM</label>
              <Form.Input
                icon='table'
                error={touched.simSerialNumber && validationErrors.simSerialNumber}
                value={formData['simSerialNumber']}
                name='simSerialNumber'
                onChange={onChangeHandler}
              />
            </Form.Field>
          </Form.Group>
          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}

import React, { useEffect, useState } from 'react';
import DataTable from '../../Layout/Table/DataTable';
import PageCardContainer from './../../Layout/PageCardContainer';
import permissions, { hasPermission } from './../../../helpers/permissions';
import { scheduleVisits, customersApi, departmentsApi } from './../../../api/api';

const ViewAllVisitsReports = () => {
  const [customerIdOpts, setCustomerIdOpts] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState([{}]);
  const [departmentsAr, setDepartmentAr] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const tableHeaders = [
    {
      displayName: 'رقم التقرير',
      fieldName: 'reportId',
      dbFieldName: 'svr.id',
      sortable: true,
      searchable: false,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'رقم الزيارة',
      fieldName: 'id',
      dbFieldName: 'id',
      sortable: true,
      searchable: false,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'العميل',
      fieldName: 'customerName',
      dbFieldName: 'full_name',
      viewable: true,
      sortable: false,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerIdOpts,
      dropdownArMap: customerIdArMap,
    },
    {
      displayName: 'الدائرة',
      fieldName: 'department',
      dbFieldName: 'department',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: departmentOptions,
      dropdownArMap: departmentsAr,
    },
    {
      displayName: 'اسم الفرع',
      fieldName: 'branchName',
      dbFieldName: 'branch_name',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'العقد',
      fieldName: 'contractName',
      dbFieldName: 'name',
      viewable: true,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'منشىء التقرير',
      fieldName: 'username',
      dbFieldName: 'full_name',
      viewable: true,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'تاريخ الانشاء',
      fieldName: 'pmReport',
      dbFieldName: 'svr.created_at',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'تاريخ الصيانة',
      fieldName: 'visitDate',
      dbFieldName: 'visit_date',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'وصف المشكلة',
      fieldName: 'issueDesc',
      dbFieldName: 'full_name',
      viewable: false,
      sortable: false,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'وصف الاجراء المتخذ',
      fieldName: 'actionDesc',
      dbFieldName: 'full_name',
      viewable: false,
      sortable: false,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'الملاحظات',
      fieldName: 'note',
      dbFieldName: 'full_name',
      viewable: false,
      sortable: false,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'ملاحظات العميل',
      fieldName: 'customerNotes',
      dbFieldName: 'customer_notes',
      viewable: false,
      sortable: false,
      searchable: false,
      searchType: 'text',
    },
  ];

  const tableActions = [
    {
      actionType: 'view',
      actionUrl: '/client-visits/ViewReport',
    },
    ...(hasPermission(permissions.EDIT_VISIT_REPORT)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/client-visits/edit-report',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_VISIT_REPORT)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: scheduleVisits.deleteGeneratedVisitReport,
          },
        ]
      : []),
    {
      actionType: 'custom',
      onClick: (id) => window.open(`/client-visits/printVisitReport/?id=${id}`),
      title: 'طباعة',
      icon: 'print',
    },
  ];

  useEffect(() => {
    const fetchCustomers = async () => {
      const customers = await customersApi.getCustomersDropDownForScheduleVisits();
      let clientsOptionsAr = [];
      let clientsArMap = {};
      for (let item of customers.data) {
        clientsOptionsAr.push({ key: item.id, text: item.name, value: item.id });
        clientsArMap[item.id] = item.name;
      }
      const departments = await departmentsApi.getDepartmentsDropDown();
      let departmentsOptionsAr = [];
      let departmentsArMap = {};
      for (let item of departments.data) {
        departmentsOptionsAr.push({ key: item.id, text: item.name, value: item.id });
        departmentsArMap[item.id] = item.name;
      }
      setDepartmentOptions(departmentsOptionsAr);
      setDepartmentAr(departmentsArMap);
      setCustomerIdOpts(clientsOptionsAr);
      setCustomerIdArMap(clientsArMap);
    };
    fetchCustomers();
  }, []);

  return (
    <>
      <PageCardContainer>
        <div className='table-title-container merchandise-table-title-container'>
          <div>
            <h3>تقارير الزيارات</h3>
          </div>
        </div>
        <DataTable
          tableTitle='تقارير الزيارات'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={scheduleVisits.getGeneratedReportsTable}
          reloadTrigger={[]}
          infiniteMaxDate={true}
        />
      </PageCardContainer>
    </>
  );
};

export default ViewAllVisitsReports;

import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button, Divider, Form, Input, Popup, Icon } from 'semantic-ui-react';
import './UserMapSideListSearch.scss';

const emptySearch = {
  userName: '',
  departmentName: '',
  imei: '',
  simNumber: '',
  userPhone: '',
};

export default function UserMapSideListSearch({ returnSearchValues }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ ...emptySearch });

  const handleChanges = (e, v) => {
    setFormData({
      ...formData,
      [v.name]: v.value,
    });
    if (v.name === 'userName' && typeof returnSearchValues === 'function') returnSearchValues({ userName: v.value });
  };

  const onSubmit = async (e, clear) => {
    e.preventDefault();
    setOpen(!open);

    if (clear) {
      setFormData({});
      if (typeof returnSearchValues === 'function') returnSearchValues({ ...emptySearch, userName: formData.userName });
    } else {
      if (typeof returnSearchValues === 'function') returnSearchValues(formData);
    }
  };

  return (
    <div className='search-wrapper'>
      <Input
        icon='search'
        placeholder='الرجاء ادخال اسم المستخدم...'
        iconPosition='left'
        onChange={handleChanges}
        name='userName'
        value={formData.userName}
        className='rtl-input user-name-search'
      />

      <div className='advanced-search-wrapper'>
        <Popup
          content='بحث متقدم'
          trigger={<Button icon='sliders horizontal' className='shadowed-btn' onClick={() => setOpen(!open)} />}
          position='left center'
        />

        <AnimatePresence>
          {open && (
            <motion.div
              className='search-container'
              initial={{ y: -20 }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
              animate={{ y: 0 }}
            >
              <div className='search-container-title'>
                <h5>أدوات البحث</h5>
                <Icon name='remove circle' onClick={() => setOpen(!open)} />
              </div>
              <Divider />
              <Form>
                <div className='form-body-container'>
                  <div className='rtl'>
                    <div className='flex-columns'>
                      <Form.Group grouped>
                        <label>رقم هاتف المستخدم</label>
                        <Form.Input
                          name='userPhone'
                          value={formData.userPhone}
                          action={
                            Boolean(formData.userPhone) && {
                              icon: 'remove circle',
                              className: 'remove-icon',
                              onClick: () => setFormData({ ...formData, userPhone: '' }),
                            }
                          }
                          className='oneLineTextInput'
                          onChange={handleChanges}
                          autoComplete='off'
                        />
                      </Form.Group>

                      <Form.Group grouped>
                        <label>إدارة المستخدم</label>
                        <Form.Input
                          name='departmentName'
                          value={formData.departmentName}
                          action={
                            Boolean(formData.departmentName) && {
                              icon: 'remove circle',
                              className: 'remove-icon',
                              onClick: () => setFormData({ ...formData, departmentName: '' }),
                            }
                          }
                          className='oneLineTextInput'
                          onChange={handleChanges}
                          autoComplete='off'
                        />
                      </Form.Group>
                    </div>

                    <div className='flex-columns'>
                      <Form.Group grouped>
                        <label>رقم IMEI للجهاز</label>
                        <Form.Input
                          name='imei'
                          value={formData.imei}
                          action={
                            Boolean(formData.imei) && {
                              icon: 'remove circle',
                              className: 'remove-icon',
                              onClick: () => setFormData({ ...formData, imei: '' }),
                            }
                          }
                          className='oneLineTextInput'
                          onChange={handleChanges}
                          autoComplete='off'
                        />
                      </Form.Group>

                      <Form.Group grouped>
                        <label>رقم شريحة الجهاز</label>
                        <Form.Input
                          name='simNumber'
                          value={formData.simNumber}
                          action={
                            Boolean(formData.simNumber) && {
                              icon: 'remove circle',
                              className: 'remove-icon',
                              onClick: () => setFormData({ ...formData, simNumber: '' }),
                            }
                          }
                          className='oneLineTextInput'
                          onChange={handleChanges}
                          autoComplete='off'
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <Divider />
                <div>
                  <Button
                    icon
                    type='submit'
                    className='blue-button'
                    labelPosition='right'
                    style={{ marginLeft: '1rem' }}
                    onClick={(e) => onSubmit(e, false)}
                  >
                    <Icon name='search' className='blue-buton-icon' />
                    تأكيد
                  </Button>

                  <Button
                    labelPosition='right'
                    onClick={(e) => onSubmit(e, true)}
                    content='إالغاء'
                    icon='remove circle'
                  />
                </div>
              </Form>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

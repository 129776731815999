import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import UserCard from './UserCard';
import './UserMap.scss';

export default function UserMapSideList({ usersLocations }) {
  const firstLoad = useSelector((state) => state.usersLocations.firstLoad);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false);
    }
  }, [firstLoad]);

  return (
    <div className='user-cards-list'>
      {loading ? (
        <Loader active className='workaround table-loader' size='large' inline='centered' />
      ) : usersLocations?.length ? (
        usersLocations.map((item) => <UserCard key={item.id} item={item} />)
      ) : (
        <h3 className='no-data'>لا توجد بيانات</h3>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import PageCardContainer from '../Layout/PageCardContainer';
import { Button, Icon } from 'semantic-ui-react';
import { customersApi, departmentsApi } from '../../api/api';
import { useHistory } from 'react-router-dom';
import permissions, { hasPermission, hasPermissions } from '../../helpers/permissions';
import DataTable from '../Layout/Table/DataTable';
import { scheduleVisits } from '../../api/api';
import PermissionCheck from './../Reusable/PermissionCheck';

const ScheduleVisits = () => {
  const router = useHistory();
  const [clientsAr, setClientsAr] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [departmentsAr, setDepartmentAr] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const checkIfDeleted = (row) => {
    if (row.status === 'محذوفة') return false;
    return true;
  };

  const tableHeaders = [
    {
      displayName: 'رقم عقد الزيارات',
      fieldName: 'id',
      dbFieldName: 'id',
      sortable: true,
      searchable: false,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'رقم العقد',
      fieldName: 'contractId',
      dbFieldName: 'contract_id',
      sortable: true,
      searchable: false,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'العميل',
      fieldName: 'customer',
      dbFieldName: 'full_name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: clientsOptions,
      dropdownArMap: clientsAr,
    },
    {
      displayName: 'تاريخ البداية',
      fieldName: 'start',
      dbFieldName: 'contract_from',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'تاريخ النهاية',
      fieldName: 'end',
      dbFieldName: 'contract_to',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'نوع الزيارة',
      fieldName: 'visitType',
      dbFieldName: 'visit_type',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'تكرار الزيارة',
      fieldName: 'visitsCount',
      dbFieldName: 'visits_count',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'حالة الزيارات',
      fieldName: 'status',
      dbFieldName: 'status',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'الدائرة',
      fieldName: 'department',
      dbFieldName: 'department',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: departmentOptions,
      dropdownArMap: departmentsAr,
    },
  ];
  const tableActions = [
    {
      actionType: 'view',
      actionUrl: '/client-visits/view-contract',
    },
    ...(hasPermission(permissions.DELETE_SCHEDULE_VISIT)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: scheduleVisits.deleteScheduleVisit,
            displayCondition: checkIfDeleted,
          },
        ]
      : []),
  ];

  useEffect(async () => {
    const customers = await customersApi.getCustomersDropDownForScheduleVisits();
    let clientsOptionsAr = [];
    let clientsArMap = {};
    for (let item of customers.data) {
      clientsOptionsAr.push({ key: item.id, text: item.name, value: item.id });
      clientsArMap[item.id] = item.name;
    }
    const departments = await departmentsApi.getDepartmentsDropDown();
    let departmentsOptionsAr = [];
    let departmentsArMap = {};
    for (let item of departments.data) {
      departmentsOptionsAr.push({ key: item.id, text: item.name, value: item.id });
      departmentsArMap[item.id] = item.name;
    }
    setDepartmentOptions(departmentsOptionsAr);
    setDepartmentAr(departmentsArMap);
    setClientsOptions(clientsOptionsAr);
    setClientsAr(clientsArMap);
  }, []);

  return (
    <>
      <PageCardContainer>
        <div className='table-title-container merchandise-table-title-container'>
          <div>
            <h3>الصيانة الدورية و الزيارات</h3>
            <PermissionCheck permissions={permissions.ADD_SCHEDULE_VISIT}>
              <Button
                icon
                labelPosition='right'
                className='blue-button'
                onClick={() => router.push('/client-visits/add-schedule-visit')}
              >
                <Icon name='plus' className='blue-buton-icon' />
                اضافة زيارات دورية جديدة
              </Button>
            </PermissionCheck>
          </div>
        </div>
        <DataTable
          tableTitle='الصيانة الدورية و الزيارات'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={scheduleVisits.getAll}
          reloadTrigger={[]}
        />
      </PageCardContainer>
    </>
  );
};

export default ScheduleVisits;

import React from 'react';
import { useEffect, useState } from 'react';
import whiteLogo from '../../../assets/images/logo-white.jpg';
import { Checkbox, Icon, Image, Table } from 'semantic-ui-react';
import { scheduleVisits } from '../../../api/api';
import moment from 'moment';
import ErrorModal from './../../Reusable/ErrorModal';
const PrintVisitReport = () => {
  const [isLogoHidden, setIsLogoHidden] = useState(false);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');

  const [formData, setFormData] = useState({
    maintenanceDate: new Date(),
    createdBy: '',
    note: '',
    customerNotes: '',
    image: null,
    issueDesc: '',
    actionDesc: '',
    customerName: '',
    departmentName: '',
    createdAt: '',
    contractName: '',
    branchId: '',
    isVisited: '',
    branchName: '',
    visitId: id,
    representativeName: '',
    representativeSignature: null,
    companyRepresentativeSignature: null,
  });

  const [modalError, setModalError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await scheduleVisits.getGenerateReport(formData.visitId);
        setFormData({
          ...formData,
          actionDesc: data.actionDesc,
          branchName: data.branchName,
          contractName: data?.contractName,
          createdAt: data.createdAt,
          maintenanceDate: data.maintenanceDate,
          createdBy: data.username,
          note: data.note,
          customerNotes: data.customerNotes,
          image: data.image,
          issueDesc: data.issueDesc,
          customerName: data.customerName,
          departmentName: data.departmentName,
          branchName: data.branchName ? data.branchName : 'العميل نفسه',
          representativeName: data.representativeName,
          representativeSignature: data.representativeSignature,
          companyRepresentativeSignature: data.companyRepresentativeSignature,
        });
      } catch (error) {
        setModalError(true);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className='print-schedule-container'>
        <div className='header'>
          <div className='header-title'>
            <div>تقرير صيانة وقائية</div>
            <div>رقم الزيارة: {formData.visitId}</div>
          </div>
          <div className='header-logo'>
            <div className='hidable'>
              <Checkbox
                checked={isLogoHidden}
                onChange={(e, v) => setIsLogoHidden((pre) => !pre)}
                label='اخفاء شعار الشركة عند الطباعة'
              />
            </div>
            {!isLogoHidden && <Image src={whiteLogo} alt='logo' size='tiny' centered />}
          </div>
        </div>

        <div className='contents'>
          <div className='segment'>
            <h4>
              <Icon name='book' /> &nbsp; معلومات العميل
            </h4>
            <Table compact unstackable celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>العميل</Table.HeaderCell>
                  <Table.HeaderCell width={2}>الفرع</Table.HeaderCell>
                  <Table.HeaderCell width={2}>تاريخ الزيارة</Table.HeaderCell>
                  <Table.HeaderCell width={2}>تاريخ انشاء التقرير</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{formData.customerName}</Table.Cell>
                  <Table.Cell>{formData.branchName}</Table.Cell>
                  <Table.Cell className='ltr'>{`${moment(formData.maintenanceDate).format('YYYY/MM/DD')}`}</Table.Cell>
                  <Table.Cell className='ltr'>{`${moment(formData.createdAt).format('YYYY/MM/DD')}`}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          {formData.issueDesc && (
            <div className='segment'>
              <h4>
                <Icon name='pencil' /> &nbsp; وصف المشكلة
              </h4>
              <div className='desc'>{formData.issueDesc}</div>
            </div>
          )}
          {formData.actionDesc && (
            <div className='segment'>
              <h4>
                <Icon name='pencil' /> &nbsp; وصف الاجراء
              </h4>
              <div className='desc'>{formData.actionDesc}</div>
            </div>
          )}
          {formData.note && (
            <div className='segment'>
              <h4>
                <Icon name='pencil' /> &nbsp; ملاحظات
              </h4>
              <div className='desc'>{formData.note}</div>
            </div>
          )}
          {formData.customerNotes && (
            <div className='segment'>
              <h4>
                <Icon name='pencil' /> &nbsp; ملاحظات العميل
              </h4>
              <div className='desc'>{formData.customerNotes}</div>
            </div>
          )}
          <div className='signature-wrapper'>
            <div>
              <div className='segment'>
                <h4>
                  <Icon name='user circle' /> &nbsp; مندوب الشركة
                </h4>
                <div className='representative-name'>{formData.createdBy}</div>
              </div>
              {formData.companyRepresentativeSignature && (
                <div className='segment'>
                  <div className='signature-preview'>
                    <Image src={formData.companyRepresentativeSignature} className='img' centered size='tiny' />
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className='segment'>
                <h4>
                  <Icon name='user circle' /> &nbsp; مندوب العميل
                </h4>
                <div className='representative-name'>{formData.representativeName}</div>
              </div>
              {formData.representativeSignature && (
                <div className='segment'>
                  <div className='signature-preview'>
                    <Image src={formData.representativeSignature} className='img' centered size='tiny' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {modalError && <ErrorModal />}
    </>
  );
};

export default PrintVisitReport;

import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, Transition, Table, Form, Dropdown, Loader } from 'semantic-ui-react';
import { customersApi, trackingVehiclesApi, vehiclesApi } from '../../../api/api';
import DataTable from '../../Layout/Table/DataTable';
import PageCardContainer from './../../Layout/PageCardContainer';
import { useHistory } from 'react-router-dom';
import './Vehicles.scss';
import { useSelector } from 'react-redux';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';
import qs from 'qs';

export default function Vehicles() {
  const router = useHistory();
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});
  const [modalPopup, setModalPopup] = useState(false);
  // the delete trackingUnit popup
  const [deleteModalPopup, setDeleteModalPopup] = useState(false);
  const [deleteVehiclePopup, setDeleteVehiclePopup] = useState(false);
  const [unitInfo, setUnitInfo] = useState({});
  const [currentVehicleId, setCurrentVehicleId] = useState('');
  const [currentUnitId, setCurrentUnitId] = useState('');
  const [unitsOpts, setUnitsOpts] = useState([]);
  const [addUnitIdSelected, setAddUnitIdSelected] = useState('');

  const [deleteMessage, setDeleteMessage] = useState([]);
  const [isInTV, setIsInTV] = useState(false);
  const [isInMTV, setIsInMTV] = useState(false);
  const [loadingVehicleDelete, setLoadingVehicleDelete] = useState(true);
  const [loadingTVDelete, setLoadingTVDelete] = useState(true);

  const user = useSelector((st) => st.user).user;
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [loadingTrackingUnitInfo, setLoadingTrackingUnitInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //get customers
      const customers = await customersApi.getCustomersDropDown();
      let options1 = [];
      let options1ArMap = {};
      for (let item of customers.data) {
        options1.push({ key: item.id, text: item.name, value: item.id });
        options1ArMap[item.id] = item.name;
      }
      setCustomerIdOptions(options1);
      setCustomerIdArMap(options1ArMap);
    };

    fetchData();
  }, [rerenderFlag]);

  const handleLinkedUnitClick = async (id) => {
    setCurrentVehicleId(id);
    setLoadingTrackingUnitInfo(true);
    setModalPopup(true);

    try {
      const res = await trackingVehiclesApi.getLinkedTrackingUnit(id);
      if (res.data) {
        setUnitInfo(res.data);
      } else {
        setUnitInfo('');
        const vehicle = await vehiclesApi.getOneVehicle(id);
        const TU = await trackingVehiclesApi.getUnlinkedTUList(vehicle.data.customerId);
        let options = [];
        // in case there are no tracking units, set currently selected id = -1
        if (TU.data && TU.data.length) {
          options = TU.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
          setAddUnitIdSelected(options[0].key);
        } else {
          setAddUnitIdSelected(-1);
        }
        setUnitsOpts(options);
      }
    } catch (err) {
      console.log('Error fetching linked tracking unit...', err);
    }

    setLoadingTrackingUnitInfo(false);
  };

  const deleteUnitHandler = async (id) => {
    setLoadingTVDelete(true);
    setDeleteModalPopup(true);
    setCurrentUnitId(id);
    const { data: res } = await trackingVehiclesApi.checkVehicleInMTV(currentVehicleId);
    if (res) {
      setIsInMTV(true);
      setDeleteMessage([
        'الارتباط المطلوب حذفه موجود في تذكرة صيانة مفتوحة حاليا. سيتم حذف الارتباط من تذكرة الصيانة واذا كان هذا الارتباط الوحيد سيتم حذف تذكرة الصيانة بالكامل.',
      ]);
    }
    setLoadingTVDelete(false);
  };

  const deleteUnitConfirmHandler = async () => {
    try {
      if (isInMTV) {
        const queryString = qs.stringify(
          {
            vehicleId: currentVehicleId,
            deleteVehicle: 0,
          },
          { addQueryPrefix: true }
        );
        await trackingVehiclesApi.deleteVehicleFromMTV(queryString);
      } else {
        await trackingVehiclesApi.deleteTrackingVehicle(currentUnitId);
      }
    } catch (err) {
      console.log('Error deleting linked vehicle...', err);
    }
    setDeleteModalPopup(false);
    setModalPopup(false);
    setIsInMTV(false);
    setIsInTV(false);
    setDeleteMessage([]);
    setRerenderFlag(!rerenderFlag);
  };

  const deleteVehicleConfirmHandler = async () => {
    try {
      if (isInTV && isInMTV) {
        const queryString = qs.stringify(
          {
            vehicleId: currentVehicleId,
            deleteVehicle: 1,
          },
          { addQueryPrefix: true }
        );
        await trackingVehiclesApi.deleteVehicleFromMTV(queryString);
      } else {
        await vehiclesApi.deleteVehicle(currentVehicleId);
      }
    } catch (err) {
      console.log('Error deleting tracking unit...', err);
    }
    setDeleteVehiclePopup(false);

    setIsInMTV(false);
    setIsInTV(false);
    setDeleteMessage([]);
    setRerenderFlag(!rerenderFlag);
  };

  const addUnitSelectionHandler = (e, data) => {
    setAddUnitIdSelected(data.value);
  };

  const handleVehicleDelete = async (id, row) => {
    setLoadingVehicleDelete(true);
    setDeleteVehiclePopup(true);
    setCurrentVehicleId(id);
    let deleteMessageArr = [];
    if (row.imei !== '-') {
      deleteMessageArr.push('المركبة المطلوب حذفها مرتبطة بجهاز تتبع. سيتم حذف ارتباط هذه المركبة بالجهاز.');
      setIsInTV(true);
      const { data: res } = await trackingVehiclesApi.checkVehicleInMTV(id);
      if (res) {
        setIsInMTV(true);
        deleteMessageArr.push(
          'المركبة المطلوب حذفها موجودة في تذكرة صيانة مفتوحة حاليا. سيتم حذف ارتباط هذه المركبة في تذكرة الصيانة المفتوحة وفي حال كانت هذه المركبة الوحيدة في التذكرة سيتم حذف التذكرة بالكامل.'
        );
      }
    }
    setDeleteMessage(deleteMessageArr);
    setLoadingVehicleDelete(false);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const v = await vehiclesApi.getOneVehicle(currentVehicleId);

      const data = {
        trackingUnitId: addUnitIdSelected,
        vehicleId: currentVehicleId,
        customerId: v.data.customerId,
        createdBy: Number(user.id),
      };
      await trackingVehiclesApi.postTrackingVehicle(data);
      setRerenderFlag(!rerenderFlag);
      setModalPopup(false);
    } catch (err) {
      console.log('Error linking vehicle with tracking unit...', err);
    }
  };

  const tableHeaders = [
    {
      displayName: 'رقم المركبة',
      fieldName: 'plateNumber',
      dbFieldName: 'plate_number',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'العميل',
      fieldName: 'customerId',
      dbFieldName: 'customer_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerIdOptions,
      dropdownArMap: customerIdArMap,
    },
    {
      displayName: 'IMEI',
      fieldName: 'imei',
      dbFieldName: 'imei',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
    },
  ];

  const tableActions = [
    ...(hasPermissions([
      permissions.VIEW_ALL_TRACKING_VEHICLES,
      permissions.ASSOCIATE_TRACKING_VEHICLE,
      permissions.DISASSOCIATE_TRACKING_VEHICLE,
    ])
      ? [
          {
            actionType: 'custom',
            onClick: handleLinkedUnitClick,
            title: 'جهاز التتبع',
            icon: 'linkify',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_VEHICLE)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/vehicles/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_VEHICLE)
      ? [
          {
            actionType: 'custom',
            onClick: handleVehicleDelete,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>المركبات</h3>
          <PermissionCheck permissions={permissions.CREATE_VEHICLE}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/vehicles/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة مركبة جديدة
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='المركبات'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={vehiclesApi.getAllVehicles}
          optionalQsParams={{}}
          reloadTrigger={[rerenderFlag]}
        />
      </PageCardContainer>

      {/* Modal for linking a vehicle with a tracking unit */}
      <Transition visible={modalPopup} animation='scale' duration={1000}>
        <Modal size='small' open={modalPopup}>
          <Modal.Header>الجهاز المرتبط بالمركبة</Modal.Header>
          <Modal.Content>
            {!loadingTrackingUnitInfo ? (
              unitInfo ? (
                <>
                  <div className='modal-contents'>
                    <Table basic='very' unstackable className='modal-table'>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='qrcode' /> IMEI:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{unitInfo.imei}</b>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='table' /> SIM:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{unitInfo.sim}</b>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='list ul' /> نوع الجهاز:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{unitInfo.model}</b>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='barcode' /> رقم التسلسل:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{unitInfo.serialNumber ? unitInfo.serialNumber : 'لم يتم الادخال'}</b>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='archive' /> Firmware Version:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{unitInfo.fwVersion ? unitInfo.fwVersion : 'لم يتم الادخال'}</b>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ margin: '1rem' }} className='form-margin'>
                    <Form style={{ margin: '1rem' }}>
                      <Form.Field>
                        <label>IMEI جهاز التتبع</label>
                        <Dropdown
                          placeholder={
                            addUnitIdSelected === -1 ? 'لا توجد اجهزة تتبع غير مربوطة لهذا العميل' : 'اختر جهاز التتبع'
                          }
                          fluid
                          selection
                          search
                          value={addUnitIdSelected}
                          options={unitsOpts}
                          onChange={addUnitSelectionHandler}
                          className='table-dropdown'
                          style={{ marginLeft: '0.5rem' }}
                          disabled={addUnitIdSelected === -1}
                        />
                      </Form.Field>
                    </Form>
                  </div>
                </div>
              )
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            {!loadingTrackingUnitInfo && unitInfo && (
              <PermissionCheck permissions={permissions.DISASSOCIATE_TRACKING_VEHICLE}>
                <Button color='red' onClick={() => deleteUnitHandler(unitInfo.id)}>
                  فك الارتباط
                </Button>
              </PermissionCheck>
            )}
            {!loadingTrackingUnitInfo && !unitInfo && addUnitIdSelected !== -1 && (
              <PermissionCheck permissions={permissions.ASSOCIATE_TRACKING_VEHICLE}>
                <Button className='blue-button' onClick={(e) => onSubmitHandler(e)}>
                  ربط الجهاز بالمركبة
                </Button>
              </PermissionCheck>
            )}

            <Button onClick={() => setModalPopup(false)}>اغلاق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* a modal for deleting a tracking vehicle link */}
      <Transition visible={deleteModalPopup} animation='scale' duration={1000}>
        <Modal size='tiny' open={deleteModalPopup}>
          <Modal.Header>تأكيد فك الارتباط</Modal.Header>
          <Modal.Content>
            {!loadingTVDelete ? (
              <div className='delete-ticket-errors-modal'>
                {Boolean(deleteMessage) &&
                  Boolean(deleteMessage.length) &&
                  deleteMessage.map((str, index) => (
                    <p className='red-text' key={index}>
                      <Icon name='circle' size='tiny' />
                      {str}
                    </p>
                  ))}
                <p>هل انت متأكد من فك الارتباط؟</p>
              </div>
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteUnitConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setDeleteModalPopup(false);
                setIsInMTV(false);
                setIsInTV(false);
                setDeleteMessage([]);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* a modal for deleting a vehicle */}
      <Transition visible={deleteVehiclePopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteVehiclePopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            {!loadingVehicleDelete ? (
              <div className='delete-ticket-errors-modal'>
                {Boolean(deleteMessage) &&
                  Boolean(deleteMessage.length) &&
                  deleteMessage.map((str, index) => (
                    <p className='red-text' key={index}>
                      <Icon name='circle' size='tiny' />
                      {str}
                    </p>
                  ))}
                <p>هل انت متأكد من الحذف؟</p>
              </div>
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteVehicleConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setDeleteVehiclePopup(false);
                setIsInMTV(false);
                setIsInTV(false);
                setDeleteMessage([]);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

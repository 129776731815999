import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Icon, Dropdown, Button, Segment, Responsive } from 'semantic-ui-react';

import { teamsApi, usersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import userIcon from './../../../assets/images/user-icon.png';
import './team-members.scss';

const AssignTeamMembers = () => {
  const location = useLocation();
  const router = useHistory();
  const currentUser = useSelector((st) => st.user).user;
  const { id, row } = location.state;

  const [members, setMembers] = useState([]);
  const [usersDisplayOpts, setUsersDisplayOpts] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userImages, setUserImages] = useState({});

  useEffect(() => {
    const loadMembers = async () => {
      try {
        const membersRes = (await teamsApi.getMembers(id)).data;
        const users = (await usersApi.getAllExceptCustomersDropdown()).data.filter((user) => {
          return !Boolean(membersRes.find((mem) => user.id === mem.userId));
        });

        const options = users.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        // Get old member images + set their current state
        const imgs = {};
        for (const member of membersRes) {
          const img = await usersApi.getImageById(member.userId);
          member.state = 'old';
          imgs[member.userId] = img.data;
        }

        setUserImages(imgs);
        setMembers(membersRes);
        setUsersDisplayOpts(options);
        setLoading(false);
      } catch (error) {
        // TODO: Display an error somewhere
        console.log('error loading members', error);
      }
    };
    if (id) {
      loadMembers();
    } else {
      router.goBack();
    }
  }, [id]);

  const selectionChangeHandler = (e, v) => {
    setSelectedUserId(v.value);
  };

  const addMemberHandler = async () => {
    const user = usersDisplayOpts.find((k) => k.value === selectedUserId);
    if (!userImages[selectedUserId]) {
      setLoading(true);
      const img = await usersApi.getImageById(selectedUserId);
      setUserImages({ ...userImages, [selectedUserId]: img.data });
      setLoading(false);
    }

    setMembers([
      ...members,
      {
        userId: user.value,
        fullName: user.text,
        state: 'new',
      },
    ]);
    setUsersDisplayOpts(usersDisplayOpts.filter((k) => k.value !== selectedUserId));
    setSelectedUserId(null);
  };

  const onCancelRemove = (userId) => () => {
    const mems = [...members];
    const member = mems.find((mem) => mem.userId === userId);
    if (member.state === 'removed') member.state = 'old';
    setMembers(mems);
  };

  const onRemove = (userId) => () => {
    const mems = [...members];
    const member = mems.find((mem) => mem.userId === userId);
    if (member.state === 'removed') return;
    else if (member.state === 'old') member.state = 'removed';
    else {
      const index = mems.indexOf(member);
      mems.splice(index, 1);
      setUsersDisplayOpts([
        ...usersDisplayOpts,
        {
          value: member.userId,
          key: member.userId,
          text: member.fullName,
        },
      ]);
    }
    setMembers(mems);
  };

  const onSave = async () => {
    const data = {
      addedMemberIds: members.filter((mem) => mem.state === 'new').map((mem) => mem.userId),
      removedMemberIds: members.filter((mem) => mem.state === 'removed').map((mem) => mem.userId),
      updatedBy: currentUser.id,
    };

    try {
      const res = (await teamsApi.updateMembers(id, data)).data;
      if (res === 1) {
        router.goBack();
      } else {
        // TODO: show some error
      }
    } catch (error) {
      // TODO: Display an error somewhere
      console.log('error saving members', error);
    }
  };

  return (
    <PageCardContainer>
      <div className='form-margin m1 team-members-page'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل اعضاء فريق</h2>

        <Table basic='very' size='large' collapsing unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Icon name='users' className='ml1' /> الفريق:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.name}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='building' className='ml1' /> الادارة:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.department}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='user plus' className='ml1' /> المدير:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.manager}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='user' className='ml1' /> رئيس الفريق:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.teamLeader}</b>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Segment loading={loading}>
          <h3>الاعضاء</h3>
          <div className='flex-between'>
            <Dropdown
              name='selectedUserId'
              placeholder={!usersDisplayOpts.length ? 'لا يوجد مستخدمين يمكن اضافتهم للفريق' : 'اختر المستخدم'}
              fluid
              selection
              search
              onChange={selectionChangeHandler}
              options={usersDisplayOpts}
              value={selectedUserId}
              disabled={!usersDisplayOpts.length ? true : false}
            />
            <Button
              type='submit'
              icon='plus'
              style={{ width: '40px', height: '40px' }}
              disabled={!usersDisplayOpts.length || !Boolean(selectedUserId) ? true : false}
              onClick={addMemberHandler}
            />
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Icon name='user' className='ml1' />
                  الاسم
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {members.length ? (
                members.map((member) => (
                  <Table.Row
                    key={member.userId}
                    negative={member.state === 'removed'}
                    positive={member.state === 'new'}
                  >
                    <Table.Cell>
                      <div className='flex-between-center'>
                        <div className='user-image-container'>
                          <img className='user-image' src={userImages[member.userId] || userIcon} alt='' />
                          <label>{member.fullName}</label>
                        </div>
                        {member.state === 'removed' ? (
                          <Icon
                            name='undo'
                            color='green'
                            className='remove-icon'
                            onClick={onCancelRemove(member.userId)}
                          />
                        ) : (
                          <Icon name='cancel' color='red' className='remove-icon' onClick={onRemove(member.userId)} />
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell>لا يوجد أعضاء في الفريق</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        <p>
          ملاحظة: في حال وجود تذاكر مفتوحة أو قيد التنفيذ لأحد الاعضاء وتمت إزالته فسيتم إسناد هذه التذاكر الى رئيس
          الفريق
        </p>
        <Responsive minWidth={768}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              type='button'
              // loading={isSubmitting}
              // disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginLeft: '1rem' }}
              onClick={onSave}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              icon='chevron right'
              type='button'
              labelPosition='right'
              content='رجوع'
              onClick={() => {
                router.goBack();
              }}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={767}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              type='button'
              icon
              fluid
              // loading={isSubmitting}
              // disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginBottom: '1rem' }}
              onClick={onSave}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              type='button'
              fluid
              icon='chevron right'
              labelPosition='right'
              content='رجوع'
              onClick={() => {
                router.goBack();
              }}
            />
          </div>
        </Responsive>
      </div>
    </PageCardContainer>
  );
};

export default AssignTeamMembers;

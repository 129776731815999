import React, { useState, useEffect } from 'react';
import {
  Table,
  Icon,
  Popup,
  Pagination,
  Modal,
  Button,
  Transition,
  Responsive,
  Card,
  Loader,
  Dropdown,
} from 'semantic-ui-react';
import _ from 'lodash';
import SearchTable from './SearchTable';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import './DataTable.scss';
import moment from 'moment';
import { excelExport } from './../../../helpers/excelExport';

export default function DataTable({
  tableTitle,
  headers,
  actions,
  apiFunction,
  optionalQsParams,
  reloadTrigger,
  infiniteMaxDate = false,
}) {
  const [data, setData] = useState([]);
  const [searchObj, setSearchObj] = useState({});
  const [deletePopup, setDeletePopup] = useState({
    popup: false,
    id: null,
    actionApi: null,
  });
  const [paginationObj, setPaginationObj] = useState({
    totalItems: 0,
    activePage: 1,
    totalPages: 0,
  });
  const [currentNumOfPages, setCurrentNumOfPages] = useState(5);
  const [sortObj, setSortObj] = useState({
    sortColumn: '',
    direction: '',
  });
  const [loading, setLoading] = useState(true);
  const router = useHistory();

  const [offsetFlag, setOffsetFlag] = useState(false);

  const pageItemsDropdown = [
    {
      key: 5,
      text: 5,
      value: 5,
    },
    {
      key: 10,
      text: 10,
      value: 10,
    },
    {
      key: 50,
      text: 50,
      value: 50,
    },
    {
      key: 100,
      text: 100,
      value: 100,
    },
    {
      key: 300,
      text: 300,
      value: 300,
    },
  ];

  const returnPaginationParams = () => {
    return {
      offset: (paginationObj.activePage - 1) * currentNumOfPages,
      limit: currentNumOfPages,
    };
  };

  const returnSortParams = () => {
    return sortObj.sortColumn && sortObj.direction ? sortObj : '';
  };

  async function fetchData() {
    setLoading(true);
    let searchObjParams = {};
    // since only date values are arrays, convert them to from to before sending in qs
    Object.entries(searchObj).map(([key, value]) => {
      if (Array.isArray(value)) {
        searchObjParams[`${key}From`] = value[0];
        searchObjParams[`${key}To`] = moment(new Date(moment(value[1]).add(1, 'd')).toLocaleDateString()).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      } else searchObjParams[key] = value;

      // in the case of boolean value convert 1/2 to true/false
      if (key === 'mainPowerAlerts') {
        searchObjParams['mainPowerAlerts'] =
          searchObjParams['mainPowerAlerts'] === 2 ? false : searchObjParams['mainPowerAlerts'];
        searchObjParams['mainPowerAlerts'] =
          searchObjParams['mainPowerAlerts'] === 1 ? true : searchObjParams['mainPowerAlerts'];
      }
    });

    try {
      const queryString = qs.stringify(
        {
          ...optionalQsParams,
          ...returnPaginationParams(),
          ...returnSortParams(),
          ...searchObjParams,
        },
        { addQueryPrefix: true }
      );

      const res = await apiFunction(queryString);
      const resData = res.data.data ? res.data.data : res.data;

      for (let item of resData) {
        for (let header of headers) {
          // place '-' in the output if data field is
          item[header.fieldName] =
            item[header.fieldName] === true
              ? 'نعم'
              : item[header.fieldName] === false
              ? 'لا'
              : item[header.fieldName]
              ? item[header.fieldName]
              : '-';
          // check if the header type is date and format the output accordingly
          item[header.fieldName] =
            header.searchType === 'date' && item[header.fieldName] !== '-'
              ? moment(item[header.fieldName]).format('YYYY/MM/DD')
              : item[header.fieldName];
        }
      }

      setData(resData);
      setLoading(false);

      setPaginationObj({
        ...paginationObj,
        totalItems: res.data.total ? res.data.total : 0,
        totalPages: res.data.total ? Math.ceil(res.data.total / currentNumOfPages) : 0,
      });
    } catch (err) {
      console.log('Error fetching data...', err.response ?? err);
    }
  }

  useEffect(() => {
    fetchData();
  }, [paginationObj.activePage, searchObj, currentNumOfPages]);

  useEffect(() => {
    //works for external trigger reload
    if (paginationObj.activePage === 1) fetchData();
    setPaginationObj({ ...paginationObj, activePage: 1 });
  }, [...reloadTrigger]);

  useEffect(() => {
    // todo solve two api calls when active page != 1 (searchObj, offsetFlag )
    setPaginationObj({ ...paginationObj, activePage: 1 });
  }, [offsetFlag]);

  const viewAction = async (id, routeName) => {
    router.push(routeName, { id });
  };

  const editAction = async (id, routeName, row) => {
    router.push(routeName, { id, row });
  };

  const deleteAction = async (id, actionApi) => {
    setDeletePopup({ popup: true, id: id, actionApi: actionApi });
  };

  const deleteConfirmHandler = async () => {
    try {
      const res = await deletePopup.actionApi(deletePopup.id);
      if (paginationObj.activePage === 1) fetchData();
      setPaginationObj({ ...paginationObj, activePage: 1 });
      setDeletePopup({ popup: false, id: null });
    } catch (err) {
      console.log('Error deleting...', err);
    }
  };

  const editButton = (id, routeName, row) => (
    <span onClick={() => editAction(id, routeName, row)}>
      <Popup
        content='تعديل'
        trigger={<Icon name='pencil alternate' className='blue-icon' style={{ margin: 'auto 0.5rem' }} />}
      />
    </span>
  );

  const editPasswordButton = (id, routeName) => (
    <span onClick={() => editAction(id, routeName)}>
      <Popup
        content='تعديل كلمة السر'
        trigger={<Icon name='key' className='yellow-icon' style={{ margin: 'auto 0.5rem' }} />}
      />
    </span>
  );

  const deleteButton = (id, actionApi) => (
    <span onClick={() => deleteAction(id, actionApi)}>
      <Popup content='حذف' trigger={<Icon name='trash' className='red-icon' style={{ margin: 'auto 0.5rem' }} />} />
    </span>
  );

  const viewButton = (id, routeName) => (
    <span onClick={() => viewAction(id, routeName)}>
      <Popup content='عرض' trigger={<Icon name='eye' className='blue-icon' style={{ margin: 'auto 0.5rem' }} />} />
    </span>
  );

  const customButton = (id, action, row) => (
    <span
      onClick={async () => {
        await action.onClick(id, row);
        if (action.reloadOnClick) {
          fetchData();
        }
      }}
    >
      <Popup
        content={action.title}
        position='top center'
        trigger={
          <Icon
            name={action.icon}
            className={'blue-icon'}
            {...(action.color ? { color: action.color } : {})}
            style={{ margin: 'auto 0.5rem' }}
          />
        }
      />
    </span>
  );

  const onSortHandler = async (column) => {
    let sort = {
      sortColumn: column,
      direction: sortObj.direction === 'desc' ? 'asc' : 'desc',
    };
    setSortObj(sort);
    try {
      const queryString = qs.stringify(
        {
          ...optionalQsParams,
          ...returnPaginationParams(),
          ...sort,
          ...searchObj,
        },
        { addQueryPrefix: true }
      );

      const res = await apiFunction(queryString);
      const resData = res.data.data ? res.data.data : res.data;

      for (let item of resData) {
        for (let header of headers) {
          // place '-' in the output if data field is
          item[header.fieldName] =
            item[header.fieldName] === true
              ? 'نعم'
              : item[header.fieldName] === false
              ? 'لا'
              : item[header.fieldName]
              ? item[header.fieldName]
              : '-';
          // check if the header type is date and format the output accordingly
          item[header.fieldName] =
            header.searchType === 'date' ? moment(item[header.fieldName]).format('YYYY/MM/DD') : item[header.fieldName];
        }
      }

      setData(resData);
    } catch (err) {
      console.log('Error fetching data...', err);
    }
  };

  const onPageChangeHandler = (e, data) => {
    setPaginationObj({ ...paginationObj, activePage: data.activePage });
  };

  const footerColSpan = actions.length ? headers.length + actions.length : headers.length + 1;

  const handlePageDropdown = (e, data) => {
    setCurrentNumOfPages(data.value);
    setPaginationObj({ ...paginationObj, activePage: 1 });
  };

  const exportFileHandler = () => {
    excelExport(data, tableTitle);
  };

  return (
    <div className='settings-table'>
      <Button icon labelPosition='right' className='excel-button' onClick={exportFileHandler}>
        <Icon name='file excel' className='excel-button-icon' />
        تصدير البيانات الى تقرير اكسل
      </Button>
      <SearchTable
        returnedOffset={(e) => {
          setOffsetFlag(e);
        }}
        fillSearch={(obj) => setSearchObj(obj)}
        fields={headers}
        infiniteMaxDate={infiniteMaxDate}
      />
      {loading ? (
        <Loader active className='workaround table-loader' size='large' inline='centered' />
      ) : data && data.length ? (
        <>
          <Responsive maxWidth={767}>
            {/* cards display */}
            <Card.Group stackable>
              {/* look over data, for each field, display header */}
              {data.map((row, index) => (
                <Card fluid key={index}>
                  <Card.Content>
                    <Card.Description>
                      <div className='cards-table'>
                        <Table basic='very' unstackable compact>
                          <Table.Body>
                            {headers.map((header, header_index) => {
                              return (
                                <Table.Row key={header_index}>
                                  {header.viewable && (
                                    <>
                                      <Table.Cell width={5}>
                                        <span>{header.displayName}:</span>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <b>{row[header.fieldName]}</b>
                                      </Table.Cell>
                                    </>
                                  )}
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      </div>
                    </Card.Description>
                  </Card.Content>
                  {Boolean(actions && actions.length) && (
                    <Card.Content extra>
                      <div className='responsive-actions-div'>
                        {actions.map((action, index) => {
                          return (
                            <span key={index}>
                              {!action.displayCondition || action.displayCondition(row)
                                ? action.actionType === 'edit' && row.editable !== false
                                  ? editButton(row.id, action.actionUrl, row)
                                  : action.actionType === 'editPassword' && row.editable !== false
                                  ? editPasswordButton(row.id, action.actionUrl)
                                  : action.actionType === 'delete' && row.editable !== false
                                  ? deleteButton(row.id, action.deleteActionApi)
                                  : action.actionType === 'view'
                                  ? viewButton(row.id, action.actionUrl)
                                  : action.actionType === 'custom' 
                                  ? customButton(row.id, action, row)
                                  : null
                                : null}
                            </span>
                          );
                        })}
                      </div>
                    </Card.Content>
                  )}
                </Card>
              ))}
            </Card.Group>
            <div className='footer-card-pagination'>
              <Pagination
                activePage={paginationObj.activePage}
                totalPages={paginationObj.totalPages}
                onPageChange={onPageChangeHandler}
              />

              <div className='responsive-pages-dropdown'>
                <span>عرض</span>

                <Dropdown
                  placeholder='بحث عن...'
                  selection
                  search
                  value={currentNumOfPages}
                  options={pageItemsDropdown}
                  onChange={(e, data) => handlePageDropdown(e, data)}
                />

                <span>
                  حقل في الصفحة من اصل <b>{paginationObj.totalItems}</b>
                </span>
              </div>
            </div>
          </Responsive>
          <Responsive minWidth={768}>
            <Table sortable celled>
              <Table.Header>
                <Table.Row>
                  {/* headers */}
                  {headers.map((header, index) => {
                    return (
                      header.viewable && (
                        <Table.HeaderCell
                          key={index}
                          width={3}
                          sorted={
                            header.sortable &&
                            (sortObj.sortColumn === header.dbFieldName
                              ? sortObj.direction === 'desc'
                                ? 'descending'
                                : 'ascending'
                              : null)
                          }
                          onClick={() => header.sortable && onSortHandler(header.dbFieldName)}
                        >
                          {header.displayName}
                        </Table.HeaderCell>
                      )
                    );
                  })}
                  {Boolean(actions && actions.length) && <Table.HeaderCell width={4}></Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.map((row, index) => (
                  <Table.Row key={index}>
                    {headers.map((header, index) => {
                      return (
                        header.viewable && (
                          <Table.Cell width={1} key={index}>
                            {row[header.fieldName]}
                          </Table.Cell>
                        )
                      );
                    })}

                    {/* loop over actions passed as props */}
                    {Boolean(actions && actions.length) && (
                      <Table.Cell width={3} style={{ textAlign: 'left' }} key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {actions.map((action, index) => {
                            return (  
                              <span key={index}>
                                {/* check if the current item is editable or not */}
                                {!action.displayCondition || action.displayCondition(row)
                                  ? action.actionType === 'edit' && row.editable !== false
                                    ? editButton(row.id, action.actionUrl, row)
                                    : action.actionType === 'editPassword' && row.editable !== false
                                    ? editPasswordButton(row.id, action.actionUrl)
                                    : action.actionType === 'delete' && row.editable !== false
                                    ? deleteButton(row.id, action.deleteActionApi)
                                    : action.actionType === 'view'
                                    ? viewButton(row.id, action.actionUrl)
                                    : action.actionType === 'custom'
                                    ? customButton(row.id, action, row)
                                    : null
                                  : null}
                              </span>
                            );
                          })}
                        </div>
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={footerColSpan}>
                    <div className='footer'>
                      <Pagination
                        activePage={paginationObj.activePage}
                        totalPages={paginationObj.totalPages}
                        onPageChange={onPageChangeHandler}
                      />

                      <div className='pages-dropdown'>
                        <span>عرض</span>

                        <Dropdown
                          placeholder='بحث عن...'
                          selection
                          search
                          value={currentNumOfPages}
                          options={pageItemsDropdown}
                          onChange={(e, data) => handlePageDropdown(e, data)}
                        />

                        <span>
                          حقل في الصفحة من اصل <b>{paginationObj.totalItems}</b>
                        </span>
                      </div>
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Responsive>
        </>
      ) : (
        <h3 className='no-data'>لا توجد بيانات</h3>
      )}
      <Transition visible={deletePopup.popup} animation='scale' duration={1000}>
        <Modal size='small' open={deletePopup.popup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <p>هل انت متأكد من الحذف؟</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteConfirmHandler(deletePopup.id)}>
              نعم
            </Button>
            <Button color='green' onClick={() => setDeletePopup({ ...deletePopup, popup: false })}>
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { customerTicketsApi } from '../../api/api';
import PageCardContainer from '../Layout/PageCardContainer';
import DataTable from '../Layout/Table/DataTable';
import { Button, Icon } from 'semantic-ui-react';
import AnimatedModal from '../Reusable/AnimatedModal';
import DatePicker from 'react-datepicker';
import qs from 'qs';
import { multipleSheetsExcelExport } from './../../helpers/multipleSheetsExcelExport';

const today = new Date();
const tomorrow = new Date(today);

const EvaluationTable = () => {
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [scoreOptions, setScoreOptions] = useState([]);
  const [scoreOptionsMap, setScoreOptionsMap] = useState({});
  const [editStatusModalAttr, setEditStatusModalAttr] = useState({
    visible: false,
    loading: false,
    position: { x: 0, y: 0 },
  });
  const [searchDateFrom, setSearchDateFrom] = useState(today);
  const [searchDateTo, setSearchDateTo] = useState(tomorrow);
  const [futureDate, setFutureDate] = useState(false);

  useEffect(() => {
    const ar = new Array(5).fill();
    setScoreOptions(
      ar.map((_, i) => ({
        key: i + 1,
        value: i + 1,
        text: i + 1,
      }))
    );
    setScoreOptionsMap(ar.reduce((p, _, i) => ({ ...p, [i + 1]: i + 1 })));
  }, []);

  useEffect(() => {
    console.log({
      scoreOptionsMap,
      scoreOptions,
    });
    return () => {};
  }, [scoreOptionsMap, scoreOptions]);
  const onGenerateReport = async () => {
    setEditStatusModalAttr({ ...editStatusModalAttr, loading: true });
    const queryString = qs.stringify(
      {
        from: searchDateFrom,
        to: searchDateTo,
      },
      { addQueryPrefix: true }
    );
    var res = await customerTicketsApi.getEvaluationsStatistics(queryString);
    console.log(res);
    multipleSheetsExcelExport(
      [[...res.data.ticketEvaluations], [...res.data.ticketEvaluationsStatistics]],
      ['Results', 'Aggregate Data'],
      'RatingTicketsReport'
    );
    setEditStatusModalAttr({ ...editStatusModalAttr, visible: false, loading: false });
  };
  const onGenerateFileClicked = (e) => {
    setEditStatusModalAttr({
      visible: true,
      loading: false,
      position: {
        x: -window.innerWidth / 2 + e.screenX,
        y: -window.innerHeight / 2 + e.screenY - 80, // 80: half the modal height,
      },
    });
  };
  const tableHeaders = [
    {
      displayName: 'رقم التذكرة',
      fieldName: 'ticketId',
      dbFieldName: 'ticket_id',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'تاريخ التقييم',
      fieldName: 'evaluatedAt',
      dbFieldName: 'evaluated_at',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'التقييم',
      fieldName: 'score',
      dbFieldName: 'score',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: scoreOptions,
      dropdownArMap: scoreOptionsMap,
    },
    {
      displayName: 'الاسم',
      fieldName: 'userName',
      dbFieldName: 'user_name',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'الايميل',
      fieldName: 'userEmail',
      dbFieldName: 'user_email',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'الملاحظات',
      fieldName: 'note',
      dbFieldName: 'note',
      viewable: true,
    },
  ];

  return (
    <PageCardContainer>
      <div className='table-title-container'>
        <h3>تقييم العملاء على للتذاكر</h3>
        <Button icon labelPosition='right' className='blue-button' onClick={onGenerateFileClicked}>
          <Icon name='plus' className='blue-buton-icon' />
          إنشاء تقرير
        </Button>
      </div>
      <DataTable
        tableTitle='تقييم العملاء على للتذاكر'
        headers={tableHeaders}
        actions={[]}
        apiFunction={customerTicketsApi.getEvaluations}
        reloadTrigger={[rerenderFlag]}
        optionalQsParams={{}}
      />
      <AnimatedModal
        initialPosition={editStatusModalAttr.position}
        open={editStatusModalAttr.visible}
        content={
          <>
            <h4>تاريخ التقييم</h4>
            <div className='date-pickers-container'>
              <div className='date-picker-lbl-container'>
                <label> من تاريخ</label>
                <DatePicker
                  popperPlacement='top-end'
                  selected={searchDateFrom}
                  onChange={(date) => setSearchDateFrom(date)}
                  selectsStart
                  minDate={futureDate ? today : null}
                  maxDate={futureDate ? null : today}
                />
              </div>
              <div className='date-picker-lbl-container'>
                <label> الى تاريخ</label>
                <DatePicker
                  popperPlacement='top-end'
                  selected={searchDateTo}
                  onChange={(date) => setSearchDateTo(date)}
                  selectsEnd
                  minDate={futureDate ? today : null}
                  maxDate={futureDate ? null : today}
                />
              </div>
            </div>
          </>
        }
        footer={
          <>
            <Button
              labelPosition='left'
              content='إنشاء'
              icon='check'
              color='blue'
              onClick={onGenerateReport}
              loading={editStatusModalAttr.loading}
              disabled={editStatusModalAttr.loading}
            />
            <Button
              labelPosition='left'
              content='إلغاء'
              icon='close'
              color='grey'
              onClick={() => {
                setEditStatusModalAttr({ ...editStatusModalAttr, visible: false });
              }}
              disabled={editStatusModalAttr.loading}
            />
          </>
        }
      />
    </PageCardContainer>
  );
};

export default EvaluationTable;

import React, { useEffect, useState } from 'react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { Button, Icon, Message, Segment, Loader, Dropdown, Responsive } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { financialTeamsApi, teamsApi } from '../../../api/api';

const FinancialSettings = () => {
  const router = useHistory();

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [teams, setTeams] = useState([]);

  const [financialTeams, setFinancialTeams] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data: teamsRes } = await teamsApi.getTeamsDropDown();
        const { data: financialTeamsResponse } = await financialTeamsApi.get();
        const teamsOptions = teamsRes.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setTeams(teamsOptions);
        setFinancialTeams(financialTeamsResponse);
      } catch (err) {
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching teams dropdowns...', err);
        router.goBack();
      }
      setLoading(false);
    })();
  }, []);

  const selectionChangeHandler = (e, data) => {
    setFinancialTeams(
      financialTeams.map((item) => {
        return item.module === data.name
          ? {
              displayName: item.displayName,
              module: item.module,
              teamId: data.value === '' ? null : data.value,
            }
          : item;
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setIsSuccess(false);
      const formData = {
        data: [...financialTeams],
      };
      await financialTeamsApi.update(formData);
      setIsSuccess(true);
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      alert('حدث خطأ أثناء تعديل البيانات');
      setIsSubmitting(false);
      setIsSuccess(false);
      router.goBack();
    }
  };

  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div style={{ margin: '1rem 0.7rem' }}>
            <h3>الفرق المالية</h3>
            <Segment>
              {financialTeams.map((item) => (
                <div className='data-box' key={item.module}>
                  <div className='title-container' style={{ marginBottom: '1rem' }}>
                    <Icon name='users' size='small' />
                    <h5 className='title'>{item.displayName}</h5>
                  </div>
                  <Dropdown
                    selection
                    search
                    options={teams}
                    value={item.teamId === 0 ? null : item.teamId}
                    clearable
                    fluid
                    name={item.module}
                    onChange={selectionChangeHandler}
                  />
                </div>
              ))}
            </Segment>

            <div className='segments-wrapper' style={{ marginTop: '2rem' }}></div>
            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                  onClick={handleSubmit}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                  onClick={handleSubmit}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            {isSuccess && (
              <Message color='green'>
                <Message.Header>تم الحفظ بنجاح</Message.Header>
              </Message>
            )}
          </div>
        )}
      </PageCardContainer>
    </div>
  );
};

export default FinancialSettings;

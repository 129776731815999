import React, { useEffect, useState } from 'react';
import { Button, Card, Grid, GridColumn, GridRow, Icon, Image, Loader, Responsive, Table } from 'semantic-ui-react';
import { expensesApi } from '../../../../api/api';
import moment from 'moment';
import { useTafqeet } from '../../../../helpers/useTafqeet';
import whiteLogo from '../../../../assets/images/logo-white.jpg';
import { hasPermission } from '../../../../helpers/permissions';
import permissions from './../../../../helpers/permissions';
import { useSelector } from 'react-redux';
import { systemCodes } from '../../../../helpers/systemcodes';
import '../../ExpensesForms/ExpensesAndTransportForm.scss';

const DataBox = ({ title, icon = null, data, ltr = false, image = false, footer = false }) => {
  return (
    <div className={`${footer ? 'data-box-footer' : 'new-box-data'}`}>
      <div>{icon && <Icon className='icon-footer' name={icon} size={`${footer} ?  'large' : 'small'}`} />}</div>
      <h5 className={` ${footer ? 'title-footer' : 'new-title'}`}>{title}: </h5>
      {image ? (
        <div className='image-margin image-preview'>
          <div className='maintenance-form-img-preview'>
            <Image src={data} className='img' centered />
          </div>
        </div>
      ) : (
        <h5 className={` ${ltr ? 'ltr' : ''} ${footer ? 'cell-data-footer' : 'data-value'}`}>
          {data ? data : 'لا توجد بيانات'}
        </h5>
      )}
    </div>
  );
};

const AttachmentDataBox = ({ title, icon = null, data, ltr = false }) => {
  return (
    <div className='data-box-att'>
      <div className='title-container-att'>
        {icon && <Icon name={icon} size='small' />}
        <h5 className='title-att'>{title}</h5>
      </div>
      <h5 className={`data-att ${ltr ? 'ltr' : ''}`}>{data}</h5>
    </div>
  );
};

const ExpensesForm = ({ id, handleUpdateStatus }) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let formId = params.get('id');

  const currentUser = useSelector((st) => st.user).user;
  const [expensesForm, setExpensesForm] = useState({});
  const [previousFiles, setPreviousFiles] = useState([]);
  const [totalExpense, setTotalExpense] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data: expense } = await expensesApi.getExpense(id ? id : formId);
      if (id) {
        const { data: paths } = await expensesApi.getExpensesAttachmentPaths(id);
        if (paths.length) {
          setPreviousFiles(paths);
        }
      }
      let totalJod = 0;
      let totalFils = 0;
      if (expense.expenses.length != 0) {
        expense.expenses.map((expense) => {
          totalJod += expense.jod;
          totalFils += expense.fils;
        });
        totalJod += totalFils != 0 ? (totalFils / 1000) | 0 : 0;
        totalFils = totalFils % 1000;
        setTotalExpense(`${totalJod}.${totalFils}`);
      }
      setExpensesForm(expense);
      setLoading(false);
    })();
  }, []);

  const { tafqeetHandler, downloadFile, downloading } = useTafqeet();

  return (
    <div className={`expenses-form-view ${formId ? 'page-card-container' : ''}`}>
      {loading ? (
        <Loader active className='loader-margin-top' size='large' inline='centered' />
      ) : (
        <div style={{ margin: '1rem 0.7rem' }}>
          {!id && (
            <div className='header'>
              <div className='header-logo'>
                <Image src={whiteLogo} alt='logo' size='tiny' centered />
              </div>
              <div className='main-header-title'>الشركة العامة للحاسبات والالكترونيات</div>
            </div>
          )}
          <h3 className='main-title'>نموذج طلب مستندات متفرقة رقم #{expensesForm.id}</h3>

          <div className='order-data'>
            <h3 className='order-title'>معلومات الطلب</h3>
            <Grid>
              <GridRow columns={3} style={{ paddingTop: 0, paddingBottom: 5 }}>
                {expensesForm.teamLeaderActionId == 175 && (
                  <GridColumn>
                    <DataBox title='رئيس الفريق' icon='user' data={expensesForm.teamLeaderName} />
                  </GridColumn>
                )}
              </GridRow>
              <GridRow columns={3} style={{ paddingTop: 0, paddingBottom: 5 }}>
                <GridColumn>
                  <DataBox title='نوع الطلب' icon='microchip' ltr data={expensesForm.expenseType} />
                </GridColumn>
                <GridColumn>
                  <DataBox title='حالة الطلب' icon='tag' data={expensesForm.status} />
                </GridColumn>
                <GridColumn>
                  <DataBox title='اسم الموظف' icon='user' ltr data={expensesForm.employeeName} />
                </GridColumn>
              </GridRow>
              <GridRow columns={3} style={{ paddingTop: 0, paddingBottom: 5 }}>
                <GridColumn>
                  <DataBox title='الدائرة' icon='boxes' ltr data={expensesForm.department} />
                </GridColumn>
                <GridColumn>
                  <DataBox title='مدير الدائرة' icon='user' ltr data={expensesForm.departmentManagerName} />
                </GridColumn>
                <GridColumn>
                  <DataBox title='متخذ الاجراء من المالية' icon='user' ltr data={expensesForm.financeUser} />
                </GridColumn>
              </GridRow>

              <GridRow columns={3} style={{ paddingTop: 0, paddingBottom: 5 }}>
                <GridColumn>
                  <DataBox
                    title='تاريخ انشاء الطلب'
                    icon='calendar'
                    data={moment(expensesForm.createdAt).format('YYYY/MM/DD')}
                  />
                </GridColumn>
                <GridColumn>
                  <DataBox
                    title={
                      !expensesForm.managerActionDate
                        ? 'تاريخ رفض/قبول الطلب من الادارة'
                        : expensesForm.statusId === systemCodes.expensesStatus.rejectByFinancial ||
                          expensesForm.statusId === systemCodes.expensesStatus.rejectByAdministration ||
                          expensesForm.statusId === systemCodes.expensesStatus.returned
                        ? 'تاريخ رفض الطلب من الادارة'
                        : 'تاريخ قبول الطلب من الادارة'
                    }
                    icon='calendar'
                    data={
                      expensesForm.managerActionDate
                        ? moment(expensesForm.managerActionDate).format('YYYY/MM/DD')
                        : 'لم يتم اتخاذ اجراء'
                    }
                  />
                </GridColumn>
                <GridColumn>
                  <DataBox
                    title={
                      !expensesForm.financeActionDate
                        ? 'تاريخ رفض/قبول الطلب من المالية'
                        : expensesForm.statusId === systemCodes.expensesStatus.rejectByFinancial ||
                          expensesForm.statusId === systemCodes.expensesStatus.returned
                        ? 'تاريخ رفض الطلب من المالية'
                        : 'تاريخ قبول الطلب من المالية'
                    }
                    icon='calendar'
                    data={
                      expensesForm.financeActionDate
                        ? moment(expensesForm.financeActionDate).format('YYYY/MM/DD')
                        : 'لم يتم اتخاذ اجراء'
                    }
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </div>
          <div className='expenses-wrapper'>
            <Responsive maxWidth={767}>
              <Card.Group>
                {expensesForm.expenses &&
                  expensesForm.expenses.map((expense, index) => (
                    <Card fluid key={index}>
                      <Card.Content>
                        <Card.Description>
                          <div className='cards-table'>
                            <Table celled structured>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>
                                    <span>التاريخ:</span>
                                    <label>{moment(expense.date).format('YYYY/MM/DD')}</label>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <div className='cash-wrapper'>
                                      <div className='flex-input'>
                                        <span>المبلغ-دينار : </span>
                                        <label>{expense.jod}</label>
                                      </div>
                                      <div className='flex-input'>
                                        <span>المبلغ-فلس : </span>
                                        <label>{expense.fils}</label>
                                      </div>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <div className='flex-center'>
                                      <span>البيان : </span>
                                      <label>{expense.reason}</label>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  ))}
              </Card.Group>
            </Responsive>
            <Responsive minWidth={768}>
              <Table size='small' celled structured>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell>التاريخ</Table.HeaderCell>
                    <Table.HeaderCell>فلس</Table.HeaderCell>
                    <Table.HeaderCell>دينار</Table.HeaderCell>
                    <Table.HeaderCell>البيان</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {expensesForm.expenses &&
                    expensesForm.expenses.map((expense, index) => (
                      <Table.Row key={index} textAlign='center'>
                        <Table.Cell width={3}>
                          <label>{expense.date ? moment(expense.date).format('YYYY/MM/DD') : '---'}</label>
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <label>{expense.fils ? expense.fils : '---'}</label>
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <label>{expense.jod ? expense.jod : '---'}</label>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <label>{expense.reason ? expense.reason : '---'}</label>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Responsive>
            <div className='flex-data-boxes'>
              <DataBox title='المبلغ الاجمالي رقماً ' icon='money' ltr data={totalExpense} footer={true} />
              <DataBox
                title='المبلغ الاجمالي تفصيلاً'
                icon='money'
                footer={true}
                ltr
                data={tafqeetHandler(totalExpense)}
              />
            </div>
          </div>
          {previousFiles.length > 0 && id && (
            <div className='single-segment-wrapper full-width'>
              <div className='title-container-att'>
                <Icon name='attach' size='small' />
                <h3 className='title-att'>المرفقات</h3>
              </div>
              <div className='two-columns'>
                <AttachmentDataBox
                  data={
                    <div style={{ direction: 'ltr' }}>
                      {previousFiles.map((item, index) => (
                        <div
                          style={downloading ? { color: 'lightgray' } : {}}
                          className='attachment-path-att'
                          onClick={() => downloadFile(item)}
                          key={index}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  }
                />
              </div>
            </div>
          )}
          {expensesForm.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval &&
            +expensesForm.departmentManager === +currentUser.id &&
            id && (
              <div className='defualt-margin'>
                <Button
                  color='green'
                  onClick={() =>
                    handleUpdateStatus({
                      statusId: systemCodes.expensesStatus.approvedByAdministration,
                      formId: id,
                      departmentManagerId: expensesForm.departmentManager,
                    })
                  }
                >
                  موافقة الادارة
                </Button>
                <Button
                  color='red'
                  onClick={() =>
                    handleUpdateStatus({
                      formId: id,
                      statusId: systemCodes.expensesStatus.rejectByAdministration,
                      departmentManagerId: expensesForm.departmentManager,
                    })
                  }
                >
                  رفض الادارة
                </Button>
              </div>
            )}
          {(expensesForm.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval ||
            expensesForm.statusId === systemCodes.expensesStatus.approvedByAdministration) &&
            hasPermission(permissions.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL) &&
            id && (
              <div className='defualt-margin'>
                <Button
                  color='green'
                  onClick={() =>
                    handleUpdateStatus({
                      formId: id,
                      statusId: systemCodes.expensesStatus.readyToPaidOff,
                      financeUserId: expensesForm.financeUser,
                    })
                  }
                >
                  موافقة المالية
                </Button>
                <Button
                  color='red'
                  onClick={() =>
                    handleUpdateStatus({
                      formId: id,
                      statusId: systemCodes.expensesStatus.rejectByFinancial,
                      financeUserId: expensesForm.financeUser,
                    })
                  }
                >
                  رفض المالية
                </Button>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default ExpensesForm;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Form, Table, Icon, Responsive, Card, TextArea, Button, Loader } from 'semantic-ui-react';
import PageCardContainer from '../../../Layout/PageCardContainer';
import { departmentsApi, expensesApi, filesApi } from '../../../../api/api';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import permissions, { hasPermission } from '../../../../helpers/permissions';
import AnimatedModal from '../../../Reusable/AnimatedModal';
import DropFileUpload from '../../../Reusable/DropFileUpload';
import { systemCodes } from '../../../../helpers/systemcodes';

const EditExpensesForm = () => {
  const router = useHistory();
  const currentUser = useSelector((st) => st.user).user;
  const [loading, setLoading] = useState(true);
  const [userDepartments, setUserDepartments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [departmentManager, setDepartmentManager] = useState('');
  const [authorizedUser, setAuthorizedUser] = useState(true);
  const [files, setFiles] = useState([]);
  const [deleteFileName, setDeleteFileName] = useState(null);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [selectedExpenseType, setSelectedExpenseType] = useState();
  const [expensesTypes, setExpensesTypes] = useState([]);
  const [formData, setFromData] = useState({
    createdAt: '',
    createdBy: 0,
    department: '',
    departmentId: 0,
    departmentManager: 0,
    departmentManagerName: '',
    employeeName: '',
    financeActionDate: '',
    financeUser: '',
    id: 0,
    managerActionDate: '',
    status: '',
    statusId: 0,
  });
  const [error, setError] = useState('');
  const location = useLocation();
  const { id: formId } = location.state;

  const [expenses, setExpenses] = useState([]);

  const getExpenseTypes = async (id) => {
    const { data } = await expensesApi.getExpenseFormTypes();
    const types = data.map((item) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
    setExpensesTypes(types);
    setSelectedExpenseType(id);
  };

  const getUserDepartments = async (departmentId) => {
    const { data: departments } = await departmentsApi.getUserDepartmentsDropDown(currentUser.id);
    const departmentsOptions = departments.map((item) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
    setSelectedDepartment(departmentId);
    setUserDepartments(departmentsOptions);
    await getDepartmentManager(departmentsOptions[0]?.key);
  };

  useEffect(() => {
    (async () => {
      const { data: expense } = await expensesApi.getExpense(formId);
      const { data: previousFilesResponse } = await expensesApi.getExpensesAttachmentPaths(formId);
      await Promise.all([getUserDepartments(expense.departmentId), getExpenseTypes(expense.expenseTypeId)]);
      if (
        !(
          ((expense.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval ||
            expense.statusId === systemCodes.expensesStatus.returned) &&
            +currentUser.id === +expense.createdBy) ||
          (hasPermission(permissions.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE) &&
            (expense.statusId === systemCodes.expensesStatus.approvedByAdministration ||
              expense.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval))
        )
      )
        setAuthorizedUser(false);
      if (!!previousFilesResponse.length) {
        setPreviousFiles(previousFilesResponse);
      }
      setFromData(expense);
      setExpenses(expense.expenses);
      setDepartmentManager(expense.departmentManagerName);
    })();
    setLoading(false);
  }, []);

  const getDepartmentManager = async (depId) => {
    if (!depId) return;
    const data = await departmentsApi.getDepartmentManager(depId);
    setDepartmentManager(data.data);
  };

  const validation = () => {
    let error = '';
    if (userDepartments.length === 0) {
      error = 'لا يمكنك اضافة فاتورة دون ان تكون تابع لدائرة';
      setError(error);
      return false;
    }
    if (expenses.length === 0) {
      error = 'الرجاء تعبئة معلومات الفاتورة';
      setError(error);
      return false;
    }
    expenses.map((expense) => {
      if (!expense.date || !expense.jod || !expense.reason) {
        error = 'يرجى التأكد من تعبئة جميع معلومات الفاتورة بشكل صحيح';
        setError(error);
        return false;
      } else if (+expense.fils > 999) {
        error = 'الحد الاعلى 999 فلس يرجى ادخال البيانات بشكل صحيح';
        setError(error);
        return false;
      }
    });
    if (error) return false;
    return true;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (validation()) {
      try {
        await expensesApi.editExpensesForm({
          id: formId,
          departmentId: selectedDepartment,
          editedAt: moment().format('YYYY/MM/DD hh:mm A'),
          expenses: expenses,
          statusId:
            +formData.statusId === systemCodes.expensesStatus.returned
              ? systemCodes.expensesStatus.waitingForAdministrationApproval
              : +formData.statusId,
          expenseTypeId: selectedExpenseType    
        });
        if (files.length !== 0) uploadFiles(formId);
        router.goBack();
      } catch (e) {
        console.log('Error posting expenses...', e);
        setIsSubmitting(false);
        setError('حدث خطأ اثناء الحفظ');
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const DepartmentChangeHandler = async (e, data) => {
    setSelectedDepartment(data.value);
    await getDepartmentManager(data.value);
  };

  const ExpenseTypeChangeHandler = async (e, data) => {
    setSelectedExpenseType(data.value);
  };

  const DateChangeHandler = (date, index) => {
    let clonedExpenses = [...expenses];
    let currentExpense = { ...clonedExpenses[index] };
    currentExpense.date = moment(date).format('YYYY/MM/DD');
    clonedExpenses[index] = currentExpense;
    setExpenses(clonedExpenses);
  };

  const handleExpenseChange = (e, data, index) => {
    let clonedExpenses = [...expenses];
    clonedExpenses[index][data.name] = data.value;
    setExpenses(clonedExpenses);
  };

  const DeleteExpenseRow = (index) => {
    let clonedExpenses = [...expenses];
    clonedExpenses.splice(index, 1);
    setExpenses(clonedExpenses);
  };

  const addNewRow = () => {
    setExpenses([
      ...expenses,
      {
        date: moment().format('YYYY/MM/DD'),
        jod: 0,
        fils: 0,
        reason: '',
        id: parseInt(Math.random() * 100),
      },
    ]);
  };

  const onDropFilesChanged = (files) => {
    setFiles(files);
  };

  const uploadFiles = async (assuranceFormId) => {
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const fileData = new FormData();
        fileData.append('file', file);
        await filesApi.uploadExpensesFormAttachments(assuranceFormId, fileData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  const deleteExistingFile = async () => {
    const id = location.state.id;
    const fileName = deleteFileName;
    setDeleteFileName(null);
    try {
      setIsSubmitting(true);
      await filesApi.deleteExpenseFormAttachmentFile(id, fileName);
      setPreviousFiles(previousFiles.filter((item) => item !== fileName));
      setIsSubmitting(false);
    } catch (e) {
      console.log(e);
      alert('حدث خطأ أثناء الحذف');
    }
  };
  return (
    <PageCardContainer className='expenses-container'>
      <AnimatedModal
        open={!!deleteFileName}
        content='تأكيد عملية حذف الملف؟'
        footer={
          <>
            <Button color='red' onClick={deleteExistingFile}>
              تأكيد
            </Button>
            <Button onClick={() => setDeleteFileName(null)}>إلغاء</Button>
          </>
        }
      />
      {!loading ? (
        <div className='expenses-wrapper'>
          <h2 className='headerMargin'>مستند دفع نفقات متفرقة</h2>
          <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
            <Form.Group widths={2}>
              <Form.Field>
                <label>التاريخ</label>
                <Form.Input
                  className='rtl-input'
                  fluid
                  value={moment(formData.createdAt).format('YYYY/MM/DD')}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>تاريخ التعديل</label>
                <Form.Input className='rtl-input' fluid value={moment().format('YYYY/MM/DD hh:mm A')} readOnly />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>الاسم</label>
                <Form.Input className='rtl-input' fluid value={formData.employeeName} readOnly />
              </Form.Field>
              <Form.Field>
                <label>الدائرة</label>
                <Dropdown
                  fluid
                  selection
                  search
                  name='departmentId'
                  value={selectedDepartment}
                  options={userDepartments}
                  onChange={DepartmentChangeHandler}
                  className='table-dropdown'
                />
              </Form.Field>
              <Form.Field>
                <label>نوع الطلب</label>
                <Dropdown
                  fluid
                  selection
                  search
                  name='expenseTypeId'
                  value={selectedExpenseType}
                  options={expensesTypes}
                  onChange={ExpenseTypeChangeHandler}
                  className='table-dropdown'
                />
              </Form.Field>
            </Form.Group>
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              type='button'
              style={{ marginBottom: 15 }}
              onClick={addNewRow}
            >
              <Icon name='plus' className='blue-buton-icon' />
              إضافة سطر جديد
            </Button>
            <Responsive maxWidth={767}>
              <Card.Group>
                {expenses.map((expense, index) => (
                  <Card fluid key={expense.id}>
                    <Card.Content>
                      <Icon name='close' size='large' onClick={() => DeleteExpenseRow(index)} className='card-icon' />
                      <Card.Description>
                        <div className='cards-table'>
                          <Table basic='very' celled structured>
                            <Table.Body>
                              <Table.Row>
                                <>
                                  <Table.Cell>
                                    <div className='flex-center'>
                                      <span>التاريخ:</span>
                                      <DatePicker
                                        popperPlacement='top-end'
                                        placeholderText='تاريخ الزيارة'
                                        value={moment(expense.date).format('YYYY/MM/DD')}
                                        onChange={(date) => DateChangeHandler(date, index)}
                                      />
                                    </div>
                                  </Table.Cell>
                                </>
                              </Table.Row>
                              <Table.Row>
                                <>
                                  <Table.Cell width={5}>
                                    <div className='cash-wrapper'>
                                      <div className='flex-input'>
                                        <span>المبلغ-دينار : </span>
                                        <Form.Input
                                          className='rtl-input'
                                          value={expense.jod}
                                          name='jod'
                                          type='number'
                                          onChange={(e, data) => handleExpenseChange(e, data, index)}
                                        />
                                      </div>
                                      <div className='flex-input'>
                                        <span>المبلغ-فلس : </span>
                                        <Form.Input
                                          className='rtl-input'
                                          name='fils'
                                          type='number'
                                          value={expense.fils}
                                          onChange={(e, data) => handleExpenseChange(e, data, index)}
                                        />
                                      </div>
                                    </div>
                                  </Table.Cell>
                                </>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell width={5}>
                                  <div className='flex-center'>
                                    <span>البيان : </span>
                                    <TextArea
                                      className='rtl-input'
                                      name='reason'
                                      value={expense.reason ? expense.reason : ''}
                                      rows={1}
                                      onChange={(e, data) => handleExpenseChange(e, data, index)}
                                    />
                                  </div>
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Responsive>
            <Responsive minWidth={768}>
              <Table celled structured>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell rowSpan='2'>التاريخ</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2' textAlign='center'>
                      المبلغ
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>البيان</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2' />
                  </Table.Row>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell>فلس</Table.HeaderCell>
                    <Table.HeaderCell>دينار</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {expenses.map((expense, index) => (
                    <Table.Row key={expense.id}>
                      <Table.Cell width={3}>
                        <DatePicker
                          popperPlacement='top-end'
                          value={moment(expense.date).format('YYYY/MM/DD')}
                          onChange={(date) => DateChangeHandler(date, index)}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={2}>
                        <Form.Input
                          className='rtl-input'
                          fluid
                          name='fils'
                          type='number'
                          value={expense.fils}
                          onChange={(e, data) => handleExpenseChange(e, data, index)}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign='right' width={2}>
                        <Form.Input
                          className='rtl-input'
                          fluid
                          name='jod'
                          type='number'
                          value={expense.jod}
                          onChange={(e, data) => handleExpenseChange(e, data, index)}
                        />
                      </Table.Cell>

                      <Table.Cell>
                        <TextArea
                          className='rtl-input'
                          name='reason'
                          value={expense.reason ? expense.reason : ''}
                          rows={1}
                          onChange={(e, data) => handleExpenseChange(e, data, index)}
                        />
                      </Table.Cell>
                      <Table.Cell style={{ width: 50 }}>
                        <Icon name='close' size='large' onClick={() => DeleteExpenseRow(index)} className='card-icon' />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Responsive>
            <Form.Group widths={2} style={{ marginTop: 15 }}>
              <Form.Field>
                <label>توقيع الموظف</label>
                <Form.Input className='rtl-input input-as-label' fluid value={formData.employeeName} readOnly />
              </Form.Field>
              <Form.Field>
                <label>تاريخ تقديم الطلب</label>
                <Form.Input
                  className='rtl-input input-as-label'
                  fluid
                  value={moment(formData.createdAt).format('YYYY/MM/DD')}
                  readOnly
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>مدير الدائرة</label>
                <Form.Input className='rtl-input input-as-label' fluid value={departmentManager} readOnly />
              </Form.Field>
              <Form.Field>
                <label>حالة الطلب</label>
                <Form.Input className='rtl-input input-as-label' fluid value={formData.status} readOnly />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>توقيع مدير الدائرة</label>
                <Form.Input
                  className='rtl-input input-as-label'
                  fluid
                  value={formData.managerActionDate ? formData.departmentManagerName : ''}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>تاريخ الموافقة/الرفض</label>
                <Form.Input
                  className='rtl-input input-as-label'
                  fluid
                  value={
                    formData.managerActionDate ? moment(formData.managerActionDate).format('YYYY/MM/DD hh:mm A') : ''
                  }
                  readOnly
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>تمت المصادقة من قبل</label>
                <Form.Input
                  className='rtl-input input-as-label'
                  fluid
                  value={formData.financeActionDate ? formData.financeUser : ''}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>تاريخ المصادقة/الرفض</label>
                <Form.Input
                  className='rtl-input input-as-label'
                  fluid
                  value={
                    formData.financeActionDate ? moment(formData.financeActionDate).format('YYYY/MM/DD hh:mm A') : ''
                  }
                  readOnly
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <div className='attachments-container'>
                <DropFileUpload onFileChange={onDropFilesChanged} maxFiles={10} />
              </div>
              {uploadProgress > -1 && (
                <h4 style={{ textAlign: 'center' }}>
                  جاري رفع المرفقات {uploadProgress}/{files.length}
                </h4>
              )}
              {Boolean(previousFiles.length) && (
                <>
                  <h5>المرفقات</h5>
                  <ul className='drop-file-upload-files'>
                    {previousFiles.map((item, index) => (
                      <li key={index}>
                        <Icon name='delete' color='red' onClick={() => setDeleteFileName(item)} />
                        <label>{item}</label>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Form.Field>
            <label style={{ color: '#9f3a38' }}>{error}</label>
            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
          </Form>
        </div>
      ) : (
        <Loader active className='loader-margin-top' size='large' inline='centered' />
      )}
      <AnimatedModal open={!authorizedUser} content='انت غير مخول للدخول الى هذه الصفحة' />
    </PageCardContainer>
  );
};

export default EditExpensesForm;

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export default function PieChart({ labels, data, colors, hoverColors }) {
  const preparedData = {
    labels: [...labels],
    datasets: [
      {
        data: [...data],
        backgroundColor: [...colors],
        hoverBackgroundColor: [...hoverColors],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    animation: { animateRotate: true, animateScale: true },
    legend: {
      position: 'right',
    },
  };

  return (
    <div>
      <Doughnut data={preparedData} options={options} />
    </div>
  );
}

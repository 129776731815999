import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/rootReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';

const onSagaUncaughtErrors = (err, errInfo) => {
  console.log({ onSagaError: 'saga error', err, errInfo });
};

const loggerMiddleware = createLogger();

let reduxStore;
const sagaMiddleware = createSagaMiddleware({ onError: onSagaUncaughtErrors });

const middleWares = [/*loggerMiddleware,*/ sagaMiddleware];

const store = () => {
  const storeConfig = createStore(rootReducer, applyMiddleware(...middleWares));

  reduxStore = storeConfig;

  return storeConfig;
};

export { reduxStore };

export default store();
sagaMiddleware.run(rootSaga);

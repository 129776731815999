import React from 'react';
import CustomerTicketsSearch from './CustomerTicketsSearch';
import CustomerTicketsList from './CustomerTicketsList';
import './CustomerTickets.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { SysTicketDetailsView } from '../SystemMaintenanceTickets/SysTicketDetailsView';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Icon, Popup } from 'semantic-ui-react';
import PermissionCheck from '../../Reusable/PermissionCheck';
import p from '../../../helpers/permissions';

const CustomerTickets = ({
  loading,
  fetchData,
  data,
  total,
  onTicketSelected,
  handleDeleteTicket,
  onTicketStatusChanged,
  exportFileHandler,
  selectedTicket,
  setDateFilter,
  onTicketAssigned,
  exportFileStatisticsHandler,
  excelLoading,
}) => {
  return (
    <div className='customer-tickets-container'>
      <div className='tickets-menu'>
        <div>
          <div className='top-container'>
            <h2 className='main-title'>تذاكر الصيانة</h2>
            <div>
              <PermissionCheck permissions={p.EXPORT_STATISTICS}>
                <Popup
                  content='تصدير الاحصائيات الى ملف اكسل'
                  trigger={
                    <Button
                      icon
                      className='excel-button'
                      basic
                      onClick={() => exportFileStatisticsHandler()}
                      loading={excelLoading}
                    >
                      <Icon name='chart bar outline' />
                    </Button>
                  }
                />
              </PermissionCheck>
              <Popup
                content='تصدير التذاكر الى ملف اكسل'
                trigger={
                  <Button
                    icon
                    className='excel-button'
                    basic
                    onClick={() => exportFileHandler()}
                    loading={excelLoading}
                  >
                    <Icon name='file excel' />
                  </Button>
                }
              />
            </div>
          </div>
          <hr className='ct-divider' />
          <CustomerTicketsSearch
            setDateFilter={setDateFilter}
            onSearch={(filters, pagination) => {
              fetchData(filters, pagination);
            }}
            total={total}
            loading={loading}
          />
          <CustomerTicketsList data={data} onTicketSelected={onTicketSelected} loading={loading} />
        </div>
      </div>
      <div className='ticket-details'>
        <AnimatePresence exitBeforeEnter key={selectedTicket}>
          {Boolean(selectedTicket) ? (
            <SysTicketDetailsView
              onDelete={() => handleDeleteTicket()}
              onStatusChanged={onTicketStatusChanged}
              key={selectedTicket.id}
              ticket={selectedTicket}
              onTicketAssigned={onTicketAssigned}
              onTicketSelected={undefined}
            />
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              exit={{ opacity: 0 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Player autoplay loop src='https://assets9.lottiefiles.com/packages/lf20_hXvfqN.json' />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CustomerTickets;

import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Icon, Image, Message, Responsive, Search, Tab } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import PageCardContainer from '../../../Layout/PageCardContainer';
import './SysTicketAddForm.scss';
import Webcam from 'react-webcam';
import { useSelector } from 'react-redux';
import { customersApi, customerTicketsApi, filesApi, lookupsApi, otherMaintenanceItemApi } from '../../../../api/api';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import useDebounce from '../../../../helpers/useDebounce';
import DropFileUpload from '../../../Reusable/DropFileUpload';
import AnimatedModal from '../../../Reusable/AnimatedModal';
import permissions, { hasPermission } from '../../../../helpers/permissions';

// TODO: Split the component into multiple ones or split the funcs into mutiple files

const ticketTypes = {
  system: 15,
  servers: 16,
  trackingUnit: 26,
  workshop: 46,
  assets: 76,
  other: 122,
};

const SysTicketAddForm = () => {
  const webcamRef = React.useRef(null);
  const router = useHistory();
  const location = useLocation();
  const userInfo = useSelector((st) => st.user);

  // COMPONENT STATE DECLARATIONS //
  const [ticketType, setTicketType] = useState('');
  const [inputDate, setInputDate] = useState(new Date());
  const [customerIdOpts, setCustomerIdOpts] = useState([]);
  const [ticketTypeOpts, setTicketTypeOpts] = useState([]);
  const [ticketUrgencyOpts, setTicketUrgencyOpts] = useState([]);
  const [subTypeOpts, setSubTypeOpts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ key: null, message: null });
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    type: null,
    subType: null,
    image: null,
    customerId: null,
    note: '',
    comment: '',
    createdBy: null,
    commentType: 28, // [ADD NOTE]
    status: 18, // [Open]
    urgencyLevel: 75,
    serialNumber: '', // for workshop type only
    machineTypeModel: '', // for workshop and server types
    receiptNumber: '', // for workshop and server types
    visitDate: moment().format('YYYY/MM/DD'), // for workshop and server types
    branchLocation: '',
    ticketOpenerName: '',
    ticketOpenerEmail: '',
    ticketOpenerPhone: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    type: null,
    subType: null,
    customerId: null,
    note: null,
    ticketOpenerName: null,
    ticketOpenerEmail: null,
    ticketOpenerPhone: null,
  });
  const [touched, setTouched] = useState({
    type: false,
    subType: false,
    customerId: false,
    note: false,
    ticketOpenerName: false,
    ticketOpenerEmail: false,
    ticketOpenerPhone: false,
  });
  const [files, setFiles] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [deleteFileName, setDeleteFileName] = useState(null);
  const [filesCountValidationModalVisible, setFilesCountValidationModalVisible] = useState(false);
  // End of COMPONENT STATE DECLARATIONS //

  // Serial Number search handling
  const [snResults, setSnResults] = useState([]);
  const [snValue, setSnValue] = useState('');
  const [snLoading, setSnLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(snValue, 500);

  const onSnSelected = (e, { result }) => {
    setSnValue(result.title);
    setFormData({
      ...formData,
      serialNumber: result.title,
    });
  };

  const onSnSearch = async (e, { value }) => {
    setSnValue(value);
    setFormData({
      ...formData,
      serialNumber: value,
    });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        setSnLoading(true);
        const { data: res } =
          formData.type === ticketTypes.workshop
            ? await customerTicketsApi.getWorkshopTicketSerials(debouncedSearchTerm)
            : await customerTicketsApi.getServerTicketSerials(debouncedSearchTerm);
        setSnResults(res);
        setSnLoading(false);
      })();
    } else {
      setSnResults([]);
      setSnLoading(false);
    }
  }, [debouncedSearchTerm]);
  // End of Serial Number search handling

  // EFFECTS //
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsSubmitting(true);
        const customers = await customersApi.getCustomersDropDown();
        setCustomerIdOpts(
          customers.data.map((item) => ({
            key: item.id,
            text: item.name,
            value: item.id,
          }))
        );

        const types = await lookupsApi.getGroup(14);

        const excludedTypes = [ticketTypes.trackingUnit, ticketTypes.assets];
        if (!hasPermission(permissions.CREATE_WORKSHOP_MAINTENANCE_TICKETS)) excludedTypes.push(ticketTypes.workshop);
        if (!hasPermission(permissions.CREATE_SYSTEM_TICKET)) {
          excludedTypes.push(ticketTypes.system);
          excludedTypes.push(ticketTypes.servers);
        }
        if (!hasPermission(permissions.CREATE_OTHER_MAINTENANCE_TICKET)) {
          excludedTypes.push(ticketTypes.other);
        }

        setTicketTypeOpts(
          types.data
            .filter((item) => !excludedTypes.some((execluded) => execluded === item.id))
            .map((item) => ({ key: item.id, text: item.name, value: item.id }))
        );

        const urgencyLevels = await lookupsApi.getGroup(72);
        setTicketUrgencyOpts(urgencyLevels.data.map((item) => ({ key: item.id, text: item.name, value: item.id })));

        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
        console.log('Error fetching data...', err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      if (formData.type && formData.type != 122) {
        setIsLoading(true);
        const subTypes = await lookupsApi.getGroup(formData.type);
        setSubTypeOpts(subTypes.data.map((item) => ({ key: item.id, text: item.name, value: item.id })));
        setSnResults([]);
        setTicketType(formData.type);
        setIsLoading(false);
      } else if (formData.type && formData.type == 122 && formData.customerId) {
        setIsLoading(true);
        const subTypes = await otherMaintenanceItemApi.getOtherMaintenaceItemsForCustomer(formData.customerId);
        setSubTypeOpts(subTypes.data.map((item) => ({ key: item.id, text: item.name, value: item.id })));
        setSnResults([]);
        setTicketType(formData.type);
        setIsLoading(false);
      }
    })();
  }, [formData.type]);

  useEffect(() => {
    if (location.state && location.state.sysTicketId) {
      (async () => {
        setEditMode(true);
        const ticketInfo = await customerTicketsApi.getOne(location.state.ticketId);
        let sysTicketInfo;

        switch (location.state.ticketType) {
          case ticketTypes.system:
            sysTicketInfo = await customerTicketsApi.getRelatedSystemTicket(location.state.sysTicketId);
            break;
          case ticketTypes.servers:
            sysTicketInfo = await customerTicketsApi.getRelatedServerTicket(location.state.sysTicketId);
            break;
          case ticketTypes.workshop:
            sysTicketInfo = await customerTicketsApi.getRelatedWorkshopTicket(location.state.sysTicketId);
            break;
          case ticketTypes.other:
            sysTicketInfo = await customerTicketsApi.getRelatedOtherTicket(location.state.sysTicketId);
            break;
          default:
            sysTicketInfo = null;
            break;
        }

        if (!Boolean(sysTicketInfo)) {
          alert(
            'Something extremely wrong occured - ErrorCode: NOTYPE - Kindly refer to the developers before clicking (ok) if possible'
          );
          return router.goBack();
        }

        const { data: previousFilesResponse } = await customerTicketsApi.getAttachmentPaths(location.state.ticketId);

        if (sysTicketInfo.data.visitDate) setInputDate(new Date(sysTicketInfo.data.visitDate));
        setFormData({
          type: ticketInfo.data.type,
          subType: sysTicketInfo.data.type,
          image: sysTicketInfo.data.image,
          customerId: ticketInfo.data.customerId,
          note: sysTicketInfo.data.note,
          comment: 'TODO: fill on submit',
          createdBy: ticketInfo.data.createdBy,
          commentType: 29, // [EDIT NOTE]
          status: ticketInfo.data.status,
          urgencyLevel: ticketInfo.data.urgencyLevel || 75,
          visitDate: sysTicketInfo.data.visitDate,
          serialNumber: sysTicketInfo.data.serialNumber,
          machineTypeModel: sysTicketInfo.data.machineTypeModel,
          receiptNumber: sysTicketInfo.data.receiptNumber,
          branchLocation: ticketInfo.data.branchLocation ? ticketInfo.data.branchLocation : '',
        });
        setSnValue(sysTicketInfo.data.serialNumber);
        setPreviousFiles(previousFilesResponse);
      })();
    } else {
      setFormData({
        ...formData,
        createdBy: userInfo.user.id,
      });
    }
  }, [location.state, userInfo]);

  useEffect(() => {
    validateInputs();
  }, [touched, formData]);
  // End of EFFECTS //

  // EVENT Handlers //
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setTouched({
      type: true,
      subType: true,
      customerId: true,
      note: true,
      ticketOpenerName: !editMode,
      ticketOpenerEmail: !editMode,
      ticketOpenerPhone: !editMode,
    });
    setIsSubmitting(true);
    if (validateInputs()) {
      try {
        if (editMode) {
          if (files.length + previousFiles.length > 3) {
            setFilesCountValidationModalVisible(true);
            setIsSubmitting(false);
            return;
          }
          await editForm();
          if (files.length > 0) await uploadFiles(location.state.ticketId);
        } else {
          const customerTicketId = await saveNewForm();
          if (files.length > 0) await uploadFiles(customerTicketId.data);
        }

        router.replace('/sys-tickets');
      } catch (error) {
        setIsSubmitting(false);
        setErrors({ key: 1, message: 'حدث خطأ أثناء الحفظ' });
        console.log('error saving', error);
      }
    }
    setIsSubmitting(false);
  };

  const onDropFilesChanged = (files) => {
    setFiles(files);
  };

  const uploadFiles = async (customerTicketId) => {
    console.log(customerTicketId, files);
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const formData = new FormData();
        formData.append('file', file);
        await filesApi.uploadTicketAttachments(customerTicketId, formData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  const deleteExistingFile = async () => {
    const ticketId = location.state.ticketId;
    const fileName = deleteFileName;
    setDeleteFileName(null);
    try {
      setIsSubmitting(true);
      await filesApi.deleteAttachmentFile(ticketId, fileName);
      setPreviousFiles(previousFiles.filter((name) => name !== fileName));
      setIsSubmitting(false);
    } catch (e) {
      console.log(e);
      alert('حدث خطأ أثناء الحذف');
    }
  };

  const saveNewForm = async () => {
    const data = {
      systemTicket: {
        note: formData.note,
        image: formData.image,
        type: formData.subType,
        serialNumber: formData.serialNumber,
        machineTypeModel: formData.machineTypeModel,
        receiptNumber: formData.receiptNumber,
        visitDate: formData.visitDate,
      },
      customerTicket: {
        type: formData.type,
        createdBy: formData.createdBy,
        customerId: formData.customerId,
        status: formData.status,
        urgencyLevel: formData.urgencyLevel,
        ticketOpenerName: formData.ticketOpenerName,
        ticketOpenerEmail: formData.ticketOpenerEmail,
        ticketOpenerPhone: formData.ticketOpenerPhone,
        branchLocation: formData.branchLocation,
      },
      ticketComment: {
        comment: formData.comment,
        type: formData.commentType,
        deletable: true,
      },
    };
    return await customerTicketsApi.postSystemTicket(data);
  };

  const editForm = async () => {
    const data = {
      note: formData.note,
      image: formData.image,
      type: formData.subType,
      createdBy: formData.createdBy,
      customerTicketId: location.state.ticketId,
      serialNumber: formData.serialNumber,
      machineTypeModel: formData.machineTypeModel,
      receiptNumber: formData.receiptNumber,
      visitDate: formData.visitDate,
      parentType: formData.type,
    };
    return await customerTicketsApi.editSystemTicket(location.state.sysTicketId, data);
  };

  const cameraChangeHandler = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setFormData({ ...formData, image: imageSrc });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors({ key: null, message: null });
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({
            ...formData,
            image: `data:image/jpeg;base64, ${btoa(upload.target.result)}`,
          });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors({
          key: 1000,
          message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
        });
      }
    }
  };

  const dateChangeHandler = (date) => {
    setInputDate(date);
    setFormData({
      ...formData,
      visitDate: moment.utc(date.toLocaleDateString()).format('YYYY-MM-DD'),
    });
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const handleInputChanges = (e, v) => {
    setTouched({
      ...touched,
      [v.name]: true,
    });

    setFormData({
      ...formData,
      [v.name]: v.value,
      ...(v.name === 'type' ? { subType: null } : {}),
    });
  };

  const validateInputs = () => {
    if (
      (!touched.type && !touched.subType && !touched.customerId && !touched.note) ||
      (!editMode && (!touched.ticketOpenerName || !touched.ticketOpenerName || !touched.ticketOpenerName))
    )
      return false;

    const errors = {};
    if (touched.type && !formData.type) errors.type = true;
    if (touched.subType && !formData.subType) errors.subType = true;
    if (touched.customerId && !formData.customerId) errors.customerId = true;
    if (touched.note && !formData.note) errors.note = 'يجب اضافة وصف للتذكرة';
    if (touched.ticketOpenerName && !formData.ticketOpenerName) errors.ticketOpenerName = 'يرجى إدخال اسم المتصل';
    if (touched.ticketOpenerEmail)
      if (!formData.ticketOpenerEmail) errors.ticketOpenerEmail = 'يرجى إدخال ايميل المتصل';
      else if (!formData.ticketOpenerEmail.includes('@') || /[\u0621-\u064A\s]+/.test(formData.ticketOpenerEmail))
        errors.ticketOpenerEmail = 'الرجاء ادخال ايميل صحيح';

    if (touched.ticketOpenerPhone)
      if (!formData.ticketOpenerPhone) errors.ticketOpenerPhone = 'يرجى إدخال هاتف المتصل';
      else if (
        formData.ticketOpenerPhone.length < 7 ||
        formData.ticketOpenerPhone.length > 15 ||
        !/^[0-9]+$/.test(formData.ticketOpenerPhone)
      )
        errors.ticketOpenerPhone = 'الرجاء ادخال رقم هاتف صحيح';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // End of EVENT handlers //

  const panes = [
    {
      menuItem: 'تحميل صورة من الجهاز',
      render: () => <Tab.Pane>{imageFromDevice()}</Tab.Pane>,
    },
    {
      menuItem: 'صورة من الكاميرا',
      render: () => <Tab.Pane>{imageFromCamera()}</Tab.Pane>,
    },
  ];

  const imageFromDevice = () => {
    return (
      <Form.Field>
        <Form.Input type='file' id='fileUpload' onChange={fileChangeHandler} />
      </Form.Field>
    );
  };

  const imageFromCamera = () => {
    return !Boolean(formData.image) ? (
      <div className='camera-div'>
        <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' className='video-div' />
        <Button fluid onClick={cameraChangeHandler}>
          التقاط صورة
        </Button>
      </div>
    ) : (
      <Button fluid onClick={() => setFormData({ ...formData, image: null })}>
        التقاط صورة جديدة
      </Button>
    );
  };

  return (
    <PageCardContainer>
      <AnimatedModal
        open={!!deleteFileName}
        content='تأكيد عملية حذف الملف؟'
        footer={
          <>
            <Button color='red' onClick={deleteExistingFile}>
              تأكيد
            </Button>
            <Button onClick={() => setDeleteFileName(null)}>إلغاء</Button>
          </>
        }
      />
      <AnimatedModal
        open={!!filesCountValidationModalVisible}
        content='عدد الملفات الجديد والقديم تجاوز الحد المسموح'
        footer={<Button onClick={() => setFilesCountValidationModalVisible(false)}>حسناً</Button>}
      />

      {editMode ? (
        <h2 style={{ marginBottom: '2rem' }}>تعديل تذكرة صيانة # {location.state.ticketId}</h2>
      ) : (
        <h2 style={{ marginBottom: '2rem' }}>اضافة تذكرة صيانة جديدة</h2>
      )}
      <Form error onSubmit={onSubmitHandler} className='add-sys-ticket-form'>
        <Form.Group widths={!editMode || !!formData.branchLocation ? 3 : 2}>
          <Form.Field disabled={editMode}>
            <label>العميل</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='customerId'
              selection
              search
              onChange={handleInputChanges}
              options={customerIdOpts}
              value={formData.customerId}
              error={validationErrors.customerId}
              disabled={editMode}
            />
          </Form.Field>

          {(!editMode || !!formData.branchLocation) && (
            <Form.Field disabled={editMode}>
              <label>الموقع/الفرع</label>
              <Form.Input fluid value={formData.branchLocation} onChange={handleInputChanges} name='branchLocation' />
            </Form.Field>
          )}

          <Form.Field>
            <label>وقت انشاء التذكرة</label>
            <Form.Input className='date-input-ltr' readOnly value={moment().format('YYYY/MM/DD hh:mm A')} fluid />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={3}>
          <Form.Field disabled={editMode}>
            <label>نوع التذكرة</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='type'
              selection
              search
              onChange={handleInputChanges}
              options={ticketTypeOpts}
              value={formData.type}
              error={validationErrors.type}
              disabled={editMode}
            />
          </Form.Field>

          <Form.Field>
            <label>الاجراء المطلوب</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='subType'
              selection
              search
              loading={isLoading}
              onChange={handleInputChanges}
              options={subTypeOpts}
              value={formData.subType}
              error={validationErrors.subType}
            />
          </Form.Field>

          <Form.Field disabled={editMode}>
            <label>مستوى الأهمية</label>
            <Dropdown
              placeholder='يرجى الاختيار'
              fluid
              name='urgencyLevel'
              selection
              onChange={handleInputChanges}
              options={ticketUrgencyOpts}
              value={formData.urgencyLevel}
              disabled={editMode}
            />
          </Form.Field>
        </Form.Group>

        {(ticketType === ticketTypes.workshop || ticketType === ticketTypes.servers) && (
          <Form.Group widths={ticketType === ticketTypes.workshop ? 3 : 2}>
            <Form.Field>
              <label>الرقم المتسلسل (S/N)</label>
              <Search
                fluid
                onResultSelect={onSnSelected}
                results={snResults}
                noResultsMessage='رقم متسلسل جديد'
                onSearchChange={onSnSearch}
                value={snValue}
                loading={snLoading}
              />
            </Form.Field>
            <Form.Field>
              <label>موديل نوع الجهاز (MTM)</label>
              <Form.Input
                fluid
                value={formData.machineTypeModel}
                onChange={handleInputChanges}
                name='machineTypeModel'
              />
            </Form.Field>
            {ticketType === ticketTypes.workshop && (
              <Form.Field>
                <label>رقم وصل الصيانة</label>
                <Form.Input fluid value={formData.receiptNumber} onChange={handleInputChanges} name='receiptNumber' />
              </Form.Field>
            )}
          </Form.Group>
        )}

        <Form.Field>
          <label>تاريخ الزيارة المطلوب</label>
          <DatePicker
            selected={inputDate}
            onChange={(date) => dateChangeHandler(date)}
            minDate={moment().toDate()}
            onChangeRaw={handleDateChangeRaw}
          />
        </Form.Field>

        <Form.Field disabled={editMode}>
          <label>وصف المشكلة</label>
          <Form.TextArea
            error={validationErrors.note}
            value={formData.note}
            onChange={handleInputChanges}
            name='note'
          />
        </Form.Field>

        {!editMode && (
          <Form.Field>
            <label>ملاحظات إضافية</label>
            <Form.Input fluid value={formData.comment} onChange={handleInputChanges} name='comment' />
          </Form.Field>
        )}

        {!editMode && (
          <Form.Group widths={3}>
            <Form.Field>
              <label>اسم المتصل</label>
              <Form.Input
                fluid
                value={formData.ticketOpenerName}
                onChange={handleInputChanges}
                name='ticketOpenerName'
                error={validationErrors.ticketOpenerName}
              />
            </Form.Field>
            <Form.Field>
              <label>ايميل المتصل</label>
              <Form.Input
                fluid
                value={formData.ticketOpenerEmail}
                onChange={handleInputChanges}
                name='ticketOpenerEmail'
                error={validationErrors.ticketOpenerEmail}
              />
            </Form.Field>
            <Form.Field>
              <label>هاتف المتصل</label>
              <Form.Input
                fluid
                value={formData.ticketOpenerPhone}
                onChange={handleInputChanges}
                name='ticketOpenerPhone'
                error={validationErrors.ticketOpenerPhone}
              />
            </Form.Field>
          </Form.Group>
        )}

        <h5>المرفقات</h5>
        <div className='attachments-container'>
          <DropFileUpload onFileChange={onDropFilesChanged} />

          {editMode && Boolean(previousFiles.length) && (
            <>
              <h5>المرفقات السابقة</h5>
              <ul className='drop-file-upload-files'>
                {previousFiles.map((fileName, index) => (
                  <li key={fileName}>
                    <Icon name='delete' color='red' onClick={() => setDeleteFileName(fileName)} />
                    <lebel style={{ direction: 'ltr' }}>{fileName}</lebel>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        <h5>صورة إضافية</h5>
        <Tab panes={panes} />
        {formData.image && (
          <>
            <div className='image-preview-header'>عرض الصورة الاضافية</div>
            <div className='image-preview'>
              <div className='image-cropper'>
                <Image src={formData.image} className='img' centered />
              </div>
            </div>
          </>
        )}

        <Message error key={errors.key} content={errors.message} />

        <Responsive minWidth={768}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginLeft: '1rem', backgroundColor: '#602365', color: 'white' }}
            >
              <Icon name='save' className='blue-buton-icon' />
              {editMode ? 'تعديل' : 'حفظ'}
            </Button>

            <Button
              icon='chevron right'
              labelPosition='right'
              disabled={isSubmitting}
              content='رجوع'
              onClick={(e) => {
                e.preventDefault();
                router.goBack();
              }}
            />
            {uploadProgress > -1 && (
              <h4 style={{ display: 'inline-block', marginRight: 14 }}>
                جاري رفع المرفقات {uploadProgress}/{files.length}
              </h4>
            )}
          </div>
        </Responsive>
        <Responsive maxWidth={767}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              fluid
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginBottom: '1rem' }}
            >
              <Icon name='save' className='blue-buton-icon' />
              {editMode ? 'تعديل' : 'حفظ'}
            </Button>

            <Button
              fluid
              icon='chevron right'
              labelPosition='right'
              disabled={isSubmitting}
              content='رجوع'
              onClick={(e) => {
                e.preventDefault();
                router.goBack();
              }}
            />

            {uploadProgress > -1 && (
              <h4 style={{ textAlign: 'center' }}>
                جاري رفع المرفقات {uploadProgress}/{files.length}
              </h4>
            )}
          </div>
        </Responsive>
      </Form>
    </PageCardContainer>
  );
};

export default SysTicketAddForm;

import { userAuth as actions } from './../actions/index';
// import setAuthToken from "./../../api/setAuthToken";
import { saveState, loadState } from '../../api/sessionStorage';
import jwt from 'jsonwebtoken';

let initialState;
const emptyState = {
  isLoggedIn: false,
  err: '',
  token: null,
  user: {
    id: '',
    FullName: '',
    Email: '',
    role: '',
    jobTitle: '',
    p: new Set(),
    image: '',
  },
};
// if token exists in browser session, load it into redux state
const userState = loadState();
if (userState && userState.token) {
  const decodedToken = jwt.decode(userState.token);
  initialState = {
    ...userState,
    user: {
      id: decodedToken.id,
      FullName: decodedToken.FullName,
      Email: decodedToken.Email,
      role: decodedToken.role,
      jobTitle: decodedToken.jobTitle,
      p: Array.isArray(decodedToken.p) ? new Set(decodedToken.p) : new Set(),
      image: userState.image,
    },
  };
} else {
  initialState = { ...emptyState };
}

export const userAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      let returnedState;
      const decodedToken = jwt.decode(action.payload.token);
      returnedState = {
        isLoggedIn: true,
        err: '',
        token: action.payload.token,
        user: {
          id: decodedToken.id,
          FullName: decodedToken.FullName,
          Email: decodedToken.Email,
          role: decodedToken.role,
          jobTitle: decodedToken.jobTitle,
          p: Array.isArray(decodedToken.p) ? new Set(decodedToken.p) : new Set(),
          image: action.payload.image,
        },
      };
      saveState({ isLoggedIn: true, err: '', image: action.payload.image, token: action.payload.token });
      return returnedState;

    case actions.LOGOUT:
      //remove from browser session
      saveState(emptyState);
      return emptyState;

    case actions.LOGIN_FAILED:
      if (action.payload) {
        let returnedState = {
          token: null,
          isLoggedIn: false,
          err: action.payload,
        };
        saveState(returnedState);
        return returnedState;
      }
      break;

    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Loader, Segment } from 'semantic-ui-react';
import { customersApi, lookupsApi, teamsApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import './TeamAssignments.scss';

const TeamAddRow = ({ onSave, onCancel, customers, loading }) => {
  const [form, setForm] = useState({});
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const onCustomerDropdownChange = async (e, { value }) => {
    setListLoading(true);
    setForm({
      customerId: value,
      customerName: customers.find((c) => c.value === value)?.text,
    });

    const teamsRes = await teamsApi.getTeamsDropDownByCustomerId(value);
    setTeams(
      teamsRes.data.map((tr) => ({
        value: tr.id,
        key: tr.id,
        text: tr.name,
      }))
    );
    setListLoading(false);
  };

  const onTeamDropdownChange = async (e, { value }) => {
    setListLoading(true);
    setForm({
      ...form,
      teamId: value,
      teamName: teams.find((c) => c.value === value)?.text,
      memberId: null,
      memberName: null,
    });

    const membersRes = await teamsApi.getMembers(value);
    setMembers(
      membersRes.data.map((tr) => ({
        value: tr.userId,
        key: tr.userId,
        text: tr.isTeamLeader ? `${tr.fullName} (رئيس الفريق)` : tr.fullName,
      }))
    );
    setListLoading(false);
  };

  const onMemberDropdownChange = async (e, { value }) => {
    setForm({
      ...form,
      memberId: value,
      memberName: members.find((c) => c.value === value)?.text,
    });
  };

  const submit = () => {
    if (!!form.teamId && !!form.customerId && !!form.memberId) onSave(form);
  };

  return (
    <div className='assign-team-row'>
      <label>عند فتح تذكرة للعميل</label>
      <Dropdown
        placeholder='اسم العميل'
        value={form.customerId}
        options={customers}
        error={!form.customerId}
        scrolling
        onChange={onCustomerDropdownChange}
      />
      <label>يتم اسناد فريق</label>
      <Dropdown
        placeholder='اسم الفريق'
        loading={listLoading}
        value={form.teamId}
        options={teams}
        error={!form.teamId}
        onChange={onTeamDropdownChange}
      />
      <label>للعضو</label>
      <Dropdown
        placeholder='العضو'
        loading={listLoading}
        value={form.memberId}
        options={members}
        error={!form.memberId}
        onChange={onMemberDropdownChange}
      />
      <Button
        loading={loading}
        disabled={loading}
        icon='save'
        color='blue'
        size='mini'
        labelPosition='right'
        content='حفظ'
        onClick={submit}
      />
      <Button
        loading={loading}
        disabled={loading}
        icon='close'
        color='red'
        size='mini'
        labelPosition='right'
        content='الغاء'
        onClick={onCancel}
      />
    </div>
  );
};

const TeamAssignment = () => {
  const [ticketTypes, setTicketTypes] = useState([]);
  const [assignments, setAssignments] = useState({});
  const [customers, setCustomers] = useState([]);
  const [addEnabledOnTicketTypeId, setAddEnabledOnTicketTypeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getTeamsAssignmentsIsLoading, setGetTeamsAssignmentsIsLoading] = useState(true);

  const fetchData = async () => {
    const typesRes = await lookupsApi.getGroup(14);
    const customersRes = await customersApi.getCustomersDropDown();
    const assignmentsRes = await teamsApi.getTeamAssignmentDefaults();

    const types = typesRes.data.filter((t) => t.id !== 26);
    setTicketTypes(types);
    setAssignments(
      types.reduce((p, c) => ({ ...p, [c.id]: assignmentsRes.data.filter((a) => a.taskTypeId === c.id) }), {})
    );
    setCustomers(
      customersRes.data.map((c) => ({
        value: c.id,
        key: c.id,
        text: c.name,
      }))
    );

    setGetTeamsAssignmentsIsLoading(false);
  };

  const onTeamAdd = (ticketTypeId) => {
    setAddEnabledOnTicketTypeId(ticketTypeId);
  };

  const onCancel = () => {
    setAddEnabledOnTicketTypeId(null);
    setLoading(false);
  };

  const onSave = async (form) => {
    setLoading(true);
    await teamsApi.createTeamAssignmentDefaults({ taskTypeId: addEnabledOnTicketTypeId, ...form });
    setAssignments({
      ...assignments,
      [addEnabledOnTicketTypeId]: [...assignments[addEnabledOnTicketTypeId], form],
    });
    setAddEnabledOnTicketTypeId(null);
    setLoading(false);
  };

  const onRemove = async (taskTypeId, assignment) => {
    setLoading(true);
    await teamsApi.deleteTeamAssignmentDefaults({ taskTypeId, customerId: assignment.customerId });
    setAssignments({
      ...assignments,
      [taskTypeId]: assignments[taskTypeId].filter((c) => c.customerId !== assignment.customerId),
    });
    setAddEnabledOnTicketTypeId(null);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageCardContainer>
      <div className='task-assignment-page-container'>
        <h2 className='title'>اعدادات اسناد الفرق</h2>
        {getTeamsAssignmentsIsLoading ? (
          <Loader active={getTeamsAssignmentsIsLoading} inline='centered' size='large' />
        ) : (
          <>
            {ticketTypes.map((t) => (
              <Segment key={t.id}>
                <div className='task-type-inner-container'>
                  <h4>{t.name}</h4>
                  <div>
                    <span className='add-new-btn' onClick={() => onTeamAdd(t.id)}>
                      <Icon name='plus circle' color='green' size='large' />
                      <label>اضافة فريق / عميل</label>
                    </span>
                  </div>
                  {addEnabledOnTicketTypeId === t.id && (
                    <TeamAddRow
                      ticketTypeId={t.id}
                      customers={customers.filter((c) => !assignments[t.id].map((a) => a.customerId).includes(c.value))}
                      onSave={onSave}
                      loading={loading}
                      onCancel={onCancel}
                    />
                  )}
                  {assignments[t.id]?.map((assignment, i) => (
                    <div key={assignment.customerId}>
                      <Icon
                        name='remove circle'
                        color='red'
                        size='large'
                        style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                        onClick={() => onRemove(t.id, assignment)}
                        loading={loading}
                        disabled={loading}
                      />
                      <label>
                        عند فتح تذكرة للعميل ({assignment.customerName}) يتم اسناد فريق ({assignment.teamName}) للعضو (
                        {assignment.memberName})
                      </label>
                    </div>
                  ))}
                </div>
              </Segment>
            ))}
            <label style={{ fontSize: '0.85rem', color: 'red', marginRight: 4 }}>
              يتم الحفظ بشكل تلقائي عند الإضافة أو الحذف
            </label>
          </>
        )}
      </div>
    </PageCardContainer>
  );
};

export default TeamAssignment;

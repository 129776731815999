export const mapSearchHelper = (data, filters) => {
  if (filters && Object.keys(filters).length) {
    if (filters.userName) {
      data = data.filter((item) => item.userName.includes(filters.userName));
    }
    if (filters.userPhone) {
      data = data.filter((item) => item.userPhone.includes(filters.userPhone));
    }
    if (filters.imei) {
      data = data.filter((item) => item.imei.includes(filters.imei));
    }
    if (filters.simNumber) {
      data = data.filter((item) => item.simNumber.includes(filters.simNumber));
    }
    if (filters.departmentName) {
      data = data.filter((item) => item.departmentName.includes(filters.departmentName));
    }
  }
  return data;
};

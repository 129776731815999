import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import './NotFound.scss';
import PageCardContainer from './../Layout/PageCardContainer';

export default function NotFound() {
  const router = useHistory();

  return (
    <PageCardContainer>
      <div className='not-found'>
        <h2 className='header'>خطأ 404</h2>
        <h3 className='sub-header'>لم يتم ايجاد الصفحة</h3>
        <Player autoplay loop src='https://assets7.lottiefiles.com/temp/lf20_dzWAyu.json' style={{ height: '200px' }} />
        <Button size='large' animated='vertical' onClick={() => router.push('/home')}>
          <Button.Content hidden>الرئيسية</Button.Content>
          <Button.Content visible>
            <Icon name='home' />
          </Button.Content>
        </Button>
      </div>
    </PageCardContainer>
  );
}

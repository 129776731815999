export const systemCodes = {
  expensesStatus: {
    readyToPaidOff: 160,
    paidOffExpenses: 171,
    rejectByFinancial: 164,
    rejectByAdministration: 163,
    approvedByAdministration: 161,
    waitingForAdministrationApproval: 162,
    returned : 177
  },
  teamLeaderActions : {
    rejectByTeamLeader : 173,
    approvedByTeamLeader : 175,
    WaitingForTeamLeaderToTakeAction: 176
  }
};

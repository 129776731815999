import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Icon, Loader, Table } from 'semantic-ui-react';
import moment from 'moment';
import { customerTicketsApi } from '../../../../api/api';
import '../SysTicketContents/SystemTicketContent.scss';

const AssetTicketContents = ({ ticketId }) => {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(false);

  useEffect(() => {
    if (ticketId) fetchSystemTicket(ticketId);
  }, [ticketId]);

  const fetchSystemTicket = async (id) => {
    setLoading(true);
    const { data: ticket } = await customerTicketsApi.getRelatedAssetTicket(id);
    setTicket(ticket);
    setLoading(false);
  };

  return (
    <>
      <AnimatePresence>
        {!loading && ticket && fullscreenImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.4 } }}
            exit={{ opacity: 0, transition: { duration: 0.4 } }}
            className='ticket-img-wrppaer-fullscreen'
            onClick={() => setFullscreenImage(false)}
          >
            <img src={ticket.image} alt='' className='ticket-img-fullscreen' />
          </motion.div>
        )}
      </AnimatePresence>
      <div className='system-ticket-content-wrapper'>
        <h4>+ تفاصيل التذكرة</h4>
        {!loading ? (
          ticket && (
            <>
              <div className='side-by-side'>
                <div>
                  <h5 className='title'>نوع الاجراء</h5>
                  <p className='type-name'>{ticket.typeName}</p>
                </div>
                <div>
                  <h5 className='title'>تاريخ الزيارة</h5>
                  <p className='type-name'>{moment(ticket.visitDate).format('YYYY/MM/DD')}</p>
                </div>
              </div>
              <div className='side-by-side'>
                <div>
                  <h5 className='title'>العقد</h5>
                  <p className='type-name'>{ticket.contract || 'لا يوجد'}</p>
                </div>
                {!!ticket.contract && (
                  <div>
                    <h5 className='title'>تاريخ انتهاء العقد</h5>
                    <p className='type-name'>
                      {!!ticket.contractEndDate ? moment(ticket.contractEndDate).format('YYYY/MM/DD') : 'لا يوجد'}
                    </p>
                  </div>
                )}
              </div>

              <div className='ticket-asset-container'>
                <Table compact='very' size='small' color='blue'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>IMEI</Table.HeaderCell>
                      <Table.HeaderCell>SIM</Table.HeaderCell>
                      <Table.HeaderCell>S/N</Table.HeaderCell>
                      <Table.HeaderCell>النوع</Table.HeaderCell>
                      <Table.HeaderCell>الموديل</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{ticket.asset.imei}</Table.Cell>
                      <Table.Cell>{ticket.asset.sim}</Table.Cell>
                      <Table.Cell>{ticket.asset.serialNumber}</Table.Cell>
                      <Table.Cell>{ticket.asset.type}</Table.Cell>
                      <Table.Cell>{ticket.asset.model}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>

              <h5 className='title'>وصف المشكلة</h5>
              <p className='content'>{ticket.note}</p>
              {Boolean(ticket.image) && (
                <>
                  <h5 className='title'>صورة إضافية</h5>
                  <div className='ticket-img-wrapper' onClick={() => setFullscreenImage(true)}>
                    <Icon name='expand' size='large' />
                    <img src={ticket.image} alt='' className='ticket-img' />
                  </div>
                </>
              )}
            </>
          )
        ) : (
          <Loader active inline size='large' />
        )}
      </div>
    </>
  );
};

export default AssetTicketContents;

import React, { useState, useEffect, useRef } from 'react';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import { fromLonLat } from 'ol/proj';
import { baseLayers } from './MapLayers';
import { FullScreen, ZoomSlider, defaults as defaultControls } from 'ol/control';
import LayerSwitcher from 'ol-layerswitcher';
import 'ol/ol.css';
import './Map.scss';
import 'ol-layerswitcher/src/ol-layerswitcher.css';

export default function Map({
  center = [35.930359, 31.963158],
  zoom = 7,
  returnMap = null,
  mapWidth = null,
  mapHeight = null,
  mapControls = true,
}) {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!map) {
      var view = new OlView({
        center: fromLonLat(center),
        zoom: zoom,
      });

      var mapTemp = new OlMap({
        target: 'map',
        view: view,
        controls: mapControls
          ? defaultControls().extend([new FullScreen(), new ZoomSlider(), new LayerSwitcher()])
          : [],
        layers: baseLayers(),
      });

      setMap(mapTemp);
      if (typeof returnMap === 'function') returnMap(mapTemp);
    }
  }, []);

  return (
    <div id='map' style={mapWidth && mapHeight ? { width: mapWidth, height: mapHeight } : {}}>
      <div id='tooltip-container'>
        <div id='tooltip-content'></div>
      </div>
    </div>
  );
}

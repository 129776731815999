import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Message, Loader, Responsive, Icon, Checkbox, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PageCardContainer from '../../Layout/PageCardContainer';
import { contractsApi, customersApi, departmentsApi } from '../../../api/api';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';
import AccordionBranchVisits from './AccordionBranchVisits';
import { scheduleVisits } from '../../../api/api';
import ErrorModal from '../../Reusable/ErrorModal';

const AddMaintenanceContract = () => {
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();

  const [formData, setFormData] = useState({
    branches: [],
    contractId: '',
    customerId: '',
    departmentId: '',
    visitsCount: '',
    visitsDateType: '',
    visitsFrom: new Date(),
    visitsTo: new Date(),
    createdBy: Number(currentUser.id),
  });
  const [activeAccordionIndex, setActiveAccordionIndex] = useState([]);
  const [modalError, setModalError] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [branches, setBranches] = useState([]);
  const [clients, setClients] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllBranchesChecked, setIsAllBranchesChecked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationDateError, setValidationDateError] = useState(null);
  const [generatedVisits, setGeneratedVisits] = useState([]);

  let datesType = [
    {
      key: 140,
      text: 'اسبوعي',
      value: 140,
    },
    {
      key: 141,
      text: 'شهري',
      value: 141,
    },
    {
      key: 142,
      text: 'سنوي',
      value: 142,
    },
  ];

  const [validationErrors, setValidationErrors] = useState({
    customerId: null,
    departmentId: null,
    visitsCount: null,
    visitsDateType: null,
    visitsFrom: null,
    visitsTo: null,
    contractId: null,
  });

  const [touched, setTouched] = useState({
    customerId: null,
    departmentId: null,
    visitsCount: null,
    visitsDateType: null,
    visitsFrom: null,
    visitsTo: null,
    contractId: null,
  });

  const onAccordionClick = (idx) => {
    if (!activeAccordionIndex.includes(idx)) setActiveAccordionIndex([...activeAccordionIndex, idx]);
    else setActiveAccordionIndex(activeAccordionIndex.filter((id) => id !== idx));
  };

  const clearStates = () => {
    setLoading(false);
    setError(null);
    setFormData({
      branches: [],
      contractId: '',
      departmentId: '',
      customerId: '',
      visitsCount: '',
      visitsDateType: '',
      visitsFrom: new Date(),
      visitsTo: new Date(),
      createdBy: Number(currentUser.id),
    });
    setTouched({
      customerId: null,
      departmentId: '',
      visitsCount: null,
      visitsDateType: null,
      visitsFrom: null,
      visitsTo: null,
      contractId: null,
    });
  };

  const validateDate = (dateDiffInDays) => {
    if (formData.visitsDateType === 140 && dateDiffInDays < 7) {
      setValidationDateError('عدد الايام في فترة العقد اقل من عدد الزيارات');
      return false;
    } else if (formData.visitsDateType === 141 && dateDiffInDays < 28) {
      setValidationDateError('عدد الايام في فترة العقد اقل من عدد الزيارات');
      return false;
    } else if (formData.visitsDateType === 142 && dateDiffInDays < 364) {
      setValidationDateError('عدد الايام في فترة العقد اقل من عدد الزيارات');
      return false;
    }
    setValidationDateError(null);
    return true;
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const customers = await customersApi.getCustomersDropDownForScheduleVisits();
      const customersOptions = customers.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      const departments = await departmentsApi.getDepartmentsDropDown();
      const departmentsOptions = departments.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setDepartments(departmentsOptions);
      setClients(customersOptions);
      setLoading(false);
    } catch (err) {
      setModalError(true);
      setLoading(false);
      console.log('Error getting data...', err.response ?? err);
    }
  }, []);

  const validation = () => {
    const errors = {
      customerId: null,
      departmentId: null,
      visitsCount: null,
      visitsDateType: null,
      visitsFrom: null,
      visitsTo: null,
      contractId: null,
    };

    if (!formData.customerId) {
      errors.customerId = 'الرجاء اختيار العميل';
    }
    if (!formData.visitsCount || formData.visitsCount <= 0) {
      errors.visitsCount = 'الرجاء ادخال عدد مرات الزيارة';
    }
    if (formData.visitsDateType === 140 && formData.visitsCount > 7) {
      errors.visitsCount = 'الرجاء ادخال عدد مرات الزيارة بشكل لا يتعدى عدد ايام الاسبوع';
    }
    if (formData.visitsDateType === 141 && formData.visitsCount > 30) {
      errors.visitsCount = 'الرجاء ادخال عدد مرات الزيارة بشكل لا يتعدى عدد ايام الشهر';
    }
    if (formData.visitsDateType === 142 && formData.visitsCount > 365) {
      errors.visitsCount = 'الرجاء ادخال عدد مرات الزيارة بشكل لا يتعدى عدد ايام السنة';
    }
    if (!formData.visitsDateType) {
      errors.visitsDateType = 'الرجاء ادخال تكرار الزيارة';
    }
    if (!formData.visitsFrom) {
      errors.visitsFrom = 'الرجاء ادخال تاريخ بداية العقد';
    }
    if (!formData.visitsTo) {
      errors.visitsTo = 'الرجاء ادخال تاريخ نهاية العقد';
    }
    if (!formData.contractId) {
      errors.contractId = 'الرجاء ادخال العقد';
    }
    if (!formData.departmentId) {
      errors.departmentId = 'الرجاء ادخال الدائرة';
    }
    setValidationErrors(errors);

    if (
      errors.customerId ||
      errors.visitsCount ||
      errors.visitsDateType ||
      errors.visitsFrom ||
      errors.visitsTo ||
      errors.contractId ||
      errors.departmentId
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const getCustomerBranches = async (customer) => {
    const queryString = qs.stringify(
      {
        id: customer,
      },
      { addQueryPrefix: true }
    );
    const branch = await customersApi.getCustomerBranches(queryString);
    if (branch.status != 204) {
      const branchesOptions = branch.data.data.map((item) => ({
        key: item.id,
        text: item.branchName,
        value: item.id,
      }));
      setBranches(branchesOptions);
    }

    const contract = await contractsApi.getContractsCustomersDropDown(customer);
    const contractsOptions = [
      { key: -1, text: 'بدون عقد', value: -1 },
      ...contract.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      })),
    ];
    setContracts(contractsOptions);
  };

  const getContractInfo = async (contractValue) => {
    if (contractValue !== -1) {
      const { data: contract } = await contractsApi.getOneContract(contractValue);
      setFormData({
        ...formData,
        visitsFrom: new Date(contract.startDate),
        visitsTo: new Date(contract.endDate),
        contractId: contractValue,
      });
    }
  };

  const onChangeHandler = async (e, v) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [v.name]: v.value });
    if (v.name == 'customerId') {
      await getCustomerBranches(v.value);
    } else if (v.name == 'contractId') {
      await getContractInfo(v.value);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setTouched({
      customerId: true,
      departmentId: true,
      visitsCount: true,
      visitsDateType: true,
      visitsFrom: true,
      visitsTo: true,
      contractId: true,
    });
    if (validation()) {
      setIsSubmitting(true);
      try {
        let generatedVisitsApiForm = {
          customerId: formData.customerId,
          departmentId: formData.departmentId,
          contractFrom: new Date(formData.visitsFrom),
          visitsCount: formData.visitsCount,
          contractTo: new Date(formData.visitsTo),
          createdBy: formData.createdBy,
          visitType: formData.visitsDateType,
          Branches: [{}],
        };
        if (formData.contractId !== -1) generatedVisitsApiForm['contractId'] = formData.contractId;
        generatedVisitsApiForm.Branches = generatedVisits.reduce((p, c) => {
          return [
            ...p,
            ...c.visits
              .filter((v) => v.active)
              .map((v) => ({
                date: new Date(v.date),
                branchId: c.branchId === 0 ? null : c.branchId,
                customerId: formData.customerId,
              })),
          ];
        }, []);
        await scheduleVisits.createVisits(generatedVisitsApiForm);
        setIsSuccess(true);
        clearStates();
        setTimeout(() => {
          router.goBack();
        }, 1111);
      } catch (err) {
        setError('حدث خطأ اثناء اضافة العقد');
        setLoading(false);
        setIsSubmitting(false);
        console.log('Error Adding Maintenance Contract...', err.response ?? err);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div style={{ margin: '1rem 0.7rem' }}>
            <h3>اضافة زيارة جديد</h3>
            <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
              <Form.Field>
                <div className='title-container' style={{ marginBottom: '1rem' }}>
                  <h5 className='title'>الدائرة</h5>
                </div>
                <Dropdown
                  placeholder='اختر الدائرة'
                  fluid
                  selection
                  search
                  name='departmentId'
                  value={formData['departmentId']}
                  options={departments}
                  onChange={onChangeHandler}
                  className='table-dropdown'
                  error={Boolean(touched.departmentId) && Boolean(validationErrors.departmentId)}
                />
              </Form.Field>
              <Form.Field>
                <div className='title-container' style={{ marginBottom: '1rem' }}>
                  <h5 className='title'>العميل</h5>
                </div>
                <Dropdown
                  placeholder='اختر العميل'
                  fluid
                  selection
                  search
                  name='customerId'
                  value={formData['customerId']}
                  options={clients}
                  onChange={onChangeHandler}
                  className='table-dropdown'
                  error={Boolean(touched.customerId) && Boolean(validationErrors.customerId)}
                />
              </Form.Field>
              <Form.Field>
                <div className='title-container' style={{ marginBottom: '1rem' }}>
                  <h5 className='title'>العقد</h5>
                </div>
                <Dropdown
                  placeholder='العقد'
                  fluid
                  selection
                  search
                  disabled={formData['customerId'] ? false : true}
                  name='contractId'
                  value={formData['contractId']}
                  options={contracts}
                  onChange={onChangeHandler}
                  className='table-dropdown'
                  error={Boolean(touched.contractId) && Boolean(validationErrors.contractId)}
                />
              </Form.Field>
              <Form.Field>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className='title-container' style={{ marginBottom: '1rem' }}>
                    <h5 className='title'>الفروع</h5>
                  </div>
                  <div className='title-container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                      <Checkbox
                        checked={isAllBranchesChecked}
                        disabled={branches.length == 0 ? true : false}
                        onChange={(e, v) => {
                          if (v.checked) setFormData({ ...formData, branches: branches.map((branch) => branch.value) });
                          else setFormData({ ...formData, branches: [] });
                          setIsAllBranchesChecked(v.checked);
                        }}
                      />
                      <div className='title'>تحديد الكل</div>
                    </div>
                  </div>
                </div>
                <Dropdown
                  fluid
                  multiple
                  selection
                  disabled={branches.length == 0 || isAllBranchesChecked ? true : false}
                  search
                  name='branches'
                  value={formData['branches']}
                  options={branches}
                  onChange={onChangeHandler}
                  className='table-dropdown'
                />
              </Form.Field>
              <Form.Group widths={2}>
                <Form.Field>
                  <h5 className='title'>تاريخ بداية الزيارات</h5>
                  <DatePicker
                    popperPlacement='top-end'
                    placeholderText='تاريخ بداية العقد'
                    value={moment(formData['visitsFrom']).format('YYYY/MM/DD')}
                    onChange={(date) => setFormData({ ...formData, visitsFrom: date || null })}
                    maxDate={formData.visitsTo}
                  />
                  <label style={{ color: '#9f3a38' }}>{validationDateError}</label>
                </Form.Field>
                <Form.Field>
                  <h5 className='title'>تاريخ نهاية الزيارات</h5>
                  <DatePicker
                    popperPlacement='top-end'
                    placeholderText='تاريخ نهاية العقد'
                    value={moment(formData.visitsTo).format('YYYY/MM/DD')}
                    onChange={(date) => setFormData({ ...formData, visitsTo: date || null })}
                    minDate={formData.visitsFrom}
                  />
                  <label style={{ color: '#9f3a38' }}>{touched.visitsTo && validationErrors.visitsTo}</label>
                </Form.Field>
              </Form.Group>
              <Form.Group widths={2}>
                <Form.Field>
                  <div className='title-container' style={{ marginBottom: '1rem' }}>
                    <h5 className='title'>عدد الزيارات</h5>
                  </div>
                  <Form.Input
                    className='rtl-input'
                    fluid
                    name='visitsCount'
                    type='number'
                    onChange={onChangeHandler}
                    value={formData.visitsCount}
                    error={touched.visitsCount && validationErrors.visitsCount}
                  />
                </Form.Field>
                <Form.Field>
                  <div className='title-container' style={{ marginBottom: '1rem' }}>
                    <h5 className='title'>نوع التكرار</h5>
                  </div>
                  <Dropdown
                    placeholder='التكرار'
                    fluid
                    selection
                    search
                    name='visitsDateType'
                    value={formData.visitsDateType}
                    options={datesType}
                    onChange={onChangeHandler}
                    className='table-dropdown'
                    error={Boolean(touched.visitsDateType) && Boolean(validationErrors.visitsDateType)}
                  />
                </Form.Field>
              </Form.Group>
              <AccordionBranchVisits
                activeAccordionIndex={activeAccordionIndex}
                onAccordionClick={onAccordionClick}
                validateDate={validateDate}
                formData={formData}
                validation={validation}
                branches={branches}
                generatedVisits={generatedVisits}
                setGeneratedVisits={setGeneratedVisits}
              />
              <Responsive minWidth={768}>
                <div style={{ marginTop: '2rem' }}>
                  <Button
                    icon
                    type='submit'
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    className='blue-button'
                    labelPosition='right'
                    style={{ marginLeft: '1rem' }}
                  >
                    <Icon name='save' className='blue-buton-icon' />
                    حفظ
                  </Button>

                  <Button
                    icon='chevron right'
                    labelPosition='right'
                    content='رجوع'
                    onClick={(e) => {
                      e.preventDefault();
                      router.goBack();
                    }}
                  />
                </div>
              </Responsive>
              <Responsive maxWidth={767}>
                <div style={{ marginTop: '2rem' }}>
                  <Button
                    icon
                    fluid
                    type='submit'
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    className='blue-button'
                    labelPosition='right'
                    style={{ marginBottom: '1rem' }}
                    onClick={onSubmitHandler}
                  >
                    <Icon name='save' className='blue-buton-icon' />
                    حفظ
                  </Button>

                  <Button
                    fluid
                    icon='chevron right'
                    labelPosition='right'
                    content='رجوع'
                    onClick={(e) => {
                      e.preventDefault();
                      router.goBack();
                    }}
                  />
                </div>
              </Responsive>
              {isSuccess && (
                <Message color='green'>
                  <Message.Header>تم الحفظ بنجاح</Message.Header>
                </Message>
              )}
              {error && (
                <Message color='red'>
                  <Message.Header>{error}</Message.Header>
                </Message>
              )}
            </Form>
          </div>
        )}
      </PageCardContainer>
      {modalError && <ErrorModal />}
    </div>
  );
};

export default AddMaintenanceContract;

import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import CustomerTicketsSearch from '../CustomerTickets/CustomerTicketsSearch';
import CustomerTicketsList from '../CustomerTickets/CustomerTicketsList';
import PermissionCheck from './../../Reusable/PermissionCheck';
import p from '../../../helpers/permissions';

const TicketsSideMenu = ({
  data,
  total,
  fetchData,
  loading,
  onTicketSelected,
  setDateFilter,
  exportFileHandler,
  exportFileStatisticsHandler,
  excelLoading,
}) => {
  return (
    <div className='tickets-menu'>
      <div>
        <div className='top-container'>
          <h2 className='main-title'>تذاكر الصيانة</h2>
          <div>
            <PermissionCheck permissions={p.EXPORT_STATISTICS}>
              <Popup
                content='تصدير الاحصائيات الى ملف اكسل'
                trigger={
                  <Button
                    icon
                    className='excel-button'
                    basic
                    onClick={() => exportFileStatisticsHandler()}
                    loading={excelLoading}
                  >
                    <Icon name='chart bar outline' />
                  </Button>
                }
              />
            </PermissionCheck>

            <Popup
              content='تصدير التذاكر الى ملف اكسل'
              trigger={
                <Button icon className='excel-button' basic onClick={() => exportFileHandler()} loading={excelLoading}>
                  <Icon name='file excel' />
                </Button>
              }
            />
          </div>
        </div>
        <hr className='divider' />
        <CustomerTicketsSearch
          setDateFilter={setDateFilter}
          onSearch={(filters, pagination) => {
            fetchData(filters, pagination);
          }}
          total={total}
          loading={loading}
        />
        <CustomerTicketsList data={data} onTicketSelected={onTicketSelected} loading={loading} />
      </div>
    </div>
  );
};

export default TicketsSideMenu;

import Axios from 'axios';
import { getTokenHeader } from './setAuthToken';
import jwt from 'jsonwebtoken';
import { loadState } from './sessionStorage';
import { reduxStore } from '../redux/store/store';
import { userAuthActions } from '../redux/actions/actionCreator';
import FileDownload from 'js-file-download';

const axiosConfig = {
  // baseURL: `http://192.168.100.203:3000`,
  // baseURL: `https://cloud.jonav.me/api`,
  // baseURL: `https://${window.location.hostname}:5001/api`,
  // baseURL: `https://gce.jonav.me/api`,
  baseURL: process.env.REACT_APP_BACKEND_URL,
};

const axios = Axios.create(axiosConfig);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      const userState = loadState();
      if (userState.token) {
        const token = jwt.decode(userState.token);
        if (token.exp * 1000 < Date.now()) {
          reduxStore.dispatch(userAuthActions.logout());
          window.location.href = '/login';
          return;
        }
      }
    }
    throw error;
  }
);

// Lookups CRUD
export const lookupsApi = {
  getAll: (pagination = '') => axios.get(`/lookups${pagination}`, getTokenHeader()),
  getOne: (id) => axios.get(`/lookups/${id}`, getTokenHeader()),
  getGroup: (id) => axios.get(`/lookups/dropdownList/${id}`, getTokenHeader()),
  post: (data) => axios.post(`/lookups`, data, getTokenHeader()),
  edit: (id, data) => axios.put(`/lookups/${id}`, data, getTokenHeader()),
  delete: (id) => axios.delete(`/lookups/${id}`, getTokenHeader()),
};

// Roles CRUD
export const rolesApi = {
  getAllRoles: (pagination = '') => axios.get(`/roles${pagination}`, getTokenHeader()),
  getOneRole: (id) => axios.get(`/roles/${id}`, getTokenHeader()),
  postRole: (data) => axios.post(`/roles`, data, getTokenHeader()),
  editRole: (id, data) => axios.put(`/roles/${id}`, data, getTokenHeader()),
  deleteRole: (id) => axios.delete(`/roles/${id}`, getTokenHeader()),
  getAllPermissions: () => axios.get(`/roles/permissions`, getTokenHeader()),
  getPermissionsForRole: (id) => axios.get(`/roles/permissions/${id}`, getTokenHeader()),
};

export const authApi = {
  login: (authCredentials) => axios.post('/users/authenticate', authCredentials),
};

// Users CRUD
export const usersApi = {
  getAllUsers: (pagination = '') => axios.get(`/users${pagination}`, getTokenHeader()),
  getAllExceptCustomersDropdown: () => axios.get(`/users/dropdownlist`, getTokenHeader()),
  getOneUser: (id) => axios.get(`/users/${id}`, getTokenHeader()),
  viewOneUser: (id) => axios.get(`/users/view/${id}`, getTokenHeader()),
  postUser: (data) => axios.post(`/users`, data, getTokenHeader()),
  editUser: (id, data) => axios.put(`/users/${id}`, data, getTokenHeader()),
  editPassword: (data) => axios.put(`/users/ChangePassword`, data, getTokenHeader()),
  deleteUser: (id) => axios.delete(`/users/${id}`, getTokenHeader()),
  verifyUser: (id) => axios.put(`/users/verify/${id}`, null, getTokenHeader()),
  getImageById: (id) => axios.get(`/users/image/${id}`, getTokenHeader()),
  getAllUsersWithTrackingAssetsImages: () => axios.get(`/users/allImagesWithTrackingAssets`, getTokenHeader()),
  unlockUser: (id) => axios.put(`/users/unlockUser/${id}`, {},getTokenHeader()),
  forceAllUsersToChangePassword: () => axios.put(`/users/forcedAllUsersToChangePassword`, {}, getTokenHeader()),
  forceOneUserToChangePassword: (id) => axios.put(`/users/forceOneUserToChangePassword/${id}`, {}, getTokenHeader()),
  changePasswordWhenExpired: (data) => axios.put(`/users/changePasswordWhenExpired`, data, getTokenHeader())
};

// Customers CRUD
export const customersApi = {
  getAllCustomers: (pagination = '') => axios.get(`/customers${pagination}`, getTokenHeader()),
  getOneCustomer: (id) => axios.get(`/customers/${id}`, getTokenHeader()),
  postCustomer: (data) => axios.post(`/customers`, data, getTokenHeader()),
  editCustomer: (id, data) => axios.put(`/customers/${id}`, data, getTokenHeader()),
  deleteCustomer: (id) => axios.delete(`/customers/${id}`, getTokenHeader()),
  getCustomersDropDown: () => axios.get(`/customers/dropdownList`, getTokenHeader()),
  getCustomersDropDownForScheduleVisits: () => axios.get(`/customers/DropdownListForScheduleVisits`, getTokenHeader()),
  canBeDeleted: (id) => axios.get(`/customers/canDeleteCustomer/${id}`, getTokenHeader()),
  getCustomerGroups: () => axios.get(`/customers/groups`, getTokenHeader()),
  postCustomerGroups: (data) => axios.post(`/customers/groups`, data, getTokenHeader()),
  getCustomersByUserId: (id) => axios.get(`/customers/GetCustomerByUserId/${id}`, getTokenHeader()),
  getCustomerBranches: (pagination = '') => axios.get(`/customers/GetCustomerBranches${pagination}`, getTokenHeader()),
  postCustomerBranch: (data) => axios.post(`/customers/AddCustomerBranches`, data, getTokenHeader()),
  deleteBranch: (id) => axios.delete(`/customers/Branch/${id}`, getTokenHeader()),
  getBranch: (id) => axios.get(`/customers/GetBranchData/${id}`, getTokenHeader()),
  editBranch: (data, id) => axios.post(`/customers/EditBranch/${id}`, data, getTokenHeader()),
};

// CustomerTickets CRUD
export const customerTicketsApi = {
  getAll: (pagination = '') => axios.get(`/CustomerTickets${pagination}`, getTokenHeader()),
  getAllStatistics: (queryString) => axios.get(`/CustomerTickets/statistics${queryString}`, getTokenHeader()),
  getAllTicketsStatistics: (queryString) =>
    axios.get(`/CustomerTickets/TicketStatistics${queryString}`, getTokenHeader()),
  getExcelData: (pagination = '') => axios.get(`/CustomerTickets/ExcelData${pagination}`, getTokenHeader()),
  getOne: (id) => axios.get(`/CustomerTickets/${id}`, getTokenHeader()),
  getAttachmentPaths: (id) => axios.get(`/CustomerTickets/${id}/files`, getTokenHeader()),
  getRelatedSystemTicket: (relatedTicketId) =>
    axios.get(`/CustomerTickets/SystemTicket/${relatedTicketId}`, getTokenHeader()),
  getRelatedOtherTicket: (relatedTicketId) =>
    axios.get(`/CustomerTickets/OtherTicket/${relatedTicketId}`, getTokenHeader()),
  getRelatedServerTicket: (relatedTicketId) =>
    axios.get(`/CustomerTickets/ServerTicket/${relatedTicketId}`, getTokenHeader()),
  getRelatedWorkshopTicket: (relatedTicketId) =>
    axios.get(`/CustomerTickets/WorkshopTicket/${relatedTicketId}`, getTokenHeader()),
  getRelatedAssetTicket: (relatedTicketId) =>
    axios.get(`/CustomerTickets/AssetTicket/${relatedTicketId}`, getTokenHeader()),
  postSystemTicket: (data) => axios.post(`/CustomerTickets/SystemTicket`, data, getTokenHeader()),
  editSystemTicket: (id, data) => axios.put(`/CustomerTickets/SystemTicket/${id}`, data, getTokenHeader()),
  edit: (id, data) => axios.put(`/CustomerTickets/${id}`, data, getTokenHeader()),
  delete: (queryString) => axios.delete(`/CustomerTickets${queryString}`, getTokenHeader()),
  postComment: (commentData) => axios.post(`/CustomerTickets/Comment`, commentData, getTokenHeader()),
  getTicketComments: (id) => axios.get(`/CustomerTickets/${id}/Comments`, getTokenHeader()),
  changeStatus: (id, status) => axios.put(`/CustomerTickets/${id}/Status`, status, getTokenHeader()),
  assignTicket: (id, queryString) =>
    axios.put(`/CustomerTickets/AssignTicket/${id}${queryString}`, null, getTokenHeader()),
  unassignTicket: (id, queryString) =>
    axios.put(`/CustomerTickets/UnassignTicket/${id}${queryString}`, null, getTokenHeader()),
  getServerTicketSerials: (term) => axios.get(`/CustomerTickets/GetServerSerials?term=${term}`, getTokenHeader()),
  getWorkshopTicketSerials: (term) => axios.get(`/CustomerTickets/GetWorkshopSerials?term=${term}`, getTokenHeader()),
  escalateTicket: (id, data) => axios.put(`/CustomerTickets/${id}/escalate`, data, getTokenHeader()),
  evaluateTicket: (id, data) => axios.post(`/CustomerTickets/${id}/Evaluate`, data, getTokenHeader()),
  getEvaluations: (pagination = '') => axios.get(`/CustomerTickets/Evaluations${pagination}`, getTokenHeader()),
  getEvaluationsStatistics: (queryString) =>
    axios.get(`/CustomerTickets/EvaluationsStatistics${queryString}`, getTokenHeader()),
};

export const filesApi = {
  //ticket files api
  uploadTicketAttachments: (id, file) =>
    axios.post(`/FileUpload/Upload/${id}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  downloadFile: async (fileName) => {
    const response = await axios.get(`/FileUpload/Download?fileName=${fileName}`, {
      ...getTokenHeader(),
      responseType: 'blob',
    });
    FileDownload(response.data, fileName, response.headers['content-type']);
  },
  deleteAttachmentFile: (id, fileName) =>
    axios.delete(`/FileUpload/delete/${id}?fileName=${fileName}`, getTokenHeader()),

  //merchandise files apis
  uploadMerchandiseAttachments: (id, file) =>
    axios.post(`/FileUpload/uploadMerchandiseRequest/${id}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  downloadMerchandiseFile: async (fileName) => {
    const response = await axios.get(`/FileUpload/Download?fileName=${fileName}`, {
      ...getTokenHeader(),
      responseType: 'blob',
    });
    FileDownload(response.data, fileName, response.headers['content-type']);
  },
  deleteMerchandiseAttachmentFile: (id, fileName) =>
    axios.delete(`/FileUpload/deleteMerchandiseRequest/${id}?fileName=${fileName}`, getTokenHeader()),

  //assurance form files apis
  uploadAssuranceFormAttachments: (id, attachmentType, file) =>
    axios.post(`/FileUpload/uploadAssuranceFormAttachment/${id}/${attachmentType}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteAssuranceFormAttachmentFile: (id, fileName) =>
    axios.delete(`/FileUpload/deleteAssuranceFormAttachment/${id}?fileName=${fileName}`, getTokenHeader()),

  //assurance edit request files apis
  uploadAssuranceEditRequestAttachments: (id, attachmentType, file) =>
    axios.post(`/FileUpload/uploadAssuranceEditRequestAttachment/${id}/${attachmentType}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteAssuranceEditRequestAttachmentFile: (id, fileName) =>
    axios.delete(`/FileUpload/deleteAssuranceEditRequestAttachment/${id}?fileName=${fileName}`, getTokenHeader()),

  //stamp receipt files apis
  uploadStampReceiptAttachment: (id, attachmentType, file) =>
    axios.post(`/FileUpload/uploadStampReceiptAttachment/${id}/${attachmentType}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteStampReceiptAttachment: (id, fileName) =>
    axios.delete(`/FileUpload/deleteStampReceiptAttachment/${id}?fileName=${fileName}`, getTokenHeader()),
  uploadTransportExpensesFormAttachments: (id, file) =>
    axios.post(`/FileUpload/uploadTransportExpensesFormRequest/${id}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  uploadExpensesFormAttachments: (id, file) =>
    axios.post(`/FileUpload/uploadExpensesFormRequest/${id}`, file, {
      headers: {
        ...getTokenHeader().headers,
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteExpenseFormAttachmentFile: (id, fileName) =>
    axios.delete(`/FileUpload/deleteExpenseFormAttachmentFile/${id}?fileName=${fileName}`, getTokenHeader()),
  deleteTransportExpenseFormAttachmentFile: (id, fileName) =>
    axios.delete(`/FileUpload/deleteTransportExpenseFormAttachmentFile/${id}?fileName=${fileName}`, getTokenHeader()),
};

// TrackingUnits CRUD
export const trackingUnitsApi = {
  getAllTrackingUnits: (pagination = '') => axios.get(`/trackingUnits${pagination}`, getTokenHeader()),
  getOneTrackingUnit: (id) => axios.get(`/trackingUnits/${id}`, getTokenHeader()),
  getViewOneTrackingUnit: (id) => axios.get(`/trackingUnits/viewOne/${id}`, getTokenHeader()),
  getTrackingUnitsDropDown: () => axios.get(`/trackingUnits/dropdownList`, getTokenHeader()),
  postTrackingUnit: (data) => axios.post(`/trackingUnits`, data, getTokenHeader()),
  editTrackingUnit: (id, data) => axios.put(`/trackingUnits/${id}`, data, getTokenHeader()),
  deleteTrackingUnit: (id) => axios.delete(`/trackingUnits/${id}`, getTokenHeader()),
};

// Vehicles CRUD
export const vehiclesApi = {
  getAllVehicles: (pagination = '') => axios.get(`/vehicles${pagination}`, getTokenHeader()),
  getOneVehicle: (id) => axios.get(`/vehicles/${id}`, getTokenHeader()),
  getVehiclesDropDown: () => axios.get(`/vehicles/dropdownList`, getTokenHeader()),
  postVehicle: (data) => axios.post(`/vehicles`, data, getTokenHeader()),
  editVehicle: (id, data) => axios.put(`/vehicles/${id}`, data, getTokenHeader()),
  deleteVehicle: (id) => axios.delete(`/vehicles/${id}`, getTokenHeader()),
};

// Tracking Vehicles Association CRUD
export const trackingVehiclesApi = {
  getTrackingVehicles: (pagination = '') => axios.get(`/trackingVehicles${pagination}`, getTokenHeader()),
  getTrackingVehiclesList: (id) => axios.get(`/trackingVehicles/dropdown/${id}`, getTokenHeader()),
  getLinkedTrackingUnit: (id) => axios.get(`/trackingVehicles/trackingUnit/${id}`, getTokenHeader()),
  getLinkedVehicle: (id) => axios.get(`/trackingVehicles/vehicle/${id}`, getTokenHeader()),
  getUnlinkedTUList: (id) => axios.get(`/trackingVehicles/dropdown/trackingUnits/${id}`, getTokenHeader()),
  getUnlinkedVehiclesList: (id) => axios.get(`/trackingVehicles/dropdown/vehicles/${id}`, getTokenHeader()),
  postTrackingVehicle: (data) => axios.post(`/trackingVehicles`, data, getTokenHeader()),
  deleteTrackingVehicle: (id) => axios.delete(`/trackingVehicles/${id}`, getTokenHeader()),
  checkTUInMTV: (id) => axios.delete(`/trackingVehicles/tuCheckInMTV/${id}`, getTokenHeader()),
  deleteTuFromMTV: (queryString) => axios.delete(`/trackingVehicles/tuFromMtv${queryString}`, getTokenHeader()),
  checkVehicleInMTV: (id) => axios.delete(`/trackingVehicles/vehicleCheckInMTV/${id}`, getTokenHeader()),
  deleteVehicleFromMTV: (queryString) =>
    axios.delete(`/trackingVehicles/vehicleFromMtv${queryString}`, getTokenHeader()),
};

// MaintenanceForms CRUD
export const maintenanceFormApi = {
  getAllForms: (pagination = '') => axios.get(`/maintenanceForms${pagination}`, getTokenHeader()),
  getOneForm: (id) => axios.get(`/maintenanceForms/${id}`, getTokenHeader()),
  postForm: (data) => axios.post(`/maintenanceForms`, data, getTokenHeader()),
  editForm: (id, data) => axios.put(`/maintenanceForms/${id}`, data, getTokenHeader()),
  deleteForm: (id) => axios.delete(`/maintenanceForms/${id}`, getTokenHeader()),
};

// MaintenanceTickets CRUD
export const maintenanceTicketsApi = {
  getOneMaintenanceTicket: (id) => axios.get(`/maintenanceTickets/${id}`, getTokenHeader()),
  closeMaintenanceTicketVehicle: (id) => axios.get(`/maintenanceTickets/closeTicket/${id}`, getTokenHeader()),
  getFormByMaintenanceTicketVehicle: (id) => axios.get(`/maintenanceTickets/form/${id}`, getTokenHeader()),
  postMaintenanceTicket: (data) => axios.post(`/maintenanceTickets/`, data, getTokenHeader()),
  editMaintenanceTicket: (id, data) => axios.put(`/maintenanceTickets/${id}`, data, getTokenHeader()),
};

// Dashboard
export const dashboardApi = {
  allTickets: () => axios.get(`/dashboard/totalTickets`, getTokenHeader()),
  allTypes: () => axios.get(`/dashboard/totalTypes`, getTokenHeader()),
  trackingUnitsDistribution: () => axios.get(`/dashboard/trackingUnitsDistribution`, getTokenHeader()),
  currentTVTickets: () => axios.get(`/dashboard/currentTrackingVehiclesInTicket`, getTokenHeader()),
  mostCommonActions: () => axios.get(`/dashboard/mostCommonActions`, getTokenHeader()),
  mostCommonIssues: () => axios.get(`/dashboard/mostCommonIssues`, getTokenHeader()),
  mostCommonVehicleIssues: () => axios.get(`/dashboard/mostCommonVehicleIssues`, getTokenHeader()),
  mostProblematicTrackingUnits: () => axios.get(`/dashboard/mostProblematicTrackingUnits`, getTokenHeader()),
  mostProblematicVehicles: () => axios.get(`/dashboard/mostProblematicVehicles`, getTokenHeader()),
  mainPowerAlertsCount: () => axios.get(`/dashboard/mainPowerAlertsCount`, getTokenHeader()),
};

// Departments
export const departmentsApi = {
  getDepartments: (pagination = '') => axios.get(`/departments${pagination}`, getTokenHeader()),
  getOneDepartment: (id) => axios.get(`/departments/${id}`, getTokenHeader()),
  postDepartment: (data) => axios.post(`/departments`, data, getTokenHeader()),
  editDepartment: (id, data) => axios.put(`/departments/${id}`, data, getTokenHeader()),
  deleteDepartment: (id) => axios.delete(`/departments/${id}`, getTokenHeader()),
  canDeleteDepartment: (id) => axios.get(`/departments/canDelete/${id}`, getTokenHeader()),
  getDepartmentsDropDown: () => axios.get(`/departments/dropdownList`, getTokenHeader()),
  getDepartmentsByUserIdDropDown: (id) => axios.get(`/departments/dropdownList/${id}`, getTokenHeader()),
  getDepartmentUsersDropDown: (id) => axios.get(`/departments/usersDropdownByDepartment/${id}`, getTokenHeader()),
  getUserDepartmentsDropDown: (id) =>
    axios.get(`/departments/usersDropdownDepartmentsByUserId/${id}`, getTokenHeader()),
  getDepartmentManager: (id) => axios.get(`/departments/departmentManager/${id}`, getTokenHeader()),
  getDepartmentEmployees: () => axios.get(`/departments/departmentEmployees`, getTokenHeader()),
  getEmployeeDepartment: () => axios.get(`/departments/employeeDepartments`, getTokenHeader()),
};

// Teams
export const teamsApi = {
  getTeams: (pagination = '') => axios.get(`/Teams${pagination}`, getTokenHeader()),
  getOneTeam: (id) => axios.get(`/Teams/${id}`, getTokenHeader()),
  postTeam: (data) => axios.post(`/Teams`, data, getTokenHeader()),
  editTeam: (id, data) => axios.put(`/Teams/${id}`, data, getTokenHeader()),
  deleteTeam: (id) => axios.delete(`/Teams/${id}`, getTokenHeader()),
  canDeleteTeam: (id) => axios.get(`/Teams/canDelete/${id}`, getTokenHeader()),
  getTeamsDropDownByCustomerId: (id) => axios.get(`/Teams/dropdownList/${id}`, getTokenHeader()),
  getTeamsDropDownByDepartmentId: (id) => axios.get(`/Teams/dropdownByDepartment/${id}`, getTokenHeader()),
  getTeamsDropDown: () => axios.get(`/Teams/dropdownList`, getTokenHeader()),
  getMembers: (id) => axios.get(`/Teams/members/${id}`, getTokenHeader()),
  getCustomers: (id) => axios.get(`/Teams/customers/${id}`, getTokenHeader()),
  updateMembers: (id, data) => axios.put(`/Teams/updateMembers/${id}`, data, getTokenHeader()),
  updateTeamCustomers: (id, data) => axios.put(`/Teams/updateTeamCustomers/${id}`, data, getTokenHeader()),
  getTeamAssignmentDefaults: () => axios.get('/Teams/assignmentDefaults', getTokenHeader()),
  createTeamAssignmentDefaults: (data) => axios.post('/Teams/assignmentDefaults', data, getTokenHeader()),
  deleteTeamAssignmentDefaults: (data) => axios.delete('/Teams/assignmentDefaults', { data, ...getTokenHeader() }),
};

// Contracts
export const contractsApi = {
  getContracts: (pagination = '') => axios.get(`/contracts${pagination}`, getTokenHeader()),
  getOneContract: (id) => axios.get(`/contracts/${id}`, getTokenHeader()),
  postContract: (data) => axios.post(`/contracts`, data, getTokenHeader()),
  editContract: (id, data) => axios.put(`/contracts/${id}`, data, getTokenHeader()),
  deleteContract: (id) => axios.delete(`/contracts/${id}`, getTokenHeader()),
  canDeleteContract: (id) => axios.get(`/contracts/canDelete/${id}`, getTokenHeader()),
  getContractsDropDown: () => axios.get(`/contracts/dropdownList`, getTokenHeader()),
  getContractsCustomersDropDown: (id) => axios.get(`/contracts/CustomersContractDropdownList${id}`, getTokenHeader()),
  getContractWarranties: (id) => axios.get(`/contracts/warranties/${id}`, getTokenHeader()),
};

// Assets CRUD
export const assetsApi = {
  getAllAssets: (pagination = '') => axios.get(`/assets${pagination}`, getTokenHeader()),
  getOneAsset: (id) => axios.get(`/assets/${id}`, getTokenHeader()),
  getViewOneAsset: (id) => axios.get(`/assets/viewOne/${id}`, getTokenHeader()),
  getAssetsDropDown: () => axios.get(`/assets/dropdownList`, getTokenHeader()),
  postAsset: (data) => axios.post(`/assets`, data, getTokenHeader()),
  editAsset: (id, data) => axios.put(`/assets/${id}`, data, getTokenHeader()),
  deleteAsset: (id) => axios.delete(`/assets/${id}`, getTokenHeader()),
  ddlSearch: (search) => axios.get(`/assets/ddlSearch${search}`, getTokenHeader()),
  getContractAvailableAssets: (contractId) => axios.get(`/assets/contractAssets/${contractId}`, getTokenHeader()),
  assignContractAssets: (contractId, data) => axios.put(`/assets/contractAssets/${contractId}`, data, getTokenHeader()),
};

// UserTrackingAssets CRUD
export const userTrackingAssetsApi = {
  getAllUserTrackingAssets: (pagination = '') => axios.get(`/userTrackingAssets${pagination}`, getTokenHeader()),
  getOneUserTrackingAsset: (id) => axios.get(`/userTrackingAssets/${id}`, getTokenHeader()),
  getViewOneUserTrackingAsset: (id) => axios.get(`/userTrackingAssets/viewOne/${id}`, getTokenHeader()),
  postUserTrackingAsset: (data) => axios.post(`/userTrackingAssets`, data, getTokenHeader()),
  editUserTrackingAsset: (id, data) => axios.put(`/userTrackingAssets/${id}`, data, getTokenHeader()),
  deleteUserTrackingAsset: (id) => axios.delete(`/userTrackingAssets/${id}`, getTokenHeader()),
  linkTrackingAssetWithUser: (id, data) => axios.put(`/userTrackingAssets/linkUser/${id}`, data, getTokenHeader()),
  unlinkTrackingAssetFromUser: (id) => axios.put(`/userTrackingAssets/unlinkUser/${id}`, null, getTokenHeader()),
  departmentsOfUserDropdown: () => axios.get(`/userTrackingAssets/departmentsOfUserDropdown/`, getTokenHeader()),
};

// UserTrackingAssetLocations
export const userTrackingAssetLocationsApi = {
  getAllAssetsLastLocations: () => axios.get(`/userTrackingAssetLocations`, getTokenHeader()),
};

// MerchandiseDeliveryRequests CRUD
export const merchandiseDeliveryRequestsApi = {
  getAllMerchandiseDeliveryRequests: (pagination = '') =>
    axios.get(`/merchandiseDeliveryRequests${pagination}`, getTokenHeader()),
  getOneMerchandiseDeliveryRequest: (id) => axios.get(`/merchandiseDeliveryRequests/${id}`, getTokenHeader()),
  getViewOneMerchandiseDeliveryRequest: (id) =>
    axios.get(`/merchandiseDeliveryRequests/viewOne/${id}`, getTokenHeader()),
  postMerchandiseDeliveryRequest: (data) => axios.post(`/merchandiseDeliveryRequests`, data, getTokenHeader()),
  editMerchandiseDeliveryRequest: (id, data) => axios.put(`/merchandiseDeliveryRequests/${id}`, data, getTokenHeader()),
  updateMerchandiseDeliveryRequestStatus: (id, data) =>
    axios.put(`/merchandiseDeliveryRequests/updateStatus/${id}`, data, getTokenHeader()),
  deleteMerchandiseDeliveryRequest: (id) => axios.delete(`/merchandiseDeliveryRequests/${id}`, getTokenHeader()),
  getAttachmentPaths: (id) => axios.get(`/merchandiseDeliveryRequests/${id}/files`, getTokenHeader()),
};

export const playbackApi = {
  getPlaybackLocations: async (id, from, to) => {
    return axios.get(`/Playback/getPlaybackLocations?userId=${id}&from=${from}&to=${to}`, getTokenHeader());
  },
};

// Maintenance Items CRUD
export const otherMaintenanceItemApi = {
  getAll: (pagination = '') => {
    return axios.get(`/OtherMaintenanceItems${pagination}`, getTokenHeader());
  },
  postOtherMaintenanceItem: (data) => axios.post(`/OtherMaintenanceItems`, data, getTokenHeader()),
  deleteItem: (id) => axios.delete(`/OtherMaintenanceItems/${id}`, getTokenHeader()),
  getOneItem: (id) => axios.get(`/OtherMaintenanceItems/${id}`, getTokenHeader()),
  editItem: (id, data) => axios.put(`/OtherMaintenanceItems/${id}`, data, getTokenHeader()),
  getOtherMaintenaceItemsForCustomer: (id) => axios.get(`/OtherMaintenanceItems/dropdownList/${id}`, getTokenHeader()),
};

// Assurance Form
export const assuranceFormApi = {
  getAll: (pagination = '') => {
    return axios.get(`/AssuranceForms${pagination}`, getTokenHeader());
  },
  postAssuranceForm: (data) => axios.post(`/AssuranceForms`, data, getTokenHeader()),
  getOneAssuranceForm: (id) => axios.get(`/AssuranceForms/${id}`, getTokenHeader()),
  getAttachmentPaths: (id) => axios.get(`/AssuranceForms/${id}/files`, getTokenHeader()),
  editAssuranceForm: (id, data) => axios.put(`/AssuranceForms/${id}`, data, getTokenHeader()),
  getPrintAssuranceForm: (id) => axios.get(`/AssuranceForms/${id}/print`, getTokenHeader()),
  updateAssuranceFormStatus: (id, status) => axios.put(`/AssuranceForms/${id}/updateStatus/`, status, getTokenHeader()),
};

// Assurance Edit Request Form
export const assuranceEditRequestApi = {
  getAll: (pagination = '') => {
    return axios.get(`/AssuranceEditRequest${pagination}`, getTokenHeader());
  },
  postAssuranceEditRequest: (data) => axios.post(`/AssuranceEditRequest`, data, getTokenHeader()),
  getOne: (id) => axios.get(`/AssuranceEditRequest/${id}`, getTokenHeader()),
  getAttachmentPaths: (id) => axios.get(`/AssuranceEditRequest/${id}/files`, getTokenHeader()),
  edit: (id, data) => axios.put(`/AssuranceEditRequest/${id}`, data, getTokenHeader()),
  getPrintData: (id) => axios.get(`/AssuranceEditRequest/${id}/print`, getTokenHeader()),
  updateStatus: (id, status) => axios.put(`/AssuranceEditRequest/${id}/updateStatus/`, status, getTokenHeader()),
};

// Stamp receipt Form api
export const stampReceiptFormApi = {
  getAll: (pagination = '') => {
    return axios.get(`/StampReceiptForm${pagination}`, getTokenHeader());
  },
  post: (data) => axios.post('/StampReceiptForm', data, getTokenHeader()),
  getOne: (id) => axios.get(`/StampReceiptForm/${id}`, getTokenHeader()),
  getAttachmentPaths: (id) => axios.get(`/StampReceiptForm/${id}/files`, getTokenHeader()),
  edit: (id, data) => axios.put(`/StampReceiptForm/${id}`, data, getTokenHeader()),
  getPrintData: (id) => axios.get(`/StampReceiptForm/${id}/print`, getTokenHeader()),
  updateStatus: (id, status) => axios.put(`/StampReceiptForm/${id}/updateStatus/`, status, getTokenHeader()),
};

//Financial teams api
export const financialTeamsApi = {
  get: () => {
    return axios.get(`/FinancialTeams`, getTokenHeader());
  },
  update: (data) => {
    return axios.put(`/FinancialTeams`, data, getTokenHeader());
  },
};

//Schedule Visits Api
export const scheduleVisits = {
  getAll: (pagination = '') => {
    return axios.get(`/ScheduleVisits${pagination}`, getTokenHeader());
  },
  createVisits: (data) => {
    return axios.post(`/ScheduleVisits`, data, getTokenHeader());
  },
  getCustomerRecurringMaintenanceVisits: (id) => {
    return axios.get(`/ScheduleVisits/GetVisits/${id}`, getTokenHeader());
  },
  getAllRecurringMaintenanceVisits: (data) => {
    return axios.get(`/ScheduleVisits/GetAllVisits${data}`, getTokenHeader());
  },
  updateVisitDate: (data) => {
    return axios.put(`/ScheduleVisits/UpdateVisitDate${data}`, null, getTokenHeader());
  },
  deleteVisit: (id) => {
    return axios.delete(`/ScheduleVisits/DeleteVisit/${id}`, getTokenHeader());
  },
  generateReport: (data) => {
    return axios.post(`/ScheduleVisits/GenerateReport`, data, getTokenHeader());
  },
  getGenerateReport: (id) => {
    return axios.get(`/ScheduleVisits/GetGeneratedReport/${id}`, getTokenHeader());
  },
  EditGeneratedReport: (data, id) => {
    return axios.put(`/ScheduleVisits/EditGeneratedReport/${id}`, data, getTokenHeader());
  },
  deleteGeneratedVisitReport: (id) => {
    return axios.delete(`/ScheduleVisits/DeleteGeneratedVisitReport/${id}`, getTokenHeader());
  },
  getGeneratedReportsTable: (pagination = '') => {
    return axios.get(`/ScheduleVisits/GetGeneratedReportsTable${pagination}`, getTokenHeader());
  },
  deleteScheduleVisit: (id) => {
    return axios.delete(`/ScheduleVisits/DeleteScheduleVisit/${id}`, getTokenHeader());
  },
  createVisit: (data) => {
    return axios.post(`/ScheduleVisits/CreateVisit`, data, getTokenHeader());
  },
  getExcelStatistics: (id) => {
    return axios.get(`/ScheduleVisits/ExportExcel/${id}`, getTokenHeader());
  },
  getAllExcelStatistics: (data) => {
    return axios.get(`/ScheduleVisits/AllExportExcel${data}`, getTokenHeader());
  },
};

//Expenses

//Financial teams api
export const expensesApi = {
  postExpensesForm: (data) => {
    return axios.post(`/ExpensesForms`, data, getTokenHeader());
  },
  getAll: (pagination = '') => axios.get(`/ExpensesForms${pagination}`, getTokenHeader()),
  getExpense: (id) => axios.get(`/ExpensesForms/${id}`, getTokenHeader()),
  editExpensesForm: (data) => {
    return axios.put(`/ExpensesForms`, data, getTokenHeader());
  },
  deleteExpenseForm: (id) => {
    return axios.delete(`/ExpensesForms/delete/${id}`, getTokenHeader());
  },
  // managerAction: (data) => axios.put(`/ExpensesForms/managerAction`, data, getTokenHeader()),
  // financeAction: (data) => axios.put(`/ExpensesForms/financeAction`, data, getTokenHeader()),
  getExpensesAttachmentPaths: (id) => axios.get(`/ExpensesForms/${id}/files`, getTokenHeader()),
  postTransportExpensesForm: (data) => {
    return axios.post(`/ExpensesForms/Transport`, data, getTokenHeader());
  },
  getAllTransport: (pagination = '') => axios.get(`/ExpensesForms/Transport${pagination}`, getTokenHeader()),
  getTransportExpense: (id) => axios.get(`/ExpensesForms/Transport/${id}`, getTokenHeader()),
  editTransportExpensesForm: (data) => {
    return axios.put(`/ExpensesForms/Transport`, data, getTokenHeader());
  },
  deleteTransportExpenseForm: (id) => {
    return axios.delete(`/ExpensesForms/Transport/delete/${id}`, getTokenHeader());
  },
  managerTransportAction: (data) => axios.put(`/ExpensesForms/Transport/managerAction`, data, getTokenHeader()),
  financeTransportAction: (data) => axios.put(`/ExpensesForms/Transport/financeAction`, data, getTokenHeader()),
  updateExpenseFormStatus: (data) => axios.put(`/ExpensesForms/UpdateExpenseFormStatus`, data, getTokenHeader()),
  updateTransportExpenseFormStatus: (data) =>
    axios.put(`/ExpensesForms/UpdateTransportExpenseFormStatus`, data, getTokenHeader()),
  updateTeamLeaderActionIdForTransportExpenses: (data) =>
    axios.put(`/ExpensesForms/UpdateTeamLeaderActionIdForTransportExpenses`, data, getTokenHeader()),
  updateTeamLeaderActionIdForExpensesForm: (data) =>
    axios.put(`/ExpensesForms/UpdateTeamLeaderActionIdForExpensesForm`, data, getTokenHeader()),
  getDestinations: () => axios.get(`/ExpensesForms/Transport/Destinations`, getTokenHeader()),
  getTransportExpensesAttachmentPaths: (id) => axios.get(`/ExpensesForms/Transport/${id}/files`, getTokenHeader()),
  getExpensesFormAudit: (id) => axios.get(`/ExpensesForms/${id}/ExpensesAudit`, getTokenHeader()),
  getTransportExpensesAudit: (id) => axios.get(`/ExpensesForms/Transport/${id}/ExpensesAudit`, getTokenHeader()),
  getExpenseFormTypes : () => axios.get(`/ExpensesForms/ExpenseTypes`, getTokenHeader()),
};

import { useState, useCallback } from 'react';
import { tafqeet } from './Tafqeet';
import { filesApi } from '../api/api';

export const useTafqeet = () => {
  const [downloading, setDownloading] = useState(false);

  const tafqeetHandler = useCallback((number) => {
    const fraction = number.toString().split('.');
    if (fraction.length === 2) {
      const filsTafqeet = +fraction[1] === 0 ? 'صفر' : tafqeet(fraction[1]);
      const DinarTafqeet = +fraction[0] === 0 ? 'صفر' : tafqeet(fraction[0]);
      return `${DinarTafqeet}  دينار و ${filsTafqeet} فلس `;
    } else if (fraction.length === 1) {
      const DinarTafqeet = +fraction[0] === 0 ? 'صفر' : tafqeet(fraction[0]);
      return DinarTafqeet + ' دينار ';
    }
  }, []);

  const downloadFile = useCallback(async (fileName) => {
    try {
      setDownloading(true);
      await filesApi.downloadFile(fileName);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      alert('حدق خطأ اثناء تحميل المرفقات');
      console.log(error);
    }
  }, []);

  return { tafqeetHandler, downloadFile, downloading };
};

import React, { useEffect, useState } from 'react';
import './../MaintenanceForm/PrintMaintenanceForm.scss';
import { Icon, Image, Table } from 'semantic-ui-react';
import whiteLogo from './../../../assets/images/logo-white.jpg';
import { merchandiseDeliveryRequestsApi } from '../../../api/api';
import moment from 'moment';

export default function PrintMerchandiseDeliveryRequest() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  const [formData, setFormData] = useState({});

  useEffect(() => {
    (async () => {
      const res = await merchandiseDeliveryRequestsApi.getViewOneMerchandiseDeliveryRequest(id);
      setFormData(res.data);
    })();
  }, [id]);

  return (
    <div className='print-container'>
      <div className='header'>
        <div className='header-logo'>
          <Image src={whiteLogo} alt='logo' size='tiny' centered />
        </div>
        <div className='header-title'>الشركة العامة للحاسبات والالكترونيات</div>
      </div>

      <div className='contents'>
        <h3>طلب توصيل بضاعة رقم #{formData.id}</h3>

        <div className='segment'>
          <h4>
            <Icon name='book' /> &nbsp; معلومات الطلب
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>اسم مندوب المبيعات</Table.HeaderCell>
                <Table.HeaderCell width={2}>تاريخ إنشاء الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>منشيء الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>حالة الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>آخر تعديل على حالة الطلب بواسطة</Table.HeaderCell>
                <Table.HeaderCell width={2}>تاريخ آخر تعديل على حالة الطلب</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.salesRepresentativeName}</Table.Cell>
                <Table.Cell className='ltr'>{moment(formData.createdAt).format('YYYY-MM-DD, hh:mm A')}</Table.Cell>
                <Table.Cell>{formData.createdBy}</Table.Cell>
                <Table.Cell>{formData.status}</Table.Cell>
                <Table.Cell>{formData.statusChangedBy}</Table.Cell>
                <Table.Cell className='ltr'>
                  {moment(formData.statusChangeDate).format('YYYY-MM-DD, hh:mm A')}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='sticky note' /> &nbsp; معلومات العميل
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>العميل</Table.HeaderCell>
                <Table.HeaderCell width={2}>تاريخ التسليم المطلوب لدى العميل</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.customer}</Table.Cell>
                <Table.Cell className='ltr'>
                  {formData.customerDeliveryDate
                    ? moment(formData.customerDeliveryDate).format('YYYY/MM/DD')
                    : 'لا يوجد'}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='cogs' /> &nbsp; معلومات البضاعة
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>اسم المستلم</Table.HeaderCell>
                <Table.HeaderCell>هاتف المستلم</Table.HeaderCell>
                <Table.HeaderCell>الموقع</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.personToCallName ? formData.personToCallName : 'لا يوجد'}</Table.Cell>
                <Table.Cell>{formData.personToCallPhone ? formData.personToCallPhone : 'لا يوجد'}</Table.Cell>
                <Table.Cell>{formData.location ? formData.location : 'لا يوجد'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {formData.deliverableMerchandise && (
          <div className='segment'>
            <h4>
              <Icon name='boxes' /> &nbsp; المواد المطلوب تسليمها
            </h4>
            <div className='desc'>{formData.deliverableMerchandise}</div>
          </div>
        )}
        {formData.description && (
          <div className='segment'>
            <h4>
              <Icon name='book' /> &nbsp; وصف المهمة المطلوبة
            </h4>
            <div className='desc'>{formData.description}</div>
          </div>
        )}
      </div>
    </div>
  );
}

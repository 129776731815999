import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Loader, Transition } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi } from './../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';

export default function Customers() {
  const router = useHistory();
  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const [deleteCustomerPopup, setDeleteCustomerPopup] = useState(false);
  const [deleteCustomerErrPopup, setDeleteCustomerErrPopup] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState([]);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [groupsIdsOptions, setGroupsIdsOptions] = useState([]);
  const [groupsIdsArMap, setGroupsIdsArMap] = useState([]);

  const tableHeaders = [
    {
      displayName: 'المجموعة',
      fieldName: 'customerGroup',
      dbFieldName: 'customer_group',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: groupsIdsOptions,
      dropdownArMap: groupsIdsArMap,
    },
    {
      displayName: 'الاسم التجاري للعميل',
      fieldName: 'fullName',
      dbFieldName: 'full_name',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
      searchValues: [],
      dropdownArMap: {},
    },
    {
      displayName: 'الاسم المختصر',
      fieldName: 'shortName',
      dbFieldName: 'short_Name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'الموقع الاكتروني',
      fieldName: 'website',
      dbFieldName: 'website',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'اسم التواصل',
      fieldName: 'contactName',
      dbFieldName: 'contact_name',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'رقم الهاتف',
      fieldName: 'contactPhone',
      dbFieldName: 'contact_phone',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'البريد الالكتروني',
      fieldName: 'contactEmail',
      dbFieldName: 'contact_email',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const groups = await customersApi.getCustomerGroups();
      let options = [];
      let optionsArMap = {};
      for (let item of groups.data) {
        options.push({ key: item.id, text: item.name, value: item.id });
        optionsArMap[item.id] = item.name;
      }
      setGroupsIdsOptions(options);
      setGroupsIdsArMap(optionsArMap);
    };

    fetchData();
  }, [rerenderFlag]);

  const handleDeleteCustomer = async (id) => {
    setCurrentCustomerId(id);
    const { data: res } = await customersApi.canBeDeleted(id);
    if (res) {
      setDeleteCustomerPopup(true);
    } else {
      setDeleteMessage([
        'العميل لديه اجهزة تتبع',
        'العميل لديه مركبات',
        'العميل لديه اجهزة تتبع مربوطة بمركبات',
        'العميل لديه تذاكر',
      ]);
      setDeleteCustomerErrPopup(true);
    }
  };

  const handleViewBranches = async (id) => {
    router.push({ pathname: '/customers/branches', state: { id: id } });
  };

  const handleDeleteCustomerConfirm = async () => {
    try {
      await customersApi.deleteCustomer(currentCustomerId);
      setRerenderFlag(!rerenderFlag);
      setDeleteCustomerPopup(false);
    } catch (e) {
      console.log('Error deleting customer...', e);
    }
  };

  const tableActions = [
    ...(hasPermissions([permissions.VIEW_CLIENTS, permissions.VIEW_ALL_CLIENTS])
      ? [
          {
            actionType: 'view',
            actionUrl: '/customers/view',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_CLIENT)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/customers/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_CLIENT)
      ? [
          {
            actionType: 'custom',
            onClick: handleDeleteCustomer,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
    ...(hasPermissions([
      permissions.VIEW_CUSTOMER_BRANCHES,
      permissions.EDIT_CUSTOMER_BRANCH,
      permissions.DELETE_CUSTOMER_BRANCH,
      permissions.ADD_CUSTOMER_BRANCH,
    ])
      ? [
          {
            actionType: 'custom',
            onClick: handleViewBranches,
            title: 'الفروع',
            icon: 'building',
            color: 'blue',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>العملاء</h3>
          <PermissionCheck permissions={permissions.CREATE_CLIENT}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/customers/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة عميل جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='العملاء'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={customersApi.getAllCustomers}
          reloadTrigger={[rerenderFlag]}
          optionalQsParams={{}}
        />
      </PageCardContainer>

      {/* popup for deleting customer */}
      <Transition visible={deleteCustomerPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteCustomerPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <p>هل انت متأكد من الحذف</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => handleDeleteCustomerConfirm()}>
              نعم
            </Button>
            <Button color='green' onClick={() => setDeleteCustomerPopup(false)}>
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* popup for error message */}
      <Transition visible={deleteCustomerErrPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteCustomerErrPopup}>
          <Modal.Header>حدث خطأ</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>لا يمكن حذف العميل لأحد الاسباب التالية: </p>
              {deleteMessage.map((str, index) => (
                <p className='red-text' key={index}>
                  <Icon name='circle' size='tiny' />
                  {str}
                </p>
              ))}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setDeleteCustomerErrPopup(false)}>موافق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Transition, Modal, Dropdown, Form, Table, Message, Loader, Checkbox } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi, lookupsApi, merchandiseDeliveryRequestsApi, usersApi } from '../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';
import './MerchandiseDeliveryRequests.scss';
import StatusUpdatePopup from './StatusUpdatePopup';

export default function MerchandiseDeliveryRequests() {
  const router = useHistory();
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [onlyActiveRequests, setOnlyActiveRequests] = useState(true);
  const [toggleLabel, setToggleLabel] = useState('الطلبات الفعالة');
  const [statusUpdatePopupVisible, setStatusUpdatePopupVisible] = useState(false);
  const [statusPopupOptions, setStatusPopupOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});

  const [customersOptions, setCustomersOptions] = useState([]);
  const [customersArMap, setCustomersArMap] = useState({});

  const [usersOptions, setUsersOptions] = useState([]);
  const [usersArMap, setUsersArMap] = useState({});

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusArMap, setStatusArMap] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customers = await customersApi.getCustomersDropDown();
        let options = [];
        let optionsArMap = {};
        for (let item of customers.data) {
          options.push({ key: item.id, text: item.name, value: item.id });
          optionsArMap[item.id] = item.name;
        }
        setCustomersOptions(options);
        setCustomersArMap(optionsArMap);

        const users = await usersApi.getAllExceptCustomersDropdown();
        let options2 = [];
        let optionsArMap2 = {};
        for (let item of users.data) {
          options2.push({ key: item.id, text: item.name, value: item.id });
          optionsArMap2[item.id] = item.name;
        }
        setUsersOptions(options2);
        setUsersArMap(optionsArMap2);

        const statusTypes = await lookupsApi.getGroup(117);
        let options3 = [];
        let optionsArMap3 = {};
        let statusUpdatePopupOptions = [];
        for (let item of statusTypes.data) {
          // 120: closed requests, don't show in options because there's a toggle at the top
          if (item.id !== 120) {
            options3.push({ key: item.id, text: item.name, value: item.id });
            optionsArMap3[item.id] = item.name;
          }
          statusUpdatePopupOptions.push({ key: item.id, text: item.name, value: item.id });
        }

        setStatusPopupOptions(statusUpdatePopupOptions);
        setStatusOptions(options3);
        setStatusArMap(optionsArMap3);
      } catch (err) {
        console.log('Error fetching page dropdowns data...', err?.response ?? err);
      }
    };

    fetchData();
  }, []);

  const handleUpdateStatus = (id, row) => {
    setSelectedRow(row);
    setStatusUpdatePopupVisible(true);
  };

  const tableHeaders = [
    {
      displayName: 'الرقم',
      fieldName: 'id',
      dbFieldName: 'id',
      sortable: true,
      searchable: false,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'مندوب المبيعات',
      fieldName: 'salesRepresentativeName',
      dbFieldName: 'sales_representative_name',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'العميل',
      fieldName: 'customer',
      dbFieldName: 'customer',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customersOptions,
      dropdownArMap: customersArMap,
    },

    {
      displayName: 'الموقع',
      fieldName: 'location',
      dbFieldName: 'location',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'اسم المستلم',
      fieldName: 'personToCallName',
      dbFieldName: 'person_to_call_name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'هاتف المستلم',
      fieldName: 'personToCallPhone',
      dbFieldName: 'person_to_call_phone',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'حالة الطلب',
      fieldName: 'statusName',
      dbFieldName: 'status',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
    },
    {
      displayName: 'حالة الطلب',
      fieldName: 'status',
      dbFieldName: 'status',
      viewable: false,
      sortable: false,
      searchable: onlyActiveRequests,
      searchType: 'dropdown',
      searchValues: statusOptions,
      dropdownArMap: statusArMap,
    },
    {
      displayName: 'منشيء الطلب',
      fieldName: 'createdBy',
      dbFieldName: 'created_by',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'dropdown',
      searchValues: usersOptions,
      dropdownArMap: usersArMap,
    },
    {
      displayName: 'آخر تعديل على حالة الطلب بواسطة',
      fieldName: 'statusChangedBy',
      dbFieldName: 'status_changed_by',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'dropdown',
      searchValues: usersOptions,
      dropdownArMap: usersArMap,
    },
    {
      displayName: 'تاريخ آخر تعديل على حالة الطلب',
      fieldName: 'statusChangeDate',
      dbFieldName: 'status_change_date',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'تاريخ إنشاء الطلب',
      fieldName: 'createdAt',
      dbFieldName: 'created_at',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'تاريخ التسليم المطلوب لدى العميل',
      fieldName: 'customerDeliveryDate',
      dbFieldName: 'customer_delivery_date',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'date',
      futureDate: true,
    },
    {
      displayName: 'المواد المطلوب تسليمها',
      fieldName: 'deliverableMerchandise',
      dbFieldName: 'deliverable_merchandise',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'وصف المهمة المطلوبة',
      fieldName: 'description',
      dbFieldName: 'description',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
  ];

  const tableActions = [
    ...(hasPermission(permissions.UPDATE_MERCHANDISE_DELIVERY_REQUEST_STATUS)
      ? [
          {
            actionType: 'custom',
            onClick: handleUpdateStatus,
            title: 'تعديل حالة الطلب',
            icon: 'linkify',
            color: 'green',
          },
        ]
      : []),
    ...(hasPermission(permissions.VIEW_MERCHANDISE_DELIVERY_REQUESTS)
      ? [
          {
            actionType: 'view',
            actionUrl: '/merchandiseDeliveryRequests/view',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_MERCHANDISE_DELIVERY_REQUEST)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/merchandiseDeliveryRequests/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_MERCHANDISE_DELIVERY_REQUESTS)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: merchandiseDeliveryRequestsApi.deleteMerchandiseDeliveryRequest,
          },
        ]
      : []),
  ];

  const onChangeCheckbox = (evt, data) => {
    if (onlyActiveRequests === false) {
      setToggleLabel('الطلبات الفعالة');
      setOnlyActiveRequests(true);
    } else if (onlyActiveRequests === true) {
      setToggleLabel('الطلبات المغلقة');
      setOnlyActiveRequests(false);
    }
  };

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container merchandise-table-title-container'>
          <div>
            <h3>طلبات تسليم البضائع</h3>
            <PermissionCheck permissions={permissions.CREATE_MERCHANDISE_DELIVERY_REQUEST}>
              <Button
                icon
                labelPosition='right'
                className='blue-button'
                onClick={() => router.push('/merchandiseDeliveryRequests/add')}
              >
                <Icon name='plus' className='blue-buton-icon' />
                اضافة طلب تسليم بضاعة جديد
              </Button>
            </PermissionCheck>
          </div>

          <div className='toggle-bar'>
            <Checkbox
              label={toggleLabel}
              toggle
              checked={onlyActiveRequests === true}
              onClick={(evt, data) => onChangeCheckbox(evt, data)}
            />
          </div>
        </div>
        <DataTable
          tableTitle='طلبات تسليم البضائع'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={merchandiseDeliveryRequestsApi.getAllMerchandiseDeliveryRequests}
          optionalQsParams={{ onlyActiveRequests: onlyActiveRequests }}
          reloadTrigger={[rerenderFlag, onlyActiveRequests]}
        />
      </PageCardContainer>
      <StatusUpdatePopup
        visible={statusUpdatePopupVisible}
        setVisible={setStatusUpdatePopupVisible}
        row={selectedRow}
        statusOptions={statusPopupOptions}
        toggleRerenderFlag={(data) => {
          if (data) setRerenderFlag(!rerenderFlag);
        }}
      />
    </div>
  );
}

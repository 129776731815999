import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersApi } from '../../api/api';
import { mapSearchHelper } from '../../helpers/mapSearchHelper';
import { usersLocationsActionsCreator } from '../../redux/actions/actionCreator';
import Map from '../Map/Map';
import {
  createLocationsLayer,
  hasLayer,
  initUsersLocationsTooltip,
  removeLayer,
  zoomOnFeature,
} from '../Map/MapHelpers';
import './UserMap.scss';
import UserMapSideList from './UserMapSideList';
import UserMapSideListSearch from './UserMapSideListSearch';

export default function UsersMap() {
  const [map, setMap] = useState(null);
  const [searchValues, setSearchValues] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const usersLocationsState = useSelector((state) => state.usersLocations.locations);
  const selectedUserLocation = useSelector((state) => state.usersLocations.selectedUser);
  const dispatch = useDispatch();

  useEffect(() => {
    //fetch data upon entering the component in the saga
    dispatch(usersLocationsActionsCreator.usersLocationsRequest());
    dispatch(usersLocationsActionsCreator.usersLocationsClearAllSelections());

    (async () => {
      try {
        const { data: userImages } = await usersApi.getAllUsersWithTrackingAssetsImages();
        let userImagesHash = {};
        userImages.forEach((item) => {
          userImagesHash[item.userId] = item.userImage;
        });
        dispatch(usersLocationsActionsCreator.loadUsersImages(userImagesHash));
      } catch (err) {
        console.log('Error fetching users images...', err?.response ?? err);
      }
    })();

    return () => {
      //clear data upon exiting
      dispatch(usersLocationsActionsCreator.usersLocationsRequestStop());
      dispatch(usersLocationsActionsCreator.usersLocationsClearAllSelections());
      dispatch(usersLocationsActionsCreator.usersFirstLoadSet(false));
    };
  }, []);

  useEffect(() => {
    if (usersLocationsState?.length) {
      if (searchValues && Object.keys(searchValues).length) {
        const data = mapSearchHelper(usersLocationsState, searchValues);
        setFilteredData([...data]);
      } else {
        setFilteredData([...usersLocationsState]);
      }
    }
  }, [usersLocationsState, searchValues]);

  useEffect(() => {
    if (map && selectedUserLocation && selectedUserLocation.longitude && selectedUserLocation.latitude) {
      zoomOnFeature(map, selectedUserLocation.longitude, selectedUserLocation.latitude, 17);
    }
  }, [selectedUserLocation, map]);

  useEffect(() => {
    if (map && filteredData?.length) {
      initUsersLocationsTooltip(map);

      if (hasLayer(map, 'usersLocations')) {
        removeLayer(map, 'usersLocations');
        createLocationsLayer(map, 'usersLocations', 'مواقع المستخدمين', 'usersLocations', filteredData);
      } else {
        createLocationsLayer(
          map,
          'usersLocations',
          'مواقع المستخدمين',
          'usersLocations',
          filteredData,
          undefined,
          undefined,
          true
        );
      }
    } else if (map && !filteredData?.length && hasLayer(map, 'usersLocations')) {
      removeLayer(map, 'usersLocations');
    }
  }, [filteredData]);

  return (
    <div className='user-map-container'>
      <div className='user-map-list-container'>
        <UserMapSideListSearch returnSearchValues={(values) => setSearchValues({ ...searchValues, ...values })} />
        <UserMapSideList usersLocations={filteredData} />
      </div>
      <Map mapHeight='calc(100vh - 62px - 2rem)' mapWidth='100%' returnMap={setMap} />
    </div>
  );
}

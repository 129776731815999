import React, { useState } from 'react';
import { Form, Button, Table, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import './warranty.scss';

const WarrantyEditList = ({ warrantyList, setWarrantyList, disabled = false }) => {
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    note: '',
  });

  const onDateChanged = (key, value) => {
    if (key === 'startDate' && (!formData.endDate || new Date(formData.endDate) < new Date(value))) {
      const next = new Date(value);
      next.setDate(next.getDate() + 1);
      setFormData({ ...formData, startDate: value, endDate: next });
    } else if (key === 'endDate' && !formData.startDate) {
      const prev = new Date(value);
      prev.setDate(prev.getDate() - 1);
      setFormData({ ...formData, endDate: value, startDate: prev });
    } else {
      setFormData({ ...formData, [key]: value || null });
    }
  };

  const addWarranty = (e) => {
    if (formData.startDate && formData.endDate) {
      setWarrantyList([
        ...warrantyList,
        {
          key: Math.random(),
          ...formData,
        },
      ]);
      setFormData({ startDate: '', endDate: '', note: '' });
    } else {
      // TODO: Show some proper validation error
      alert('يجب اختيار تاريخ البداية والنهاية للكفالة');
    }
  };

  const handleTvRemoveBtn = (key) => {
    setWarrantyList(warrantyList.filter((w) => w.key !== key));
  };

  return (
    <>
      <h4>الكفالات</h4>
      <Segment className='warranty-form'>
        {!disabled && (
          <div className='flex fj-between fa-end gap1'>
            <Form.Field width='4'>
              <label>تاريخ بداية الكفالة</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='من تاريخ'
                selected={formData.startDate}
                onChange={(date) => onDateChanged('startDate', date)}
                startDate={formData.startDate}
                endDate={formData.endDate}
                selectsStart
              />
            </Form.Field>
            <Form.Field width='4'>
              <label>تاريخ نهاية الكفالة</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='إلى تاريخ'
                selected={formData.endDate}
                onChange={(date) => onDateChanged('endDate', date)}
                minDate={formData.startDate}
                startDate={formData.startDate}
                endDate={formData.endDate}
                selectsEnd
              />
            </Form.Field>
            <Form.Field width='8'>
              <label>الملاحظات</label>
              <Form.Input
                icon='pen square'
                value={formData['note']}
                onChange={(e, v) => setFormData({ ...formData, note: v.value })}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    addWarranty();
                    return false;
                  }
                }}
              />
            </Form.Field>
            <Button type='button' icon='plus' className='mb1 w-add-btn' onClick={addWarranty} />
          </div>
        )}

        <div>
          <Table celled size='small' compact>
            {warrantyList.length ? (
              <>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>البداية</Table.HeaderCell>
                    <Table.HeaderCell>النهاية</Table.HeaderCell>
                    <Table.HeaderCell>ملاحظات</Table.HeaderCell>
                    {!disabled && <Table.HeaderCell textAlign='left'>حذف</Table.HeaderCell>}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {warrantyList.map((item) => (
                    <Table.Row key={item.key}>
                      <Table.Cell>{moment(item.startDate).format('YYYY/MM/DD')}</Table.Cell>
                      <Table.Cell>{moment(item.endDate).format('YYYY/MM/DD')}</Table.Cell>
                      <Table.Cell>{item.note}</Table.Cell>
                      {!disabled && (
                        <Table.Cell textAlign='left'>
                          <Button icon='x' color='red' onClick={() => handleTvRemoveBtn(item.id)} size='tiny' />
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            ) : (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <h5>لا يوجد كفالات مرتبطه بالعقد</h5>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </div>
      </Segment>
    </>
  );
};

export default WarrantyEditList;

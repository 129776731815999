import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Image, Segment, Popup, Loader } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import moment from 'moment';
import { stampReceiptFormApi, filesApi } from '../../../api/api';

const ViewStampReceipt = () => {
  const location = useLocation();
  const { id } = location.state;

  const [loading, setLoading] = useState(true);

  const [attachmentPaths, setAttachmentPaths] = useState([]);
  const [formData, setFromData] = useState({});
  const [downloading, setDownloading] = useState(false);

  const currancyLookup = {
    JOD: 'دينار',
    USD: 'دولار',
    EUR: 'يورو',
  };

  const downloadFile = async (fileName) => {
    try {
      setDownloading(true);
      await filesApi.downloadMerchandiseFile(fileName);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      alert('حدق خطأ اثناء تحميل المرفقات');
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: res } = await stampReceiptFormApi.getPrintData(id);
        const { data: paths } = await stampReceiptFormApi.getAttachmentPaths(id);
        setAttachmentPaths(paths);
        setFromData(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        alert('حدث خطأ اثناء تحميل البيانات');
      }
    };

    fetchData();
  }, []);

  const DataBox = ({ title, icon = null, data, ltr = false, image = false, bordered = true, padded = true }) => {
    return (
      <div className={`data-box ${!bordered ? 'no-border' : ''} ${!padded ? 'no-padding' : ''}`}>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        {image ? (
          <div className='image-margin image-preview'>
            <div className='maintenance-form-img-preview'>
              <Image src={data} className='img' centered />
            </div>
          </div>
        ) : (
          <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data ? data : 'لا توجد بيانات'}</h5>
        )}
      </div>
    );
  };
  const AttachmentDataBox = ({ title, icon = null, data, ltr = false }) => {
    return (
      <div className='data-box-att'>
        <div className='title-container-att'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title-att'>{title}</h5>
        </div>
        <h5 className={`data-att ${ltr ? 'ltr' : ''}`}>{data}</h5>
      </div>
    );
  };
  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div style={{ margin: '1rem 0.7rem' }}>
            <div className='header-wrapper'>
              <div>
                <h3>نموذج طلب دفع طوابع رقم #{formData.id}</h3>
                <DataBox title='منشيء الطلب' icon='user circle' data={formData.createdBy} />
              </div>
            </div>

            <div className='segments-wrapper'>
              <div className='single-segment-wrapper'>
                <h3>معلومات الطلب</h3>
                <Segment>
                  <div className='form-columns-wrapper'>
                    <DataBox
                      title='تاريخ انشاء الطلب من النظام'
                      icon='calendar'
                      data={moment(formData.createdAt).format('YYYY/MM/DD hh:mm A')}
                    />
                    <DataBox title='منشئ الطلب' icon='user' data={formData.createdBy} />
                    <DataBox title='حالة الطلب' icon='tag' data={formData.status} />
                    <DataBox title='القسم' icon='building' data={formData.departmentName} />
                  </div>
                </Segment>
              </div>

              <div className='single-segment-wrapper full-width'>
                <h3>معلومات العميل و الطوابع</h3>
                <div className='two-columns'>
                  <Segment>
                    <DataBox title='اسم العميل' icon='user' data={formData.customer} />
                    <DataBox title='رقم الكفالة' icon='hashtag' data={formData.assuranceNumber} />
                    <DataBox
                      title='تاريخ الاحالة الفعلي'
                      icon='calendar'
                      data={moment(formData.actualAssuranceDate).format('YYYY/MM/DD')}
                    />
                  </Segment>
                  <Segment>
                    <DataBox title='قيمة الاحالة ' icon='money bill alternate' data={formData.assuranceAmount} />
                    <DataBox title='قيمة الطوابع ' icon='money bill alternate' data={formData.stampAmount} />
                    <DataBox title='العملة' icon='dollar sign' data={currancyLookup[formData.currancy]} />
                  </Segment>
                </div>
              </div>
              {attachmentPaths.length > 0 && (
                <div className='single-segment-wrapper full-width'>
                  <div className='title-container-att'>
                    <Icon name='attach' size='small' />
                    <h3 className='title-att'>المرفقات</h3>
                  </div>
                  <div className='two-columns'>
                    <AttachmentDataBox
                      data={
                        <div style={{ direction: 'ltr' }}>
                          {attachmentPaths.map((item, index) => (
                            <div
                              style={downloading ? { color: 'lightgray' } : {}}
                              className='attachment-path-att'
                              onClick={() => downloadFile(item.name)}
                              key={index}
                            >
                              {item.name}-{item.attachmentType}
                            </div>
                          ))}
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
};

export default ViewStampReceipt;

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive, Popup } from 'semantic-ui-react';
import { customersApi, vehiclesApi, trackingUnitsApi, lookupsApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';

export default function EditTrackingUnit() {
  const router = useHistory();
  const location = useLocation();
  const { id, row } = location.state;

  const [modelOpts, setModelOpts] = useState([]);

  const [formData, setFormData] = useState({
    imei: '',
    sim: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    imei: null,
    sim: null,
  });

  const [touched, setTouched] = useState({
    imei: false,
    sim: false,
  });

  const [customerIdOptions, setCustomerIdOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //get data for editing
      const res = await trackingUnitsApi.getOneTrackingUnit(id);
      setFormData(res.data);

      //get lookups for models
      const models = await lookupsApi.getGroup(23);
      const options1 = models.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setModelOpts(options1);
      //get customers
      const customers = await customersApi.getCustomersDropDown();
      const options2 = customers.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setCustomerIdOptions(options2);
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      imei: null,
      sim: null,
    };

    if (!formData.imei) {
      errors.imei = 'الرجاء ادخال الIMEI';
    } else if (!/^\d+$/.test(formData.imei)) {
      errors.imei = ' الIMEI يجب ان يحتوي على ارقام فقط';
    }
    if (!formData.sim) {
      errors.sim = 'الرجاء ادخال رقم الSIM';
    }

    setValidationErrors(errors);

    if (validationErrors.sim || validationErrors.imei) return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const modelSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, model: data.value });
  };

  const customerSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, customerId: data.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      imei: true,
      sim: true,
    });

    if (validation()) {
      try {
        await trackingUnitsApi.editTrackingUnit(id, formData);
        router.goBack();
      } catch (e) {
        console.log('Error editing tracking unit...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}> تعديل معلومات جهاز التتبع</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>IMEI</label>
              <Form.Input
                icon='qrcode'
                value={formData['imei']}
                name='imei'
                onChange={onChangeHandler}
                error={touched.imei && validationErrors.imei}
              />
            </Form.Field>

            <Form.Field>
              <label>SIM</label>
              <Form.Input
                icon='table'
                value={formData['sim']}
                name='sim'
                onChange={onChangeHandler}
                error={touched.sim && validationErrors.sim}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>العميل</label>
              {row.plateNumber !== '-' ? (
                <Popup
                  content='لا يمكن تعديل العميل لان جهاز التتبع مرتبط بمركبة'
                  trigger={<div className='form-display'>{row.customerId}</div>}
                  position='top center'
                />
              ) : (
                <Dropdown
                  placeholder='اختر العميل'
                  fluid
                  selection
                  search
                  value={formData['customerId']}
                  options={customerIdOptions}
                  onChange={customerSelectionChangeHandler}
                  className='table-dropdown'
                  style={{ marginLeft: '0.5rem' }}
                />
              )}
            </Form.Field>

            <Form.Field>
              <label>نوع الجهاز</label>
              <Dropdown
                placeholder='اختر نوع الجهاز'
                fluid
                selection
                search
                value={formData['model']}
                options={modelOpts}
                onChange={modelSelectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>رقم التسلسل</label>
              <Form.Input
                icon='barcode'
                value={formData['serialNumber']}
                name='serialNumber'
                onChange={onChangeHandler}
              />
            </Form.Field>

            <Form.Field>
              <label>Firmware Version</label>
              <Form.Input icon='archive' value={formData['fwVersion']} name='fwVersion' onChange={onChangeHandler} />
            </Form.Field>
          </Form.Group>

          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import './AnimatedModal.scss';

const AnimatedModal = ({ open = false, content, footer = null, initialPosition = { x: 0, y: 0 } }) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1, transition: { duration: 0.4 } }}
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
          className='animated-modal-container'
        >
          <motion.div
            initial={{ scale: 0, ...initialPosition }}
            animate={{ scale: 1, x: 0, y: 0, transition: { duration: 0.5 } }}
            exit={{ scale: 0, ...initialPosition, transition: { duration: 0.4 } }}
            className='modal-wrapper'
          >
            {content}
            <div className='modal-btns-container'>{footer}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimatedModal;

import React from 'react';
import NotificationBadge from './NotificationBadge';
import UserBadge from './UserBadge';
import './TopNavbar.scss';

function TopNavbar() {
  return (
    <div className='full-top-navbar'>
      {/* <NotificationBadge icon='bell' tooltip='التنبيهات / هذه الخاصية غير جاهزة' backgroundColor='#6c83ff' /> */}
      <div className='vertical-divider' />
      <UserBadge />
    </div>
  );
}

export default TopNavbar;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Loader,
  Icon,
  Image,
  Segment,
  Modal,
  Transition,
  Accordion,
  List,
  Form,
  Checkbox,
  Popup,
  Dropdown,
} from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { scheduleVisits, customersApi, usersApi } from '../../../api/api';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import qs from 'qs';
import DatePicker from 'react-datepicker';
import AddVisitsCalendar from './AddVisitsCalendar';
import ar from '@fullcalendar/core/locales/ar';
import { hasPermission, hasPermissions } from '../../../helpers/permissions';
import permissions from './../../../helpers/permissions';
import PermissionCheck from './../../Reusable/PermissionCheck';
import { excelExport } from './../../../helpers/excelExport';
import ErrorModal from '../../Reusable/ErrorModal';

const ViewCustomerMaintenanceVisitCalendar = () => {
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();

  const [formData, setFormData] = useState({
    customerName: '',
    customerId: '',
    contractName: '',
    departmentName: '',
    visitsCount: '',
    visitTypeName: '',
    visitsFrom: new Date(),
    visitsTo: new Date(),
    createdBy: Number(currentUser.id),
  });

  const [addedEventFormData, setAddedEventFormData] = useState({
    scheduleMaintenanceId: '',
    date: new Date(),
    branchId: '',
  });

  const [branches, setBranches] = useState([]);

  const [clickedBranch, setClickedBranch] = useState({
    branchId: '',
    visitId: '',
    date: '',
    title: '',
    assignedToId: -1,
    currentAssignedToId: -1,
    updateVisitedDate: '',
    isVisited: false,
    deleted: '',
    reportId: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  const [visits, setVisits] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calenderLoading, setCalenderLoading] = useState(false);
  const [alreadyVisited, setAlreadyVisited] = useState(false);
  const [clientbranches, setClientBranches] = useState([]);
  const [clients, setClients] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [excelExportLoading, setExcelExportLoading] = useState(false);

  const location = useLocation();
  const { id } = location.state;

  const DataBox = ({ title, icon = null, data, ltr = false, image = false, bordered = true, padded = true }) => {
    return (
      <div className={`data-box ${!bordered ? 'no-border' : ''} ${!padded ? 'no-padding' : ''}`}>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        {image ? (
          <div className='image-margin image-preview'>
            <div className='maintenance-form-img-preview'>
              <Image src={data} className='img' centered />
            </div>
          </div>
        ) : (
          <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data ? data : 'لا توجد بيانات'}</h5>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await scheduleVisits.getCustomerRecurringMaintenanceVisits(id);
        setFormData({
          ...formData,
          contractName:
            data.recurringMaintenance.contractId === null ? 'بدون عقد' : data.recurringMaintenance.contractName,
          customerName: data.recurringMaintenance.customerName,
          departmentName: data.recurringMaintenance.departmentName,
          customerId: data.recurringMaintenance.customerId,
          visitsCount: data.recurringMaintenance.visitsCount,
          visitTypeName: data.recurringMaintenance.visitTypeName,
          visitsFrom: data.recurringMaintenance.contractFrom,
          visitsTo: data.recurringMaintenance.contractTo,
        });

        const events = data.recurringBranchesVisits.map((c) => {
          return {
            id: c.visitId,
            branchId: c.branchId === 0 ? -1 : c.branchId,
            title: c.branchId === 0 ? 'العميل نفسه' : c.branchName,
            date: moment(c.visitDate).format('YYYY-MM-DD'),
            deleted: c.deleted,
            updateVisitedDate: c.updateVisitedDate,
            isVisited: c.isVisited,
            assignedToId: c.assignedToId ? c.assignedToId : -1,
            currentAssignedToId: c.assignedToId ? c.assignedToId : -1,
            reportId: c.reportId,
            editable: !c.isVisited && !c.deleted && hasPermission(permissions.EDIT_VISIT_DATE),
            color: c.deleted ? '#dc143c' : c.reportId !== 0 ? '#2e8b57' : c.isVisited ? '#ffa500' : '#003A71',
          };
        });

        const branchesOptionsArr = [
          ...new Map(data.recurringBranchesVisits.map((item) => [item['branchId'], item])).values(),
        ];

        const branchesOptions = branchesOptionsArr
          .filter((b) => b.branchId !== 0)
          .map((item) => ({
            key: item.branchId,
            text: item.branchName,
            value: item.branchId,
          }));

        setAddedEventFormData({
          ...addedEventFormData,
          customerId: data.recurringMaintenance.customerId,
          scheduleMaintenanceId: id,
        });

        setVisits(events);
        setLoading(false);
        setBranches(branchesOptions);
        setClients([
          {
            key: data.recurringMaintenance.customerId,
            text: data.recurringMaintenance.customerName,
            value: data.recurringMaintenance.customerId,
          },
        ]);
        getCustomerBranches(data.recurringMaintenance.customerId);
      } catch (err) {
        setModalError(true);
        setLoading(false);
        console.log('Error getting data...', err.response ?? err);
      }
    };
    fetchData();
  }, [rerender]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const usersData = await usersApi.getAllExceptCustomersDropdown();
        const usersOptions = usersData.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setUsers([
          {
            key: -1,
            text: 'دون اسناد',
            value: -1,
          },
          ...usersOptions,
        ]);
        setLoading(false);
      } catch (err) {
        setModalError(true);
        setLoading(false);
        console.log('Error getting data...', err.response ?? err);
      }
    };
    fetchData();
  }, []);

  const onEventDrop = async (info) => {
    setCalenderLoading(true);
    const { start: date } = info.event._instance.range;
    const { publicId: id } = info.event._def;

    try {
      const queryString = qs.stringify(
        {
          date: date,
          id: id,
        },
        { addQueryPrefix: true }
      );
      await scheduleVisits.updateVisitDate(queryString);
      setCalenderLoading(false);
    } catch (err) {
      setModalError(true);
      setCalenderLoading(false);
      setVisits([...visits]);
      console.log('Error getting data...', err.response ?? err);
    }
  };

  const onEventClick = (info) => {
    const { branchId, isVisited, reportId, assignedToId, currentAssignedToId, deleted, updateVisitedDate } =
      info.event._def.extendedProps;
    const { publicId: visitId } = info.event._def;
    const { start: date } = info.event._instance.range;
    const { title } = info.event._def;
    setClickedBranch({
      branchId: branchId,
      visitId: visitId,
      date: date,
      title: title,
      updateVisitedDate: updateVisitedDate,
      assignedToId: assignedToId,
      currentAssignedToId: currentAssignedToId,
      isVisited: isVisited,
      reportId: reportId,
      deleted: deleted,
    });
    setAlreadyVisited(isVisited);
    setIsModalOpen(true);
  };

  const DeleteCurrentClickedVisit = async () => {
    try {
      setLoading(true);
      const { data } = await scheduleVisits.deleteVisit(clickedBranch.visitId);
      if (data == -1) alert('لا يمكن حذف زيارة مكتملة');
      setRerender(!rerender);
      setIsConfirmModalOpen(false);
      setLoading(false);
    } catch (err) {
      setModalError(true);
      setIsConfirmModalOpen(false);
      setLoading(false);
      console.log('Error getting data...', err.response ?? err);
    }
  };

  const EditScheduleVisit = async () => {
    setCalenderLoading(true);
    try {
      const queryString = qs.stringify(
        {
          date: clickedBranch.date,
          id: clickedBranch.visitId,
          isVisited: clickedBranch.isVisited,
          assignedToId: clickedBranch.assignedToId,
          assignmentChanged: clickedBranch.assignedToId !== clickedBranch.currentAssignedToId,
        },
        { addQueryPrefix: true }
      );
      await scheduleVisits.updateVisitDate(queryString);
      setIsModalOpen(false);
      setRerender(!rerender);
      setCalenderLoading(false);
    } catch (err) {
      setModalError(true);
      setCalenderLoading(false);
      setIsModalOpen(false);
      setVisits([...visits]);
      console.log('Error getting data...', err.response ?? err);
    }
  };

  const getCustomerBranches = async (customer) => {
    const queryString = qs.stringify(
      {
        id: customer,
      },
      { addQueryPrefix: true }
    );
    const branch = await customersApi.getCustomerBranches(queryString);
    if (branch.status != 204) {
      const branchesOptions = branch.data.data.map((item) => ({
        key: item.id,
        text: item.branchName,
        value: item.id,
      }));
      setClientBranches([{ key: -1, text: 'العميل نفسه', value: -1 }, ...branchesOptions]);
    }
  };

  const handleDateClicked = async (info) => {
    setIsAddModalOpen(true);
    setAddedEventFormData({ ...addedEventFormData, date: info.date });
  };

  const exportFileStatisticsHandler = async () => {
    try {
      setExcelExportLoading(true);
      const { data } = await scheduleVisits.getExcelStatistics(id);
      const visits = data.map((c) => {
        return {
          visitDate: moment(c.visitDate).format('YYYY/MM/DD'),
          customerName: c.customerName,
          branchName: c.branchName === null ? 'العميل نفسه' : c.branchName,
          departmentName: c.departmentName,
          assignedTo: c.assignedTo ? c.assignedTo : 'دون اسناد',
          isVisited: c.isVisited ? 'تمت الزيارة' : 'لم تتم الزيارة بعد',
          updateVisitedDate:
            c.updateVisitedDate == null ? 'لم تتم الزيارة بعد' : moment(c.updateVisitedDate).format('YYYY/MM/DD'),
          contractName: c.contractName === null ? 'بدون عقد' : c.contractName,
          reportCreatedBy: c.createdBy === null ? 'لم يتم اصدار تقرير' : c.createdBy,
          reportCreatedAt: c.reportCreatedAt === null ? '' : moment(c.reportCreatedAt).format('YYYY/MM/DD'),
          status: c.deleted ? 'زيارة محذوفة' : 'فعالة',
        };
      });
      excelExport(visits, 'زيارات العميل');

      setExcelExportLoading(false);
    } catch (error) {
      setExcelExportLoading(false);
    }
  };

  const userAssignedHandler = (e, v) => {
    setClickedBranch({ ...clickedBranch, assignedToId: v.value });
  };

  return (
    <div className='maintenance-schedule-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <>
            <div style={{ margin: '1rem 0.7rem' }}>
              <div className='schedule-content-header'>
                <h3>عقد زيارة رقم#{id}</h3>
                <PermissionCheck permissions={permissions.EXPORT_SCHEDULE_STATISTICS}>
                  <Popup
                    content='تصدير الزيارات الى ملف اكسل'
                    trigger={
                      <Button icon basic onClick={() => exportFileStatisticsHandler()} loading={excelExportLoading}>
                        <Icon name='file excel' />
                      </Button>
                    }
                  />
                </PermissionCheck>
              </div>
              <div className='segments-wrapper'>
                <div className='single-segment-wrapper'>
                  <h3>معلومات العقد</h3>
                  <Segment>
                    <div className='form-columns-wrapper'>
                      <DataBox title='اسم العميل' icon='user' data={formData.customerName} />
                      <DataBox title='الدائرة' icon='users' ltr data={formData.departmentName} />
                      <DataBox
                        title='بدء الزيارات'
                        icon='calendar'
                        ltr
                        data={moment(formData.visitsFrom).format('YYYY/MM/DD')}
                      />
                      <DataBox
                        title='انتهاء الزيارات'
                        icon='calendar'
                        ltr
                        data={moment(formData.visitsTo).format('YYYY/MM/DD')}
                      />
                      <DataBox title='نوع الزيارة' icon='tag' data={formData.visitTypeName} />
                      <DataBox title='مرات التكرار' icon='user' data={formData.visitsCount} />
                      <DataBox title='اسم العقد' icon='tag' data={formData.contractName} />
                    </div>
                  </Segment>
                </div>
              </div>
              {branches.length > 0 && (
                <Segment>
                  <Accordion fluid>
                    <Accordion.Title
                      active={activeIndex}
                      content='الفروع'
                      index={0}
                      style={{ marginBottom: '10px' }}
                      onClick={(e, titleProps) => setActiveIndex(!titleProps.active)}
                    />
                    <Accordion.Content
                      active={activeIndex}
                      content={
                        <List horizontal selection>
                          {branches.map((item, index) => (
                            <List.Item key={index} style={{ marginLeft: 0, textAlign: 'center' }}>
                              <List.Icon name='building' size='large' verticalAlign='middle' />
                              <List.Content>
                                <List.Header>{item.text}</List.Header>
                              </List.Content>
                            </List.Item>
                          ))}
                        </List>
                      }
                    />
                  </Accordion>
                </Segment>
              )}
              <Segment loading={calenderLoading}>
                <FullCalendar
                  events={visits}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  contentHeight={500}
                  locale={ar}
                  dayMaxEventRows={3}
                  initialView='dayGridMonth'
                  droppable
                  editable
                  // validRange={{
                  //   start: moment(formData.visitsFrom).format('YYYY-MM-DD'),
                  //   end: moment(formData.visitsTo).format('YYYY-MM-DD'),
                  // }}
                  dateClick={hasPermission(permissions.ADD_VISIT) ? handleDateClicked : () => {}}
                  eventDrop={onEventDrop}
                  eventClick={onEventClick}
                />
              </Segment>
            </div>
            <div>
              <Transition visible={isModalOpen} animation='scale' duration={250}>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} size='small'>
                  <Modal.Header>
                    <div className='flex-space'>
                      <div>
                        {clickedBranch.branchId === -1 ? (
                          <div>
                            زيارة العميل {formData.customerName} بتاريخ{' '}
                            {moment(clickedBranch.date).format('YYYY/MM/DD')}
                          </div>
                        ) : (
                          <div>
                            زيارة العميل {formData.customerName} لفرع {clickedBranch.title} بتاريخ{' '}
                            {moment(clickedBranch.date).format('YYYY/MM/DD')}
                          </div>
                        )}
                      </div>
                      <Icon name='close' size='small' onClick={() => setIsModalOpen(false)} />
                    </div>
                  </Modal.Header>
                  <Modal.Content>
                    <Form style={{ margin: '1rem' }}>
                      <Form.Field>
                        <label>تاريخ الزيارة</label>
                        <DatePicker
                          popperPlacement='top-end'
                          placeholderText='تاريخ الزيارة'
                          value={moment(clickedBranch.date).format('YYYY/MM/DD')}
                          onChange={(date) => setClickedBranch({ ...clickedBranch, date: date })}
                          disabled={
                            alreadyVisited || clickedBranch.deleted || !hasPermission(permissions.EDIT_VISIT_DATE)
                          }
                          maxDate={new Date(clickedBranch.visitsTo)}
                          minDate={new Date(clickedBranch.visitsFrom)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>تم اسناد الزيارة الى</label>
                        <Dropdown
                          disabled={alreadyVisited || clickedBranch.deleted || !hasPermission(permissions.ASSIGN_VISIT)}
                          fluid
                          selection
                          search
                          name='userId'
                          value={clickedBranch.assignedToId}
                          options={users}
                          onChange={userAssignedHandler}
                          className='table-dropdown'
                        />
                      </Form.Field>
                      <Form.Field>
                        <div className='title-container'>
                          <div className='edit-visit-container'>
                            <div className='flex-row'>
                              <Checkbox
                                checked={clickedBranch.isVisited}
                                disabled={
                                  alreadyVisited || clickedBranch.deleted || !hasPermission(permissions.EDIT_VISIT_DATE)
                                }
                                onChange={(e, v) => setClickedBranch({ ...clickedBranch, isVisited: v.checked })}
                              />
                              <div className='title'>
                                {clickedBranch.isVisited && clickedBranch.updateVisitedDate
                                  ? 'زيارة منتهية'
                                  : 'انهاء الزيارة بدون تقرير'}
                              </div>
                            </div>
                            <div>
                              {clickedBranch.isVisited && clickedBranch.updateVisitedDate && (
                                <span>
                                  <Popup
                                    content={`تم تحديث انه تم زيارة هذا الفرع بتاريخ ${moment(
                                      clickedBranch.updateVisitedDate
                                    ).format('YYYY/MM/DD')}`}
                                    trigger={<Icon name='info' size='small' circular />}
                                  />
                                </span>
                              )}
                              {!clickedBranch.deleted &&
                                clickedBranch.reportId === 0 &&
                                hasPermission(permissions.ADD_VISIT_REPORT) && (
                                  <span
                                    onClick={() =>
                                      router.push('/client-visits/generateReport', {
                                        ...clickedBranch,
                                        customerName: formData.customerName,
                                        contractName: formData.contractName,
                                        departmentName: formData.departmentName,
                                      })
                                    }
                                  >
                                    <Popup content={'إنشاء تقرير'} trigger={<Icon name='newspaper' size='large' />} />
                                  </span>
                                )}
                              {clickedBranch.reportId !== 0 &&
                                hasPermissions([
                                  permissions.VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS,
                                  permissions.VIEW_VISIT_REPORT,
                                  permissions.ADD_VISIT_REPORT,
                                ]) && (
                                  <span
                                    onClick={() =>
                                      router.push('/client-visits/ViewReport', {
                                        id: clickedBranch.visitId,
                                      })
                                    }
                                  >
                                    <Popup
                                      content={'عرض التقرير'}
                                      trigger={<Icon name='envelope open' size='large' />}
                                    />
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </Form.Field>
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    {!clickedBranch.deleted && !clickedBranch.isVisited && hasPermission(permissions.DELETE_VISIT) && (
                      <Button
                        style={{ backgroundColor: '#dc143c', color: 'white' }}
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                          setIsModalOpen(false);
                        }}
                      >
                        حذف
                        <Icon name='trash' />
                      </Button>
                    )}
                    {!clickedBranch.deleted && !alreadyVisited && hasPermission(permissions.EDIT_VISIT_DATE) && (
                      <Button style={{ backgroundColor: '#003A71', color: 'white' }} onClick={EditScheduleVisit}>
                        تعديل
                        <Icon name='pencil' />
                      </Button>
                    )}
                  </Modal.Actions>
                </Modal>
              </Transition>
              <Transition visible={isConfirmModalOpen} animation='scale' duration={250}>
                <Modal size='tiny' open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
                  <Modal.Header>حذف موعد زيارة</Modal.Header>
                  <Modal.Content>
                    <p>هل انت متأكد من حذف موعد الزيارة؟</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='grey' onClick={() => setIsConfirmModalOpen(false)}>
                      لا
                    </Button>
                    <Button color='blue' onClick={DeleteCurrentClickedVisit}>
                      نعم
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Transition>
              <AddVisitsCalendar
                clients={clients}
                setIsAddModalOpen={setIsAddModalOpen}
                isAddModalOpen={isAddModalOpen}
                setAddedEventFormData={setAddedEventFormData}
                addedEventFormData={addedEventFormData}
                getCustomerBranches={getCustomerBranches}
                formData={formData}
                clientbranches={clientbranches}
                setClientBranches={setClientBranches}
                setRerender={setRerender}
                rerender={rerender}
                setModalError={setModalError}
              />
            </div>
          </>
        )}
      </PageCardContainer>
      {modalError && <ErrorModal />}
    </div>
  );
};

export default ViewCustomerMaintenanceVisitCalendar;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Responsive } from 'semantic-ui-react';

export default function StackedBarChart({
  labels,
  data1,
  data2,
  data1Color,
  data1HoverColor,
  data1Label,
  data2Color,
  data2HoverColor,
  data2Label,
}) {
  const options = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
    },
    type: 'bar',
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  const data = {
    labels: [...labels],
    datasets: [
      // يتم صيانتها
      {
        label: data2Label,
        backgroundColor: data2Color,
        hoverBackgroundColor: data2HoverColor,
        stack: 1,
        data: [...data2],
      },
      //   الكل
      {
        label: data1Label,
        backgroundColor: data1Color,
        hoverBackgroundColor: data1HoverColor,
        stack: 1,
        data: [...data1],
      },
    ],
  };
  return (
    <>
      <Responsive maxWidth={410}>
        <Bar data={data} width={null} height={300} options={options} />
      </Responsive>

      <Responsive minWidth={411} maxWidth={767}>
        <Bar data={data} width={null} height={180} options={options} />
      </Responsive>

      <Responsive minWidth={768}>
        <Bar data={data} width={null} height={80} options={options} />
      </Responsive>
    </>
  );
}

import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { userAuthActions, ticketsActions } from './../../redux/actions/actionCreator';
import { useHistory } from 'react-router-dom';

const UserNavbarMenu = ({ open }) => {
  const userId = useSelector((state) => state.user.user.id);
  const router = useHistory();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(ticketsActions.clearSelectedTicket());
    dispatch(userAuthActions.logout());
    return router.push(`/login`);
  };

  const onChangePassword = () => {
    router.push('/users/editPassword', { userId });
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ y: -45, opacity: 0 }}
          exit={{ opacity: 0, transition: { duration: 0.2 } }}
          animate={{ y: 0, opacity: 1 }}
          className='user-navbar-menu-container'
        >
          <div className='menu-item' onClick={onChangePassword}>
            <Icon name='lock' />
            <h5>تغيير كلمة المرور</h5>
          </div>
          <div className='menu-item' onClick={onLogout}>
            <Icon name='log out' />
            <h5>تسجيل خروج</h5>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UserNavbarMenu;

import { motion } from 'framer-motion';
import moment from 'moment';
import React from 'react';
import { Icon, Popup, Segment } from 'semantic-ui-react';
import userImage from '../../../assets/images/user-icon.png';

// TODO: Find a way to keep these lookups consistant across deployments
const statusColor = {
  18: { color: 'orange', icon: 'clock outline' }, // Open
  19: { color: 'blue', icon: 'sync' }, // In Progress
  20: { color: 'green', icon: 'check circle outline' }, // Closed
};

const listItem = {
  hidden: {
    x: 300,
  },
  show: {
    x: 0,
    transition: {
      type: 'easeInOut',
      duration: 0.1,
    },
  },
};

const CustomerTicketsListItem = ({ ticket, onTicketSelected, selectedTicket, assignedImage }) => {
  return (
    <motion.div key={ticket.id} className='ticket-card' variants={listItem}>
      <Segment
        color={statusColor[ticket.status].color}
        onClick={() => onTicketSelected(ticket)}
        raised={selectedTicket === ticket.id}
        secondary={selectedTicket === ticket.id}
      >
        <div className='cutomer-ticket-title'>
          <h5 className='customer'>{`${ticket.fullName} ${
            ticket.branchLocation ? '/' + ticket.branchLocation : ''
          }`}</h5>
          <h5 className='ticket-id'>
            {ticket.id}
            {' # '}
            {!!ticket.receiptNumber && `[${ticket.receiptNumber}]`}
          </h5>
        </div>
        <div className='ticket-body'>
          <h5 className='title'>{ticket.typeName}</h5>
          {Boolean(ticket.teamMember) && (
            <Popup
              content={ticket.teamMember}
              trigger={<img alt='' src={assignedImage || userImage} className='assignee-thump' />}
            />
          )}
        </div>
        <div className='caller-name-section'>
          <Icon size='small' name='phone' color='grey' />
          {!!ticket.ticketOpenerName ? (
            <label>
              {ticket.ticketOpenerName} / {ticket.ticketOpenerPhone}
            </label>
          ) : (
            <label>اسم المتصل غير مدخل</label>
          )}
        </div>
        <div className='footer'>
          <span>
            <p className={`status-${statusColor[ticket.status].color}`}>{ticket.statusName}</p>
            <Icon size='small' color={statusColor[ticket.status].color} name={statusColor[ticket.status].icon} />
          </span>
          <span>
            <label>{moment(ticket.createdAt).format('YYYY/MM/DD, hh:mm A')}</label>
            <Icon name='clock outline' size='small' />
          </span>
        </div>
      </Segment>
    </motion.div>
  );
};

export default CustomerTicketsListItem;

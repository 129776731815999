import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Transition, Loader } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi, lookupsApi, assetsApi } from './../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';

export default function Assets() {
  const router = useHistory();
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});
  const [modelOpts, setModelOpts] = useState([]);
  const [modelArMap, setModelArMap] = useState({});

  const [deleteAssetPopup, setDeleteAssetPopup] = useState(false);
  const [deleteNotAllowedModalVisible, setDeleteNotAllowedModalVisible] = useState(false);

  const [currentAssetId, setCurrentAssetId] = useState('');

  const [rerenderFlag, setRerenderFlag] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //get lookups for models
      const models = await lookupsApi.getGroup(77);
      let options1 = [];
      let options1ArMap = {};
      for (let item of models.data) {
        options1.push({ key: item.id, text: item.name, value: item.id });
        options1ArMap[item.id] = item.name;
      }
      setModelOpts(options1);
      setModelArMap(options1ArMap);
      //get customers
      const customers = await customersApi.getCustomersDropDown();
      let options2 = [];
      let options2ArMap = {};
      for (let item of customers.data) {
        options2.push({ key: item.id, text: item.name, value: item.id });
        options2ArMap[item.id] = item.name;
      }
      setCustomerIdOptions(options2);
      setCustomerIdArMap(options2ArMap);
    };

    fetchData();
  }, [rerenderFlag]);

  const deleteAssetConfirmHandler = async () => {
    try {
      const { data: res } = await assetsApi.deleteAsset(currentAssetId);
      if (res === -1) {
        setDeleteNotAllowedModalVisible(true);
      }
    } catch (err) {
      // TODO: show error
      console.log('Error deleting asset...', err);
    }
    setDeleteAssetPopup(false);
    setRerenderFlag(!rerenderFlag);
  };

  const handleAssetDelete = async (id, row) => {
    setDeleteAssetPopup(true);
    setCurrentAssetId(id);
  };

  const tableHeaders = [
    {
      displayName: 'IMEI',
      fieldName: 'imei',
      dbFieldName: 'imei',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'SIM',
      fieldName: 'sim',
      dbFieldName: 'sim',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'نوع الجهاز',
      fieldName: 'assetType',
      dbFieldName: 'asset_type',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: modelOpts,
      dropdownArMap: modelArMap,
    },
    {
      displayName: 'موديل الجهاز',
      fieldName: 'assetModel',
      dbFieldName: 'asset_model',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: modelOpts,
      dropdownArMap: modelArMap,
    },
    {
      displayName: 'العميل',
      fieldName: 'customer',
      dbFieldName: 'customer_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerIdOptions,
      dropdownArMap: customerIdArMap,
    },
  ];

  const tableActions = [
    ...(hasPermissions([permissions.VIEW_ASSETS, permissions.EDIT_ASSET])
      ? [
          {
            actionType: 'view',
            actionUrl: '/assets/view',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_ASSET)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/assets/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_ASSET)
      ? [
          {
            actionType: 'custom',
            onClick: handleAssetDelete,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>الاجهزة والمعدات</h3>
          <PermissionCheck permissions={permissions.CREATE_ASSET}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/assets/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة جهاز جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='الاجهزة'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={assetsApi.getAllAssets}
          reloadTrigger={[rerenderFlag]}
          optionalQsParams={{}}
        />
      </PageCardContainer>

      <Transition visible={deleteAssetPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteAssetPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>هل انت متأكد من الحذف؟</p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteAssetConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setDeleteAssetPopup(false);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* invalid deletion modal */}
      <Transition visible={deleteNotAllowedModalVisible} animation='scale' duration={1000}>
        <Modal size='small' open={deleteNotAllowedModalVisible}>
          <Modal.Header>لم يتم الحذف</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>لا يمكن حذف الجهاز بسبب وجود تذكرة غير مغلقه مرتبطه به!</p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color='green'
              onClick={() => {
                setDeleteNotAllowedModalVisible(false);
              }}
            >
              حسناً
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

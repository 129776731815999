import React, { useState, useEffect } from 'react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive, Radio } from 'semantic-ui-react';
import DropFileUpload from '../../Reusable/DropFileUpload';
import { assuranceFormApi, customersApi, departmentsApi, filesApi, lookupsApi } from '../../../api/api';
import AnimatedModal from '../../Reusable/AnimatedModal';
import { tafqeet } from '../../../helpers/Tafqeet';

const EditAssuranceForm = () => {
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();
  const location = useLocation();
  const { id } = location.state;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const [deleteFileName, setDeleteFileName] = useState(null);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [customersIdOptions, setCustomersIdOptions] = useState([]);
  const [assuranceTypesIdOptions, setAssuranceTypeIdOptions] = useState([]);
  const [userDepartments, setUserDepartments] = useState([]);

  const [currancyOptions, setCurrancyOptions] = useState([
    {
      key: 'JOD',
      text: 'دينار اردني',
      value: 'JOD',
    },
    {
      key: 'EUR',
      text: 'يورو',
      value: 'EUR',
    },
    {
      key: 'USD',
      text: 'دولار امريكي',
      value: 'USD',
    },
  ]);
  const [currentCurrancy, setCurrentCurrancy] = useState('دينار أردني');

  const [formData, setFormData] = useState({
    submitionDateFromSystem: null,
    submitionDate: null,
    assuranceType: 0,
    assuranceNumber: '',
    offeredMaterial: '', // المواد المقدمة
    customerId: 0,
    phoneNumber: '',
    country: '',
    city: '',
    address: '',
    fax: '',
    postalCode: '',
    POBox: '',
    assuranceAmountNumbers: 0,
    assuranceAmountWritten: '',
    currancy: '',
    assuranceFromDate: null,
    assuranceToDate: null,
    createdBy: Number(currentUser.id),
    departmentId: 0,
    notes: '',
  });

  const [touched, setTouched] = useState({
    submitionDate: false,
    assuranceType: false,
    assuranceNumber: false,
    offeredMaterial: false, // المواد المقدمة
    customerId: false,
    phoneNumber: false,
    country: false,
    city: false,
    address: false,
    fax: false,
    postalCode: false,
    POBox: false,
    assuranceAmountNumbers: false,
    assuranceAmountWritten: false,
    currancy: false,
    assuranceFromDate: false,
    assuranceToDate: false,
    departmentId: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    submitionDate: null,
    assuranceType: null,
    assuranceNumber: null,
    offeredMaterial: null, // المواد المقدمة
    customerId: null,
    phoneNumber: null,
    address: null,
    fax: null,
    postalCode: null,
    POBox: null,
    assuranceAmountNumbers: null,
    assuranceAmountWritten: null,
    currancy: null,
    assuranceFromDate: null,
    assuranceToDate: null,
    departmentId: null,
    assuranceAttachment: null,
  });

  const onDropFilesChanged = (files) => {
    setFiles(files);
  };

  const uploadFiles = async (assuranceFormId) => {
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const fileData = new FormData();
        fileData.append('file', file);
        await filesApi.uploadAssuranceFormAttachments(assuranceFormId, file.attachmentType, fileData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: customers } = await customersApi.getCustomersDropDown();
        const { data: lookups } = await lookupsApi.getGroup(124);
        const { data: form } = await assuranceFormApi.getOneAssuranceForm(id);
        const { data: previousFilesResponse } = await assuranceFormApi.getAttachmentPaths(id);
        const { data: departments } = await departmentsApi.getDepartmentsByUserIdDropDown(currentUser.id);

        const departmentsOptions = departments.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setUserDepartments(departmentsOptions);
        if (!!previousFilesResponse.length) {
          setPreviousFiles(previousFilesResponse);
        }

        setFormData({
          ...formData,
          submitionDate: form.submitionDate ? new Date(form.submitionDate) : null,
          submitionDateFromSystem: form.submitionDateFromSystem ? new Date(form.submitionDateFromSystem) : null,
          assuranceType: form.assuranceType ?? null,
          assuranceNumber: form.assuranceNumber ?? null,
          offeredMaterial: form.offeredMaterial ?? null,
          customerId: form.customerId ?? null,
          phoneNumber: form.phoneNumber ?? null,
          address: form.address ?? null,
          fax: form.fax ?? null,
          country: form.country ?? null,
          city: form.city ?? null,
          postalCode: form.postalCode ?? null,
          POBox: form.poBox ?? null,
          assuranceAmountNumbers: form.assuranceAmountNumbers ?? null,
          assuranceAmountWritten: form.assuranceAmountWritten ?? null,
          currancy: form.currancy ?? null,
          assuranceFromDate: form.assuranceFromDate ? new Date(form.assuranceFromDate) : null,
          assuranceToDate: form.assuranceToDate ? new Date(form.assuranceToDate) : null,
          departmentId: form.departmentId,
          notes: form.notes ?? null,
        });

        const customersOptions = customers.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCustomersIdOptions(customersOptions);

        const assuranceTypesOptions = lookups.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setAssuranceTypeIdOptions(assuranceTypesOptions);
      } catch (err) {
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching assruance form dropdowns...', err);
        router.goBack();
      }
      setLoading(false);
    })();
  }, []);

  const validation = () => {
    const errors = {
      submitionDate: null,
      assuranceType: null,
      assuranceNumber: null,
      offeredMaterial: null,
      customerId: null,
      phoneNumber: null,
      address: null,
      fax: null,
      postalCode: null,
      POBox: null,
      assuranceAmountNumbers: null,
      assuranceAmountWritten: null,
      currancy: null,
      assuranceFromDate: null,
      assuranceToDate: null,
      departmentId: null,
    };

    if (!formData.submitionDate) {
      errors.submitionDate = 'الرجاء ادخال تاريخ تقديم الطلب';
    }
    if (!formData.assuranceType) {
      errors.assuranceType = 'الرجاء اختيار نوع الكفالة';
    }
    if (!formData.assuranceNumber) {
      errors.assuranceNumber = 'الرجاء ادخال رقم العطاء';
    }
    if (!formData.offeredMaterial) {
      errors.offeredMaterial = 'الرجاء ادخال المواد المقدمة';
    }
    if (!formData.customerId) {
      errors.customerId = 'الرجاء اختيار عميل';
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = 'الرجاء ادخال رقم الهاتف';
    }
    if (!formData.address) {
      errors.address = 'الرجاء ادخال العنوان';
    }
    if (!formData.fax) {
      errors.fax = 'الرجاء ادخال الفاكس';
    }
    if (!formData.postalCode) {
      errors.postalCode = 'الرجاء ادخال الرمز البريدي';
    }
    if (!formData.POBox) {
      errors.POBox = 'الرجاء ادخال الـ ص.ب';
    }
    if (!formData.assuranceAmountNumbers) {
      errors.assuranceAmountNumbers = 'الرجاء ادخال قيمة الكفالة رقما';
    }
    if (!formData.assuranceAmountWritten) {
      errors.assuranceAmountWritten = 'الرجاء ادخال قيمة الكفالة تفقيط';
    }
    if (!formData.currancy) {
      errors.currancy = 'الرجاء اختيار العملة';
    }
    if (!formData.assuranceFromDate) {
      errors.assuranceFromDate = 'الرجاء اختيار تاريخ بداية الكفالة';
    }
    if (!formData.assuranceToDate) {
      errors.assuranceToDate = 'الرجاء اختيار تاريخ نهاية الكفالة';
    }
    if (!formData.departmentId) {
      errors.assuranceToDate = 'الرجاء اختيار قسم';
    }

    setValidationErrors(errors);

    if (
      validationErrors.POBox ||
      validationErrors.address ||
      validationErrors.assuranceAmountNumbers ||
      validationErrors.assuranceAmountWritten ||
      validationErrors.assuranceFromDate ||
      validationErrors.assuranceToDate ||
      validationErrors.currancy ||
      validationErrors.customerId ||
      validationErrors.fax ||
      validationErrors.offeredMaterial ||
      validationErrors.phoneNumber ||
      validationErrors.postalCode ||
      validationErrors.submitionDate ||
      validationErrors.assuranceNumber ||
      validationErrors.assuranceType ||
      validationErrors.departmentId
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const filesHasAssignmentAttachmentAndType = () => {
    let checkForAssignment = true;
    let assignmentAttachmentExist = true;
    let assignmentAttachmentTypeExist = false;
    previousFiles.forEach((file) => {
      if (file.attachmentType === 'مرفق احالة') {
        checkForAssignment = false;
        assignmentAttachmentExist = false;
      }
    });

    files.forEach((file) => {
      if (checkForAssignment)
        if (file.attachmentType === 149)
          //مرفق احالة
          assignmentAttachmentExist = false;
      if (!file.attachmentType) assignmentAttachmentTypeExist = true;
    });
    return assignmentAttachmentExist || assignmentAttachmentTypeExist;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      submitionDate: true,
      assuranceType: true,
      assuranceNumber: true,
      offeredMaterial: true, // المواد المقدمة
      customerId: true,
      phoneNumber: true,
      country: true,
      city: true,
      address: true,
      fax: true,
      postalCode: true,
      POBox: true,
      assuranceAmountNumbers: true,
      assuranceAmountWritten: true,
      currancy: true,
      assuranceFromDate: true,
      assuranceToDate: true,
      departmentId: true,
    });

    if (validation()) {
      if (filesHasAssignmentAttachmentAndType()) {
        setValidationErrors({
          ...validationErrors,
          assuranceAttachment: 'الرجاء رفع مرفق الاحالة و تحديد انواع المرفقات',
        });
        setIsSubmitting(false);
        return;
      }
      try {
        await assuranceFormApi.editAssuranceForm(id, {
          ...formData,
        });
        uploadFiles(id);
        router.goBack();
      } catch (e) {
        console.log('Error posting contract...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const deleteExistingFile = async () => {
    const id = location.state.id;
    const fileName = deleteFileName;
    setDeleteFileName(null);
    try {
      setIsSubmitting(true);
      await filesApi.deleteAssuranceFormAttachmentFile(id, fileName);
      setPreviousFiles(previousFiles.filter((item) => item.name !== fileName));
      setIsSubmitting(false);
    } catch (e) {
      console.log(e);
      alert('حدث خطأ أثناء الحذف');
    }
  };

  const tafqeetHandler = () => {
    if (!!formData.assuranceAmountNumbers) {
      const fraction = formData.assuranceAmountNumbers.toString().split('.');

      if (fraction.length === 2) {
        const writtentNumber = tafqeet(fraction[0]) + ' دينار و ' + tafqeet(fraction[1]) + ' فلس ';
        setFormData({ ...formData, assuranceAmountWritten: writtentNumber });
      } else if (fraction.length === 1) {
        const writtentNumber = tafqeet(fraction[0]) + ' دينار ';
        setFormData({ ...formData, assuranceAmountWritten: writtentNumber });
      }
    }
  };

  return (
    <PageCardContainer>
      <AnimatedModal
        open={!!deleteFileName}
        content='تأكيد عملية حذف الملف؟'
        footer={
          <>
            <Button color='red' onClick={deleteExistingFile}>
              تأكيد
            </Button>
            <Button onClick={() => setDeleteFileName(null)}>إلغاء</Button>
          </>
        }
      />
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>نموذج طلب كفالة</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>المندوب</label>
              <Form.Input className='rtl-input' fluid value={currentUser.FullName} readOnly />
            </Form.Field>
            <Form.Field>
              <label>القسم</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  search
                  name='departmentId'
                  value={formData.departmentId}
                  options={userDepartments}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.departmentId && validationErrors.departmentId}</label>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>تاريخ تقديم الطلب من النظام</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='تاريخ تقديم الطلب'
                selected={formData.submitionDateFromSystem}
                dateFormat={'dd/MM/yyyy hh:mm aa'}
                className='ltr text-right'
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>تاريخ تقديم الطلب</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='تاريخ تقديم الطلب'
                selected={formData.submitionDate}
                dateFormat={'dd/MM/yyyy hh:mm aa'}
                showTimeSelect
                className='ltr text-right'
                timeIntervals={30}
                onChange={(dateTime) => setFormData({ ...formData, submitionDate: dateTime })}
              />
              <label style={{ color: '#9f3a38' }}>{touched.submitionDate && validationErrors.submitionDate}</label>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>تاريخ بداية مدة الكفالة</label>
              <DatePicker
                popperPlacement='bottom-end'
                placeholderText='من تاريخ'
                dateFormat={'dd/MM/yyyy'}
                selected={formData.assuranceFromDate}
                onChange={(date) => setFormData({ ...formData, assuranceFromDate: date })}
              />
              <label style={{ color: '#9f3a38' }}>
                {touched.assuranceFromDate && validationErrors.assuranceFromDate}
              </label>
            </Form.Field>
            <Form.Field>
              <label>تاريخ نهاية مدة الكفالة</label>
              <DatePicker
                popperPlacement='bottom-end'
                placeholderText='الى تاريخ'
                dateFormat={'dd/MM/yyyy'}
                selected={formData.assuranceToDate}
                onChange={(date) => setFormData({ ...formData, assuranceToDate: date })}
              />
              <label style={{ color: '#9f3a38' }}>{touched.assuranceToDate && validationErrors.assuranceToDate}</label>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>رقم العطاء/احالة/توريد/شراء</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceNumber'
                onChange={onChangeHandler}
                value={formData.assuranceNumber}
                error={touched.assuranceNumber && validationErrors.assuranceNumber}
              />
            </Form.Field>
            <Form.Field>
              <label>نوع الكفالة</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  search
                  name='assuranceType'
                  value={formData.assuranceType}
                  options={assuranceTypesIdOptions}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.assuranceType && validationErrors.assuranceType}</label>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>المواد المقدمة</label>
            <Form.Input
              className='rtl-input'
              fluid
              name='offeredMaterial'
              onChange={onChangeHandler}
              value={formData.offeredMaterial}
              error={touched.offeredMaterial && validationErrors.offeredMaterial}
            />
          </Form.Field>
          <Form.Group widths={2}>
            <Form.Field>
              <label>المستفيد</label>
              <Dropdown
                fluid
                selection
                search
                value={formData['customerId']}
                name='customerId'
                options={customersIdOptions}
                onChange={selectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
            <Form.Field>
              <label>الهاتف</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='phoneNumber'
                onChange={onChangeHandler}
                value={formData.phoneNumber}
                error={touched.phoneNumber && validationErrors.phoneNumber}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={3}>
            <Form.Field>
              <label>الدولة</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='country'
                onChange={onChangeHandler}
                value={formData.country}
                error={touched.country && validationErrors.country}
              />
            </Form.Field>
            <Form.Field>
              <label>المدينة</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='city'
                onChange={onChangeHandler}
                value={formData.city}
                error={touched.city && validationErrors.city}
              />
            </Form.Field>
            <Form.Field>
              <label>العنوان</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='address'
                onChange={onChangeHandler}
                value={formData.address}
                error={touched.address && validationErrors.address}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={3}>
            <Form.Field>
              <label>فاكس</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='fax'
                onChange={onChangeHandler}
                value={formData.fax}
                error={touched.fax && validationErrors.fax}
              />
            </Form.Field>
            <Form.Field>
              <label>الرمز البريدي</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='postalCode'
                onChange={onChangeHandler}
                value={formData.postalCode}
                error={touched.postalCode && validationErrors.postalCode}
              />
            </Form.Field>
            <Form.Field>
              <label>ص.ب</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='POBox'
                onChange={onChangeHandler}
                value={formData.POBox}
                error={touched.POBox && validationErrors.POBox}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={3}>
            <Form.Field>
              <label>قيمة الكفالة رقما</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceAmountNumbers'
                onChange={onChangeHandler}
                value={formData.assuranceAmountNumbers}
                error={touched.assuranceAmountNumbers && validationErrors.assuranceAmountNumbers}
                onBlur={tafqeetHandler}
              />
            </Form.Field>
            <Form.Field>
              <label>قيمة الكفالة تفقيط</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceAmountWritten'
                onChange={onChangeHandler}
                value={formData.assuranceAmountWritten}
                error={touched.assuranceAmountWritten && validationErrors.assuranceAmountWritten}
              />
            </Form.Field>
            <Form.Field>
              <label>العملة</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  clearable
                  value={formData.currancy}
                  name='currancy'
                  options={currancyOptions}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.currancy && validationErrors.currancy}</label>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>الملاحظات</label>
            <Form.Input className='rtl-input' fluid name='notes' onChange={onChangeHandler} value={formData.notes} />
          </Form.Field>
          <Form.Field>
            <div className='attachments-container'>
              <DropFileUpload onFileChange={onDropFilesChanged} attachmentType={147} />
            </div>
            {uploadProgress > -1 && (
              <h4 style={{ textAlign: 'center' }}>
                جاري رفع المرفقات {uploadProgress}/{files.length}
              </h4>
            )}
            <label style={{ color: '#9f3a38' }}>{validationErrors.assuranceAttachment}</label>
            {Boolean(previousFiles.length) && (
              <>
                <h5>المرفقات</h5>
                <ul className='drop-file-upload-files'>
                  {previousFiles.map((item, index) => (
                    <li key={index}>
                      <Icon name='delete' color='red' onClick={() => setDeleteFileName(item.name)} />
                      <label style={{ direction: 'ltr' }}>
                        {item.name}-{item.attachmentType}
                      </label>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Form.Field>
          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default EditAssuranceForm;

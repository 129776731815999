import React, { useEffect, useState, useRef } from 'react';
import userImage from './../../assets/images/user-icon.png';
import { useSelector } from 'react-redux';
import UserNavbarMenu from './UserNavbarMenu';
import { Icon } from 'semantic-ui-react';

function UserBadge() {
  const [userInfo, setUserInfo] = useState({});
  const user = useSelector((st) => st.user);
  const [open, setOpen] = useState(false);

  const node = useRef();

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (user.token) {
      setUserInfo({ ...user.user });
    }
  }, []);

  return (
    <div ref={node} className='user-badge-container' onClick={() => setOpen(!open)}>
      <Icon name='angle down' size='large' />
      <img className='user-img' src={userInfo.image ? userInfo.image : userImage} alt='' />
      <div className='name-container'>
        <h5>{userInfo.FullName}</h5>
        <p>{userInfo.jobTitle}</p>
      </div>
      <UserNavbarMenu open={open} />
    </div>
  );
}

export default UserBadge;

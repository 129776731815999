import XLSX from 'xlsx';
import moment from 'moment';

export const multipleSheetsExcelExport = (data, sheetsName, title) => {
  // remove editable field that exists in lookups & roles
  for (let i in data) {
    for (let j in data[i]) {
      delete data[i][j].editable;
    }
  }
  var workbook = XLSX.utils.book_new();
  for (let i in data) {
    var ws = XLSX.utils.json_to_sheet([...data[i]]);
    XLSX.utils.book_append_sheet(workbook, ws, sheetsName[i]);
  }
  XLSX.writeFile(workbook, `${title}-${moment(moment().toDate()).format('YYYY-MM-DD-HH-mm')}.xlsx`, { type: 'file' });
};

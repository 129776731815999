import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Checkbox, Loader } from 'semantic-ui-react';
import { rolesApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import _ from 'lodash';

export default function ViewRoleDetails() {
  const location = useLocation();
  const { id } = location.state;

  const [allPermissions, setAllPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const oneRole = await rolesApi.getOneRole(id);
        setFormData(oneRole.data);

        const rolePermissions = await rolesApi.getPermissionsForRole(id);

        const permissions = await rolesApi.getAllPermissions();
        let results = permissions.data;
        for (let result of results) {
          const found = rolePermissions.data.some((el) => el.id === result.id);
          if (found) result.isChecked = true;
          else result.isChecked = false;
        }
        setAllPermissions(results);
        setLoading(false);
      } catch (err) {
        console.log('Error fetching data...', err);
      }
    };

    fetchData();
  }, []);

  return (
    <PageCardContainer>
      {loading ? (
        <Loader active className='loader-margin-top' size='large' inline='centered' />
      ) : (
        <div style={{ margin: '1rem' }}>
          <h2>تفاصيل مجموعة الصلاحيات</h2>
          <div style={{ marginTop: '3rem' }}>
            <div style={{ fontSize: '1.2rem' }}>اسم مجموعة الصلاحيات</div>
            <div style={{ marginTop: '1rem', fontSize: '1.1rem' }}>
              <Icon name='star' /> &nbsp;
              {formData['name']}
            </div>

            <div style={{ marginTop: '3rem', fontSize: '1.2rem' }}>صلاحيات المجموعة</div>

            <div style={{ margin: '1rem' }}>
              {Object.keys(_.groupBy(allPermissions, 'groupName')).map((key) => {
                return (
                  <div key={key}>
                    <h5 style={{ marginTop: 20 }}>✩ {key}</h5>
                    {_.groupBy(allPermissions, 'groupName')[key].map((permission) => {
                      return (
                        <div key={permission.id} style={{ margin: '0.5rem 1rem' }}>
                          <Checkbox
                            label={permission.name}
                            name={permission.name}
                            checked={permission.isChecked}
                            readOnly
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PageCardContainer>
  );
}

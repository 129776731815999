import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import DataTable from '../../Layout/Table/DataTable';
import { otherMaintenanceItemApi } from '../../../api/api';
import { customersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';
import { Button, Icon, Modal, Transition } from 'semantic-ui-react';
import { useHistory } from 'react-router';

const ViewOtherMaintenanceItems = () => {
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});
  const [currentItemId, setCurrentItemId] = useState(null);
  const [deleteItemPopup, setdeleteItemPopup] = useState(false);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const userId = useSelector((x) => x.user).user.id;
  const router = useHistory();

  const tableHeaders = [
    {
      // a field that is used for display only (in arabic)
      displayName: 'اسم الرمز',
      // a field that is used as a key (in english camelCase)
      fieldName: 'itemName',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'item_name',
      viewable: true,
      sortable: true,
      searchable: true,
      // either text, dropdown, or date
      searchType: 'text',
      // if the searchType if dropdown, this field contains the dropdown options as key, text, value
      searchValues: '',
      // the dropdown key: value as enName: arName for display in the label
      dropdownArMap: '',
    },
    {
      displayName: 'اسم العميل',
      fieldName: 'customerId',
      dbFieldName: 'customer_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      // if the searchType if dropdown, this field contains the dropdown options as key, text, value
      searchValues: customerIdOptions,
      dropdownArMap: customerIdArMap,
    },
  ];

  const getCustomersByUserId = async (id) => {
    const res = await customersApi.getCustomersByUserId(id);

    if (res?.data) {
      let options = [];
      let optionsArMap = {};
      for (let item of res.data) {
        options.push({ key: item.id, text: item.name, value: item.id });
        optionsArMap[item.id] = item.name;
      }
      setCustomerIdOptions(options);
      setCustomerIdArMap(optionsArMap);
    }
  };

  useEffect(() => {
    getCustomersByUserId(userId);
  }, []);

  const deleteConfirmHandler = async () => {
    try {
      await otherMaintenanceItemApi.deleteItem(currentItemId);
    } catch (err) {
      console.log('Error deleting', err);
    }
    setdeleteItemPopup(false);
    setRerenderFlag(!rerenderFlag);
  };

  const handleDeleteItem = async (id) => {
    setdeleteItemPopup(true);
    setCurrentItemId(id);
  };

  const tableActions = [
    ...(hasPermission(permissions.EDIT_MAINTENANCE_TYPES)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/EditOtherMaintenanceItems',
          },
        ]
      : []),

    ...(hasPermission(permissions.DELETE_MAINTENANCE_TYPES)
      ? [
          {
            actionType: 'custom',
            onClick: handleDeleteItem,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>رموز الصيانة</h3>
          <PermissionCheck permissions={permissions.ADD_MAINTENANCE_TYPES}>
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              onClick={() => router.push('/addOtherMaintenanceItemsForm')}
            >
              <Icon name='plus' className='blue-buton-icon' />
              اضافة رمز جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='رموز الصيانة'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={otherMaintenanceItemApi.getAll}
          reloadTrigger={[rerenderFlag]}
        />
      </PageCardContainer>
      <Transition visible={deleteItemPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteItemPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>هل انت متأكد من الحذف؟</Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={deleteConfirmHandler}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setdeleteItemPopup(false);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
};

export default ViewOtherMaintenanceItems;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Transition, Loader } from 'semantic-ui-react';
import { departmentsApi, teamsApi, usersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission } from '../../../helpers/permissions';

const Teams = () => {
  const router = useHistory();
  const [managerIds, setMangerIds] = useState({ opts: [], arMap: {} });
  const [departmentIds, setDepartmentIds] = useState({ opts: [], arMap: {} });
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [deleteTeamPopup, setDeleteTeamPopup] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [currentTeamId, setCurrentTeamId] = useState('');
  const [deleteMessage, setDeleteMessage] = useState([]);
  const [loadingTeamDelete, setLoadingTeamDelete] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const users = await usersApi.getAllExceptCustomersDropdown();
      let options = [];
      let optionsArMap = {};
      for (let item of users.data) {
        options.push({ key: item.id, text: item.name, value: item.id });
        optionsArMap[item.id] = item.name;
      }
      setMangerIds({ opts: options, arMap: optionsArMap });

      const departments = await departmentsApi.getDepartmentsDropDown();
      let options2 = [];
      let optionsArMap2 = {};
      for (let item of departments.data) {
        options2.push({ key: item.id, text: item.name, value: item.id });
        optionsArMap2[item.id] = item.name;
      }
      setDepartmentIds({ opts: options2, arMap: optionsArMap2 });
    };

    fetchData();
  }, [rerenderFlag]);

  const handleTeamDelete = async (id, row) => {
    setLoadingTeamDelete(true);
    setDeleteTeamPopup(true);
    setCurrentTeamId(id);
    let deleteMessageArr = [];

    const { data: res } = await teamsApi.canDeleteTeam(id);
    if (res) {
      setCanDelete(true);
    } else {
      setCanDelete(false);
      deleteMessageArr.push('يجب ازالة كامل اعضاء الفريق قبل الحذف');
    }

    setDeleteMessage(deleteMessageArr);
    setLoadingTeamDelete(false);
  };

  const deleteConfirmHandler = async () => {
    try {
      await teamsApi.deleteTeam(currentTeamId);
    } catch (err) {
      console.log('Error deleting', err);
    }

    setDeleteTeamPopup(false);
    setDeleteMessage([]);
    setCanDelete(false);
    setRerenderFlag(!rerenderFlag);
  };

  const tableHeaders = [
    {
      displayName: 'اسم الفريق',
      // a field that is used as a key (in english camelCase)
      fieldName: 'name',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'الإدارة',
      fieldName: 'department',
      dbFieldName: 'department_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: departmentIds.opts,
      dropdownArMap: departmentIds.arMap,
    },
    {
      displayName: 'المدير',
      fieldName: 'manager',
      dbFieldName: 'department_manager_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: managerIds.opts,
      dropdownArMap: managerIds.arMap,
    },
    {
      displayName: 'رئيس الفريق',
      fieldName: 'teamLeader',
      dbFieldName: 'team_leader_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: managerIds.opts,
      dropdownArMap: managerIds.arMap,
    },
  ];

  const tableActions = [
    ...(hasPermission(permissions.EDIT_TEAM)
      ? [
        {
          actionType: 'edit',
          actionUrl: '/teams/edit',
        },
      ]
      : []),
    ...(hasPermission(permissions.EDIT_TEAM)
      ? [
        {
          actionType: 'custom',
          onClick: (id, row) => router.push('/teams/teamMembers', { id, row }),
          title: 'اعضاء الفريق',
          icon: 'users',
        },
      ]
      : []),
    ...(hasPermission(permissions.EDIT_TEAM)
      ? [
        {
          actionType: 'custom',
          onClick: (id, row) => router.push('/teams/teamCustomers', { id, row }),
          title: 'عملاء الفريق',
          icon: 'building',
        },
      ]
      : []),
    ...(hasPermission(permissions.DELETE_TEAM)
      ? [
        {
          actionType: 'custom',
          onClick: handleTeamDelete,
          title: 'حذف',
          icon: 'trash',
          color: 'red',
        },
      ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>الأفرقة</h3>
          <PermissionCheck permissions={permissions.CREATE_DEPARTMENT}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/teams/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              إضافة فريق جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='الفِرق'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={teamsApi.getTeams}
          optionalQsParams={{}}
          reloadTrigger={[rerenderFlag]}
        />
      </PageCardContainer>

      <Transition visible={deleteTeamPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteTeamPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            {!loadingTeamDelete ? (
              <div className='delete-ticket-errors-modal'>
                {Boolean(deleteMessage) &&
                  Boolean(deleteMessage.length) &&
                  deleteMessage.map((str, index) => (
                    <p className='red-text' key={index}>
                      <Icon name='circle' size='tiny' />
                      {str}
                    </p>
                  ))}
                <p>هل انت متأكد من الحذف؟</p>
                <p>سيتم ازالة اسناد جميع التذاكر الغير مغلقة عن الفريق!</p>
              </div>
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={!canDelete} color='red' onClick={() => deleteConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setDeleteTeamPopup(false);
                setDeleteMessage([]);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
};

export default Teams;

import React, { useEffect, useState } from 'react';
import '../../Tickets/MaintenanceForm/PrintMaintenanceForm.scss';
import { Icon, Image, Table } from 'semantic-ui-react';
import whiteLogo from '../../../assets/images/logo-white.jpg';
import moment from 'moment';
import { assuranceEditRequestApi } from '../../../api/api';

export default function AssuranceEditPrint() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  const [formData, setFormData] = useState({});
  const [newEdits, setNewEdits] = useState({});

  useEffect(() => {
    (async () => {
      const res = await assuranceEditRequestApi.getPrintData(id);
      setFormData(res.data);
      setNewEdits(JSON.parse(res.data.newEditJson));
    })();
  }, [id]);

  return (
    <div className='print-container'>
      <div className='header'>
        <div className='header-logo'>
          <Image src={whiteLogo} alt='logo' size='tiny' centered />
        </div>
        <div className='header-title'>الشركة العامة للحاسبات والالكترونيات</div>
      </div>

      <div className='contents'>
        <h3>نموذج طلب تعديل كفالة رقم #{formData.assuranceNumber}</h3>

        <div className='segment'>
          <h4>
            <Icon name='book' /> &nbsp; معلومات الطلب
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>تاريخ إنشاء الطلب من النظام</Table.HeaderCell>
                <Table.HeaderCell width={2}>منشيء الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>حالة الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>نوع الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>القسم</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell className='ltr'>{moment(formData.createdAt).format('YYYY-MM-DD, hh:mm A')}</Table.Cell>
                <Table.Cell>{formData.createdBy}</Table.Cell>
                <Table.Cell>{formData.status}</Table.Cell>
                <Table.Cell>{formData.assuranceType}</Table.Cell>
                <Table.Cell>{formData.departmentName}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='sticky note' /> &nbsp; معلومات المستفيد
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>المستفيد</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.customer}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='edit' /> &nbsp; التعديل الجديد
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                {Object.values(newEdits).map((item) =>
                  item.type === 135 && item.selected ? (
                    <>
                      <Table.HeaderCell>تاريخ بداية الكفالة الجديد</Table.HeaderCell>
                      <Table.HeaderCell>تاريخ نهاية الكفالة الجديد</Table.HeaderCell>
                    </>
                  ) : item.type === 136 && item.selected ? (
                    <>
                      <Table.HeaderCell>قيمة الكفالة الجديدة رقما</Table.HeaderCell>
                      <Table.HeaderCell>قيمة الكفالة الجديدة تفقيط</Table.HeaderCell>
                    </>
                  ) : item.type === 137 && item.selected ? (
                    <Table.HeaderCell>النص الجديد</Table.HeaderCell>
                  ) : (
                    <></>
                  )
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                {Object.values(newEdits).map((item) =>
                  item.type === 135 && item.selected ? (
                    <>
                      <Table.Cell>{moment(newEdits[135].editJson.assuranceFromDate).format('YYYY/MM/DD a')}</Table.Cell>
                      <Table.Cell>{moment(newEdits[135].editJson.assuranceToDate).format('YYYY/MM/DD a')}</Table.Cell>
                    </>
                  ) : item.type === 136 && item.selected ? (
                    <>
                      <Table.Cell>{newEdits[136].editJson.newAssuranceAmountNumbers}</Table.Cell>
                      <Table.Cell>{newEdits[136].editJson.newAssuranceAmountWritten}</Table.Cell>
                    </>
                  ) : item.type === 137 && item.selected ? (
                    <Table.Cell>{newEdits[137].editJson.newText}</Table.Cell>
                  ) : (
                    <></>
                  )
                )}
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='cogs' /> &nbsp; معلومات الكفالة
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>رقم الكفالة</Table.HeaderCell>
                <Table.HeaderCell>قيمة الكفالة رقما</Table.HeaderCell>
                <Table.HeaderCell>قيمة الكفالة تفقيط</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.assuranceNumber}</Table.Cell>
                <Table.Cell>{formData.assuranceAmountNumbers}</Table.Cell>
                <Table.Cell>{formData.assuranceAmountWritten}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

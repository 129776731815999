import React, { useEffect, useState } from 'react';
import { TextArea, Button, Icon } from 'semantic-ui-react';
import './TicketEvaluation.scss';
import { motion } from 'framer-motion';
import Emoticons from './Emoticons';
import Star from './Star';
import { customerTicketsApi } from '../../api/api';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

const TicketEvaluation = () => {
  const [rateState, setRateState] = useState(3);
  const [hoverIndex, setHoverIndex] = useState(3);
  const [evaluationKey, setEvaluationKey] = useState(null);
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [evaluated, setEvaluated] = useState(false);
  const [ticketId, setTicketId] = useState(0);

  const location = useLocation();
  const router = useHistory();

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c });
    if (query['key'] && query['id']) {
      setTicketId(+query['id']);
      setEvaluationKey(query['key']);
    } else router.goBack();
  }, []);

  const evaluate = async () => {
    setLoading(true);
    try {
      const res = await customerTicketsApi.evaluateTicket(ticketId, {
        evaluationKey,
        score: rateState,
        note,
      });
    } catch (error) {
      console.log('error on evaluation', error);
      setLoading(false);
    }

    setEvaluated(true);
  };

  return evaluated ? (
    <>
      <div className={`evaluation-page-container face-${hoverIndex || rateState || 1}`}>
        <h2>تم ارسال التقييم بنجاح 🤗</h2>
        <Icon className='go-home-btn' name='home' circular onClick={() => router.replace('/')} size='big' />
      </div>
    </>
  ) : (
    <div className={`evaluation-page-container face-${hoverIndex || rateState || 1}`}>
      <div className='header'>
        <h2>تم اغلاق تذكرة رقم #{ticketId}</h2>
        {/* <h5>تذكرة صيانة معدات وخوادم</h5> */}
      </div>
      <Emoticons selectedRating={hoverIndex || rateState} />

      <motion.div className='stars-container' onHoverEnd={() => setHoverIndex(0)}>
        <Star
          hoverIndex={hoverIndex}
          rateState={rateState}
          setHoverIndex={setHoverIndex}
          setRateState={setRateState}
          value={1}
        />
        <Star
          hoverIndex={hoverIndex}
          rateState={rateState}
          setHoverIndex={setHoverIndex}
          setRateState={setRateState}
          value={2}
        />
        <Star
          hoverIndex={hoverIndex}
          rateState={rateState}
          setHoverIndex={setHoverIndex}
          setRateState={setRateState}
          value={3}
        />
        <Star
          hoverIndex={hoverIndex}
          rateState={rateState}
          setHoverIndex={setHoverIndex}
          setRateState={setRateState}
          value={4}
        />
        <Star
          hoverIndex={hoverIndex}
          rateState={rateState}
          setHoverIndex={setHoverIndex}
          setRateState={setRateState}
          value={5}
        />
      </motion.div>

      <div className='text-area'>
        <label>رأيك يهمنا، في حال وجود اي ملاحظات يرجى كتابتها هنا</label>
        <TextArea rows={5} onChange={(e, { value }) => setNote(value)} value={note} />
        <Button
          loading={loading}
          disabled={loading}
          color='blue'
          icon='send'
          labelPosition='right'
          content='ارسال'
          onClick={evaluate}
        />
      </div>
    </div>
  );
};

export default TicketEvaluation;

import React, { useState, useEffect } from 'react';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { otherMaintenanceItemApi, customersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const OtherMaintenanceItemsDetails = () => {
  const userId = useSelector((st) => st.user).user.id;
  const router = useHistory();
  const location = useLocation();
  const { id } = location.state;

  const [formData, setFormData] = useState({
    name: '',
    customerId: 0,
    createdBy: Number(userId),
  });
  const [validationErrors, setValidationErrors] = useState({
    name: '',
  });
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState({
    name: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customers, setCustomers] = useState([]);

  const getCustomersByUserId = async (id) => {
    try {
      const res = await customersApi.getCustomersByUserId(id);
      if (res && res.data && res.data.length > 0) {
        setCustomers(
          res.data.map((customer) => {
            return {
              key: customer.id,
              text: customer.name,
              value: customer.id,
            };
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      alert('حدث خطأ أثناء تحميل البيانات');
      console.log('Error fetching team form data...', error);
      router.goBack();
    }
  };

  const fillData = async () => {
    try {
      const item = await otherMaintenanceItemApi.getOneItem(id);
      setFormData({ name: item.data.itemName, createdBy: item.data.createdBy, customerId: item.data.customerId });
    } catch (error) {
      setIsLoading(false);
      alert('حدث خطأ أثناء تحميل البيانات');
      console.log('Error fetching team form data...', error);
      router.goBack();
    }
  };

  useEffect(() => {
    getCustomersByUserId(userId);
    fillData();
  }, []);

  useEffect(() => {
    validation();
  }, [formData]);

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,

      [e.target.name]: true,
    });

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTouched({
      name: true,
    });

    if (validation()) {
      try {
        await otherMaintenanceItemApi.editItem(id, formData);
        router.goBack();
      } catch (error) {
        console.log('Error posting Item...', error);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const validation = () => {
    const errors = {
      name: null,
    };

    if (!formData.name) {
      errors.name = 'الرجاء ادخال اسم الرمز';
    }
    setValidationErrors(errors);
    if (validationErrors.name) return false;
    else return true;
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل رمز الصيانة </h2>
        <Form style={{ margin: '1rem' }} loading={!isLoading} onSubmit={onSubmitHandler}>
          <Form.Field>
            <label>اسم رمز الصيانة</label>
            <Form.Input
              placeholder='ادخل اسم رمز الصيانة الجديد'
              icon='star'
              value={formData['name']}
              name='name'
              error={touched.name && validationErrors.name}
              onChange={onChangeHandler}
            />
          </Form.Field>
          <Form.Field>
            <label>العميل</label>
            <Dropdown
              placeholder='اختر العميل'
              fluid
              selection
              search
              options={customers}
              value={formData['customerId']}
              name='customerId'
              onChange={selectionChangeHandler}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
          </Form.Field>
          <Message error key={errors.key} content={errors.message} />
          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>
              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();

                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>
              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();

                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default OtherMaintenanceItemsDetails;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Transition, Loader } from 'semantic-ui-react';
import { departmentsApi, usersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission } from '../../../helpers/permissions';

const Departments = () => {
  const router = useHistory();
  const [mangerIdsOptions, setMangerIdsOptions] = useState([]);
  const [mangerIdsArMap, setMangerIdsArMap] = useState({});
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [deleteDepartmentPopup, setDeleteDepartmentPopup] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [currentDepartmentId, setCurrentDepartmentId] = useState('');
  const [deleteMessage, setDeleteMessage] = useState([]);
  const [loadingDepartmentDelete, setLoadingDepartmentDelete] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const users = await usersApi.getAllExceptCustomersDropdown();
      let options = [];
      let optionsArMap = {};
      for (let item of users.data) {
        options.push({ key: item.id, text: item.name, value: item.id });
        optionsArMap[item.id] = item.name;
      }
      setMangerIdsOptions(options);
      setMangerIdsArMap(optionsArMap);
    };

    fetchData();
  }, [rerenderFlag]);

  const handleDepartmentDelete = async (id, row) => {
    setLoadingDepartmentDelete(true);
    setDeleteDepartmentPopup(true);
    setCurrentDepartmentId(id);
    let deleteMessageArr = [];

    const { data: res } = await departmentsApi.canDeleteDepartment(id);
    if (res) {
      setCanDelete(true);
    } else {
      setCanDelete(false);
      deleteMessageArr.push('يوجد فرق مرتبطة بالادارة يجب حذف او نقل الفرق قبل حذف الادارة');
    }

    setDeleteMessage(deleteMessageArr);
    setLoadingDepartmentDelete(false);
  };

  const deleteConfirmHandler = async () => {
    try {
      await departmentsApi.deleteDepartment(currentDepartmentId);
    } catch (err) {
      console.log('Error deleting', err);
    }

    setDeleteDepartmentPopup(false);
    setDeleteMessage([]);
    setCanDelete(false);
    setRerenderFlag(!rerenderFlag);
  };

  const tableHeaders = [
    {
      // a field that is used for display only (in arabic)
      displayName: 'اسم الادارة',
      // a field that is used as a key (in english camelCase)
      fieldName: 'name',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'name',
      viewable: true,
      sortable: true,
      searchable: true,
      // either text, dropdown, or date
      searchType: 'text',
    },
    {
      displayName: 'اسم المدير',
      fieldName: 'departmentManagerId',
      dbFieldName: 'department_manager_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: mangerIdsOptions,
      dropdownArMap: mangerIdsArMap,
    },
  ];

  const tableActions = [
    ...(hasPermission(permissions.EDIT_DEPARTMENT)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/departments/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_DEPARTMENT)
      ? [
          {
            actionType: 'custom',
            onClick: handleDepartmentDelete,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>الإدارات</h3>
          <PermissionCheck permissions={permissions.CREATE_DEPARTMENT}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/departments/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة ادارة جديدة
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='الإدارات'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={departmentsApi.getDepartments}
          optionalQsParams={{}}
          reloadTrigger={[rerenderFlag]}
        />
      </PageCardContainer>

      <Transition visible={deleteDepartmentPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteDepartmentPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            {!loadingDepartmentDelete ? (
              <div className='delete-ticket-errors-modal'>
                {Boolean(deleteMessage) &&
                  Boolean(deleteMessage.length) &&
                  deleteMessage.map((str, index) => (
                    <p className='red-text' key={index}>
                      <Icon name='circle' size='tiny' />
                      {str}
                    </p>
                  ))}
                <p>هل انت متأكد من الحذف؟</p>
              </div>
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={!canDelete} color='red' onClick={() => deleteConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setDeleteDepartmentPopup(false);
                setDeleteMessage([]);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
};

export default Departments;

import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown, Label, Icon } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './SearchTable.scss';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

export default function SearchTable({ fillSearch, returnedOffset, fields, infiniteMaxDate = false }) {
  const [formData, setFormData] = useState(null);
  // the first dropdown key
  const [currentKey, setCurrentKey] = useState('');
  // first dropdown options
  const [firstDropdownOptions, setFirstDropdownOptions] = useState([{}]);
  // the text value if the first dropdown type was text
  const [currentValue, setCurrentValue] = useState('');
  // the current input type of the current search key
  const [currentSelectionType, setCurrentSelectionType] = useState('');
  // the second dropdown menu options
  const [currentDropdownOptions, setCurrentDropdownOptions] = useState([{}]);
  // the second dropdown value
  const [searchItemDropdownValue, setSearchItemDropdownValue] = useState('');
  // the start value of the date item
  const [searchDateFrom, setSearchDateFrom] = useState(today);
  // the end value of the date item
  const [searchDateTo, setSearchDateTo] = useState(today);
  // a flag for checking whether there are any searchable items
  const [searchableItems, setSearchableItems] = useState(false);
  // a flag for checking whether the currently selected date is in the future or not
  const [futureDate, setFutureDate] = useState(false);

  const [offsetToggler, setOffsetToggler] = useState(false);

  const searchChangeHandler = (event) => {
    // handles change for the text fields
    setCurrentValue(event.target.value);
  };

  const selectionChangeHandler = (e, data) => {
    // handles change for the main dropdown
    setCurrentKey(data.value);
    Object.entries(firstDropdownOptions).map(([key, val]) => {
      if (val.value === data.value) {
        setCurrentSelectionType(val.desc.searchType);
        if (val.desc.searchType === 'dropdown') {
          //set the dropdown options of the passed in search value
          setCurrentDropdownOptions(val.desc.searchValues);
          //set the value of the second dropdown to the first value in the array (to avoid null values)
          if (val.desc.searchValues && val.desc.searchValues.length)
            setSearchItemDropdownValue(val.desc.searchValues[0].key);
        } else if (val.desc.searchType === 'date') {
          setFutureDate(val.desc.futureDate ?? false);
        }
      }
    });
  };

  const searchDropdownChangeHandler = (e, data) => {
    // handles change for the second dropdown
    setSearchItemDropdownValue(data.value);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    //return search key:value to the calling component
    fillSearch(formData);
  };

  useEffect(() => {
    // fill in the searchable fields passed as props
    const searchOptions = [];
    for (const field of fields) {
      if (field.searchable)
        searchOptions.push({
          key: field.fieldName,
          text: field.displayName,
          value: field.fieldName,
          desc: {
            searchType: field.searchType,
            searchValues: field.searchValues,
            futureDate: field.futureDate ?? false,
          },
        });
    }

    if (searchOptions && searchOptions.length) {
      setFirstDropdownOptions(searchOptions);
      setCurrentKey(searchOptions[0].key);
      setCurrentSelectionType(searchOptions[0].desc.searchType);
      if (searchOptions[0].desc.searchType === 'dropdown') {
        setCurrentDropdownOptions(searchOptions[0].desc.searchValues);
        //set the value of the second dropdown to the first value in the array (to avoid null values)
        if (searchOptions[0].desc.searchValues && searchOptions[0].desc.searchValues.length)
          setSearchItemDropdownValue(searchOptions[0].desc.searchValues[0].key);
      }
      setSearchableItems(true);
    }
  }, [fields]);

  const deleteTagHandler = (key) => {
    //whenever tags change, trigger a search request with the remaining keys
    const newState = { ...formData };
    delete newState[key];
    setFormData(newState);
    fillSearch(newState);
  };

  // current search keys with their arabic names for labeling
  const mapKeyArName = {};
  fields.map((field) => (mapKeyArName[field.fieldName] = field.displayName));

  const tagHelper = (key, value) => {
    // all date fields must contains "date" substring used to identify them
    if (value) {
      //check the type of the current field
      for (let field of fields) {
        if (field.fieldName === key) {
          if (field.searchType === 'date') {
            return (
              <Label circular>
                {mapKeyArName[key]}: من {moment(searchDateFrom.toLocaleDateString()).format('YYYY/MM/DD')} الى
                {moment(searchDateTo.toLocaleDateString()).format('YYYY/MM/DD')} &nbsp;
                <Icon name='delete' onClick={() => deleteTagHandler(key)} />
              </Label>
            );
          } else if (field.searchType === 'dropdown') {
            return (
              <Label circular>
                {mapKeyArName[key]}: {field.dropdownArMap[value]} &nbsp;
                <Icon name='delete' onClick={() => deleteTagHandler(key)} />
              </Label>
            );
          } else {
            //text field
            return (
              <Label circular>
                {mapKeyArName[key]}: {value} &nbsp;
                <Icon name='delete' onClick={() => deleteTagHandler(key)} />
              </Label>
            );
          }
        }
      }
    }
  };

  const btnAddSearchKey = () => {
    returnedOffset(!offsetToggler);
    setOffsetToggler(!offsetToggler);

    if (currentSelectionType === 'date')
      setFormData({
        ...formData,
        [currentKey]: [
          moment(searchDateFrom.toLocaleDateString()).format('YYYY-MM-DD HH:mm:ss'),
          moment(searchDateTo.toLocaleDateString()).format('YYYY-MM-DD HH:mm:ss'),
        ],
      });
    else if (currentSelectionType === 'dropdown') {
      // select key and value
      setFormData({ ...formData, [currentKey]: searchItemDropdownValue });
    } else {
      // text field
      setFormData({ ...formData, [currentKey]: currentValue });
      setCurrentValue('');
    }
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  // useEffect(() => {
  //   if (searchDateTo) searchDateTo.setDate(new Date(searchDateTo).getDate() + 1);
  // }, [searchDateTo]);

  return (
    <>
      {searchableItems && (
        <div className='table-search'>
          <Form onSubmit={formSubmitHandler}>
            <div className='wrapper'>
              <Dropdown
                placeholder='فلترة حسب ...'
                selection
                search
                value={currentKey}
                options={firstDropdownOptions}
                onChange={selectionChangeHandler}
                className='table-dropdown'
              />
              <div className='search-input'>
                {currentSelectionType === 'text' ? (
                  <Form.Input
                    placeholder='بحث عن...'
                    icon='search'
                    value={currentValue}
                    onChange={searchChangeHandler}
                    className='text-box'
                  />
                ) : currentSelectionType === 'dropdown' ? (
                  <Dropdown
                    placeholder='بحث عن...'
                    selection
                    search
                    value={searchItemDropdownValue}
                    options={currentDropdownOptions}
                    onChange={searchDropdownChangeHandler}
                    className='dropdown-search'
                  />
                ) : (
                  <>
                    <DatePicker
                      popperPlacement='top-end'
                      placeholderText='من تاريخ'
                      selected={searchDateFrom}
                      onChange={(date) => setSearchDateFrom(date)}
                      maxDate={today}
                      onChangeRaw={handleDateChangeRaw}
                      popperPlacement='auto left'
                      enableTabLoop={false}
                    />
                    <DatePicker
                      popperPlacement='top-start'
                      placeholderText='إلى تاريخ'
                      selected={searchDateTo}
                      onChange={(date) => setSearchDateTo(date)}
                      onChangeRaw={handleDateChangeRaw}
                      popperPlacement='auto left'
                      enableTabLoop={false}
                      minDate={futureDate ? today : null}
                      maxDate={infiniteMaxDate ? null : futureDate ? null : today}
                    />
                  </>
                )}

                <Button
                  type='submit'
                  icon='plus'
                  style={{ width: '40px', height: '40px' }}
                  onClick={() => btnAddSearchKey()}
                />
              </div>
            </div>
          </Form>
          <div className='tags-wrapper'>
            {formData
              ? Object.entries(formData).map(([key, value]) => {
                  return <span key={key}>{tagHelper(key, value)}</span>;
                })
              : ''}
          </div>
        </div>
      )}
    </>
  );
}

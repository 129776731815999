import React, { useEffect, useState } from 'react';
import { Button, Message, Loader, Responsive, Icon, Input } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { customersApi } from './../../../api/api';

const AddBranches = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const router = useHistory();
  const locationWin = useLocation();
  const currentUser = useSelector((st) => st.user).user;

  const [formData, setFormData] = useState({
    branchName: '',
    location: '',
    customerId: +locationWin.state.customerName[0].value,
    contactNumber: '',
    contactEmail: '',
    contactName: '',
    userId: +currentUser.id,
  });
  const [touched, setTouched] = useState({
    branchName: false,
    location: false,
  });
  const [validationErrors, setValidationErrors] = useState({
    branchName: null,
    location: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await customersApi.getOneCustomer(formData.customerId);
      console.log(data);
      setFormData({
        ...formData,
        contactEmail: data.contactEmail,
        contactName: data.contactName,
        contactNumber: data.contactPhone,
      });
      setLoading(false);
    };

    fetchData();
  }, []);
  const validation = () => {
    const errors = {
      branchName: null,
      location: null,
    };

    //branchName VALIDATION
    if (!formData.branchName) {
      errors.branchName = 'الرجاء ادخال اسم الفرع ';
    }
    //location VALIDATION
    if (!formData.location) {
      errors.location = 'الرجاء ادخال عنوان الفرع ';
    }

    setValidationErrors(errors);

    if (validationErrors.branchName || validationErrors.location) return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const clearStates = () => {
    setLoading(false);
    setError(null);
    setFormData({
      branchName: '',
      location: '',
      customerId: locationWin.state.customerName[0].value,
      contactNumber: '',
      contactEmail: '',
      contactName: '',
      userId: currentUser.id,
    });
  };
  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    setTouched({
      branchName: true,
      location: true,
    });
    e.preventDefault();
    if (validation()) {
      try {
        setLoading(true);
        await customersApi.postCustomerBranch(formData);
        router.goBack();
        setIsSuccess(true);
        clearStates();
      } catch (err) {
        setError('حدث خطأ اثناء اضافة الفرع');
        setLoading(false);
        setIsSuccess(false);
        console.log('Error Adding Branch ...', err.response ?? err);
      }
    }
  };
  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div style={{ margin: '1rem 0.7rem' }}>
            <h3>اضافة فرع عميل {locationWin.state.customerName[0].text}</h3>
            <div className='data-box'>
              <div className='title-container' style={{ marginBottom: '1rem' }}>
                <h5 className='title'> اسم الفرع *</h5>
              </div>
              <Input
                className='rtl-input'
                fluid
                name='branchName'
                type='text'
                onChange={onChangeHandler}
                value={formData['branchName']}
                error={validationErrors.branchName && touched.branchName}
              />
            </div>
            <div className='data-box'>
              <div className='title-container' style={{ marginBottom: '1rem' }}>
                <h5 className='title'>موقع الفرع *</h5>
              </div>
              <Input
                className='rtl-input'
                fluid
                name='location'
                type='text'
                onChange={onChangeHandler}
                value={formData['location']}
                error={validationErrors.location && touched.location}
              />
            </div>
            <div className='data-box'>
              <div className='title-container' style={{ marginBottom: '1rem' }}>
                <h5 className='title'>اسم جهات الاتصال</h5>
              </div>
              <Input
                className='rtl-input'
                fluid
                name='contactName'
                type='text'
                onChange={onChangeHandler}
                value={formData['contactName']}
              />
            </div>
            <div className='data-box'>
              <div className='title-container' style={{ marginBottom: '1rem' }}>
                <h5 className='title'>الايميل</h5>
              </div>
              <Input
                className='rtl-input'
                fluid
                name='contactEmail'
                type='text'
                onChange={onChangeHandler}
                value={formData['contactEmail']}
              />
            </div>
            <div className='data-box'>
              <div className='title-container' style={{ marginBottom: '1rem' }}>
                <h5 className='title'>الهاتف</h5>
              </div>
              <Input
                className='rtl-input'
                fluid
                name='contactNumber'
                type='text'
                onChange={onChangeHandler}
                value={formData['contactNumber']}
              />
            </div>
            <div className='segments-wrapper' style={{ marginTop: '2rem' }}></div>
            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={loading}
                  disabled={loading}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                  onClick={onSubmitHandler}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={loading}
                  disabled={loading}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                  onClick={onSubmitHandler}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            {isSuccess && (
              <Message color='green'>
                <Message.Header>تم الحفظ بنجاح</Message.Header>
              </Message>
            )}
            {error && (
              <Message color='red'>
                <Message.Header>{error}</Message.Header>
              </Message>
            )}
          </div>
        )}
      </PageCardContainer>
    </div>
  );
};

export default AddBranches;

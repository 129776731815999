import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { rolesApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';

export default function RolesPage() {
  const router = useHistory();

  const tableHeaders = [
    {
      // a field that is used for display only (in arabic)
      displayName: 'اسم مجموعة الصلاحيات',
      // a field that is used as a key (in english camelCase)
      fieldName: 'name',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'name',
      viewable: true,
      sortable: true,
      searchable: true,
      // either text, dropdown, or date
      searchType: 'text',
    },
  ];

  const tableActions = [
    ...(hasPermissions([permissions.EDIT_ROLE, permissions.VIEW_ROLES])
      ? [
          {
            actionType: 'view',
            actionUrl: '/roles/view',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_ROLE)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/roles/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_ROLE)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: rolesApi.deleteRole,
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>صلاحيات المستخدمين</h3>
          <PermissionCheck permissions={permissions.CREATE_ROLE}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/roles/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة مجموعة صلاحيات جديدة
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='الصلاحيات'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={rolesApi.getAllRoles}
          optionalQsParams={{}}
          reloadTrigger={[]}
        />
      </PageCardContainer>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Icon, Image, Segment, Button, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import signaturePlaceholder from './../../../assets/images/signature-placeholder.png';
import PageCardContainer from '../../Layout/PageCardContainer';
import { scheduleVisits } from '../../../api/api';
import ErrorModal from '../../Reusable/ErrorModal';

const ViewMaintenanceReport = () => {
  const location = useLocation();
  const [modalError, setModalError] = useState(false);

  const DataBox = ({
    title,
    icon = null,
    data,
    ltr = false,
    image = false,
    bordered = true,
    padded = true,
    style = {},
  }) => {
    return (
      <div className={`data-box ${!bordered ? 'no-border' : ''} ${!padded ? 'no-padding' : ''}`} style={style}>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        {image ? (
          <div className='image-margin image-preview'>
            <div className='maintenance-form-img-preview'>
              <Image src={data} className='img' centered />
            </div>
          </div>
        ) : (
          <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data ? data : 'لا توجد بيانات'}</h5>
        )}
      </div>
    );
  };

  const [formData, setFormData] = useState({
    maintenanceDate: new Date(),
    createdBy: '',
    note: '',
    customerNotes: '',
    image: null,
    issueDesc: '',
    actionDesc: '',
    customerName: '',
    departmentName: '',
    createdAt: '',
    contractName: '',
    branchId: '',
    isVisited: '',
    branchName: '',
    visitId: location.state.id,
    representativeName: '',
    representativeSignature: null,
    companyRepresentativeSignature: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await scheduleVisits.getGenerateReport(formData.visitId);
        setFormData({
          ...formData,
          actionDesc: data.actionDesc,
          branchName: data.branchName,
          contractName: data?.contractName,
          createdAt: data.createdAt,
          maintenanceDate: data.maintenanceDate,
          createdBy: data.username,
          note: data.note,
          customerNotes: data.customerNotes,
          image: data.image,
          issueDesc: data.issueDesc,
          customerName: data.customerName,
          departmentName: data.departmentName,
          branchName: data.branchName ? data.branchName : 'العميل نفسه',
          representativeName: data.representativeName,
          representativeSignature: data.representativeSignature,
          companyRepresentativeSignature: data.companyRepresentativeSignature,
        });
      } catch (error) {
        setModalError(true);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className='maintenance-schedule-view'>
        <PageCardContainer>
          <div style={{ margin: '1rem 0.7rem' }}>
            <div className='header-wrapper'>
              <h3>تقرير صيانة لزيارة رقم #{formData.visitId}</h3>
              <Popup
                content='طباعة التقرير'
                trigger={
                  <Button
                    className='blue-button height-50'
                    onClick={() => window.open(`/client-visits/printVisitReport/?id=${formData.visitId}`)}
                  >
                    <Icon name='print' />
                  </Button>
                }
                position='left center'
              />
            </div>
            <div style={{ display: 'flex', margin: '1rem 0 2rem', flexWrap: 'wrap' }}>
              <DataBox style={{ minWidth: 180 }} title='منشيء الطلب' icon='user circle' data={formData.createdBy} />
              <DataBox
                style={{ minWidth: 180 }}
                title='تاريخ الزيارة'
                icon='calendar'
                data={moment(formData.maintenanceDate).format('YYYY/MM/DD')}
              />
              <DataBox
                style={{ minWidth: 180 }}
                title='تاريخ انشاء التقرير'
                icon='calendar'
                data={moment(formData.createdAt).format('YYYY/MM/DD')}
              />
              <DataBox style={{ minWidth: 180 }} title='الدائرة' icon='users' data={formData.departmentName} />
            </div>
            <h3>معلومات العميل</h3>
            <div style={{ display: 'flex', margin: '1rem 0 2rem', flexWrap: 'wrap' }}>
              <DataBox style={{ minWidth: 180 }} title='العميل' icon='user' ltr data={formData.customerName} />
              <DataBox
                style={{ minWidth: 180 }}
                title='العقد'
                icon='pen square'
                data={formData.contractName ? formData.contractName : 'بدون عقد'}
              />
              <DataBox
                style={{ minWidth: 180 }}
                title='الفرع'
                icon='building outline'
                data={formData.branchName ? formData.branchName : 'العميل نفسه'}
              />
              <DataBox
                style={{ minWidth: 180 }}
                title='مندوب العميل'
                icon='building outline'
                data={formData.representativeName}
              />
            </div>
            <div className='segments-wrapper'>
              <div className='single-segment-wrapper full-width'>
                <div className='two-columns'>
                  <Segment>
                    <DataBox title='وصف المشكلة' data={formData.issueDesc} />
                  </Segment>
                  <Segment>
                    <DataBox title='وصف الاجراء المتخذ' icon='key' data={formData.actionDesc} />
                  </Segment>
                </div>
              </div>
              <div className='single-segment-wrapper full-width'>
                <div className='two-columns'>
                  <Segment>
                    <DataBox title='ملاحظات' icon='sticky note' data={formData.note} />
                  </Segment>
                  <Segment>
                    <DataBox title='ملاحظات العميل' icon='sticky note' data={formData.customerNotes} />
                  </Segment>
                </div>
              </div>
              <div className='single-segment-wrapper full-width'>
                <div className='two-columns'>
                  {formData.image && (
                    <Segment>
                      <div className='image-preview-header'>الصورة</div>
                      <div className='image-preview'>
                        <div className='image-cropper'>
                          <Image src={formData.image} size='large' className='img' centered />
                        </div>
                      </div>
                    </Segment>
                  )}
                  {formData.companyRepresentativeSignature && (
                    <Segment>
                      <div className='image-preview-header'>توقيع مندوب الشركة</div>
                      <div className='signature-preview'>
                        <Image
                          src={
                            formData.companyRepresentativeSignature
                              ? formData.companyRepresentativeSignature
                              : signaturePlaceholder
                          }
                          size='large'
                          centered
                        />
                      </div>
                    </Segment>
                  )}
                  {formData.representativeSignature && (
                    <Segment>
                      <div className='image-preview-header'>توقيع مندوب العميل ({formData.representativeName})</div>
                      <div className='signature-preview'>
                        <Image
                          src={
                            formData.representativeSignature ? formData.representativeSignature : signaturePlaceholder
                          }
                          size='large'
                          centered
                        />
                      </div>
                    </Segment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageCardContainer>
      </div>
      {modalError && <ErrorModal />}
    </>
  );
};
export default ViewMaintenanceReport;

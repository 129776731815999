import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Form,
  Message,
  Button,
  Icon,
  Responsive,
  Dropdown,
  Grid,
  Image,
  Tab,
  Transition,
  Dimmer,
} from 'semantic-ui-react';
import { lookupsApi, usersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { maintenanceFormApi, maintenanceTicketsApi } from './../../../api/api';
import Webcam from 'react-webcam';
import './MaintenanceForm.scss';
import signaturePlaceholder from './../../../assets/images/signature-placeholder.png';
import SignatureCanvas from 'react-signature-canvas';
import { loadMaintenanceFormStorage, saveMaintenanceFormStorage } from './../../../helpers/maintenanceFormStorage';

export default function EditMaintenanceForm() {
  const [inputDate, setInputDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [formId, setFormId] = useState(null);
  const [signatureModal, setSignatureModal] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);
  const sigPad = useRef({});

  const [formData, setFormData] = useState({
    maintenanceDate: moment().format('YYYY-MM-DD'),
    maintenanceHour: 0,
    createdBy: '',
    note: '',
    image: null,
    type: 26 /*maintenance ticket*/,
    trackingVehicleId: 1,
    issueType: '',
    vehicleIssueType: '',
    issueDesc: '',
    actionType: '',
    actionDesc: '',
    mainPowerAlerts: false,
    technicianUserId: '',
    customerId: '',
    maintenanceTicketVehicleId: null,
    representativeName: '',
    representativeSignature: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    issueDesc: null,
    actionDesc: null,
  });

  const [touched, setTouched] = useState({
    issueDesc: false,
    actionDesc: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const router = useHistory();

  const [technicianUserIdOpts, setTechnicianUserIdOpts] = useState([]);
  const [issueTypeOpts, setIssueTypeOpts] = useState([]);
  const [actionTypeOpts, setActionTypeOpts] = useState([]);
  const [vehicleIssueTypeOpts, setVehicleIssueTypeOpts] = useState([]);
  const [powerAlertOpts, setPowerAlertOpts] = useState([
    { key: true, text: 'نعم', value: true },
    { key: false, text: 'لا', value: false },
  ]);

  let id,
    trackingVehicleId,
    maintenanceTicketVehicleId,
    customerId = null;

  const [hourDropDown, setHourDropDown] = useState([]);

  const fillHourDropDown = () => {
    let arr = [];
    for (let i = 0; i <= 23; i++) {
      arr.push({ key: i, text: i, value: i });
    }
    setHourDropDown(arr);
  };

  useEffect(() => {
    //if ids come from maintenance ticket page
    if (
      location.state &&
      'trackingVehicleId' in location.state &&
      'maintenanceTicketVehicleId' in location.state &&
      'customerId' in location.state
    ) {
      ({ trackingVehicleId, maintenanceTicketVehicleId, customerId } = location.state);
    }

    //if id comes from maintenance form page
    if (location.state && 'id' in location.state) {
      ({ id } = location.state);
      setFormId(id);
    }

    const fetchData = async () => {
      try {
        if (trackingVehicleId && maintenanceTicketVehicleId && customerId) {
          const formId = await maintenanceTicketsApi.getFormByMaintenanceTicketVehicle(maintenanceTicketVehicleId);
          id = formId.data;
          setFormId(id);
        }

        //get technicians (all users with no customerId field)
        const technicians = await usersApi.getAllExceptCustomersDropdown();
        let techniciansOpts = technicians.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setTechnicianUserIdOpts(techniciansOpts);

        //get lookup group where id = 1 (problems)
        const problems = await lookupsApi.getGroup(1);
        let problemsOpts = problems.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
        setIssueTypeOpts(problemsOpts);

        //get lookup group where id =42 (vehicle problems)
        const vehicleProblems = await lookupsApi.getGroup(42);
        let vehicleProbsOpts = vehicleProblems.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
        setVehicleIssueTypeOpts(vehicleProbsOpts);

        //get lookup group where id = 13 (actions)
        const actions = await lookupsApi.getGroup(13);
        let actionsOpts = actions.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
        setActionTypeOpts(actionsOpts);

        fillHourDropDown();

        setLoading(false);

        if (loadMaintenanceFormStorage().form) {
          // in case there was form data in session storage
          setFormData(loadMaintenanceFormStorage().form);
          setInputDate(new Date(loadMaintenanceFormStorage().form.maintenanceDate));
        } else {
          const form = await maintenanceFormApi.getOneForm(id);
          setInputDate(new Date(form.data.maintenanceDate));
          setFormData(form.data);
        }
      } catch (err) {
        console.log('Error fetching data...', err);
      }
    };

    fetchData();

    return () => {
      if (window.location.pathname !== '/maintenanceForm/edit') {
        sessionStorage.removeItem('maintenanceForm');
      }
    };
  }, []);

  const validation = () => {
    const errors = {
      issueDesc: false,
      actionDesc: false,
    };

    if (!formData.issueDesc) {
      errors.issueDesc = 'الرجاء ادخال وصف المشكلة';
    }
    if (!formData.actionDesc) {
      errors.actionDesc = 'الرجاء ادخال وصف الاجراء';
    }

    setValidationErrors(errors);

    if (validationErrors.issueDesc || validationErrors.actionDesc) return false;
    else return true;
  };

  useEffect(() => {
    if (!loading) {
      validation();
      saveMaintenanceFormStorage({ form: formData });
    }
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors([]);
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({
            ...formData,
            image: `data:image/jpeg;base64, ${btoa(upload.target.result)}`,
          });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors([
          {
            key: 1000,
            message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
          },
        ]);
      }
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      issueDesc: true,
      actionDesc: true,
    });

    if (validation()) {
      try {
        const res = await maintenanceFormApi.editForm(formId, {
          ...formData,
          maintenanceHour: Number(formData.maintenanceHour),
        });
        sessionStorage.removeItem('maintenanceForm');

        router.goBack();
      } catch (e) {
        console.log('Error editing maintenance form...', e);
        setIsSubmitting(false);
        setErrors({ key: 1, message: 'خطأ اثناء الحفظ' });
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const DateChangeHandler = (date) => {
    setInputDate(date);
    setTouched({
      ...touched,
      maintenanceDate: true,
    });
    setFormData({
      ...formData,
      maintenanceDate: moment.utc(date.toLocaleDateString()).format('YYYY-MM-DD'),
    });
  };

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  // capture camera image
  const webcamRef = React.useRef(null);

  const cameraChangeHandler = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setFormData({ ...formData, image: imageSrc });
  };

  const panes = [
    {
      menuItem: 'تحميل صورة من الجهاز',
      render: () => <Tab.Pane>{imageFromDevice()}</Tab.Pane>,
    },
    {
      menuItem: 'صورة من الكاميرا',
      render: () => <Tab.Pane>{imageFromCamera()}</Tab.Pane>,
    },
  ];

  const imageFromDevice = () => {
    return (
      <>
        <Form.Field>
          <Form.Input
            type='file'
            id='fileUpload'
            onChange={fileChangeHandler}
            error={touched.image && validationErrors.image}
          />
        </Form.Field>
      </>
    );
  };

  const imageFromCamera = () => {
    return (
      <>
        {!Boolean(formData.image) ? (
          <div className='camera-div'>
            <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' className='video-div' />
            <Button fluid onClick={cameraChangeHandler}>
              التقاط صورة
            </Button>
          </div>
        ) : (
          <Button fluid onClick={() => setFormData({ ...formData, image: null })}>
            التقاط صورة جديدة
          </Button>
        )}
      </>
    );
  };

  const signatureClickHandler = () => {
    setSignatureModal(true);
  };

  useEffect(() => {
    const parent = document.getElementsByClassName('signature-canvas');
    setCanvasHeight(parent[0] ? parent[0].clientHeight : null);
    setCanvasWidth(parent[0] ? parent[0].clientWidth : null);
  }, [signatureModal]);

  const addSignatureHandler = () => {
    setFormData({ ...formData, representativeSignature: sigPad.current.getTrimmedCanvas().toDataURL('image/png') });
    setSignatureModal(false);
  };

  return (
    <PageCardContainer>
      <div className='edit-maintenance-form'>
        <div style={{ margin: '1rem' }} className='form-margin'>
          <h2 style={{ marginBottom: '2rem' }}>تعديل تقرير الصيانة</h2>
          <Grid stackable className='edit-grid'>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <label>
                <b>العميل</b>
              </label>
              <p>{formData.customerName}</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <label>
                <b>رقم لوحة المركبة</b>
              </label>
              <p>{formData.plateNumber}</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <label>
                <b>IMEI جهاز التتبع</b>
              </label>
              <p>{formData.imei}</p>
            </Grid.Column>
          </Grid>
          <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
            <Form.Group widths={2}>
              <Form.Field>
                <label>تاريخ اجراءالصيانة</label>
                <DatePicker
                  selected={inputDate}
                  onChange={(date) => DateChangeHandler(date)}
                  maxDate={moment().toDate()}
                  onChangeRaw={handleDateChangeRaw}
                />
              </Form.Field>

              <Form.Field>
                <label>ساعة اجراءالصيانة</label>
                <Dropdown
                  name='maintenanceHour'
                  placeholder='اختر الساعة'
                  fluid
                  selection
                  search
                  onChange={selectionChangeHandler}
                  options={hourDropDown}
                  value={formData.maintenanceHour}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <label>الفني المسؤول عن الصيانة</label>
                <Dropdown
                  name='technicianUserId'
                  placeholder='اختر الفني'
                  fluid
                  selection
                  search
                  onChange={selectionChangeHandler}
                  options={technicianUserIdOpts}
                  value={formData.technicianUserId}
                />
              </Form.Field>

              <Form.Field>
                <label>الاجراء المتخذ</label>
                <Dropdown
                  name='actionType'
                  placeholder='اختر الاجراء'
                  fluid
                  selection
                  search
                  onChange={selectionChangeHandler}
                  options={actionTypeOpts}
                  value={formData.actionType}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <label>حالة جهاز التتبع</label>
                <Dropdown
                  name='issueType'
                  placeholder='حالة جهاز التتبع'
                  fluid
                  selection
                  search
                  onChange={selectionChangeHandler}
                  options={issueTypeOpts}
                  value={formData.issueType}
                />
              </Form.Field>

              <Form.Field>
                <label>حالة المركبة</label>
                <Dropdown
                  name='vehicleIssueType'
                  placeholder='حالة المركبة'
                  fluid
                  selection
                  search
                  onChange={selectionChangeHandler}
                  options={vehicleIssueTypeOpts}
                  value={formData.vehicleIssueType}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>فصل مزود الطاقة الرئيسي</label>
              <Dropdown
                name='mainPowerAlerts'
                placeholder='فصل مزود الطاقة الرئيسي'
                fluid
                selection
                search
                onChange={selectionChangeHandler}
                options={powerAlertOpts}
                value={formData.mainPowerAlerts}
              />
            </Form.Field>

            <Form.Field>
              <label>وصف المشكلة</label>
              <Form.TextArea
                value={formData['issueDesc']}
                name='issueDesc'
                onChange={onChangeHandler}
                error={touched.issueDesc && validationErrors.issueDesc}
              />
            </Form.Field>

            <Form.Field>
              <label>وصف الاجراءالمتخذ</label>
              <Form.TextArea
                value={formData['actionDesc']}
                name='actionDesc'
                onChange={onChangeHandler}
                error={touched.actionDesc && validationErrors.actionDesc}
              />
            </Form.Field>

            <Form.Field>
              <label>ملاحظات</label>
              <Form.TextArea value={formData['note']} name='note' onChange={onChangeHandler} />
            </Form.Field>

            <h5>صورة المشكلة</h5>
            <Tab panes={panes} />
            {formData.image && (
              <>
                <div className='image-preview-header'>عرض صورة المشكلة الحالية</div>
                <div className='image-preview'>
                  <div className='image-cropper'>
                    <Image src={formData.image} className='img' centered />
                  </div>
                </div>
              </>
            )}

            <h5>مندوب العميل</h5>
            <div className='signature-preview-div'>
              <Form.Field>
                <label>اسم مندوب العميل</label>
                <Form.Input
                  icon='user circle'
                  value={formData['representativeName']}
                  name='representativeName'
                  onChange={onChangeHandler}
                />
              </Form.Field>
              <p>توقيع مندوب العميل</p>
              <div className='signature-preview' onClick={() => signatureClickHandler()}>
                <Image
                  src={formData.representativeSignature ? formData.representativeSignature : signaturePlaceholder}
                  size='medium'
                  centered
                />
              </div>
            </div>

            <Message error key={errors.key} content={errors.message} />

            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    sessionStorage.removeItem('maintenanceForm');
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    sessionStorage.removeItem('maintenanceForm');
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
          </Form>
        </div>

        <Transition visible={signatureModal} animation='scale' duration={1000}>
          <Dimmer
            className='signature-dimmer'
            active={signatureModal}
            onClickOutside={() => setSignatureModal(false)}
            page
          >
            <div className='signature-container'>
              <div className='signature-header'>توقيع مندوب العميل</div>
              <div className='signature-canvas'>
                <SignatureCanvas
                  id='canvas'
                  penColor='black'
                  canvasProps={{ width: canvasWidth, height: canvasHeight }}
                  ref={sigPad}
                />
              </div>
              <div className='signature-actions'>
                <Responsive minWidth={768}>
                  <div>
                    <Button
                      color='green'
                      icon='save'
                      labelPosition='right'
                      content='اضافة التوقيع'
                      style={{ marginLeft: '1rem' }}
                      onClick={() => addSignatureHandler()}
                    />

                    <Button
                      icon='chevron right'
                      labelPosition='right'
                      content='الغاء'
                      onClick={() => {
                        setSignatureModal(false);
                      }}
                    />
                  </div>
                </Responsive>
                <Responsive maxWidth={767}>
                  <div>
                    <Button
                      fluid
                      color='green'
                      icon='save'
                      labelPosition='right'
                      content='اضافة التوقيع'
                      style={{ marginBottom: '1rem' }}
                      onClick={() => addSignatureHandler()}
                    />

                    <Button
                      fluid
                      icon='chevron right'
                      labelPosition='right'
                      content='الغاء'
                      onClick={() => {
                        setSignatureModal(false);
                      }}
                    />
                  </div>
                </Responsive>
              </div>
            </div>
          </Dimmer>
        </Transition>
      </div>
    </PageCardContainer>
  );
}

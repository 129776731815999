import React, { useEffect, useState } from 'react';
import { Loader, Icon, Table, Image } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { customersApi } from './../../../api/api';
import buildingImage from './../../../assets/images/buildings.png';

const ViewBranch = () => {
  const [loading, setLoading] = useState(false);
  const router = useHistory();
  const locationWin = useLocation();
  const { id: branchId } = locationWin.state;

  const [formData, setFormData] = useState({
    branchName: '',
    location: '',
    contactNumber: '',
    contactEmail: '',
    contactName: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await customersApi.getBranch(branchId);
        setFormData(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching branch form data...', err);
        router.goBack();
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div className='user-details'>
            <div className='user-card'>
              <div className='header-img'>
                <div className='image-cropper'>
                  <Image src={buildingImage} className='img' centered />
                </div>
              </div>

              <div className='contents'>
                <h2>{formData.branchName}</h2>
                <hr />

                <Table basic='very' collapsing unstackable>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='building outline' /> اسم الفرع:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{formData.branchName}</b>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='user circle' /> موقع الفرع :
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{formData.location}</b>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='mail' /> الايميل:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{formData.contactEmail}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='phone' /> الهاتف:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{formData.contactNumber}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='user outline' /> اسم جهات الاتصال:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{formData.contactName}</b>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
};

export default ViewBranch;

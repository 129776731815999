import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { usersApi, departmentsApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';

const EditDepartmentsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    departmentManagerId: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    name: null,
  });

  const [touched, setTouched] = useState({
    name: false,
  });

  const [departmentManagerIdOptions, setdepartmentManagerIdOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const router = useHistory();
  const location = useLocation();
  const { id, row } = location.state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const department = await departmentsApi.getOneDepartment(id);
        // get users
        const { data: users } = await usersApi.getAllExceptCustomersDropdown();
        const options = users.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        setdepartmentManagerIdOptions(options);

        setFormData(department.data);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching department managers...', err);
        router.goBack();
      }
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      name: null,
    };

    if (!formData.name) {
      errors.name = 'الرجاء ادخال اسم الادارة';
    }

    setValidationErrors(errors);

    if (errors.name) return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const managerSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, departmentManagerId: data.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      name: true,
    });

    if (validation()) {
      try {
        await departmentsApi.editDepartment(id, formData);
        router.goBack();
      } catch (e) {
        console.log('Error posting department...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل ادارة</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={isLoading}>
          <Form.Field>
            <label>اسم الادارة</label>
            <Form.Input
              icon='building'
              value={formData['name']}
              name='name'
              onChange={onChangeHandler}
              error={touched.name && validationErrors.name}
            />
          </Form.Field>

          <Form.Field>
            <label>مدير الدائرة</label>
            <Dropdown
              placeholder='اختر المدير'
              fluid
              selection
              search
              value={formData['departmentManagerId']}
              options={departmentManagerIdOptions}
              onChange={managerSelectionChangeHandler}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
          </Form.Field>

          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default EditDepartmentsForm;

import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { customerTicketsApi, teamsApi } from '../../../api/api';
import qs from 'qs';
import permissions, { hasPermission } from '../../../helpers/permissions';

const TicketAssignForm = ({ ticket, onTicketAssigned }) => {
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [previewData, setPreviewData] = useState(null);

  const user = useSelector((state) => state.user).user;

  useEffect(() => {
    const fetch = async () => {
      if (ticket.status === 20 || !hasPermission(permissions.ASSIGN_TICKET_TO_TEAM)) {
        if (ticket.teamId) {
          setPreviewData({ team: ticket.teamName, member: ticket.teamMember });
        }
        setLoading(false);
      } else {
        const teamsDdl = await teamsApi.getTeamsDropDownByCustomerId(ticket.customerId);

        if (ticket.teamId && !teamsDdl.data.some((t) => t.id === ticket.teamId))
          teamsDdl.data = [...teamsDdl.data, { id: ticket.teamId, name: ticket.teamName }];

        setTeams(
          teamsDdl.data.map((t) => ({
            key: t.id,
            text: t.name,
            value: t.id,
          }))
        );

        if (ticket.teamId) {
          setSelectedTeam(ticket.teamId);
        } else {
          setLoading(false);
        }
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      fetchMembers();
      if (loading) {
        setSelectedTeamMember(ticket.teamMemberId);
      }
      setLoading(false);
    } else {
      setSelectedTeamMember(null);
      setTeamMembers([]);
    }
  }, [selectedTeam]);

  const fetchMembers = async () => {
    const mems = await teamsApi.getMembers(selectedTeam);
    setTeamMembers(
      mems.data.map((t) => ({
        key: t.userId,
        text: `${t.fullName}${t.isTeamLeader ? ' (رئيس الفريق)' : ''}`,
        value: t.userId,
      }))
    );
  };

  const fetchAllTeams = async () => {
    setLoading(true)
    const allTeams = await teamsApi.getTeamsDropDown();
    setTeams(
      allTeams.data.map((t) => ({
        key: t.id,
        text: t.name,
        value: t.id,
      }))
    );
     setLoading(false)
  };

  const onTeamMemberSelected = (e, v) => {
    setSelectedTeamMember(v.value);
  };

  const onTeamSelected = (e, v) => {
    setSelectedTeam(v.value);
  };

  const onAssign = async () => {
    try {
      const queryString = qs.stringify(
        { teamId: selectedTeam, memberId: selectedTeamMember, assignedBy: user.id },
        { addQueryPrefix: true }
      );

      setLoading(true);
      await customerTicketsApi.assignTicket(ticket.id, queryString);

      onTicketAssigned({
        teamId: selectedTeam,
        team: teams.find((item) => item.value === selectedTeam).text,
        memberId: selectedTeamMember,
        member: teamMembers.find((item) => item.value === selectedTeamMember).text,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      // TODO: show error message
    }
  };

  const onUnassign = async () => {
    try {
      setLoading(true);
      const queryString = qs.stringify({ assignedBy: user.id }, { addQueryPrefix: true });
      await customerTicketsApi.unassignTicket(ticket.id, queryString);

      onTicketAssigned({
        teamId: null,
        team: null,
        memberId: null,
        member: null,
      });

      setSelectedTeam(null);
      setSelectedTeamMember(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      // TODO: show error message
    }
  };

  return Boolean(previewData) ? (
    <>
      <div className='data-box'>
        <div className='title-container'>
          <Icon name='users' size='small' />
          <h5 className='title'>الفريق</h5>
        </div>
        <h5 className='data'>{previewData.team}</h5>
      </div>
      <div className='data-box'>
        <div className='title-container'>
          <Icon name='user' size='small' />
          <h5 className='title'>مسند الى</h5>
        </div>
        <h5 className='data'>{previewData.member}</h5>
      </div>
    </>
  ) : (
    ticket.status !== 20 && hasPermission(permissions.ASSIGN_TICKET_TO_TEAM) && (
      <div className='assign-team-container'>
        <div className='flex-between-center'>
          <h5 className='title'>إسناد الى فريق</h5>
          <Popup trigger={<Button loading={loading} icon='group' size="mini" style={{marginBottom: 4}} onClick={fetchAllTeams} />} content='عرض جميع الفرق' />
        </div>
        <Dropdown
          fluid
          loading={loading}
          clearable
          selection
          search
          onChange={onTeamSelected}
          value={selectedTeam}
          options={teams}
        />
        <h5 className='title'>تحديد العضو المسؤول</h5>
        <Dropdown
          fluid
          loading={loading}
          clearable
          selection
          search
          onChange={onTeamMemberSelected}
          value={selectedTeamMember}
          options={teamMembers}
        />
        <Button
          disabled={!Boolean(selectedTeamMember) || selectedTeamMember === ticket.teamMemberId || loading}
          loading={loading}
          className='no-margin'
          fluid
          icon='save'
          labelPosition='right'
          color='blue'
          content='حفظ'
          onClick={onAssign}
        />
        <Button
          disabled={!Boolean(ticket.teamMemberId) || loading}
          loading={loading}
          className='no-margin'
          fluid
          icon='cancel'
          labelPosition='right'
          color='red'
          content='إزالة الاسناد'
          onClick={onUnassign}
        />
      </div>
    )
  );
};

export default TicketAssignForm;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { lookupsApi, userTrackingAssetsApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';

export default function AddUserTrackingAsset() {
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((st) => st.user).user;

  const [formData, setFormData] = useState({
    imei: '',
    simNumber: '',
    departmentId: '',
    deviceTypeId: '',
    createdBy: Number(currentUser.id),
  });

  const [validationErrors, setValidationErrors] = useState({
    imei: null,
    simNumber: null,
    departmentId: null,
    deviceTypeId: null,
  });

  const [touched, setTouched] = useState({
    imei: false,
    simNumber: false,
    departmentId: false,
    deviceTypeId: false,
  });

  const [departmentIdOptions, setDepartmentIdOptions] = useState([]);
  const [deviceTypeIdOptions, setDeviceTypeIdOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const router = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const { data: deviceTypes } = await lookupsApi.getGroup(77);
        const options1 = deviceTypes.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setDeviceTypeIdOptions(options1);

        const { data: departments } = await userTrackingAssetsApi.departmentsOfUserDropdown();
        let options2 = [];
        if (departments && departments.length) {
          options2 = departments.map((item) => ({
            key: item.id,
            text: item.name,
            value: item.id,
          }));
          setDepartmentIdOptions(options2);
        }

        setFormData({
          ...formData,
          deviceTypeId: Boolean(options1.length) ? options1[0].key : '',
          departmentId: Boolean(departments.length) ? options2[0].key : '',
        });
      } catch (err) {
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching user tracking assets form data...', err);
        router.goBack();
      }
      setLoading(false);
    })();
  }, []);

  const validation = () => {
    const errors = {
      imei: null,
      simNumber: null,
      departmentId: null,
      deviceTypeId: null,
    };

    if (!formData.imei) {
      errors.imei = 'الرجاء ادخال الIMEI';
    } else if (!/^\d+$/.test(formData.imei)) {
      errors.imei = ' الIMEI يجب ان يحتوي على ارقام فقط';
    }
    if (!formData.simNumber) {
      errors.simNumber = 'الرجاء ادخال رقم الSIM';
    }
    if (!formData.deviceTypeId) {
      errors.deviceTypeId = 'الرجاء اختيار نوع الجهاز';
    }
    if (!formData.departmentId) {
      errors.departmentId = 'الرجاء اختيار الإدارة';
    }
    setValidationErrors(errors);

    if (
      validationErrors.imei ||
      validationErrors.simNumber ||
      validationErrors.deviceTypeId ||
      validationErrors.departmentId
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      imei: true,
      simNumber: true,
      departmentId: true,
      deviceTypeId: true,
    });

    if (validation()) {
      try {
        await userTrackingAssetsApi.postUserTrackingAsset(formData);
        router.goBack();
      } catch (e) {
        console.log('Error posting user tracking asset...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin userForm'>
        <h2 style={{ marginBottom: '2rem' }}>اضافة جهاز تتبع مستخدم جديد</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>IMEI</label>
              <Form.Input
                icon='qrcode'
                value={formData['imei']}
                name='imei'
                onChange={onChangeHandler}
                error={touched.imei && validationErrors.imei}
              />
            </Form.Field>

            <Form.Field>
              <label>SIM</label>
              <Form.Input
                icon='table'
                value={formData['simNumber']}
                name='simNumber'
                onChange={onChangeHandler}
                error={touched.simNumber && validationErrors.simNumber}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>الإدارة</label>
              <Dropdown
                placeholder='اختر الإدارة'
                fluid
                selection
                search
                value={formData['departmentId']}
                name='departmentId'
                options={departmentIdOptions}
                onChange={selectionChangeHandler}
                error={touched.departmentId && validationErrors.departmentId}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>

            <Form.Field>
              <label>نوع الجهاز</label>
              <Dropdown
                placeholder='اختر نوع الجهاز'
                fluid
                selection
                search
                value={formData['deviceTypeId']}
                name='deviceTypeId'
                options={deviceTypeIdOptions}
                error={touched.deviceTypeId && validationErrors.deviceTypeId}
                onChange={selectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
          </Form.Group>

          {errors.map((item) => {
            return <Message error key={item.key} content={item.message} />;
          })}

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}

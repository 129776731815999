import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

export default function ViewModal({ isOpen, onClose, children, title }) {
  return (
    <Modal open={isOpen} onClose={onClose} size='fullscreen'>
      <Header icon='info' content={title} />
      <Modal.Content
        style={{
          height: 'calc(100vh - 200px)',
          overflowY: 'auto',
        }}
      >
        {children}
      </Modal.Content>
      <Modal.Actions style={{ margin: '0px' }}>
        <Button color='red' onClick={() => onClose()}>
          <Icon name='remove' /> إغلاق
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Image, Segment, Responsive, Popup } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { maintenanceFormApi, maintenanceTicketsApi } from './../../../api/api';
import moment from 'moment';
import MaintenanceForm from './MaintenanceForm';

export default function ViewMaintenanceFormDetails() {
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [passedId, setPassedId] = useState(null);
  const [passedType, setPassedType] = useState(null);

  let id,
    trackingVehicleId,
    maintenanceTicketVehicleId,
    customerId = null;

  useEffect(() => {
    //if ids come from maintenance ticket page
    if (
      location.state &&
      'trackingVehicleId' in location.state &&
      'maintenanceTicketVehicleId' in location.state &&
      'customerId' in location.state
    ) {
      ({ trackingVehicleId, maintenanceTicketVehicleId, customerId } = location.state);
    }

    //if id comes from maintenance form page
    if (location.state && 'id' in location.state) {
      ({ id } = location.state);
    }

    (async () => {
      if (trackingVehicleId && maintenanceTicketVehicleId && customerId) {
        const formId = await maintenanceTicketsApi.getFormByMaintenanceTicketVehicle(maintenanceTicketVehicleId);
        id = formId.data;
      }
      const res = await maintenanceFormApi.getOneForm(id);
      setFormData(res.data);
    })();
  }, [id]);

  const DataBox = ({ title, icon = null, data, ltr = false, image = false, bordered = true, padded = true }) => {
    return (
      <div className={`data-box ${!bordered ? 'no-border' : ''} ${!padded ? 'no-padding' : ''}`}>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        {image ? (
          <div className='image-margin image-preview'>
            <div className='maintenance-form-img-preview'>
              <Image src={data} className='img' centered />
            </div>
          </div>
        ) : (
          <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data ? data : 'لا توجد بيانات'}</h5>
        )}
      </div>
    );
  };

  const btnClickHandler = (type, id) => {
    setPassedId(id);
    setPassedType(type);
  };

  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        <div style={{ margin: '1rem 0.7rem' }}>
          <div className='header-wrapper'>
            <div>
              <h3>تقرير الصيانة رقم #{formData.id}</h3>
              <DataBox title='الفني المسؤول' icon='user circle' data={formData.technicianName} />
            </div>
            <Popup
              content='طباعة'
              trigger={
                <Button
                  className='blue-button height-50'
                  onClick={() => window.open(`print/?id=${formData.id}`, '_blank')}
                >
                  <Icon name='print' />
                </Button>
              }
              position='left center'
            />
          </div>

          <div className='segments-wrapper'>
            <div className='single-segment-wrapper'>
              <h3>معلومات التقرير</h3>
              <Segment>
                <div className='form-columns-wrapper'>
                  <DataBox
                    title='تاريخ اجراء الصيانة'
                    icon='calendar'
                    ltr
                    data={`${moment(formData.maintenanceDate).format('YYYY/MM/DD')}, ${moment
                      .utc(moment.duration(formData.maintenanceHour, 'hours').asMilliseconds())
                      .format('hh:mm A')}`}
                  />
                  <DataBox
                    title='تاريخ انشاء التقرير'
                    icon='clock'
                    ltr
                    data={moment(formData.createdAt).format('YYYY/MM/DD, hh:mm A')}
                  />
                  <DataBox title='منشيء التقرير' icon='user' data={formData.createdByName} />
                </div>
              </Segment>
            </div>
            <div className='single-segment-wrapper'>
              <h3>معلومات الجهاز</h3>
              <Segment>
                <div className='form-columns-wrapper'>
                  <DataBox title='العميل' icon='building' data={formData.customerName} />
                  <DataBox title='رقم لوحة المركبة' icon='car' data={formData.plateNumber} />
                  <DataBox title='IMEI جهاز التتبع' icon='qrcode' data={formData.imei} />
                </div>
              </Segment>
            </div>

            <div className='single-segment-wrapper full-width'>
              <h3>معلومات الصيانة</h3>
              <div className='two-columns'>
                <Segment>
                  <div className='two-columns'>
                    <DataBox title='حالة جهاز التتبع' icon='bug' data={formData.issueTypeStr} />
                    <DataBox title='حالة المركبة' icon='bug' data={formData.vehicleIssueTypeStr} />
                  </div>

                  <DataBox
                    title='فصل مزود الطاقة الرئيسي'
                    icon='info circle'
                    data={formData.mainPowerAlerts === false ? 'نعم' : 'لا'}
                  />
                  <DataBox title='وصف المشكلة' icon='book' data={formData.issueDesc} />
                </Segment>
                <Segment>
                  <DataBox title='الاجراء' icon='cogs' data={formData.actionTypeStr} />
                  <DataBox title='وصف الاجراء' icon='book' data={formData.actionDesc} />
                </Segment>
              </div>
              <Segment>
                <div className='two-columns'>
                  <DataBox title='ملاحظات' icon='sticky note' data={formData.note} padded={false} bordered={false} />
                  {formData.image && (
                    <>
                      <span className='vertical-divider' />
                      <div>
                        <DataBox
                          title='صورة المشكلة'
                          icon='picture'
                          image
                          data={formData.image}
                          bordered={false}
                          padded={false}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Segment>
              {formData.representativeName || formData.representativeSignature ? (
                <Segment>
                  <div>
                    {formData.representativeName && (
                      <div>
                        <DataBox title='اسم مندوب العميل' icon='user circle' data={formData.representativeName} />
                      </div>
                    )}

                    {formData.representativeSignature && (
                      <div>
                        <DataBox
                          title='توقيع مندوب العميل'
                          icon='picture'
                          image
                          data={formData.representativeSignature}
                          bordered={false}
                          padded={false}
                        />
                      </div>
                    )}
                  </div>
                </Segment>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='buttons-container'>
            <Responsive minWidth={768}>
              <div className='buttons-container-contents'>
                <Button
                  icon
                  labelPosition='right'
                  className='blue-button '
                  onClick={() => btnClickHandler('vehicle', formData.vehicleId)}
                >
                  <Icon name='car' className='blue-buton-icon' />
                  تاريخ صيانة المركبة
                </Button>
                <Button
                  icon
                  labelPosition='right'
                  className='blue-button'
                  onClick={() => btnClickHandler('trackingUnit', formData.trackingUnitId)}
                >
                  <Icon name='qrcode' className='blue-buton-icon' />
                  تاريخ صيانة الجهاز
                </Button>
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  labelPosition='right'
                  className='blue-button fluid-button-margin'
                  onClick={() => btnClickHandler('vehicle', formData.vehicleId)}
                >
                  <Icon name='car' className='blue-buton-icon' />
                  تاريخ صيانة المركبة
                </Button>
                <Button
                  icon
                  fluid
                  labelPosition='right'
                  className='blue-button fluid-button-margin'
                  onClick={() => btnClickHandler('trackingUnit', formData.trackingUnitId)}
                >
                  <Icon name='qrcode' className='blue-buton-icon' />
                  تاريخ صيانة الجهاز
                </Button>
              </div>
            </Responsive>
          </div>
          {passedId && passedType && (
            <div className='embedded-table'>
              <MaintenanceForm passedId={passedId} passedType={passedType} />
            </div>
          )}
        </div>
      </PageCardContainer>
    </div>
  );
}

import OlCollection from 'ol/Collection';
import OlFeature from 'ol/Feature';
import { Circle, Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { containsXY } from 'ol/extent';
import { Style, Fill, Stroke, Icon, Text, Circle as CircleStyle } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { getTransform, fromLonLat, transform } from 'ol/proj';

import bluePin from './../../assets/images/pin-blue.png';

const WGS2Map = getTransform('EPSG:4326', 'EPSG:3857');
const WGS2MapReverse = getTransform('EPSG:3857', 'EPSG:4326');

export const hasLayer = (map, layerName) => {
  if (!Boolean(map)) {
    console.error({
      message: 'hasLayer: map is null',
      call: { map, layerName },
    });
    return null;
  }

  let found = false;
  const mapLayers = map.getLayers().getArray();
  mapLayers.forEach((layer) => {
    if (layer instanceof VectorLayer) {
      if (layer.get('name') === layerName) {
        found = true;
      }
    }
  });

  return found;
};

//remove layer from map by name
export const removeLayer = (map, layerName) => {
  map.getLayers().forEach((layer) => {
    if (layer && layer.get('name') === layerName) {
      map.removeLayer(layer);
    }
  });
};

//zoom on the provided longitude and latitude
export const zoomOnFeature = (map, longitude, latitude, zoom, duration = 1500) => {
  if (!Boolean(map)) {
    return console.error({
      message: 'zoomOnFeature: map is null',
      call: { map, longitude, latitude, zoom },
    });
  }

  map.getView().animate({
    center: fromLonLat([longitude, latitude]),
    duration: duration,
    zoom: zoom,
  });
};

//add a point to a source layer
export const addFeature = (source, coordinates, featureName, props) => {
  const coords = WGS2Map(coordinates);
  const feature = new OlFeature({
    geometry: new Point(coords),
    [featureName]: props,
  });
  source.addFeature(feature);
};

/** Display locations on map
 * @param {object} [map] - The main map
 * @param {String} [layerName] - The vector layer name
 * @param {String} [layerTitle] - Layer's name in the layer switcher
 * @param {String} [featureName] - The name used to store the feature inthe map
 * @param {Array} [data] - The data to be displayed on the map (Array of objects OR a single object)
 *                        (MUST contain longitude & latitude in the object/s)
 */
export const createLocationsLayer = (
  map,
  layerName,
  layerTitle,
  featureName,
  data,
  zoom = 10,
  zoomIn = true,
  multiplePointsFitExtent = false
) => {
  const options = {
    title: layerTitle,
    visible: true,
    opacity: 1.0,
  };

  //create empty features source
  const features = new OlCollection([], { unique: true });
  const source = new VectorSource({
    features: features,
  });
  //create features style
  const style = (feature, resolution) => {
    let scale = 0.1 - resolution * 0.00006;
    scale = scale >= 0 ? scale : 0.0005;

    return new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
        scale: scale,
        src: bluePin,
      }),
      zIndex: 2001,
    });
  };
  //create locations layer
  const locationsLayer = new VectorLayer({
    title: options.title,
    source: source,
    visible: options.visible,
    opacity: options.opacity,
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    style: style,
    zIndex: 2001,
  });

  locationsLayer.set('name', layerName);

  if (Array.isArray(data)) {
    // display all locations on map
    data.forEach((element) => {
      addFeature(source, [element.longitude, element.latitude], featureName, element);
    });
    if (multiplePointsFitExtent) {
      map.getView().fit(source.getExtent(), { maxZoom: zoom, duration: 1500, padding: [0, 50, 0, 50] });
    }
  } else {
    // display a single point
    addFeature(source, [data.longitude, data.latitude], featureName, data);
    if (zoomIn) zoomOnFeature(map, data.longitude, data.latitude, zoom);
  }

  map.addLayer(locationsLayer);
};

export const initUsersLocationsTooltip = (map) => {
  const overlayContainerElement = document.getElementById('tooltip-container');
  const overlayContentElement = document.getElementById('tooltip-content');

  const displayFeatureTooltip = (pixel, coordinates) => {
    var feature = map.forEachFeatureAtPixel(pixel, function (feature) {
      return feature;
    });
    if (feature) {
      if (feature.get('usersLocations')) {
        overlayContainerElement.style.display = 'block';
        map.getViewport().style.cursor = 'pointer';
        overlayContainerElement.style.height = '61px';
        overlayContainerElement.style.top = pixel[1] + 20 + 'px';
        overlayContentElement.innerHTML = `
        <div>
        ${feature.get('usersLocations').userName}
        </div>
        <div>
        السرعة: ${feature.get('usersLocations').speed} كم\\س ,الدقة: ${feature.get('usersLocations').accuracy} م
        </div>
        `;
      } else {
        overlayContainerElement.style.display = 'none';
        map.getViewport().style.cursor = 'auto';
      }
    } else {
      overlayContainerElement.style.display = 'none';
      map.getViewport().style.cursor = 'auto';
    }
    overlayContainerElement.style.left = pixel[0] - 125 + 'px';
  };

  map.on('pointermove', (evt) => {
    if (evt.dragging) {
      overlayContainerElement.style.display = 'none';
    }
    displayFeatureTooltip(map.getEventPixel(evt.originalEvent), evt.coordinate);
  });
};

import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageCardContainer from '../../../Layout/PageCardContainer';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import DataTable from '../../../Layout/Table/DataTable';
import { departmentsApi, expensesApi, lookupsApi } from '../../../../api/api';
import { useState } from 'react';
import { hasPermission } from '../../../../helpers/permissions';
import permissions from '../../../../helpers/permissions';
import { useSelector } from 'react-redux';
import { systemCodes } from '../../../../helpers/systemcodes';
import { AcceptRejectExpenseModal } from '../../../Reusable/AcceptRejectExpenseModal';
import StatusModal from '../../../Reusable/StatusModal';
import ViewModal from '../../../Reusable/ViewModal';
import ExpensesForm from './ExpensesForm';

const ExpensesFormsList = () => {
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();
  const [formsStatus, setFormsStatus] = useState(0);
  const [statusOptions, setStatusOptions] = useState([{ key: 0, text: 'الكل', value: 0 }]);
  const [employeeIdOpts, setEmployeeIdOpts] = useState([]);
  const [employeeIdArMap, setEmployeeIdArMap] = useState({});
  const [departmentIdOpts, setDepartmentIdOpts] = useState([]);
  const [departmentIdArMap, setDepartmentIdArMap] = useState({});
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formId, setFormId] = useState(false);
  const [statusId, setStatusId] = useState(0);
  const [actionId, setActionId] = useState(0);
  const [isUpdatedByManager, setIsUpdatedByManager] = useState(false);
  const [modalStatusNote, setModalStatusNote] = useState('');
  const [statusUpdatePopupVisible, setStatusUpdatePopupVisible] = useState(false);
  const [isViewModalShown, setIsViewModalShown] = useState(false);
  const [updateTeamLeaderActionPopupVisible, setUpdateTeamLeaderActionPopupVisible] = useState(false);
  const [statusModalPopupVisible, setStatusModalPopupVisible] = useState(false);
  const [expenseStatusContent, setExpenseStatusContent] = useState([]);
  const [expensesTypesIdArMap, setExpensesTypesArMap] = useState({});
  const [expensesTypes, setExpensesTypes] = useState([]);

  const getExpenseTypes = async () => {
    const expensesTypesIdArMapCopy = {};
    const { data } = await expensesApi.getExpenseFormTypes();
    const types = data.map((item) => {
      expensesTypesIdArMapCopy[item.id] = item.name;
      return {
        key: item.id,
        text: item.name,
        value: item.id,
      };
    });
    setExpensesTypes(types);
    setExpensesTypesArMap(expensesTypesIdArMapCopy);
  };

  const tableHeaders = [
    {
      displayName: 'رقم الطلب',
      fieldName: 'id',
      dbFieldName: 'id',
      searchType: 'text',
      sortable: true,
      searchable: true,
      viewable: true,
    },
    {
      displayName: 'حالة الطلب',
      fieldName: 'status',
      dbFieldName: 'status',
      searchable: false,
      viewable: true,
    },
    {
      displayName: 'نوع الطلب',
      fieldName: 'expenseType',
      dbFieldName: 'expense_form_type_id',
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: expensesTypes,
      dropdownArMap: expensesTypesIdArMap,
    },
    {
      displayName: 'القسم',
      fieldName: 'department',
      dbFieldName: 'department',
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: departmentIdOpts,
      dropdownArMap: departmentIdArMap,
    },
    {
      displayName: 'اسم مقدم الطلب',
      fieldName: 'employeeName',
      dbFieldName: 'u.full_name',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: employeeIdOpts,
      dropdownArMap: employeeIdArMap,
    },
    {
      displayName: 'تاريخ الطلب',
      fieldName: 'createdAt',
      dbFieldName: 'created_at',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'إجراء قائد الفريق',
      fieldName: 'teamLeaderAction',
      dbFieldName: 'teamLeaderAction',
      searchable: false,
      viewable: true,
    },
  ];

  const tableActions = [
    {
      actionType: 'custom',
      onClick: (_, row) =>
        handleUpdateStatus({
          formId: row.id,
          departmentManagerId: row.departmentManager,
          statusId: systemCodes.expensesStatus.returned,
        }),
      title: 'إرجاع المعاملة',
      icon: 'retweet',
      color: 'blue',
      displayCondition: (row) => {
        return (
          (row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval &&
            (+row.departmentManager === +currentUser.id ||
              hasPermission(permissions.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL))) ||
          (row.statusId === systemCodes.expensesStatus.approvedByAdministration &&
            hasPermission(permissions.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL))
        );
      },
    },
    {
      actionType: 'custom',
      onClick: (_, row) =>
        handleUpdateStatus({
          formId: row.id,
          departmentManagerId: row.departmentManager,
          statusId: systemCodes.expensesStatus.approvedByAdministration,
        }),
      title: 'موافقة الادارة',
      icon: 'check',
      color: 'green',
      displayCondition: (row) => {
        return (
          row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval &&
          +row.departmentManager === +currentUser.id
        );
      },
    },
    {
      actionType: 'custom',
      onClick: (_, row) =>
        handleUpdateStatus({
          formId: row.id,
          departmentManagerId: row.departmentManager,
          statusId: systemCodes.expensesStatus.rejectByAdministration,
        }),
      title: 'رفض الادارة',
      icon: 'delete',
      color: 'red',
      displayCondition: (row) => {
        return (
          row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval &&
          +row.departmentManager === +currentUser.id
        );
      },
    },
    {
      actionType: 'custom',
      onClick: (_, row) => handleUpdateTeamLeaderActionStatus(row, systemCodes.teamLeaderActions.approvedByTeamLeader),
      title: 'موافقة قائد الفريق',
      icon: 'check',
      color: 'green',
      displayCondition: (row) => {
        const currentUserLeaderRegex = new RegExp(`\\b${currentUser.id}\\b`);
        return (
          row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval &&
          row.teamLeaderActionId === systemCodes.teamLeaderActions.WaitingForTeamLeaderToTakeAction &&
          currentUserLeaderRegex.test(row.teamLeaderIds)
        );
      },
    },
    {
      actionType: 'custom',
      onClick: (_, row) => handleUpdateTeamLeaderActionStatus(row, systemCodes.teamLeaderActions.rejectByTeamLeader),
      title: 'رفض قائد الفريق',
      icon: 'delete',
      color: 'red',
      displayCondition: (row) => {
        const currentUserLeaderRegex = new RegExp(`\\b${currentUser.id}\\b`);
        return (
          row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval &&
          row.teamLeaderActionId === systemCodes.teamLeaderActions.WaitingForTeamLeaderToTakeAction &&
          currentUserLeaderRegex.test(row.teamLeaderIds)
        );
      },
    },
    {
      actionType: 'custom',
      onClick: (_, row) =>
        handleUpdateStatus({
          formId: row.id,
          departmentManagerId: row.departmentManager,
          statusId: systemCodes.expensesStatus.readyToPaidOff,
        }),
      title: 'موافقة المالية',
      icon: 'check',
      color: 'green',
      displayCondition: (row) => {
        return (
          (row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval ||
            row.statusId === systemCodes.expensesStatus.approvedByAdministration) &&
          hasPermission(permissions.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL)
        );
      },
    },
    {
      actionType: 'custom',
      onClick: (_, row) =>
        handleUpdateStatus({
          formId: row.id,
          departmentManagerId: row.departmentManager,
          statusId: systemCodes.expensesStatus.rejectByFinancial,
        }),
      title: 'رفض المالية',
      icon: 'delete',
      color: 'red',
      displayCondition: (row) => {
        return (
          (row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval ||
            row.statusId === systemCodes.expensesStatus.approvedByAdministration) &&
          hasPermission(permissions.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL)
        );
      },
    },

    {
      actionType: 'custom',
      onClick: (_, row) =>
        handleUpdateStatus({
          formId: row.id,
          departmentManagerId: row.departmentManager,
          statusId: systemCodes.expensesStatus.paidOffExpenses,
        }),
      title: 'صرف المستحقات المالية',
      icon: 'handshake',
      color: 'green',
      displayCondition: (row) => {
        return (
          row.statusId === systemCodes.expensesStatus.readyToPaidOff &&
          hasPermission(permissions.PAYMENT_OF_FINANCIAL_DUES)
        );
      },
    },
    {
      actionType: 'edit',
      actionUrl: '/FinancialServices/EditExpensesForm',
      displayCondition: (row) =>
        (+row.createdBy === +currentUser.id &&
          (row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval ||
            row.statusId === systemCodes.expensesStatus.returned)) ||
        (hasPermission(permissions.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE) &&
          row.statusId === systemCodes.expensesStatus.approvedByAdministration),
    },
    {
      actionType: 'delete',
      deleteActionApi: expensesApi.deleteExpenseForm,
      displayCondition: (row) =>
        +row.createdBy === +currentUser.id &&
        row.statusId === systemCodes.expensesStatus.waitingForAdministrationApproval,
    },
    {
      actionType: 'custom',
      onClick: (_, row) => {
        setFormId(row.id);
        setIsViewModalShown(true);
      },
      icon: 'eye',
      color: 'blue',
      title: 'عرض',
    },
    {
      actionType: 'custom',
      onClick: (_, row) => showStatusModal(row),
      title: 'الإجراءات',
      icon: 'info',
      color: 'blue',
    },
    {
      actionType: 'custom',
      onClick: (id) => window.open(`printExpenseForm/?id=${id}`),
      title: 'طباعة',
      icon: 'print',
    },
  ];

  useEffect(() => {
    const fetch = async () => {
      await getExpenseTypes();
      const statusTypes = await lookupsApi.getGroup(159);
      const departmentEmployees = await departmentsApi.getDepartmentEmployees();
      const userDepartments = await departmentsApi.getEmployeeDepartment();

      const employeeArMap = {};
      const employeeOpts = departmentEmployees.data?.map((item) => {
        employeeArMap[item.id] = item.name;
        return {
          key: item.id,
          text: item.name,
          value: item.id,
        };
      });

      const departmentArMap = {};
      const departmentOpts = userDepartments.data?.map((item) => {
        departmentArMap[item.id] = item.name;
        return {
          key: item.id,
          text: item.name,
          value: item.id,
        };
      });

      const statusOptArr = statusTypes.data?.map((item) => ({ key: item.id, text: item.name, value: item.id }));

      setDepartmentIdOpts(departmentOpts);
      setDepartmentIdArMap(departmentArMap);
      setEmployeeIdOpts(employeeOpts);
      setEmployeeIdArMap(employeeArMap);
      const statusOptArrCopy = [...statusOptions, ...statusOptArr];
      setStatusOptions(statusOptArrCopy);
    };

    fetch();
  }, []);

  const onStatusSelection = (evt, { value }) => {
    setFormsStatus(value);
  };

  const submitBtnHandler = async () => {
    try {
      const body = {
        id: formId,
        statusId: statusId,
        isUpdatedByManager: isUpdatedByManager,
        note: modalStatusNote,
      };
      setIsSubmitting(true);
      await expensesApi.updateExpenseFormStatus(body);

      setRerenderFlag(true);
      setStatusUpdatePopupVisible(false);
      setIsSubmitting(false);
      setRerenderFlag(false);
      setFormId(null);
      setModalStatusNote('');
      setIsViewModalShown(false);
    } catch (error) {
      alert('حدث خطأ عند تغيير الحالة');
      setStatusUpdatePopupVisible(false);
      setIsSubmitting(false);
      setRerenderFlag(false);
      console.log(error);
    }
  };

  const submitUpdateTeamLeaderAction = async () => {
    try {
      const body = {
        id: formId,
        actionId: actionId,
        note: modalStatusNote,
      };
      setIsSubmitting(true);
      await expensesApi.updateTeamLeaderActionIdForExpensesForm(body);

      setRerenderFlag(true);
      setIsSubmitting(false);
      setRerenderFlag(false);
      setUpdateTeamLeaderActionPopupVisible(false);
    } catch (error) {
      alert('حدث خطأ عند تغيير الحالة');
      setUpdateTeamLeaderActionPopupVisible(false);
      setIsSubmitting(false);
      setRerenderFlag(false);
      console.log(error);
    }
  };

  const handleUpdateTeamLeaderActionStatus = useCallback((row, actionId) => {
    setFormId(row.id);
    setActionId(actionId);
    setUpdateTeamLeaderActionPopupVisible(true);
    setModalStatusNote(
      actionId === systemCodes.teamLeaderActions.approvedByTeamLeader
        ? 'موافق عليها من رئيس الفريق'
        : 'مرفوضة من رئيس الفريق'
    );
  }, []);

  const handleUpdateStatus = useCallback(
    ({ formId, departmentManagerId, statusId }) => {
      setFormId(formId);
      setStatusId(statusId);
      setStatusUpdatePopupVisible(true);
      setIsUpdatedByManager(+departmentManagerId === +currentUser.id);
    },
    [currentUser.id]
  );

  const onCloseUpdateTeamLeaderActionStatusModal = useCallback(() => {
    setModalStatusNote('');
    setUpdateTeamLeaderActionPopupVisible(false);
  }, []);

  const onCloseChangeStatusByAdministrationOrFinical = useCallback(() => {
    setModalStatusNote('');
    setStatusUpdatePopupVisible(false);
  }, []);

  const showStatusModal = useCallback(async (row) => {
    setStatusModalPopupVisible(true);
    try {
      const result = await expensesApi.getExpensesFormAudit(row.id);
      setExpenseStatusContent(result.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onCloseStatusModal = useCallback(() => {
    setStatusModalPopupVisible(false);
    setExpenseStatusContent([]);
  }, []);

  const isAcceptModal =
    statusId === systemCodes.expensesStatus.approvedByAdministration ||
    statusId === systemCodes.expensesStatus.readyToPaidOff;

  return (
    <>
      <PageCardContainer>
        <StatusModal
          isOpen={statusModalPopupVisible}
          onClose={onCloseStatusModal}
          expenseStatusContent={expenseStatusContent}
        />
        <div className='table-title-container merchandise-table-title-container'>
          <div>
            <h3>مستند دفع نفقات متفرقة</h3>
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              onClick={() => router.push('/FinancialServices/AddExpensesForm')}
            >
              <Icon name='plus' className='blue-buton-icon' />
              إضافة مستند جديد
            </Button>
          </div>
          <div className='status-container'>
            <label className='status-lbl'>الحالة</label>
            <Dropdown
              selection
              options={statusOptions}
              value={formsStatus}
              className='table-dropdown'
              onChange={onStatusSelection}
            />
          </div>
        </div>

        <DataTable
          tableTitle='مستندات دفع نفقات متفرقة'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={expensesApi.getAll}
          reloadTrigger={[formsStatus, rerenderFlag]}
          optionalQsParams={{ status: formsStatus }}
        />
      </PageCardContainer>

      <ViewModal isOpen={isViewModalShown} title='عرض النفقات المتفرقة' onClose={() => setIsViewModalShown(false)}>
        <ExpensesForm id={formId} handleUpdateStatus={handleUpdateStatus} />
      </ViewModal>

      <AcceptRejectExpenseModal
        content={
          statusId === systemCodes.expensesStatus.paidOffExpenses
            ? 'هل انت متأكد من صرف المستحقات المالية؟'
            : statusId === systemCodes.expensesStatus.returned
            ? 'هل انت متأكد من إرجاع هذه المعاملة ؟'
            : isAcceptModal
            ? 'هل انت متأكد من قبول طلب المستند؟'
            : 'هل انت متأكد من رفض طلب المستند؟'
        }
        actionTitle={
          isAcceptModal || statusId === systemCodes.expensesStatus.paidOffExpenses
            ? 'قبول'
            : statusId === systemCodes.expensesStatus.returned
            ? 'إرجاع'
            : 'رفض'
        }
        title={
          statusId === systemCodes.expensesStatus.paidOffExpenses
            ? 'صرف المستحقات المالية'
            : statusId === systemCodes.expensesStatus.returned
            ? 'إرجاع المعاملة'
            : isAcceptModal
            ? 'قبول  طلب المستند'
            : 'رفض  طلب المستند'
        }
        isSubmitting={isSubmitting}
        inputValue={modalStatusNote}
        isAcceptModal={isAcceptModal || statusId === systemCodes.expensesStatus.paidOffExpenses}
        isOpen={statusUpdatePopupVisible}
        onSubmit={submitBtnHandler}
        onClose={onCloseChangeStatusByAdministrationOrFinical}
        onInputChange={(data) => setModalStatusNote(data)}
      />

      <AcceptRejectExpenseModal
        content={
          actionId === systemCodes.teamLeaderActions.approvedByTeamLeader
            ? 'هل انت متأكد من قبول طلب المستند؟'
            : 'هل انت متأكد من رفض طلب المستند؟'
        }
        actionTitle={actionId === systemCodes.teamLeaderActions.approvedByTeamLeader ? 'قبول' : 'رفض'}
        title={
          actionId === systemCodes.teamLeaderActions.approvedByTeamLeader ? 'قبول  طلب المستند' : 'رفض  طلب المستند'
        }
        isSubmitting={isSubmitting}
        inputValue={modalStatusNote}
        isAcceptModal={actionId === systemCodes.teamLeaderActions.approvedByTeamLeader}
        isOpen={updateTeamLeaderActionPopupVisible}
        onSubmit={submitUpdateTeamLeaderAction}
        onClose={onCloseUpdateTeamLeaderActionStatusModal}
        onInputChange={(data) => setModalStatusNote(data)}
      />
    </>
  );
};

export default ExpensesFormsList;

import React from 'react';
import _ from 'lodash';
import DataTable from '../../Layout/Table/DataTable';
import { lookupsApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';

export default function LookupsGroups() {
  const tableHeaders = [
    {
      // a field that is used for display only (in arabic)
      displayName: 'اسم مجموعة الرموز',
      // a field that is used as a key (in english camelCase)
      fieldName: 'name',
      // for sort, needs to be snake_case to match db field
      dbFieldName: 'name',
      viewable: true,
      sortable: true,
      searchable: true,
      // either text, dropdown, or date
      searchType: 'text',
      // if the searchType if dropdown, this field contains the dropdown options as key, text, value
      searchValues: '',
      // the dropdown key: value as enName: arName for display in the label
      dropdownArMap: '',
    },
  ];

  const tableActions = [
    {
      // action type: view, delete, or edit
      // view and delete require actionUrl to specify the url to redirect to (supplies an id)
      // delete requires deleteActionApi ex (lookupsApi.delete)
      actionType: 'view',
      actionUrl: '/lookupsDetails',
    },
  ];

  // parentId = int (get children of that parent)
  // no parentId (get parents only)
  const optionalParams = {};

  return (
    <div>
      <PageCardContainer>
        <DataTable
          tableTitle='رموز_النظام_الرئيسية'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={lookupsApi.getAll}
          optionalQsParams={optionalParams}
          reloadTrigger={[]}
        />
      </PageCardContainer>
    </div>
  );
}

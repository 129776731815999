import React from 'react';
import './Emoticon.scss';

const Emoticons = (props) => {
  const { selectedRating } = props;

  let mouth = '';
  switch (selectedRating) {
    case 1:
      mouth = 'shock';
      break;
    case 2:
      mouth = 'sad';
      break;
    case 3:
      mouth = 'flat';
      break;
    case 4:
      mouth = 'smile';
      break;
    case 5:
      mouth = 'happy';
      break;
    default:
      mouth = 'flat';
  }

  return (
    <div className='face'>
      <div className='eye' />
      <div className='mouth'>
        <div className={mouth} />
      </div>
      <div className='eye' />
    </div>
  );
};

export default Emoticons;

import React from 'react';
import { Button, Header, Icon, Modal, Table } from 'semantic-ui-react';
import moment from 'moment';

function StatusModal({ isOpen, onClose, expenseStatusContent }) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Header icon='info' content='الإجراءات' />
      <Modal.Content
        style={{
          height: 'calc(100vh - 200px)',
          overflowY: 'auto',
        }}
      >
        {expenseStatusContent.length !== 0 ? (
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell singleLine>الاسم</Table.HeaderCell>
                <Table.HeaderCell>الحالة</Table.HeaderCell>
                <Table.HeaderCell>التاريخ</Table.HeaderCell>
                <Table.HeaderCell>الملاحظات</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {expenseStatusContent.map((status, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{status.name}</Table.Cell>
                    <Table.Cell>{status.status}</Table.Cell>
                    <Table.Cell>{moment(status.date).format('YYYY/MM/DD HH:mm:ss a')}</Table.Cell>
                    <Table.Cell>{status.note}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        ) : (
          'لا يوجد أي إجراء '
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={() => onClose()}>
          <Icon name='remove' /> إغلاق
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default StatusModal;

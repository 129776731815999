import React from 'react';
import './UserMap.scss';
import userIcon from './../../assets/images/user-icon.png';
import { Image, Popup, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { usersLocationsActionsCreator } from '../../redux/actions/actionCreator';
import { useHistory } from 'react-router';

export default function UserCard({ item }) {
  const dispatch = useDispatch();
  const usersImages = useSelector((state) => state.usersLocations.usersImages);
  const router = useHistory();

  const handleShowOnMap = (e, item) => {
    e.stopPropagation();
    router.push('/users/view', { id: item.userId });
  };

  const playBack = (e) => {
    e.stopPropagation();
    router.push('/playback', { id: item.userId, phone: item.userPhone, name: item.userName });
  };

  return (
    <div
      className='user-card-wrapper'
      onClick={() => dispatch(usersLocationsActionsCreator.usersLocationsSelection({ ...item }))}
    >
      <div className='header'>
        <Image src={usersImages.hasOwnProperty(item.userId) ? usersImages[item.userId] : userIcon} />
        <div>
          <div className='header-info'>
            <div> {item.userName} </div>
          </div>
          <div> {item.departmentName}</div>
          <div>{item.userPhone}</div>
          <div className='gpsDate'>
            <div style={{ direction: 'ltr' }}> {moment(item.gpsDate).format('DD/MM/YYYY, hh:mm:ss A')}</div>
          </div>
        </div>
      </div>
      <div className='show-button'>
        <div className='icon-container' onClick={(e) => playBack(e)}>
          <Popup
            content='عرض نقاط المستخدم'
            position='bottom right'
            trigger={<Icon className='icon-shared-color' name='map marker alternate' onClick={(e) => playBack(e)} />}
          />
        </div>
        <div className='icon-container'>
          <Popup
            content='عرض معلومات المستخدم'
            position='bottom left'
            trigger={
              <Icon
                className='icon-shared-color'
                name='info circle'
                // onClick={(e) => handleShowOnMap(e, item)}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

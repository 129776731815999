import store from '../redux/store/store';

let userPermissions = store.getState().user.user.p;
store.subscribe(() => {
  userPermissions = store.getState().user.user.p;
});

const permissions = {
  VIEW_DASHBOARD: 4,
  VIEW_MAINTENANCE_TICKETS: 5,
  VIEW_MAINTENANCE_REPORTS: 6,
  EDIT_MAINTENANCE_REPORTS: 7,
  EDIT_MAINTENANCE_TICKETS: 8,
  DELETE_MAINTENANCE_REPORT: 9,
  DELETE_TICKET: 10,
  VIEW_USERS: 11,
  CREATE_USER: 12,
  ACTIVATE_NEW_USER: 13,
  EDIT_USER: 14,
  DELETE_USER: 15,
  EDIT_TICKET_STATUS: 16,
  ADD_TICKET_NOTES: 17,
  CREATE_REPORT_FROM_TICKET: 18,
  CREATE_REPORT_WITHOUT_TICKET: 19,
  CREATE_TRACKING_VEHICLE_TICKET: 20,
  CREATE_SYSTEM_TICKET: 21,
  VIEW_CLIENTS: 22,
  EDIT_CLIENT: 23,
  DELETE_CLIENT: 24,
  CREATE_VEHICLE: 25,
  EDIT_VEHICLE: 26,
  DELETE_VEHICLE: 27,
  DISASSOCIATE_TRACKING_VEHICLE: 28,
  CREATE_TRACKING_UNIT: 29,
  EDIT_TRACKING_UNIT: 30,
  DELETE_TRACKING_UNIT: 31,
  CREATE_ROLE: 32,
  EDIT_ROLE: 33,
  DELETE_ROLE: 34,
  CHANGE_USER_ROLE: 35,
  CHANGE_USER_PASSWORD: 36,
  CREATE_LOOKUP: 37,
  EDIT_LOOKUP: 38,
  DELETE_LOOKUP: 39,
  VIEW_ALL_TRACKING_VEHICLES: 40,
  VIEW_ALL_MAINTENANCE_TICKETS: 41,
  VIEW_ALL_MAINTENANCE_REPORTS: 42,
  VIEW_ALL_USERS: 43,
  VIEW_ALL_CLIENTS: 44,
  EDIT_TICKET_STATUS_AFTER_CLOSING: 45,
  ASSOCIATE_TRACKING_VEHICLE: 46,
  CREATE_CLIENT: 47,
  VIEW_VEHICLES: 48,
  VIEW_TRACKING_UNITS: 49,
  VIEW_ROLES: 50,
  VIEW_LOOKUP: 51,
  VIEW_ALL_VEHICLES: 52,
  VIEW_ALL_TRACKING_UNITS: 53,
  VIEW_ALL_ASSET_MAINTENANCE_TICKETS: 55, //	عرض جميع تذاكر صيانة الاجهزة
  VIEW_ASSET_MAINTENANCE_TICKETS: 56, //	عرض تذاكر صيانة الاجهزة
  ADD_ASSET_MAINTENANCE_TICKET_NOTES: 57,
  CREATE_ASSET_MAINTENANCE_TICKETS: 58, //	إضافة تذكرة صيانة اجهزة
  // CREATE_ASSET_MAINTENANCE_REPORT : 59, //	إضافة تقرير صيانة اجهزة
  // VIEW_ALL_ASSET_MAINTENANCE_REPORTS : 60, //	عرض جميع تقارير صيانة الاجهزة
  // VIEW_ASSET_MAINTENANCE_REPORTS : 61, //	عرض تقارير صيانة الاجهزة
  // CREATE_ASSET_MAINTENANCE_REPORT_WITHOUT_TICKET : 62, //	إضافة تقرير صيانة جهاز بدون تذكرة
  // DELETE_ASSET_MAINTENANCE_REPORT : 63, //	حذف تقرير صيانة جهاز
  // EDIT_ASSET_MAINTENANCE_REPORT : 64, //	تعديل تقرير صيانة جهاز
  // DELETE_ASSET_MAINTENANCE_TICKET : 65, //	حذف تذكرة صيانة الاجهزة
  //  : 66, //	عرض جميع تذاكر صيانة اجهزة التتبع
  //  : 67, //	عرض تذاكر صيانة اجهزة التتبع
  //  : 68, //	تعديل تذاكر صيانة اجهزة التتبع
  //  : 69, //	حذف تذكرة صيانة اجهزة التتبع

  ADD_WORKSHOP_MAINTENANCE_TICKETS_NOTES: 71,
  VIEW_ALL_WORKSHOP_MAINTENANCE_TICKETS: 72, //	عرض جميع تذاكر صيانة المشغل
  VIEW_WORKSHOP_MAINTENANCE_TICKETS: 73, //	عرض تذاكر صيانة المشغل
  CREATE_WORKSHOP_MAINTENANCE_TICKETS: 74, //	إضافة تذكرة صيانة المشغل
  EDIT_WORKSHOP_MAINTENANCE_TICKETS: 75, //	تعديل تذكرة صيانة المشغل
  DELETE_WORKSHOP_MAINTENANCE_TICKETS: 76, //	حذف تذكرة صيانة المشغل

  EDIT_ASSET_MAINTENANCE_TICKET: 77, //	تعديل تذكرة صيانة اجهزة
  //  : 78, //	امكانية اسناد تذكرة لفريق
  //  : 79, //	عرض تقارير صيانة المشغل
  //  : 80, //	عرض جميع تقارير صيانة المشغل
  //  : 81, //	حذف تقرير صيانة مشغل
  //  : 82, //	تعديل تقرير صيانة مشغل
  //  : 83, //	إضافة تقرير صيانة مشغل بدون تذكرة
  //  : 84, //	إضافة تقرير صيانة مشغل
  VIEW_DEPARTMENTS: 85, //	عرض الإدارات
  CREATE_DEPARTMENT: 86, //	إضافة ادارة جديدة
  EDIT_DEPARTMENT: 87, //	تعديل الادارات
  DELETE_DEPARTMENT: 88, //	حذف إدارة
  VIEW_TEAMS: 89,
  CREATE_TEAM: 90,
  EDIT_TEAM: 91,
  DELETE_TEAM: 92,
  VIEW_CONTRACTS: 93,
  VIEW_ALL_CONTRACTS: 94,
  CREATE_CONTRACT: 95,
  EDIT_CONTRACT: 96,
  DELETE_CONTRACT: 97,
  CHANGE_TICKET_STATUS_TO_OPEN: 98,
  ASSIGN_TICKET_TO_TEAM: 99,
  EDIT_ASSET: 100,
  DELETE_ASSET: 101,
  VIEW_ASSETS: 102,
  VIEW_ALL_ASSETS: 103,
  CREATE_ASSET: 104,
  ESCALATE_TICKET: 105,
  EDIT_TEAM_ASSIGNMENT_DEFAULTS: 106,
  VIEW_EVALUATIONS: 107,

  //user tracking assets permissions
  VIEW_ALL_USERS_TRACKING_ASSETS: 108,
  VIEW_USERS_TRACKING_ASSETS_BASED_ON_USER_DEPARTMENTS: 115,
  EDIT_USERS_TRACKING_ASSET: 109,
  DELETE_USERS_TRACKING_ASSET: 110,
  CREATE_USERS_TRACKING_ASSET: 111,
  LINK_TRACKING_ASSET_WITH_USER: 112,
  UNLINK_TRACKING_ASSET_FROM_USER: 114,
  VIEW_USERS_TRACKING_MAP: 113,

  VIEW_ALL_MERCHANDISE_DELIVERY_REQUESTS: 116,
  VIEW_MERCHANDISE_DELIVERY_REQUESTS: 122,
  CREATE_MERCHANDISE_DELIVERY_REQUEST: 123,
  EDIT_MERCHANDISE_DELIVERY_REQUEST: 124,
  DELETE_MERCHANDISE_DELIVERY_REQUESTS: 125,
  UPDATE_MERCHANDISE_DELIVERY_REQUEST_STATUS: 127,

  VIEW_PLAYBACK: 128,
  VIEW_MAINTENANCE_TYPES: 129,
  DELETE_MAINTENANCE_TYPES: 130,
  ADD_MAINTENANCE_TYPES: 131,
  EDIT_MAINTENANCE_TYPES: 132,
  EXPORT_STATISTICS: 133,

  CREATE_OTHER_MAINTENANCE_TICKET: 134,

  //financial services permissions
  VIEW_ASSURANCE_FORMS: 135,
  VIEW_ASSURANCE_EDIT_REQUEST_FORMS: 136,
  VIEW_STAMP_RECEIPT_FORMS: 137,
  ADD_EDIT_ASSURANCE_FORMS: 138,
  ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS: 139,
  ADD_EDIT_STAMP_RECEIPT_FORMS: 140,
  CHANGE_ASSURANCE_FORMS_STATUS: 141,
  CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS: 142,
  CHANGE_STAMP_RECEIPT_FORMS_STATUS: 143,
  VIEW_ALL_ASSURANCE_FORMS: 144,
  VIEW_ALL_ASSURANCE_EDIT_FORMS: 145,
  VIEW_ALL_STAMP_RECEIPT_FORMS: 146,

  //Customer Branches permissions
  VIEW_CUSTOMER_BRANCHES: 147,
  EDIT_CUSTOMER_BRANCH: 148,
  DELETE_CUSTOMER_BRANCH: 149,
  ADD_CUSTOMER_BRANCH: 150,

  //SCHEDULE VISITS
  VIEW_ALL_SCHEDULE_VISITS_AND_REPORTS_FOR_ALL_CUSTOMERS: 151,
  VIEW_CUSTOMER_VISITS_SCHEDULE: 152,
  ADD_SCHEDULE_VISIT: 154,
  DELETE_SCHEDULE_VISIT: 155,
  ADD_VISIT: 156,
  EDIT_VISIT_DATE: 157,
  ADD_VISIT_REPORT: 159,
  VIEW_VISIT_REPORT: 160,
  DELETE_VISIT: 161,
  DELETE_VISIT_REPORT: 163,
  EDIT_VISIT_REPORT: 164,
  EXPORT_SCHEDULE_STATISTICS: 165,
  ASSIGN_VISIT: 166,

  //expenses Form
  ADD_EXPENSES_FORM: 167,
  REJECT_APPROVE_EXPENSES_FORM: 168,
  EDIT_EXPENSE_FORM_AFTER_APPROVE: 169,
  ADD_TRANSPORT_EXPENSES_FORM: 170,
  REJECT_APPROVE_TRANSPORT_EXPENSES_FORM: 171,
  EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE: 172,
  PAYMENT_OF_FINANCIAL_DUES: 173,
  REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL: 174,

  //Visits Permissions
  CLOSE_VISIT_WITHOUT_REPORT: 175,

  UNLOCK_USER : 176,
  FORCE_ALL_USERS_TO_CHANGE_PASSWORD: 177,
  FORCE_ONE_USER_TO_CHANGE_PASSWORD: 178
};

/**
 * @param {number} perm Permission to validate against
 * @param {object} obj (object|{}) to return instead of true|false
 * @returns {any} (object if provided or True) if all provided permissions are satisfied
 */
const hasPermission = (perm, obj = null) => {
  return userPermissions.has(perm) ? (Boolean(obj) ? obj : true) : Boolean(obj) ? {} : false;
};

/**
 * @param {number[]} perms Permissions to validate against
 * @param {object} obj (object|{}) to return instead of true|false
 * @returns {any} (object if provided or True) if any provided permission is satisfied
 */
const hasPermissions = (perms, obj = null) => {
  for (let i = 0; i < perms.length; i++) {
    if (userPermissions.has(perms[i])) {
      if (obj) return obj;
      return true;
    }
  }
  if (obj) return {};
  return false;
};

export { hasPermission, hasPermissions };
export default permissions;

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Menu, Image, Responsive, Sidebar, Popup } from 'semantic-ui-react';
import logo from './../../assets/images/logo.png';
import UserBadge from './UserBadge';
import PermissionCheck from '../Reusable/PermissionCheck';
import p from '../../helpers/permissions';

export default function SidebarContent(props) {
  const location = useLocation();
  const router = useHistory();

  const [activeItem, setActiveItem] = useState(location.pathname.split('/')[1] || 'home');
  const [visibleNav, setVisibleNav] = useState(false);

  const handleItemClick = (e, { name }) => {
    if (name !== activeItem) {
      setActiveItem(name);
    }
    router.push(`/${name}`);
    setVisibleNav(false);
  };

  const verticalMenu = () => {
    return (
      <>
        <Menu icon='labeled' vertical className='borderless'>
          <Menu.Header className='menu-header'>
            <Image src={logo} alt='logo' size='tiny' />
          </Menu.Header>
          <PermissionCheck permissions={p.VIEW_DASHBOARD}>
            <Menu.Item name='dashboard' active={activeItem === 'dashboard'} onClick={handleItemClick}>
              <Icon name='chart pie' />
              الرئيسية
            </Menu.Item>
          </PermissionCheck>
          <Menu.Item name='home' active={activeItem === 'home'} onClick={handleItemClick}>
            <Icon name='home' />
            القائمة الرئيسية
          </Menu.Item>
          <PermissionCheck
            permissions={[
              p.CREATE_CLIENT,
              p.VIEW_CLIENTS,
              p.EDIT_CLIENT,
              p.DELETE_CLIENT,
              p.ACTIVATE_NEW_USER,
              p.CREATE_USER,
              p.DELETE_USER,
              p.EDIT_USER,
              p.CHANGE_USER_PASSWORD,
              p.VIEW_VEHICLES,
              p.EDIT_VEHICLE,
              p.CREATE_VEHICLE,
              p.DELETE_VEHICLE,
              p.VIEW_ALL_VEHICLES,
              p.CREATE_TRACKING_UNIT,
              p.EDIT_TRACKING_UNIT,
              p.VIEW_TRACKING_UNITS,
              p.DELETE_TRACKING_UNIT,
              p.VIEW_ALL_TRACKING_UNITS,
              p.VIEW_ROLES,
              p.EDIT_ROLE,
              p.CREATE_ROLE,
              p.DELETE_ROLE,
              p.VIEW_LOOKUP,
              p.EDIT_LOOKUP,
              p.CREATE_LOOKUP,
              p.DELETE_LOOKUP,
              p.VIEW_DEPARTMENTS,
              p.EDIT_DEPARTMENT,
              p.CREATE_DEPARTMENT,
              p.DELETE_DEPARTMENT,
              p.VIEW_TEAMS,
              p.EDIT_TEAM,
              p.CREATE_TEAM,
              p.DELETE_TEAM,
              p.VIEW_CONTRACTS,
              p.VIEW_ALL_CONTRACTS,
              p.EDIT_CONTRACT,
              p.CREATE_CONTRACT,
              p.DELETE_CONTRACT,
              p.VIEW_ALL_ASSETS,
              p.VIEW_ASSETS,
              p.EDIT_TEAM_ASSIGNMENT_DEFAULTS,
              p.VIEW_ALL_USERS_TRACKING_ASSETS,
              p.VIEW_USERS_TRACKING_ASSETS_BASED_ON_USER_DEPARTMENTS,
              p.EDIT_USERS_TRACKING_ASSET,
              p.DELETE_USERS_TRACKING_ASSET,
              p.CREATE_USERS_TRACKING_ASSET,
              p.LINK_TRACKING_ASSET_WITH_USER,
              p.UNLINK_TRACKING_ASSET_FROM_USER,
              p.VIEW_ASSURANCE_FORMS,
              p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
              p.VIEW_STAMP_RECEIPT_FORMS,
              p.ADD_EDIT_ASSURANCE_FORMS,
              p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
              p.ADD_EDIT_STAMP_RECEIPT_FORMS,
              p.CHANGE_ASSURANCE_FORMS_STATUS,
              p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
              p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
            ]}
          >
            <Menu.Item name='settings' active={activeItem === 'settings'} onClick={handleItemClick}>
              <Icon name='cogs' />
              الإعدادات
            </Menu.Item>
          </PermissionCheck>
          <PermissionCheck permissions={[p.VIEW_USERS_TRACKING_MAP]}>
            <Menu.Item name='usersMap' active={activeItem === 'usersMap'} onClick={handleItemClick}>
              <Icon name='map' />
              خريطة تتبع المستخدمين
            </Menu.Item>
          </PermissionCheck>
        </Menu>
      </>
    );
  };

  const verticalMenuSlim = () => {
    return (
      <>
        <div className='vertical-slim'>
          <Menu icon='labeled' vertical className='borderless'>
            <Menu.Header className='menu-header'>
              <Image src={logo} alt='logo' size='tiny' />
            </Menu.Header>
            <PermissionCheck permissions={p.VIEW_DASHBOARD}>
              <Popup
                content='لوحة التحكم'
                trigger={
                  <Menu.Item name='dashboard' active={activeItem === 'dashboard'} onClick={handleItemClick}>
                    <Icon name='chart pie' />
                  </Menu.Item>
                }
                position='left center'
              />
            </PermissionCheck>
            <Popup
              content='القائمة الرئيسية'
              trigger={
                <Menu.Item name='home' active={activeItem === 'home'} onClick={handleItemClick}>
                  <Icon name='home' />
                </Menu.Item>
              }
              position='left center'
            />
            <PermissionCheck
              permissions={[
                p.CREATE_CLIENT,
                p.VIEW_CLIENTS,
                p.EDIT_CLIENT,
                p.DELETE_CLIENT,
                p.ACTIVATE_NEW_USER,
                p.CREATE_USER,
                p.DELETE_USER,
                p.EDIT_USER,
                p.CHANGE_USER_PASSWORD,
                p.VIEW_VEHICLES,
                p.EDIT_VEHICLE,
                p.CREATE_VEHICLE,
                p.DELETE_VEHICLE,
                p.VIEW_ALL_VEHICLES,
                p.CREATE_TRACKING_UNIT,
                p.EDIT_TRACKING_UNIT,
                p.VIEW_TRACKING_UNITS,
                p.DELETE_TRACKING_UNIT,
                p.VIEW_ALL_TRACKING_UNITS,
                p.VIEW_ROLES,
                p.EDIT_ROLE,
                p.CREATE_ROLE,
                p.DELETE_ROLE,
                p.VIEW_LOOKUP,
                p.EDIT_LOOKUP,
                p.CREATE_LOOKUP,
                p.DELETE_LOOKUP,
                p.VIEW_DEPARTMENTS,
                p.EDIT_DEPARTMENT,
                p.CREATE_DEPARTMENT,
                p.DELETE_DEPARTMENT,
                p.VIEW_TEAMS,
                p.EDIT_TEAM,
                p.CREATE_TEAM,
                p.DELETE_TEAM,
                p.VIEW_CONTRACTS,
                p.VIEW_ALL_CONTRACTS,
                p.EDIT_CONTRACT,
                p.CREATE_CONTRACT,
                p.DELETE_CONTRACT,
                p.VIEW_ALL_ASSETS,
                p.VIEW_ASSETS,
                p.EDIT_TEAM_ASSIGNMENT_DEFAULTS,
                p.VIEW_ALL_USERS_TRACKING_ASSETS,
                p.VIEW_USERS_TRACKING_ASSETS_BASED_ON_USER_DEPARTMENTS,
                p.EDIT_USERS_TRACKING_ASSET,
                p.DELETE_USERS_TRACKING_ASSET,
                p.CREATE_USERS_TRACKING_ASSET,
                p.LINK_TRACKING_ASSET_WITH_USER,
                p.UNLINK_TRACKING_ASSET_FROM_USER,
                p.VIEW_ASSURANCE_FORMS,
                p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
                p.VIEW_STAMP_RECEIPT_FORMS,
                p.ADD_EDIT_ASSURANCE_FORMS,
                p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
                p.ADD_EDIT_STAMP_RECEIPT_FORMS,
                p.CHANGE_ASSURANCE_FORMS_STATUS,
                p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
                p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
                p.VIEW_ALL_ASSURANCE_FORMS,
                p.VIEW_ALL_ASSURANCE_EDIT_FORMS,
                p.VIEW_ALL_STAMP_RECEIPT_FORMS,
              ]}
            >
              <Popup
                content='الإعدادات'
                trigger={
                  <Menu.Item name='settings' active={activeItem === 'settings'} onClick={handleItemClick}>
                    <Icon name='cogs' />
                  </Menu.Item>
                }
                position='left center'
              />
            </PermissionCheck>
            <PermissionCheck permissions={[p.VIEW_USERS_TRACKING_MAP]}>
              <Popup
                content=' خريطة تتبع المستخدمين'
                trigger={
                  <Menu.Item name='usersMap' active={activeItem === 'usersMap'} onClick={handleItemClick}>
                    <Icon name='map' />
                  </Menu.Item>
                }
                position='left center'
              />
            </PermissionCheck>
          </Menu>
        </div>
      </>
    );
  };

  const topMenu = () => {
    return (
      <Menu inverted style={{ height: '60px', marginBottom: '0' }}>
        <Menu.Item position='left'>
          <UserBadge />
        </Menu.Item>
        <Menu.Item className='menu-header' position='right'>
          <Image src={logo} alt='logo' size='tiny' style={{ height: '60px', width: '60px' }} />
        </Menu.Item>
        <Menu.Item onClick={() => setVisibleNav(!visibleNav)}>
          <Icon name='sidebar' size='large' />
        </Menu.Item>
      </Menu>
    );
  };

  const horizontalMenu = (props) => {
    return (
      <Sidebar.Pushable>
        <Sidebar as={Menu} animation='overlay' icon='labeled' inverted vertical visible={visibleNav} direction='right'>
          <div className='vertical-menu'>{verticalMenu()}</div>
        </Sidebar>

        <Sidebar.Pusher
          dimmed={visibleNav}
          onClick={() => {
            if (visibleNav) setVisibleNav(false);
          }}
          style={{ minHeight: '100vh' }}
        >
          <div className='top-menu'>{topMenu()}</div>
          {props}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  };

  return (
    <>
      <Responsive maxWidth={767}>{horizontalMenu(props.children)}</Responsive>
      <Responsive minWidth={768}>{verticalMenuSlim()}</Responsive>
    </>
  );
}

import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import './AcceptRejectExpenseModal.scss';
export const AcceptRejectExpenseModal = ({
  onClose,
  onSubmit,
  isSubmitting,
  onInputChange,
  inputValue,
  isOpen,
  isAcceptModal,
  content,
  title,
  actionTitle,
  withInput = true,
}) => {
  return (
    <Modal onClose={onClose} open={isOpen} size={'tiny'} className='accept-reject-expense-modal'>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <div className='flex-column-gap-1'>
          <div>{content}</div>
          {withInput && (
            <div className='flex-input '>
              <span className='bold-1'>الملاحظة : </span>
              <Form.Input className='rtl-input' value={inputValue} onChange={(e) => onInputChange(e.target.value)} />
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            icon
            type='submit'
            loading={isSubmitting}
            disabled={isSubmitting}
            className={isAcceptModal ? 'green-button' : 'red-button'}
            labelPosition='right'
            style={{ marginLeft: '1rem' }}
            onClick={onSubmit}
          >
            <Icon
              name={isAcceptModal ? 'check' : 'delete'}
              className={isAcceptModal ? 'green-buton-icon' : 'red-buton-icon'}
            />
            {actionTitle}
          </Button>

          <Button icon='chevron right' labelPosition='right' content='الغاء' onClick={onClose} />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

import React from 'react';
import './FinancialServicesCards.scss';
import { Card } from 'semantic-ui-react';
import SettingsCard from '../Settings/SettingsList/SettingsCard';
import { motion } from 'framer-motion';
import PermissionCheck from '../Reusable/PermissionCheck';
import p from '../../helpers/permissions';

const FinancialServicesCards = () => {
  return (
    <div className='financial-services-cards-container'>
      <Card.Group className='settings-card-group'>
        <PermissionCheck
          permissions={[
            p.VIEW_ASSURANCE_FORMS,
            p.ADD_EDIT_ASSURANCE_FORMS,
            p.CHANGE_ASSURANCE_FORMS_STATUS,
            p.VIEW_ALL_ASSURANCE_FORMS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='نموذج طلب كفالة'
              iconName='clipboard'
              url='/FinancialServices/ViewAssuranceFormsList'
            />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
            p.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
            p.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS,
            p.VIEW_ALL_ASSURANCE_EDIT_FORMS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='نموذج تعديل كفالة'
              iconName='pen square'
              url='/FinancialServices/AssuranceEditFormList'
            />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_STAMP_RECEIPT_FORMS,
            p.ADD_EDIT_STAMP_RECEIPT_FORMS,
            p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
            p.VIEW_ALL_STAMP_RECEIPT_FORMS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='نموذج طلب دفع طوابع'
              iconName='envelope open'
              url='/FinancialServices/stampReceiptList'
            />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.VIEW_STAMP_RECEIPT_FORMS,
            p.ADD_EDIT_STAMP_RECEIPT_FORMS,
            p.CHANGE_STAMP_RECEIPT_FORMS_STATUS,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard title='فرق المالية' iconName='users' url='/FinancialServices/settings' />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.ADD_EXPENSES_FORM,
            p.REJECT_APPROVE_EXPENSES_FORM,
            p.EDIT_EXPENSE_FORM_AFTER_APPROVE,
            p.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL,
            p.PAYMENT_OF_FINANCIAL_DUES,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='نموذج دفع نفقات متفرقة'
              iconName='pen square'
              url='/FinancialServices/ExpensesFormsList'
            />
          </motion.div>
        </PermissionCheck>
        <PermissionCheck
          permissions={[
            p.ADD_TRANSPORT_EXPENSES_FORM,
            p.REJECT_APPROVE_TRANSPORT_EXPENSES_FORM,
            p.EDIT_TRANSPORT_EXPENSE_FORM_AFTER_APPROVE,
            p.REJECT_APPROVE_EXPENSES_FORM_BY_FINANCIAL,
            p.PAYMENT_OF_FINANCIAL_DUES,
          ]}
        >
          <motion.div
            className='motion-card'
            animate={{ x: 0 }}
            transition={{ duration: 0.4, type: 'spring', stiffness: 120 }}
            initial={{ x: 100 }}
          >
            <SettingsCard
              title='نموذج دفع بدل مواصلات '
              iconName='pen square'
              url='/FinancialServices/TransportExpensesFormsList'
            />
          </motion.div>
        </PermissionCheck>
      </Card.Group>
    </div>
  );
};

export default FinancialServicesCards;

import React, { useEffect, useState } from 'react';
import { customersApi, lookupsApi, rolesApi, usersApi } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import PageCardContainer from '../../Layout/PageCardContainer';
import { Icon, Image, Table, Loader, Checkbox, List } from 'semantic-ui-react';
import userImage from './../../../assets/images/user-icon.png';
import './Users.scss';

export default function ViewUserDetails() {
  const location = useLocation();
  const { id } = location.state;

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await usersApi.viewOneUser(id);
      const jobName = await lookupsApi.getOne(res.data.jobTitle);
      const roleName = await rolesApi.getOneRole(res.data.role);

      setUserData({
        ...res.data,
        jobTitle: jobName.data.name,
        role: roleName.data.name,
      });
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div className='user-details'>
            <div className='user-card'>
              <div className='header-img'>
                <div className='image-cropper'>
                  <Image src={userData.image ? userData.image : userImage} className='img' centered />
                </div>
              </div>

              <div className='contents'>
                <h2>{userData.fullName}</h2>
                <hr />

                <Table basic='very' collapsing unstackable>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='user circle' /> اسم المستخدم:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{userData.userName}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='mail' /> الايميل:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{userData.email}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='phone' /> الهاتف:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{userData.phone}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='address card' /> المسمى الوظيفي:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{userData.jobTitle}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='list alternate' /> الصلاحيات:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{userData.role}</b>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell verticalAlign='top'>
                        <span style={{ marginLeft: '1rem' }}>
                          <Icon name='users' /> العملاء الفرعيون:
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        {userData.customerIds && !userData.customerIds.includes(null) ? (
                          <List>
                            {userData.customerIds.map((customer) => {
                              return (
                                <List.Item>
                                  <b>{customer}</b>
                                </List.Item>
                              );
                            })}
                          </List>
                        ) : (
                          <b>لا يوجد</b>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
}

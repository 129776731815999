import React, { useEffect } from 'react';
import Sidebar from './SidebarContent';
import './Sidebar.scss';
import { Responsive } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import TopNavbar from './TopNavbar';

export default function SidebarPage(props) {
  const router = useHistory();
  useEffect(() => {
    // const userAuth = sessionStorage.getItem('user');
    // if (!userAuth) router.replace('/login');
  }, []);

  return (
    <>
      <Responsive maxWidth={767}>
        <div className='sidebar-content'>
          <div className='sidebar-page'>
            <Sidebar>
              <div className='page-contents'>{props.children}</div>
            </Sidebar>
          </div>
        </div>
      </Responsive>
      <Responsive minWidth={768}>
        <div className='sidebar-content'>
          <div className='sidebar-page'>
            <Sidebar />
          </div>
          <div className='page-contents-nav'>
            <TopNavbar />
            <div className='page-contents'>{props.children}</div>
          </div>
        </div>
      </Responsive>
    </>
  );
}

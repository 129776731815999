import React from 'react';
import TicketsSideMenu from './TicketsSideMenu';
import { SysTicketDetailsView } from '../SystemMaintenanceTickets/SysTicketDetailsView';

const CustomerTicketsMobile = ({
  loading,
  fetchData,
  data,
  total,
  onTicketSelected,
  handleDeleteTicket,
  onTicketStatusChanged,
  exportFileHandler,
  selectedTicket,
  onTicketAssigned,
  setDateFilter,
  exportFileStatisticsHandler,
  excelLoading,
}) => {
  return (
    <div className='customer-tickets-container'>
      {!selectedTicket ? (
        <TicketsSideMenu
          data={data}
          total={total}
          exportFileHandler={exportFileHandler}
          fetchData={fetchData}
          loading={loading}
          setDateFilter={setDateFilter}
          exportFileStatisticsHandler={exportFileStatisticsHandler}
          onTicketSelected={onTicketSelected}
          excelLoading={excelLoading}
        />
      ) : (
        <div className='ticket-details'>
          <SysTicketDetailsView
            onDelete={() => handleDeleteTicket()}
            onStatusChanged={onTicketStatusChanged}
            key={selectedTicket.id}
            ticket={selectedTicket}
            onTicketSelected={onTicketSelected}
            onTicketAssigned={onTicketAssigned}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerTicketsMobile;

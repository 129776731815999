import { containsXY } from 'ol/extent';
import { getTransform, fromLonLat } from 'ol/proj';
import { LineString, Point } from 'ol/geom';
import Feature from 'ol/Feature';
import 'ol/ol.css';
import { Fill, Stroke, Style, Circle } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';

const WGS2MAP = getTransform('EPSG:4326', 'EPSG:3857');

export const setMarkerPosition = (position) => {
  return new Feature({
    geometry: new Point(position),
    type: 'geoMarker',
  });
};

export const moveTechSelectedLocation = (geometry, coordinates, map) => {
  if (geometry && coordinates.length > 0) {
    console.log(coordinates);
    const coords = WGS2MAP(coordinates);
    geometry.setCoordinates(coords);

    const mapExtent = map.getView().calculateExtent(map.getSize());
    const isInViewport = containsXY(mapExtent, coords[0], coords[1]);
    if (!isInViewport) {
      //recenter on point if it's outside the current viewport
      map.getView().animate({
        center: fromLonLat([coordinates[0], coordinates[1]]),
        duration: 1500,
      });
    }
  }
};

export const drawRoute = (currPath, locationSelectedGeometry, map) => {
  if (currPath.length > 0) {
    var route = new LineString(currPath).transform('EPSG:4326', 'EPSG:3857');

    const styles = {
      route: new Style({
        stroke: new Stroke({
          width: 3,
          color: 'rgba(0, 50, 100, 0.9)',
        }),
      }),
      start: new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({ color: 'limegreen' }),
          stroke: new Stroke({
            color: 'green',
            width: 5,
          }),
        }),
      }),
      end: new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({ color: '#ff5050' }),
          stroke: new Stroke({
            color: 'crimson',
            width: 5,
          }),
        }),
      }),

      geoMarker: new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({
            color: 'white',
            width: 5,
          }),
        }),
      }),
    };

    const feature = new Feature({
      geometry: route,
      type: 'route',
    });

    const startMarker = new Feature({
      geometry: new Point(WGS2MAP(currPath[0])),
      type: 'start',
    });

    const endMarker = new Feature({
      geometry: new Point(WGS2MAP(currPath[currPath.length - 1])),
      type: 'end',
    });

    const marker = setMarkerPosition(WGS2MAP(currPath[0]));

    const vectorLayer = new VectorLayer({
      name: 'techPlaybackRoute',
      source: new VectorSource({
        features: [feature],
      }),
      style: function (feature) {
        return styles[feature.get('type')];
      },
    });
    const markerVectorLayer = new VectorLayer({
      name: 'techPlaybackMarker',
      source: new VectorSource({
        features: [startMarker, endMarker, marker],
      }),
      style: function (feature) {
        return styles[feature.get('type')];
      },
    });

    const features = markerVectorLayer.getSource().getFeatures();
    locationSelectedGeometry.current = features[2].getGeometry();

    map.getView().fit(vectorLayer.getSource().getExtent(), { duration: 500 });
    map.addLayer(vectorLayer);
    map.addLayer(markerVectorLayer);
  } else {
    // setIsError(true);
    // setErrorMessage('حدث خطأ يرجى المحاولة لاحقا');
    // setTechMovePointsLocations([]);
    // setIsLoading(false);
    return -1;
  }
};

export const cleanMap = (map) => {
  const mapLayers = map.getLayers().getArray();
  mapLayers.forEach((layer) => {
    if (layer instanceof VectorLayer) {
      layer.getSource().clear();
      map.removeLayer(layer);
    }
  });
};

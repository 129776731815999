import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Image, Segment, Popup, Loader } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { merchandiseDeliveryRequestsApi, filesApi } from './../../../api/api';
import moment from 'moment';

export default function ViewMerchandiseDeliveryRequestDetails() {
  const location = useLocation();
  const { id } = location.state;

  const [merchandiseDetails, setMerchandiseDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const [attachmentPaths, setAttachmentPaths] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const loadTicketAttachments = async (id) => {
    const paths = await merchandiseDeliveryRequestsApi.getAttachmentPaths(id);
    setAttachmentPaths(paths.data);
  };

  useEffect(() => {
    loadTicketAttachments(id);
  }, []);

  const downloadFile = async (fileName) => {
    if (downloading) return;
    setDownloading(true);
    await filesApi.downloadMerchandiseFile(fileName);
    setDownloading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data: res } = await merchandiseDeliveryRequestsApi.getViewOneMerchandiseDeliveryRequest(id);
      setMerchandiseDetails(res);
      setLoading(false);
    };

    fetchData();
  }, []);

  const DataBox = ({ title, icon = null, data, ltr = false, image = false, bordered = true, padded = true }) => {
    return (
      <div className={`data-box ${!bordered ? 'no-border' : ''} ${!padded ? 'no-padding' : ''}`}>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        {image ? (
          <div className='image-margin image-preview'>
            <div className='maintenance-form-img-preview'>
              <Image src={data} className='img' centered />
            </div>
          </div>
        ) : (
          <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data ? data : 'لا توجد بيانات'}</h5>
        )}
      </div>
    );
  };
  const AttachmentDataBox = ({ title, icon = null, data, ltr = false }) => {
    return (
      <div className='data-box-att'>
        <div className='title-container-att'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title-att'>{title}</h5>
        </div>
        <h5 className={`data-att ${ltr ? 'ltr' : ''}`}>{data}</h5>
      </div>
    );
  };
  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div style={{ margin: '1rem 0.7rem' }}>
            <div className='header-wrapper'>
              <div>
                <h3>طلب توصيل بضاعة رقم #{merchandiseDetails.id}</h3>
                <DataBox title='منشيء الطلب' icon='user circle' data={merchandiseDetails.createdBy} />
              </div>
              <Popup
                content='طباعة'
                trigger={
                  <Button
                    className='blue-button height-50'
                    onClick={() => window.open(`print/?id=${merchandiseDetails.id}`, '_blank')}
                  >
                    <Icon name='print' />
                  </Button>
                }
                position='left center'
              />
            </div>

            <div className='segments-wrapper'>
              <div className='single-segment-wrapper'>
                <h3>معلومات الطلب</h3>
                <Segment>
                  <div className='form-columns-wrapper'>
                    <DataBox title='اسم مندوب المبيعات' icon='user' data={merchandiseDetails.salesRepresentativeName} />

                    <DataBox
                      title='تاريخ إنشاء الطلب'
                      icon='calendar'
                      ltr
                      data={moment(merchandiseDetails.createdAt).format('YYYY/MM/DD, hh:mm A')}
                    />

                    <DataBox title='حالة الطلب' icon='tag' data={merchandiseDetails.status} />
                    <DataBox
                      title='آخر تعديل على حالة الطلب بواسطة'
                      icon='user'
                      data={merchandiseDetails.statusChangedBy}
                    />

                    <DataBox
                      title='تاريخ آخر تعديل على حالة الطلب'
                      icon='clock outline'
                      ltr
                      data={moment(merchandiseDetails.statusChangeDate).format('YYYY/MM/DD, hh:mm A')}
                    />
                  </div>
                </Segment>
              </div>

              <div className='single-segment-wrapper full-width'>
                <h3>معلومات العميل و البضاعة</h3>
                <div className='two-columns'>
                  <Segment>
                    <DataBox title='اسم العميل' icon='user' data={merchandiseDetails.customer} />
                    <DataBox
                      title='تاريخ التسليم المطلوب لدى العميل'
                      icon='clock'
                      ltr
                      data={moment(merchandiseDetails.customerDeliveryDate).format('YYYY/MM/DD')}
                    />
                  </Segment>
                  <Segment>
                    <DataBox title='اسم المستلم' icon='users' data={merchandiseDetails.personToCallName} />
                    <DataBox title='هاتف المستلم' icon='call' data={merchandiseDetails.personToCallPhone} />
                    <DataBox title='الموقع' icon='map pin' data={merchandiseDetails.location} />
                  </Segment>
                  <Segment>
                    <DataBox
                      title='المواد المطلوب تسليمها'
                      icon='boxes'
                      data={merchandiseDetails.deliverableMerchandise}
                    />
                    <DataBox title='وصف المهمة المطلوبة' icon='book' data={merchandiseDetails.description} />
                  </Segment>
                </div>
              </div>
              {attachmentPaths.length > 0 && (
                <div className='single-segment-wrapper full-width'>
                  <div className='title-container-att'>
                    <Icon name='attach' size='small' />
                    <h3 className='title-att'>المرفقات</h3>
                  </div>
                  <div className='two-columns'>
                    <AttachmentDataBox
                      data={
                        <div style={{ direction: 'ltr' }}>
                          {attachmentPaths.map((fileName) => (
                            <div
                              style={downloading ? { color: 'lightgray' } : {}}
                              className='attachment-path-att'
                              onClick={() => downloadFile(fileName)}
                            >
                              {fileName}
                            </div>
                          ))}
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import '../../Tickets/MaintenanceForm/PrintMaintenanceForm.scss';
import { Icon, Image, Table } from 'semantic-ui-react';
import whiteLogo from '../../../assets/images/logo-white.jpg';
import moment from 'moment';
import { assuranceFormApi } from '../../../api/api';

export default function PrintAssuranceForm() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  const [formData, setFormData] = useState({});

  const currancyLookup = {
    JOD: 'دينار',
    USD: 'دولار',
    EUR: 'يورو',
  };

  useEffect(() => {
    (async () => {
      const res = await assuranceFormApi.getPrintAssuranceForm(id);
      setFormData(res.data);
    })();
  }, [id]);

  return (
    <div className='print-container'>
      <div className='header'>
        <div className='header-logo'>
          <Image src={whiteLogo} alt='logo' size='tiny' centered />
        </div>
        <div className='header-title'>الشركة العامة للحاسبات والالكترونيات</div>
      </div>

      <div className='contents'>
        <h3>نموذج طلب كفالة رقم #{formData.assuranceNumber}</h3>

        <div className='segment'>
          <h4>
            <Icon name='book' /> &nbsp; معلومات الطلب
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>تاريخ إنشاء الطلب من النظام</Table.HeaderCell>
                <Table.HeaderCell width={2}>تاريخ إنشاء الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>منشيء الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>حالة الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>نوع الطلب</Table.HeaderCell>
                <Table.HeaderCell width={2}>القسم</Table.HeaderCell>
                <Table.HeaderCell width={2}>الملاحظات</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell className='ltr'>
                  {moment(formData.submitionDateFromSystem).format('YYYY-MM-DD, hh:mm A')}
                </Table.Cell>
                <Table.Cell className='ltr'>{moment(formData.submitionDate).format('YYYY-MM-DD, hh:mm A')}</Table.Cell>
                <Table.Cell>{formData.createdBy}</Table.Cell>
                <Table.Cell>{formData.status}</Table.Cell>
                <Table.Cell>{formData.assuranceType}</Table.Cell>
                <Table.Cell>{formData.departmentName}</Table.Cell>
                <Table.Cell>{formData.notes}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='sticky note' /> &nbsp; معلومات المستفيد
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>المستفيد</Table.HeaderCell>
                <Table.HeaderCell>العنوان</Table.HeaderCell>
                <Table.HeaderCell>الهاتف</Table.HeaderCell>
                <Table.HeaderCell>فاكس</Table.HeaderCell>
                <Table.HeaderCell>ص.ب</Table.HeaderCell>
                <Table.HeaderCell>الرمز البريدي</Table.HeaderCell>
                <Table.HeaderCell>الدولة</Table.HeaderCell>
                <Table.HeaderCell>المدينة</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.customer}</Table.Cell>
                <Table.Cell>{formData.address}</Table.Cell>
                <Table.Cell>{formData.phoneNumber}</Table.Cell>
                <Table.Cell>{formData.fax}</Table.Cell>
                <Table.Cell>{formData.poBox}</Table.Cell>
                <Table.Cell>{formData.postalCode}</Table.Cell>
                <Table.Cell>{formData.country ? formData.country : 'لا يوجد'}</Table.Cell>
                <Table.Cell>{formData.city ? formData.city : 'لا يوجد'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className='segment'>
          <h4>
            <Icon name='cogs' /> &nbsp; معلومات الكفالة
          </h4>
          <Table compact unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>رقم الكفالة</Table.HeaderCell>
                <Table.HeaderCell>قيمة الكفالة رقما</Table.HeaderCell>
                <Table.HeaderCell>قيمة الكفالة تفقيط</Table.HeaderCell>
                <Table.HeaderCell>العملة</Table.HeaderCell>
                <Table.HeaderCell>بداية الكفالة</Table.HeaderCell>
                <Table.HeaderCell>نهاية الكفالة</Table.HeaderCell>
                <Table.HeaderCell width={2}>المواد المقدمة</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{formData.assuranceNumber}</Table.Cell>
                <Table.Cell>{formData.assuranceAmountNumbers}</Table.Cell>
                <Table.Cell>{formData.assuranceAmountWritten}</Table.Cell>
                <Table.Cell>{currancyLookup[formData.currancy]}</Table.Cell>
                <Table.Cell>{moment(formData.assuranceFromDate).format('YYYY-MM-DD')}</Table.Cell>
                <Table.Cell>{moment(formData.assuranceToDate).format('YYYY-MM-DD')}</Table.Cell>
                <Table.Cell>{formData.offeredMaterial}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import './TicketCommentMessages.scss';
import userIcon from './../../../assets/images/user-icon.png';
import { usersApi } from '../../../api/api';

// TODO: Find a way to keep these lookups consistant across deployments
const typeColors = {
  28: { color: 'grey', icon: 'send' }, // note
  29: { color: 'grey', icon: 'edit outline' }, // edit
  30: { color: 'grey', icon: 'check circle outline' }, // Close
  39: { color: 'grey', icon: 'trash alternate' }, // delete
  95: { color: 'grey', icon: 'users' }, // assign
  96: { color: 'grey', icon: 'users' }, // unassign
  97: { color: 'grey', icon: 'bullhorn' }, // escalate
};

const TicketCommentMessages = ({ comments }) => {
  const [userImages, setUserImages] = useState();
  useEffect(() => {
    (async () => {
      let temp = {};
      for (let item of comments) {
        if (!temp.hasOwnProperty(item.createdById)) {
          let { data: res } = await usersApi.getImageById(item.createdById);
          temp[item.createdById] = res;
        }
      }
      setUserImages(temp);
    })();
  }, [comments]);

  return (
    <div className='ticket-comments-list'>
      <h4 className='list-title'>+ الملاحظات والاجراءات</h4>
      <div className='overflow-container'>
        {comments.map((ticketComment, i) => (
          <div key={ticketComment.id} className='ticket-comments-list-item'>
            <div className='chat-image-container'>
              <img
                className='profile-img'
                src={userImages[ticketComment.createdById] ? userImages[ticketComment.createdById] : userIcon}
                alt=''
              />
              <div className='vertical-line' />
            </div>
            <div className='comment-content'>
              <div className='comment-header'>
                <div>
                  <div className='user-name-action-container'>
                    <h5 className='user-name'>{ticketComment.createdBy}</h5>
                    <p className='action-type'>{'قام ب' + ticketComment.typeName}</p>
                  </div>
                  <p className='time'>{moment(ticketComment.createdAt).format('YYYY/MM/DD, hh:mm A')}</p>
                </div>
                <div>
                  <Icon name={typeColors[ticketComment.type].icon} color={typeColors[ticketComment.type].color} />
                  {/* {ticketComment.deletable && <Icon name='ellipsis vertical' color='grey' />} */}
                </div>
              </div>
              {ticketComment.comment && (
                <div className='comment-body'>
                  {ticketComment.comment.split('\n').map((i, key) => {
                    return <div key={key}>{i}</div>;
                  })}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TicketCommentMessages;

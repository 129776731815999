import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Transition, Table, Loader, Form, Dropdown } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi, lookupsApi, trackingUnitsApi, trackingVehiclesApi } from './../../../api/api';
import { useSelector } from 'react-redux';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';
import qs from 'qs';

export default function TrackingUnits() {
  const router = useHistory();
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});
  const [modelOpts, setModelOpts] = useState([]);
  const [modelArMap, setModelArMap] = useState({});

  const [modalPopup, setModalPopup] = useState(false);
  // the delete vehicle-link popup
  const [deleteModalPopup, setDeleteModalPopup] = useState(false);
  // the delete vehicle confirmation popupt
  const [deleteTrackingUnitPopup, setDeleteTrackingUnitPopup] = useState(false);

  const [currentTrackingUnitId, setCurrentTrackingUnitId] = useState('');
  const [currentVehicleId, setCurrentVehicleId] = useState('');
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [addVehicleIdSelected, setAddVehicleIdSelected] = useState('');
  const [vehiclesOpts, setVehiclesOpts] = useState([]);

  const [deleteMessage, setDeleteMessage] = useState([]);
  const [isInTV, setIsInTV] = useState(false);
  const [isInMTV, setIsInMTV] = useState(false);
  const [loadingTUDelete, setLoadingTUDelete] = useState(true);
  const [loadingTVDelete, setLoadingTVDelete] = useState(true);

  const user = useSelector((st) => st.user).user;
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [loadingTrackingUnitInfo, setLoadingTrackingUnitInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //get lookups for models
      const models = await lookupsApi.getGroup(23);
      let options1 = [];
      let options1ArMap = {};
      for (let item of models.data) {
        options1.push({ key: item.id, text: item.name, value: item.id });
        options1ArMap[item.id] = item.name;
      }
      setModelOpts(options1);
      setModelArMap(options1ArMap);
      //get customers
      const customers = await customersApi.getCustomersDropDown();
      let options2 = [];
      let options2ArMap = {};
      for (let item of customers.data) {
        options2.push({ key: item.id, text: item.name, value: item.id });
        options2ArMap[item.id] = item.name;
      }
      setCustomerIdOptions(options2);
      setCustomerIdArMap(options2ArMap);
    };

    fetchData();
  }, [rerenderFlag]);

  const handleLinkedUnitClick = async (id) => {
    // handle showing the linked unit info in popup
    setCurrentTrackingUnitId(id);
    setLoadingTrackingUnitInfo(true);
    setModalPopup(true);

    try {
      const res = await trackingVehiclesApi.getLinkedVehicle(id);
      if (res.data) {
        setVehicleInfo(res.data);
      } else {
        setVehicleInfo('');
        const TU = await trackingUnitsApi.getOneTrackingUnit(id);
        const vehicles = await trackingVehiclesApi.getUnlinkedVehiclesList(TU.data.customerId);
        let options = [];
        // in case there are no tracking units, set currently selected id = -1
        if (vehicles.data && vehicles.data.length) {
          options = vehicles.data.map((item) => ({ key: item.id, text: item.name, value: item.id }));
          setAddVehicleIdSelected(options[0].key);
        } else {
          setAddVehicleIdSelected(-1);
        }
        setVehiclesOpts(options);
      }
    } catch (err) {
      console.log('Error fetching linked tracking unit...', err);
    }

    setLoadingTrackingUnitInfo(false);
  };

  const deleteVehicleHandler = async (id) => {
    setLoadingTVDelete(true);
    setDeleteModalPopup(true);
    setCurrentVehicleId(id);

    // check if the vehicle-unit (tv) exists in an mtv
    const { data: res } = await trackingVehiclesApi.checkTUInMTV(currentTrackingUnitId);
    if (res) {
      setIsInMTV(true);
      setDeleteMessage([
        'الارتباط المطلوب حذفه موجود في تذكرة صيانة مفتوحة حاليا. سيتم حذف الارتباط من تذكرة الصيانة واذا كان هذا الارتباط الوحيد سيتم حذف تذكرة الصيانة بالكامل.',
      ]);
    }
    setLoadingTVDelete(false);
  };

  const deleteVehicleConfirmHandler = async () => {
    // delete the tracking-vehicle link
    try {
      if (isInMTV) {
        const queryString = qs.stringify(
          {
            trackingUnitId: currentTrackingUnitId,
            deleteTU: 0,
          },
          { addQueryPrefix: true }
        );
        await trackingVehiclesApi.deleteTuFromMTV(queryString);
      } else {
        await trackingVehiclesApi.deleteTrackingVehicle(currentTrackingUnitId);
      }
    } catch (err) {
      console.log('Error deleting linked vehicle...', err);
    }
    setDeleteModalPopup(false);
    setModalPopup(false);
    setIsInMTV(false);
    setIsInTV(false);
    setDeleteMessage([]);
    setRerenderFlag(!rerenderFlag);
  };

  const deleteTrackingUnitConfirmHandler = async () => {
    try {
      if (isInTV && isInMTV) {
        const queryString = qs.stringify(
          {
            trackingUnitId: currentTrackingUnitId,
            deleteTU: 1,
          },
          { addQueryPrefix: true }
        );
        await trackingVehiclesApi.deleteTuFromMTV(queryString);
      } else {
        await trackingUnitsApi.deleteTrackingUnit(currentTrackingUnitId);
      }
    } catch (err) {
      console.log('Error deleting tracking unit...', err);
    }
    setDeleteTrackingUnitPopup(false);

    setIsInMTV(false);
    setIsInTV(false);
    setDeleteMessage([]);
    setRerenderFlag(!rerenderFlag);
  };

  const handleTrackingUnitDelete = async (id, row) => {
    setLoadingTUDelete(true);
    setDeleteTrackingUnitPopup(true);
    setCurrentTrackingUnitId(id);

    let deleteMessageArr = [];
    if (row.plateNumber !== '-') {
      deleteMessageArr.push('جهاز التتبع المطلوب حذفه مرتبط بمركبة. سيتم حذف ارتباط هذا الجهاز بالمركبة.');
      setIsInTV(true);
      const { data: res } = await trackingVehiclesApi.checkTUInMTV(id);
      if (res) {
        setIsInMTV(true);
        deleteMessageArr.push(
          'جهاز التتبع المطلوب حذفه موجود في تذكرة صيانة مفتوحة حاليا. سيتم حذف ارتباط هذا الجهاز في تذكرة الصيانة  المفتوحة وفي حال كان هذا الجهاز الوحيد في التذكرة سيتم حذف التذكرة بالكامل.'
        );
      }
    }
    setDeleteMessage(deleteMessageArr);
    setLoadingTUDelete(false);
  };

  const addUnitSelectionHandler = (e, data) => {
    setAddVehicleIdSelected(data.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const t = await trackingUnitsApi.getOneTrackingUnit(currentTrackingUnitId);
      const data = {
        trackingUnitId: currentTrackingUnitId,
        vehicleId: addVehicleIdSelected,
        customerId: t.data.customerId,
        createdBy: Number(user.id),
      };
      await trackingVehiclesApi.postTrackingVehicle(data);
      setRerenderFlag(!rerenderFlag);
      setModalPopup(false);
    } catch (err) {
      console.log('Error linking tracking unit with vehicle...', err);
    }
  };

  const tableHeaders = [
    {
      displayName: 'IMEI',
      fieldName: 'imei',
      dbFieldName: 'imei',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'SIM',
      fieldName: 'sim',
      dbFieldName: 'sim',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'نوع الجهاز',
      fieldName: 'model',
      dbFieldName: 'model',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: modelOpts,
      dropdownArMap: modelArMap,
    },
    {
      displayName: 'رقم التسلسل',
      fieldName: 'serialNumber',
      dbFieldName: 'serial_number',
      sortable: true,
      searchable: true,
      viewable: false,
      searchType: 'text',
      searchValues: [],
      dropdownArMap: {},
    },
    {
      displayName: 'Firmware Version',
      fieldName: 'fwVersion',
      dbFieldName: 'fw_version',
      viewable: false,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'العميل',
      fieldName: 'customerId',
      dbFieldName: 'customer_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerIdOptions,
      dropdownArMap: customerIdArMap,
    },
    {
      displayName: 'رقم المركبة',
      fieldName: 'plateNumber',
      dbFieldName: 'plate_number',
      viewable: true,
      sortable: true,
      searchable: false,
      searchType: 'text',
    },
  ];

  const tableActions = [
    ...(hasPermissions([
      permissions.VIEW_ALL_TRACKING_VEHICLES,
      permissions.ASSOCIATE_TRACKING_VEHICLE,
      permissions.DISASSOCIATE_TRACKING_VEHICLE,
    ])
      ? [
          {
            actionType: 'custom',
            onClick: handleLinkedUnitClick,
            title: 'المركبة المرتبطة',
            icon: 'linkify',
          },
        ]
      : []),
    ...(hasPermissions([permissions.EDIT_TRACKING_UNIT, permissions.VIEW_TRACKING_UNITS])
      ? [
          {
            actionType: 'view',
            actionUrl: '/trackingUnits/view',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_TRACKING_UNIT)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/trackingUnits/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_TRACKING_UNIT)
      ? [
          {
            actionType: 'custom',
            onClick: handleTrackingUnitDelete,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>اجهزة التتبع</h3>
          <PermissionCheck permissions={permissions.CREATE_TRACKING_UNIT}>
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              onClick={() => router.push('/trackingUnits/add')}
            >
              <Icon name='plus' className='blue-buton-icon' />
              اضافة جهاز تتبع جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='اجهزة_التتبع'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={trackingUnitsApi.getAllTrackingUnits}
          reloadTrigger={[rerenderFlag]}
          optionalQsParams={{}}
        />
      </PageCardContainer>

      {/* Modal for linking a vehicle with a tracking unit */}
      <Transition visible={modalPopup} animation='scale' duration={1000}>
        <Modal size='small' open={modalPopup}>
          <Modal.Header>المركبة المرتبطة بالجهاز</Modal.Header>
          <Modal.Content>
            {!loadingTrackingUnitInfo ? (
              vehicleInfo ? (
                <>
                  <div className='modal-contents'>
                    <Table basic='very' unstackable className='modal-table'>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='car' /> رقم المركبة:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{vehicleInfo.plateNumber}</b>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ margin: '1rem' }} className='form-margin'>
                    <Form style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
                      <Form.Field>
                        <label>رقم المركبة</label>
                        <Dropdown
                          placeholder={
                            addVehicleIdSelected === -1 ? 'لا توجد مركبات غير مربوطة لهذا العميل' : 'اختر رقم المركبة'
                          }
                          fluid
                          selection
                          search
                          value={addVehicleIdSelected}
                          options={vehiclesOpts}
                          onChange={addUnitSelectionHandler}
                          className='table-dropdown'
                          style={{ marginLeft: '0.5rem' }}
                          disabled={addVehicleIdSelected === -1}
                        />
                      </Form.Field>
                    </Form>
                  </div>
                </div>
              )
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            {!loadingTrackingUnitInfo && vehicleInfo && (
              <PermissionCheck permissions={permissions.DISASSOCIATE_TRACKING_VEHICLE}>
                <Button color='red' onClick={() => deleteVehicleHandler(vehicleInfo.id)}>
                  فك الارتباط
                </Button>
              </PermissionCheck>
            )}
            {!loadingTrackingUnitInfo && !vehicleInfo && addVehicleIdSelected !== -1 && (
              <PermissionCheck permissions={permissions.ASSOCIATE_TRACKING_VEHICLE}>
                <Button className='blue-button' onClick={(e) => onSubmitHandler(e)}>
                  ربط المركبة بجهاز تتبع
                </Button>
              </PermissionCheck>
            )}
            <Button onClick={() => setModalPopup(false)}>اغلاق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* a modal for deleting a tracking vehicle link */}
      <Transition visible={deleteModalPopup} animation='scale' duration={1000}>
        <Modal size='tiny' open={deleteModalPopup}>
          <Modal.Header>تأكيد فك الارتباط</Modal.Header>
          <Modal.Content>
            {!loadingTVDelete ? (
              <div className='delete-ticket-errors-modal'>
                {Boolean(deleteMessage) &&
                  Boolean(deleteMessage.length) &&
                  deleteMessage.map((str, index) => (
                    <p className='red-text' key={index}>
                      <Icon name='circle' size='tiny' />
                      {str}
                    </p>
                  ))}
                <p>هل انت متأكد من فك الارتباط؟</p>
              </div>
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteVehicleConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setDeleteModalPopup(false);
                setIsInMTV(false);
                setIsInTV(false);
                setDeleteMessage([]);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* a modal for deleting a tracking unit */}
      <Transition visible={deleteTrackingUnitPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteTrackingUnitPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            {!loadingTUDelete ? (
              <div className='delete-ticket-errors-modal'>
                {Boolean(deleteMessage) &&
                  Boolean(deleteMessage.length) &&
                  deleteMessage.map((str, index) => (
                    <p className='red-text' key={index}>
                      <Icon name='circle' size='tiny' />
                      {str}
                    </p>
                  ))}
                <p>هل انت متأكد من الحذف؟</p>
              </div>
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteTrackingUnitConfirmHandler()}>
              نعم
            </Button>
            <Button
              color='green'
              onClick={() => {
                setIsInMTV(false);
                setIsInTV(false);
                setDeleteMessage([]);
                setDeleteTrackingUnitPopup(false);
              }}
            >
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

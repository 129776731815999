import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Dropdown, Responsive } from 'semantic-ui-react';
import { contractsApi, customersApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import DatePicker from 'react-datepicker';
import WarrantyEditList from './WarrantyEditList';

const ViewContract = () => {
  const router = useHistory();
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: '',
    ownerName: '',
    customerGroupId: '',
    customerId: '',
    startDate: '',
    endDate: '',
    invoiceDate: '',
  });

  const [warrantyList, setWarrantyList] = useState([]);
  const [customerGroupIdOptions, setCustomerGroupIdOptions] = useState([]);
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const { id, row } = location.state;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contract = await contractsApi.getOneContract(id);
        contract.data.endDate = new Date(contract.data.endDate);
        contract.data.startDate = new Date(contract.data.startDate);
        contract.data.invoiceDate = !!contract.data.invoiceDate ? new Date(contract.data.invoiceDate) : '';

        let warranties = await contractsApi.getContractWarranties(id);
        warranties = warranties.data.map((w) => ({
          ...w,
          key: w.id,
          endDate: new Date(w.endDate),
          startDate: new Date(w.startDate),
        }));

        const options = [
          {
            key: 1,
            text: contract.data.customerGroupName,
            value: contract.data.customerGroupId,
          },
        ];
        const customersOptions = [
          {
            key: 1,
            text: contract.data.customerName,
            value: contract.data.customerId,
          },
        ];
        setCustomerGroupIdOptions(options);
        setCustomerIdOptions(customersOptions);
        setWarrantyList(warranties);
        setFormData(contract.data);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching contract info...', err);
        router.goBack();
      }
    };

    fetchData();
  }, []);

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>عرض معلومات عقد</h2>
        <Form error style={{ margin: '1rem' }} loading={isLoading}>
          <Form.Field>
            <label>الاسم التعريفي</label>
            <Form.Input icon='edit' value={formData['name']} readOnly />
          </Form.Field>
          <Form.Field>
            <label>اسم المالك</label>
            <Form.Input icon='user' value={formData['ownerName']} readOnly />
          </Form.Field>
          <div className='flex-between gap1'>
            <Form.Field width='5'>
              <label>تاريخ بداية العقد</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='من تاريخ'
                selected={formData.startDate}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.endDate}
                readOnly
              />
            </Form.Field>
            <Form.Field width='5'>
              <label>تاريخ نهاية العقد</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='إلى تاريخ'
                selected={formData.endDate}
                minDate={formData.startDate}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.endDate}
                readOnly
              />
            </Form.Field>
            <Form.Field width='6'>
              <label>تاريخ الفاتورة</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='تاريخ الفاتورة'
                selected={formData.invoiceDate}
                readOnly
              />
            </Form.Field>
          </div>
          <Form.Field>
            <label>مجموعة العملاء</label>
            <Dropdown
              fluid
              selection
              search
              name='customerGroupId'
              value={formData['customerGroupId']}
              options={customerGroupIdOptions}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
              readOnly
            />
          </Form.Field>
          <Form.Field>
            <label>العملاء</label>
            <Dropdown
              fluid
              selection
              search
              name='customerId'
              value={formData['customerId']}
              options={customerIdOptions}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
              readOnly
            />
          </Form.Field>
          <WarrantyEditList disabled warrantyList={warrantyList} setWarrantyList={setWarrantyList} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default ViewContract;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Image, Segment, Popup, Loader } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import moment from 'moment';
import { assuranceEditRequestApi, filesApi } from '../../../api/api';

const ViewAssuranceEditRequest = () => {
  const location = useLocation();
  const { id } = location.state;

  const [loading, setLoading] = useState(true);

  const [attachmentPaths, setAttachmentPaths] = useState([]);
  const [assuranceFormDetails, setAssuranceFormDetails] = useState({});
  const [newEdits, setNewEdits] = useState({});
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async (fileName) => {
    try {
      setDownloading(true);
      await filesApi.downloadMerchandiseFile(fileName);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      alert('حدق خطأ اثناء تحميل المرفقات');
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: res } = await assuranceEditRequestApi.getPrintData(id);
        const { data: paths } = await assuranceEditRequestApi.getAttachmentPaths(id);
        setNewEdits(JSON.parse(res.newEditJson));
        setAttachmentPaths(paths);
        setAssuranceFormDetails(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        alert('حدث خطأ اثناء تحميل البيانات');
      }
    };

    fetchData();
  }, []);

  const DataBox = ({ title, icon = null, data, ltr = false, image = false, bordered = true, padded = true }) => {
    return (
      <div className={`data-box ${!bordered ? 'no-border' : ''} ${!padded ? 'no-padding' : ''}`}>
        <div className='title-container'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title'>{title}</h5>
        </div>
        {image ? (
          <div className='image-margin image-preview'>
            <div className='maintenance-form-img-preview'>
              <Image src={data} className='img' centered />
            </div>
          </div>
        ) : (
          <h5 className={`data ${ltr ? 'ltr' : ''}`}>{data ? data : 'لا توجد بيانات'}</h5>
        )}
      </div>
    );
  };
  const AttachmentDataBox = ({ title, icon = null, data, ltr = false }) => {
    return (
      <div className='data-box-att'>
        <div className='title-container-att'>
          {icon && <Icon name={icon} size='small' />}
          <h5 className='title-att'>{title}</h5>
        </div>
        <h5 className={`data-att ${ltr ? 'ltr' : ''}`}>{data}</h5>
      </div>
    );
  };
  return (
    <div className='maintenance-form-view'>
      <PageCardContainer>
        {loading ? (
          <Loader active className='loader-margin-top' size='large' inline='centered' />
        ) : (
          <div style={{ margin: '1rem 0.7rem' }}>
            <div className='header-wrapper'>
              <div>
                <h3>نموذج تعديل كفالة رقم #{assuranceFormDetails.assuranceNumber}</h3>
                <DataBox title='منشيء الطلب' icon='user circle' data={assuranceFormDetails.createdBy} />
              </div>
            </div>

            <div className='segments-wrapper'>
              <div className='single-segment-wrapper'>
                <h3>معلومات الطلب</h3>
                <Segment>
                  <div className='form-columns-wrapper'>
                    <DataBox
                      title='تاريخ انشاء الطلب من النظام'
                      icon='calendar'
                      data={moment(assuranceFormDetails.createdAt).format('YYYY/MM/DD hh:mm A')}
                    />
                    <DataBox title='منشئ الطلب' icon='user' data={assuranceFormDetails.createdBy} />
                    <DataBox title='حالة الطلب' icon='tag' data={assuranceFormDetails.status} />
                    <DataBox title='نوع الطلب' icon='wpforms' ltr data={assuranceFormDetails.assuranceType} />
                    <DataBox title='القسم' icon='building' ltr data={assuranceFormDetails.departmentName} />
                  </div>
                </Segment>
              </div>

              <div className='single-segment-wrapper full-width'>
                <h3>معلومات العميل و الكفالة</h3>
                <div className='two-columns'>
                  <Segment>
                    <DataBox title='اسم العميل' icon='user' data={assuranceFormDetails.customer} />
                    <DataBox title='الهاتف' icon='phone' ltr data={assuranceFormDetails.phoneNumber} />
                    <DataBox title='العنوان' icon='map pin' data={assuranceFormDetails.address} />
                  </Segment>
                  <Segment>
                    <DataBox title='رقم الكفالة' icon='hashtag' data={assuranceFormDetails.assuranceNumber} />
                    <DataBox
                      title='بداية الكفالة'
                      icon='calendar'
                      data={moment(assuranceFormDetails.assuranceFromDate).format('YYYY/MM/DD')}
                    />
                    <DataBox
                      title='نهاية الكفالة'
                      icon='calendar'
                      data={moment(assuranceFormDetails.assuranceToDate).format('YYYY/MM/DD')}
                    />
                  </Segment>
                  <Segment>
                    <DataBox
                      title='قيمة الكفالة رقما'
                      icon='money bill alternate'
                      data={assuranceFormDetails.assuranceAmountNumbers}
                    />
                    <DataBox
                      title='قيمة الكفالة تفقيط'
                      icon='money bill alternate'
                      data={assuranceFormDetails.assuranceAmountWritten}
                    />
                  </Segment>
                </div>
              </div>
              <div className='single-segment-wrapper'>
                <h3>معلومات التعديل الجديد</h3>
                {Object.values(newEdits).map((item, index) =>
                  item.type === 135 && item.selected ? (
                    <Segment key={index}>
                      <div className='form-columns-wrapper'>
                        <DataBox title='نوع التعديل' icon='edit' data={'تعديل تاريخ الكفالة'} />
                        <DataBox
                          title='تاريخ بداية الكفالة الجديد'
                          icon='calendar'
                          data={moment(newEdits[135].editJson.assuranceFromDate).format('YYYY/MM/DD a')}
                        />
                        <DataBox
                          title='تاريخ نهاية الكفالة الجديد'
                          icon='map pin'
                          data={moment(newEdits[135].editJson.assuranceToDate).format('YYYY/MM/DD a')}
                        />
                      </div>
                    </Segment>
                  ) : item.type === 136 && item.selected ? (
                    <Segment key={index}>
                      <div className='form-columns-wrapper'>
                        <DataBox title='نوع التعديل' icon='edit' data={'تعديل قيمة الكفالة'} />
                        <DataBox
                          title='قيمة الكفالة الجديد رقما'
                          icon='money bill alternate'
                          data={newEdits[136].editJson.newAssuranceAmountNumbers}
                        />
                        <DataBox
                          title='قيمة الكفالة الجديد تفقيط'
                          icon='money bill alternate'
                          data={newEdits[136].editJson.newAssuranceAmountWritten}
                        />
                      </div>
                    </Segment>
                  ) : item.type === 137 && item.selected ? (
                    <Segment key={index}>
                      <div className='form-columns-wrapper' style={{ justifyContent: 'flex-start' }}>
                        <DataBox title='نوع التعديل' icon='edit' data={'تعديل نص'} />
                        <DataBox title='النص الجديد' icon='pencil alternate' data={newEdits[137].editJson.newText} />
                      </div>
                    </Segment>
                  ) : (
                    <React.Fragment key={index}></React.Fragment>
                  )
                )}
                <Segment>
                  <div className='form-columns-wrapper'>
                    <DataBox title='سبب لتعديل' icon='question' data={assuranceFormDetails.reason} />
                  </div>
                </Segment>
              </div>
              {attachmentPaths.length > 0 && (
                <div className='single-segment-wrapper full-width'>
                  <div className='title-container-att'>
                    <Icon name='attach' size='small' />
                    <h3 className='title-att'>المرفقات</h3>
                  </div>
                  <div className='two-columns'>
                    <AttachmentDataBox
                      data={
                        <div style={{ direction: 'ltr' }}>
                          {attachmentPaths.map((item, index) => (
                            <div
                              style={downloading ? { color: 'lightgray' } : {}}
                              className='attachment-path-att'
                              onClick={() => downloadFile(item.name)}
                              key={index}
                            >
                              {item.name}-{item.attachmentType}
                            </div>
                          ))}
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PageCardContainer>
    </div>
  );
};

export default ViewAssuranceEditRequest;

import React, { useState, useEffect } from 'react';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { Form, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import DropFileUpload from '../../Reusable/DropFileUpload';
import moment from 'moment';
import { assuranceEditRequestApi, customersApi, filesApi, lookupsApi, departmentsApi } from '../../../api/api';
import { tafqeet } from '../../../helpers/Tafqeet';

const AssuranceEditRequestForm = () => {
  const currentUser = useSelector((st) => st.user).user;
  const router = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [customersIdOptions, setCustomersIdOptions] = useState([]);
  const [assuranceTypesIdOptions, setAssuranceTypeIdOptions] = useState([]);
  const [editTypes, setEditTypes] = useState([]);
  const [newEditJSON, setNewEditJSON] = useState({
    135: {
      type: 135, //date
      selected: false,
      editJson: {
        assuranceToDate: null,
        assuranceFromDate: null,
      },
    },
    136: {
      type: 136, //amount
      selected: false,
      editJson: {
        newAssuranceAmountNumbers: 0.0,
        newAssuranceAmountWritten: '',
      },
    },
    137: {
      type: 137, //text
      selected: false,
      editJson: {
        newText: '',
      },
    },
  });
  const [userDepartments, setUserDepartments] = useState([]);

  const [formData, setFormData] = useState({
    createdAt: new Date(),
    assuranceType: 0,
    assuranceNumber: '',
    customerId: 0,
    editRequestType: [],
    assuranceAmountNumbers: 0.0,
    assuranceAmountWritten: '',
    reason: '',
    createdBy: Number(currentUser.id),
    newEdit: '{}',
    departmentId: 0,
  });

  const [touched, setTouched] = useState({
    assuranceType: false,
    assuranceNumber: false,
    customerId: false,
    editRequestType: false,
    assuranceAmountNumbers: false,
    assuranceAmountWritten: false,
    reason: false,
    departmentId: false,
    // for the new edits. Not all should be set (depends on the edit type)
    assuranceFromDate: false,
    assuranceToDate: false,
    newAssuranceAmountWritten: false,
    newAssuranceAmountNumbers: false,
    newText: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    assuranceType: null,
    assuranceNumber: null,
    customerId: null,
    editRequestType: null,
    assuranceAmountNumbers: null,
    assuranceAmountWritten: null,
    reason: null,
    departmentId: null,

    //for the new edits
    assuranceFromDate: null,
    assuranceToDate: null,
    newAssuranceAmountWritten: null,
    newAssuranceAmountNumbers: null,
    newText: null,
    assuranceAttachment: null,
  });

  const onDropFilesChanged = (files) => {
    setValidationErrors({ ...validationErrors, assuranceAttachment: null });
    setFiles(files);
  };

  const uploadFiles = async (assuranceFormId) => {
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const formData = new FormData();
        formData.append('file', file);
        await filesApi.uploadAssuranceEditRequestAttachments(assuranceFormId, file.attachmentType, formData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectionChangeHandler = (e, data) => {
    if (data.name === 'editRequestType') {
      const defaultValues = {
        135: {
          type: 135,
          selected: false,
          editJson: {
            assuranceToDate: null,
            assuranceFromDate: null,
          },
        },
        136: {
          type: 136,
          selected: false,
          editJson: {
            newAssuranceAmountNumbers: 0.0,
            newAssuranceAmountWritten: '',
          },
        },
        137: {
          type: 137,
          selected: false,
          editJson: {
            newText: '',
          },
        },
      };
      setNewEditJSON(
        Object.values(newEditJSON).reduce((p, item) => {
          if (data.value.includes(item.type)) {
            p = { ...p, [item.type]: { ...item, selected: true } };
          } else {
            p = { ...p, [item.type]: defaultValues[item.type] };
          }
          return p;
        }, {})
      );
    }
    setFormData({ ...formData, [data.name]: data.value });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: customers } = await customersApi.getCustomersDropDown();
        const { data: lookups } = await lookupsApi.getGroup(124);
        const { data: editTypesResponse } = await lookupsApi.getGroup(134);
        const { data: departments } = await departmentsApi.getDepartmentsByUserIdDropDown(currentUser.id);

        const departmentsOptions = departments.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setUserDepartments(departmentsOptions);

        const customersOptions = customers.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCustomersIdOptions(customersOptions);

        const assuranceTypesOptions = lookups.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setAssuranceTypeIdOptions(assuranceTypesOptions);

        const editTypesOptions = editTypesResponse.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setEditTypes(editTypesOptions);
      } catch (err) {
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching assruance form dropdowns...', err);
        router.goBack();
      }
      setLoading(false);
    })();
  }, []);

  const validation = () => {
    const errors = {
      assuranceType: null,
      assuranceNumber: null,
      customerId: null,
      editRequestType: null,
      assuranceAmountNumbers: null,
      assuranceAmountWritten: null,
      reason: null,
      departmentId: null,

      //for the new edits
      assuranceFromDate: null,
      assuranceToDate: null,
      newAssuranceAmountWritten: null,
      newAssuranceAmountNumbers: null,
      newText: null,
    };

    if (!formData.assuranceType) {
      errors.assuranceType = 'الرجاء اختيار نوع الكفالة';
    }
    if (!formData.assuranceNumber) {
      errors.assuranceNumber = 'الرجاء ادخال رقم العطاء';
    }
    if (!formData.customerId) {
      errors.customerId = 'الرجاء اختيار عميل';
    }
    if (!formData.assuranceAmountNumbers) {
      errors.assuranceAmountNumbers = 'الرجاء ادخال قيمة الكفالة رقما';
    }
    if (!formData.assuranceAmountWritten) {
      errors.assuranceAmountWritten = 'الرجاء ادخال قيمة الكفالة تفقيط';
    }
    if (!formData.departmentId) {
      errors.departmentId = 'الرجاء اختيار قسم';
    }
    if (!formData.reason) {
      errors.reason = 'الرجاء ادخال سبب التعديل';
    }

    //for the new edits
    if (formData.editRequestType.length === 0) {
      errors.editRequestType = 'الرجاء اختيار نوع التعديل المطلوب';
    }
    if (formData.editRequestType.includes(135)) {
      if (!Object.values(newEditJSON).find((item) => item.type === 135)?.editJson.assuranceFromDate) {
        errors.assuranceFromDate = 'الرجاء اختيار بداية مدة الكفالة الجديد';
      }
      if (!Object.values(newEditJSON).find((item) => item.type === 135)?.editJson.assuranceToDate) {
        errors.assuranceToDate = 'الرجاء اختيار نهاية مدة الكفالة الجديد';
      }
    }
    if (formData.editRequestType.includes(136)) {
      if (!Object.values(newEditJSON).find((item) => item.type === 136)?.editJson.newAssuranceAmountNumbers) {
        errors.newAssuranceAmountNumbers = 'الرجاء ادخال القيمة الجديدة للكفالة رقما';
      }
      if (!Object.values(newEditJSON).find((item) => item.type === 136)?.editJson.newAssuranceAmountWritten) {
        errors.newAssuranceAmountWritten = 'الرجاء ادخال القيمة الجديدة للكفالة تفقيط';
      }
    }
    if (formData.editRequestType.includes(137)) {
      if (!Object.values(newEditJSON).find((item) => item.type === 137)?.editJson.newText) {
        errors.newText = 'الرجاء ادخال النص الجديد';
      }
    }

    setValidationErrors(errors);

    if (
      validationErrors.assuranceAmountNumbers ||
      validationErrors.assuranceAmountWritten ||
      validationErrors.customerId ||
      validationErrors.assuranceNumber ||
      validationErrors.assuranceType ||
      validationErrors.departmentId ||
      validationErrors.reason ||
      validationErrors.assuranceFromDate ||
      validationErrors.assuranceToDate ||
      validationErrors.newAssuranceAmountNumbers ||
      validationErrors.newAssuranceAmountWritten ||
      validationErrors.newText ||
      validationErrors.editRequestType
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const filesAssignmentAttachmentType = () => {
    let assignmentAttachmentTypeExist = false;
    files.forEach((file) => {
      if (!file.attachmentType) assignmentAttachmentTypeExist = true;
    });
    return assignmentAttachmentTypeExist;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      assuranceType: true,
      assuranceNumber: true,
      customerId: true,
      assuranceAmountNumbers: true,
      assuranceAmountWritten: true,
      departmentId: true,
      reason: true,
      editRequestType: true,

      // for the new edits
      assuranceFromDate: true,
      assuranceToDate: true,
      newAssuranceAmountNumbers: true,
      newAssuranceAmountWritten: true,
      newText: true,
    });

    if (validation()) {
      if (files.length > 0) {
        if (filesAssignmentAttachmentType()) {
          setValidationErrors({
            ...validationErrors,
            assuranceAttachment: 'الرجاء تحديد انواع المرفقات',
          });
          setIsSubmitting(false);
          return;
        }
      }
      try {
        const assuranceFormId = await assuranceEditRequestApi.postAssuranceEditRequest({
          ...formData,
          newEdit: JSON.stringify(newEditJSON),
          editRequestType: 135,
        });
        if (files.length > 0) uploadFiles(assuranceFormId.data);
        router.goBack();
      } catch (e) {
        console.log('Error posting contract...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const tafqeetHandler = () => {
    if (!!formData.assuranceAmountNumbers) {
      const fraction = formData.assuranceAmountNumbers.toString().split('.');

      if (fraction.length === 2) {
        const writtentNumber = tafqeet(fraction[0]) + ' دينار و ' + tafqeet(fraction[1]) + ' فلس ';
        setFormData({ ...formData, assuranceAmountWritten: writtentNumber });
      } else if (fraction.length === 1) {
        const writtentNumber = tafqeet(fraction[0]) + ' دينار ';
        setFormData({ ...formData, assuranceAmountWritten: writtentNumber });
      }
    }
  };

  const newAssuranceTafqeetHandler = () => {
    if (!!Object.values(newEditJSON).find((item) => item.type === 136)?.editJson.newAssuranceAmountNumbers) {
      const fraction = Object.values(newEditJSON)
        .find((item) => item.type === 136)
        ?.editJson.newAssuranceAmountNumbers.split('.');

      if (fraction.length === 2) {
        const writtentNumber = tafqeet(fraction[0]) + ' دينار و ' + tafqeet(fraction[1]) + ' فلس ';
        setNewEditJSON({
          ...newEditJSON,
          136: {
            type: 136,
            selected: true,
            editJson: {
              newAssuranceAmountNumbers: newEditJSON[136].editJson.newAssuranceAmountNumbers,
              newAssuranceAmountWritten: writtentNumber,
            },
          },
        });
      } else if (fraction.length === 1) {
        const writtentNumber = tafqeet(fraction[0]) + ' دينار ';
        setNewEditJSON({
          ...newEditJSON,
          136: {
            type: 136,
            selected: true,
            editJson: {
              newAssuranceAmountNumbers: newEditJSON[136].editJson.newAssuranceAmountNumbers,
              newAssuranceAmountWritten: writtentNumber,
            },
          },
        });
      }
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>نموذج تعديل كفالة</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>المندوب</label>
              <Form.Input className='rtl-input' fluid value={currentUser.FullName} readOnly />
            </Form.Field>
            <Form.Field>
              <label>القسم</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  search
                  name='departmentId'
                  value={formData.departmentId}
                  options={userDepartments}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                  style={{ marginLeft: '0.5rem' }}
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.departmentId && validationErrors.departmentId}</label>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>تاريخ تقديم الطلب</label>
            <DatePicker
              popperPlacement='top-end'
              placeholderText='تاريخ تقديم الطلب'
              value={moment(formData.createdAt).format('YYYY/MM/DD hh:mm A')}
              readOnly
            />
          </Form.Field>
          <Form.Group widths={2}>
            <Form.Field>
              <label>رقم الكفالة المراد تعديلها</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceNumber'
                onChange={onChangeHandler}
                value={formData.assuranceNumber}
                error={touched.assuranceNumber && validationErrors.assuranceNumber}
              />
            </Form.Field>
            <Form.Field>
              <label>نوع الكفالة</label>
              <div className='flex-between'>
                <Dropdown
                  fluid
                  selection
                  search
                  name='assuranceType'
                  value={formData.assuranceType}
                  options={assuranceTypesIdOptions}
                  onChange={selectionChangeHandler}
                  className='table-dropdown'
                />
              </div>
              <label style={{ color: '#9f3a38' }}>{touched.assuranceType && validationErrors.assuranceType}</label>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>المستفيد</label>
            <Dropdown
              fluid
              selection
              search
              value={formData['customerId']}
              name='customerId'
              options={customersIdOptions}
              onChange={selectionChangeHandler}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
            <label style={{ color: '#9f3a38' }}>{touched.customerId && validationErrors.customerId}</label>
          </Form.Field>
          <Form.Group widths={2}>
            <Form.Field>
              <label>قيمة الكفالة رقما</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceAmountNumbers'
                onChange={onChangeHandler}
                value={formData.assuranceAmountNumbers}
                error={touched.assuranceAmountNumbers && validationErrors.assuranceAmountNumbers}
                onBlur={tafqeetHandler}
              />
            </Form.Field>
            <Form.Field>
              <label>قيمة الكفالة تفقيط</label>
              <Form.Input
                className='rtl-input'
                fluid
                name='assuranceAmountWritten'
                onChange={onChangeHandler}
                value={formData.assuranceAmountWritten}
                error={touched.assuranceAmountWritten && validationErrors.assuranceAmountWritten}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>نوع التعديل المطلوب</label>
            <Dropdown
              fluid
              selection
              multiple
              value={formData['editRequestType']}
              name='editRequestType'
              options={editTypes}
              onChange={selectionChangeHandler}
              className='table-dropdown'
              style={{ marginLeft: '0.5rem' }}
            />
            <label style={{ color: '#9f3a38' }}>{touched.editRequestType && validationErrors.editRequestType}</label>
          </Form.Field>
          {formData.editRequestType.map((item) =>
            item === 135 ? (
              <Form.Group widths={2} key={item}>
                <Form.Field>
                  <label>تاريخ بداية مدة الكفالة الجديد</label>
                  <DatePicker
                    popperPlacement='bottom-end'
                    placeholderText='من تاريخ'
                    selected={Object.values(newEditJSON).find((item) => item.type === 135)?.editJson.assuranceFromDate}
                    dateFormat={'yyyy/MM/dd'}
                    onChange={(date) =>
                      setNewEditJSON({
                        ...newEditJSON,
                        [135]: {
                          type: 135,
                          selected: true,
                          editJson: {
                            assuranceFromDate: date,
                            assuranceToDate: newEditJSON[135]?.editJson?.assuranceToDate,
                          },
                        },
                      })
                    }
                  />
                  <label style={{ color: '#9f3a38' }}>
                    {touched.assuranceFromDate && validationErrors.assuranceFromDate}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label>تاريخ نهاية مدة الكفالة الجديد</label>
                  <DatePicker
                    popperPlacement='bottom-end'
                    placeholderText='الى تاريخ'
                    selected={Object.values(newEditJSON).find((item) => item.type === 135)?.editJson.assuranceToDate}
                    dateFormat={'yyyy/MM/dd'}
                    onChange={(date) =>
                      setNewEditJSON({
                        ...newEditJSON,
                        [135]: {
                          type: 135,
                          selected: true,
                          editJson: {
                            assuranceToDate: date,
                            assuranceFromDate: newEditJSON[135]?.editJson?.assuranceFromDate,
                          },
                        },
                      })
                    }
                  />
                  <label style={{ color: '#9f3a38' }}>
                    {touched.assuranceToDate && validationErrors.assuranceToDate}
                  </label>
                </Form.Field>
              </Form.Group>
            ) : item === 136 ? (
              <Form.Group widths={2} key={item}>
                <Form.Field>
                  <label>القيمة الجديدة للكفالة رقما</label>
                  <Form.Input
                    className='rtl-input'
                    fluid
                    name='newAssuranceAmountNumbers'
                    onChange={(e, { value }) =>
                      setNewEditJSON({
                        ...newEditJSON,
                        [136]: {
                          type: 136,
                          selected: true,
                          editJson: {
                            newAssuranceAmountNumbers: value,
                            newAssuranceAmountWritten: newEditJSON[136]?.editJson?.newAssuranceAmountWritten,
                          },
                        },
                      })
                    }
                    onBlur={newAssuranceTafqeetHandler}
                    value={
                      Object.values(newEditJSON).find((item) => item.type === 136)?.editJson.newAssuranceAmountNumbers
                    }
                    error={touched.newAssuranceAmountNumbers && validationErrors.newAssuranceAmountNumbers}
                  />
                </Form.Field>
                <Form.Field>
                  <label>القيمة الجديدة للكفالة تفقيط</label>
                  <Form.Input
                    className='rtl-input'
                    fluid
                    name='newAssuranceAmountWritten'
                    readOnly
                    value={
                      Object.values(newEditJSON).find((item) => item.type === 136)?.editJson.newAssuranceAmountWritten
                    }
                    error={touched.newAssuranceAmountWritten && validationErrors.newAssuranceAmountWritten}
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              <Form.Field key={item}>
                <label>النص الجديد</label>
                <Form.Input
                  className='rtl-input'
                  fluid
                  name='newText'
                  onChange={(e, { value }) =>
                    setNewEditJSON({
                      ...newEditJSON,
                      [137]: {
                        type: 137,
                        selected: true,
                        editJson: {
                          newText: value,
                        },
                      },
                    })
                  }
                  value={Object.values(newEditJSON).find((item) => item.type === 137)?.editJson.newText}
                  error={touched.newText && validationErrors.newText}
                />
              </Form.Field>
            )
          )}
          <Form.Field>
            <label>سبب التعديل</label>
            <Form.Input
              className='rtl-input'
              fluid
              name='reason'
              onChange={onChangeHandler}
              value={formData.reason}
              error={touched.reason && validationErrors.reason}
            />
          </Form.Field>
          <Form.Field>
            <div className='attachments-container'>
              <DropFileUpload onFileChange={onDropFilesChanged} attachmentType={151} />
            </div>
            {uploadProgress > -1 && (
              <h4 style={{ textAlign: 'center' }}>
                جاري رفع المرفقات {uploadProgress}/{files.length}
              </h4>
            )}
            <label style={{ color: '#9f3a38' }}>{validationErrors.assuranceAttachment}</label>
          </Form.Field>
          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default AssuranceEditRequestForm;

import moment from 'moment';

export const humanizeIsoString = (period) => {
  let parts = [];
  const duration = moment.duration(period);

  // return nothing when the duration is falsy or not correctly parsed (P0D)
  if (!duration || duration.toISOString() === 'P0D') return;

  if (duration.years() >= 1) {
    const years = Math.floor(duration.years());
    parts.push(getYearsWord(years));
  }

  if (duration.months() >= 1) {
    const months = Math.floor(duration.months());
    parts.push(getMonthsWord(months));
  }

  if (duration.days() >= 1) {
    const days = Math.floor(duration.days());
    parts.push(getDaysWord(days));
  }

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(getHoursWord(hours));
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(getMinutesWord(minutes));
  }

  if (duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(getSecondsWord(seconds));
  }

  return parts.join(' و ');
};

const getYearsWord = (value) => {
  if (value === 2) return 'سنتين';
  if (value > 2 && value < 11) return value + ' سنوات';
  if (value === 1) return 'سنة';
  return value + ' سنة';
};

const getMonthsWord = (value) => {
  if (value === 2) return 'شهرين';
  if (value > 2 && value < 11) return value + ' أشهر';
  if (value === 1) return 'شهر';
  return value + ' شهر';
};

const getDaysWord = (value) => {
  if (value === 2) return 'يومين';
  if (value > 2 && value < 11) return value + ' أيام';
  if (value === 1) return 'يوم';
  return value + ' يوم';
};

const getHoursWord = (value) => {
  if (value === 2) return 'ساعتين';
  if (value > 2 && value < 11) return value + ' ساعات';
  if (value === 1) return 'ساعة';
  return value + ' ساعة';
};

const getMinutesWord = (value) => {
  if (value === 2) return 'دقيقتين';
  if (value > 2 && value < 11) return value + ' دقائق';
  if (value === 1) return 'دقيقة';
  return value + ' دقيقة';
};

const getSecondsWord = (value) => {
  if (value === 2) return 'ثانيتين';
  if (value > 2 && value < 11) return value + ' ثواني';
  if (value === 1) return 'ثانية';
  return value + ' ثانية';
};

import React, { useState, useEffect } from 'react';
import DataTable from '../../Layout/Table/DataTable';
import PermissionCheck from '../../Reusable/PermissionCheck';
import { hasPermission } from '../../../helpers/permissions';
import { hasPermissions } from '../../../helpers/permissions';
import permissions from '../../../helpers/permissions';
import { useHistory } from 'react-router-dom';
import PageCardContainer from '../../Layout/PageCardContainer';
import { assuranceEditRequestApi, departmentsApi, customersApi, lookupsApi } from '../../../api/api';
import { Button, Icon, Modal, Dropdown, Checkbox } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const AssuranceEditFormList = () => {
  const router = useHistory();
  const currentUser = useSelector((st) => st.user).user;

  const [rerenderFlag, setRerenderFlag] = useState(false);

  const [customerIdOpts, setCustomerIdOpts] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});

  const [assuranceTypesIdOpts, setAssuranceTypesIdOpts] = useState([]);
  const [assuranceTypesIdArMap, setAssuranceTypesIdArMap] = useState({});

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusArMap, setStatusArMap] = useState({});

  const [editTypesOptions, setEditTypeOptions] = useState([]);
  const [editTypesArMap, setEditTypesArMap] = useState({});

  const [userDepartmentsOptions, setUserDepartmentsOptions] = useState([]);
  const [userDepartmentsArMap, setUserDepartmentsArMap] = useState({});

  const [status, setStatus] = useState(0);
  const [assuraceFormId, setAssuranceFormId] = useState(0);
  const [statusUpdatePopupVisible, setStatusUpdatePopupVisible] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formsStatus, setFromsStatus] = useState(132);

  const tableHeaders = [
    {
      displayName: 'رقم الكفالة',
      fieldName: 'assuranceNumber',
      dbFieldName: 'assurance_number',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'العميل',
      fieldName: 'customer',
      dbFieldName: 'customer',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: customerIdOpts,
      dropdownArMap: customerIdArMap,
    },
    {
      displayName: 'تاريخ الكفالة',
      fieldName: 'createdAt',
      dbFieldName: 'created_at',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'قيمة الكفالة',
      fieldName: 'assuranceAmount',
      dbFieldName: 'assurance_amount',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'الحالة',
      fieldName: 'statusName',
      dbFieldName: 'statusName',
      viewable: true,
    },
    {
      displayName: 'الحالة',
      fieldName: 'status',
      dbFieldName: 'status',
      viewable: false,
      sortable: false,
      searchable: status,
      searchType: 'dropdown',
      searchValues: statusOptions,
      dropdownArMap: statusArMap,
    },
    {
      displayName: 'نوع الكفالة',
      fieldName: 'assuranceType',
      dbFieldName: 'assurance_type',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: assuranceTypesIdOpts,
      dropdownArMap: assuranceTypesIdArMap,
    },
    {
      displayName: 'نوع التعديل',
      fieldName: 'editRequestType',
      dbFieldName: 'editRequestType',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'dropdown',
      searchValues: editTypesOptions,
      dropdownArMap: editTypesArMap,
    },
    {
      displayName: 'القسم',
      fieldName: 'departmentId',
      dbFieldName: 'department_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: userDepartmentsOptions,
      dropdownArMap: userDepartmentsArMap,
    },
  ];

  useEffect(() => {
    const fetch = async () => {
      const customers = await customersApi.getCustomersDropDown();
      const { data: lookups } = await lookupsApi.getGroup(124);
      const { data: statusTypes } = await lookupsApi.getGroup(131);
      const { data: editTypes } = await lookupsApi.getGroup(134);
      const { data: departments } = hasPermission(permissions.VIEW_ALL_ASSURANCE_EDIT_FORMS)
        ? await departmentsApi.getDepartmentsDropDown()
        : await departmentsApi.getDepartmentsByUserIdDropDown(currentUser.id);

      let customersOpts = [];
      let customersArMap = {};
      for (let item of customers.data) {
        customersOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
        customersArMap[item.id] = item.name;
      }
      setCustomerIdOpts(customersOpts);
      setCustomerIdArMap(customersArMap);

      let departmentsOpts = [];
      let departmentsArMap = {};
      for (let item of departments) {
        departmentsOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
        departmentsArMap[item.id] = item.name;
      }
      setUserDepartmentsOptions(departmentsOpts);
      setUserDepartmentsArMap(departmentsArMap);

      let assuranceTypesOpts = [];
      let assuranceTypesArMap = {};
      for (let item of lookups) {
        assuranceTypesOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
        assuranceTypesArMap[item.id] = item.name;
      }
      setAssuranceTypesIdOpts(assuranceTypesOpts);
      setAssuranceTypesIdArMap(assuranceTypesArMap);

      let statusOptArr = [];
      let optionsArMap3 = {};
      let statusUpdatePopupOptions = [];
      for (let item of statusTypes) {
        statusOptArr.push({ key: item.id, text: item.name, value: item.id });
        optionsArMap3[item.id] = item.name;
        statusUpdatePopupOptions.push({ key: item.id, text: item.name, value: item.id });
      }
      setStatusOptions(statusOptArr);
      setStatusArMap(optionsArMap3);

      let editTypesOptArr = [];
      let editTypesArMap3 = {};
      for (let item of editTypes) {
        editTypesOptArr.push({ key: item.id, text: item.name, value: item.id });
        editTypesArMap3[item.id] = item.name;
      }
      setEditTypeOptions(editTypesOptArr);
      setEditTypesArMap(editTypesArMap3);
    };

    fetch();
  }, []);

  const handlePrintClick = (id, row) => {
    window.open(`printAssuranceEditForm/?id=${id}`);
  };

  const handleUpdateStatus = (id, row) => {
    setStatusUpdatePopupVisible(true);
    setAssuranceFormId(id);
    setStatus(row.status);
  };

  const tableActions = [
    ...(hasPermission(permissions.CHANGE_ASSURANCE_EDIT_REQUEST_FORMS_STATUS)
      ? [
          {
            actionType: 'custom',
            onClick: handleUpdateStatus,
            title: 'تعديل حالة الطلب',
            icon: 'linkify',
            color: 'green',
            displayCondition: (row) => {
              return row.status === 132; //open
            },
          },
        ]
      : []),
    ...(hasPermissions([
      permissions.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
      permissions.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
      permissions.VIEW_ALL_ASSURANCE_EDIT_FORMS,
    ])
      ? [
          {
            actionType: 'custom',
            onClick: handlePrintClick,
            title: 'طباعة',
            icon: 'print',
          },
        ]
      : []),
    ...(hasPermissions(
      permissions.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
      permissions.VIEW_ALL_ASSURANCE_EDIT_FORMS,
      permissions.VIEW_ASSURANCE_EDIT_REQUEST_FORMS
    )
      ? [
          {
            actionType: 'edit',
            actionUrl: '/assuranceEditRequest/edit',
            displayCondition: (row) => {
              return row.status === 132; //open
            },
          },
        ]
      : []),
    ...(hasPermissions([
      permissions.VIEW_ASSURANCE_EDIT_REQUEST_FORMS,
      permissions.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS,
      permissions.VIEW_ALL_ASSURANCE_EDIT_FORMS,
    ])
      ? [
          {
            actionType: 'view',
            actionUrl: '/assuranceEditRequest/view',
          },
        ]
      : []),
  ];

  const selectionChangeHandler = (e, data) => {
    setStatus(data.value);
  };

  const submitBtnHandler = async () => {
    try {
      setIsSubmitting(true);
      await assuranceEditRequestApi.updateStatus(assuraceFormId, status);
      setRerenderFlag(true);
      setStatusUpdatePopupVisible(false);
      setIsSubmitting(false);
      setRerenderFlag(false);
    } catch (error) {
      alert('حدث خطأ عند تغيير الحالة');
      setStatusUpdatePopupVisible(false);
      setIsSubmitting(false);
      setRerenderFlag(false);
      console.log(error);
    }
  };

  const onStatusSelection = (evt, { value }) => {
    setFromsStatus(value);
  };

  return (
    <>
      <PageCardContainer>
        <div className='table-title-container merchandise-table-title-container'>
          <div>
            <h3>نماذج تعديل الكفالات</h3>
            <PermissionCheck permissions={permissions.ADD_EDIT_ASSURANCE_EDIT_REQUEST_FORMS}>
              <Button
                icon
                labelPosition='right'
                className='blue-button'
                onClick={() => router.push('/FinancialServices/AddAssuranceEditRequest')}
              >
                <Icon name='plus' className='blue-buton-icon' />
                إضافة نموذج جديد
              </Button>
            </PermissionCheck>
          </div>

          <div className='status-container'>
            <label className='status-lbl'>الحالة</label>
            <Dropdown
              fluid
              selection
              options={statusOptions}
              value={formsStatus}
              className='table-dropdown'
              onChange={onStatusSelection}
            />
          </div>
        </div>
        <DataTable
          tableTitle='نماذج طلب الكفالات'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={assuranceEditRequestApi.getAll}
          reloadTrigger={[rerenderFlag, formsStatus]}
          optionalQsParams={{ status: formsStatus }}
        />
      </PageCardContainer>
      <Modal
        onClose={() => setStatusUpdatePopupVisible(false)}
        onOpen={() => setStatusUpdatePopupVisible(true)}
        open={statusUpdatePopupVisible}
        size={'tiny'}
      >
        <Modal.Header>تغيير حالة الطلب</Modal.Header>
        <Modal.Content>
          <Dropdown
            fluid
            selection
            options={statusOptions}
            value={status}
            className='table-dropdown'
            onChange={selectionChangeHandler}
          />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              icon
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginLeft: '1rem' }}
              onClick={submitBtnHandler}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              icon='chevron right'
              labelPosition='right'
              content='رجوع'
              onClick={() => setStatusUpdatePopupVisible(false)}
            />
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AssuranceEditFormList;

import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Table, Icon, Dropdown, Button, Popup, Responsive, Pagination } from 'semantic-ui-react';
import { assetsApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import './ContractAssets.scss';

const ContractAssets = () => {
  const location = useLocation();
  const router = useHistory();

  const [row, setRow] = useState({});
  const [selectedAsset, setSelectedAsset] = useState('');
  const [assetsRawData, setAssetsRawData] = useState({});
  const [assetsOptsData, setAssetsOptsData] = useState([]);
  const [preSelectedAssets, setPreSelectedAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (location.state && location.state.row && location.state.row.id) {
      setRow(location.state.row);
      (async () => {
        // TODO: error handling
        try {
          const { data: data } = await assetsApi.getContractAvailableAssets(location.state.row.id);
          setAssetsOptsData(
            data.availableAssets.map((a) => ({
              text: `IMEI: ${a.imei} - sim: ${a.sim} - S/N: ${a.serialNumber}`,
              value: a.id,
              key: a.id,
            }))
          );
          setAssetsRawData(
            data.availableAssets.reduce((acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            }, {})
          );
          setPreSelectedAssets(data.preAssets);
          setSelectedAssets(data.preAssets);

          setLoading(false);
        } catch (err) {
          setLoading(false);
          alert('حدث خطأ أثناء تحميل البيانات');
          console.log('Error fetching assets opts...', err);
          router.goBack();
        }
      })();
    } else {
      router.goBack();
    }
  }, [location]);

  const onAssetSelected = (e, { value }) => {
    setSelectedAsset(value);
  };

  const onAdd = () => {
    if (!selectedAsset) return;
    if (selectedAssets.indexOf(selectedAsset) === -1) setSelectedAssets([...selectedAssets, selectedAsset]);
    setSelectedAsset('');
  };

  const onRemove = (assetId) => () => {
    setSelectedAssets(selectedAssets.filter((a) => a !== assetId));
  };

  const onSave = async () => {
    try {
      setIsSubmitting(true);
      const newAssets = selectedAssets.filter((id) => preSelectedAssets.indexOf(id) === -1);
      const removedAssets = preSelectedAssets.filter((id) => selectedAssets.indexOf(id) === -1);
      if (newAssets.length === 0 && removedAssets.length === 0) return router.goBack();
      await assetsApi.assignContractAssets(location.state.row.id, {
        removedAssets,
        newAssets,
      });
      router.goBack();
    } catch (error) {
      setIsSubmitting(false);
      // TODO: Proper error handling
      alert('حدث خطأ يرجى المحاولة مجدداً');
      console.log(error);
    }
  };

  return (
    <PageCardContainer>
      <div className='form-margin m1 contract-assets-page'>
        <h2 style={{ marginBottom: '2rem' }}>ربط الأجهزة بالعقد</h2>
        <Table basic='very' size='large' collapsing unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Icon name='users' className='ml1' /> المجموعة:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.customerGroup}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='pencil' className='ml1' /> العقد:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.name}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='user plus' className='ml1' /> المالك:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.ownerName}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='calendar' className='ml1' /> بداية العقد:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.startDate}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='calendar' className='ml1' /> نهاية العقد:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.endDate}</b>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div className='flex'>
          <Dropdown
            selection
            search
            fluid
            placeholder='يرجى اختيار الجهاز'
            loading={loading}
            options={assetsOptsData.filter((a) => selectedAssets.indexOf(a.value) === -1)}
            onChange={onAssetSelected}
            value={selectedAsset}
          />
          <Button icon='plus' onClick={onAdd} />
        </div>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>العميل</Table.HeaderCell>
              <Table.HeaderCell>IMEI</Table.HeaderCell>
              <Table.HeaderCell>SIM</Table.HeaderCell>
              <Table.HeaderCell>S/N</Table.HeaderCell>
              <Table.HeaderCell>النوع</Table.HeaderCell>
              <Table.HeaderCell>الموديل</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!!selectedAssets.length &&
              selectedAssets.map((id) => (
                <Table.Row key={id}>
                  <Table.Cell>{assetsRawData[id].customerName}</Table.Cell>
                  <Table.Cell>{assetsRawData[id].imei}</Table.Cell>
                  <Table.Cell>{assetsRawData[id].sim}</Table.Cell>
                  <Table.Cell>{assetsRawData[id].serialNumber}</Table.Cell>
                  <Table.Cell>{assetsRawData[id].assetType}</Table.Cell>
                  <Table.Cell>{assetsRawData[id].assetModel}</Table.Cell>
                  <Table.Cell>
                    <Popup
                      trigger={<Icon name='cancel' color='red' className='remove-btn' onClick={onRemove(id)} />}
                      content='إزالة من العقد'
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          {!selectedAssets.length && (
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.Cell>
                  <h5 className='no-data-footer'>لا يوجد أجهزة مرتبطة بالعقد</h5>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
        <Responsive minWidth={768}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              type='button'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginLeft: '1rem' }}
              onClick={onSave}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              icon='chevron right'
              type='button'
              labelPosition='right'
              content='رجوع'
              disabled={isSubmitting}
              onClick={() => {
                router.goBack();
              }}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={767}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              type='button'
              icon
              fluid
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginBottom: '1rem' }}
              onClick={onSave}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              type='button'
              fluid
              icon='chevron right'
              labelPosition='right'
              content='رجوع'
              disabled={isSubmitting}
              onClick={() => {
                router.goBack();
              }}
            />
          </div>
        </Responsive>
      </div>
    </PageCardContainer>
  );
};

export default ContractAssets;

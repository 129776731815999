import React, { useEffect, useState } from 'react';
import { Icon, Loader, Message } from 'semantic-ui-react';
import './Dashboard.scss';
import { dashboardApi } from './../../api/api';
import PieChart from './PieChart';
import TableSummary from './TableSummary';
import StackedBarChart from './StackedBarChart';

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const [allTicketsStatus, setAllTicketsStatus] = useState([]);
  const [allOpen, setAllOpen] = useState(0);
  const [allClosed, setAllClosed] = useState(0);
  const [allInProgress, setAllInProgress] = useState(0);

  const [allTicketsTypes, setAllTicketsTypes] = useState([]);
  const [allMaintenance, setAllMaintenance] = useState(0);
  const [allSystems, setAllSystems] = useState(0);
  const [allServers, setAllServers] = useState(0);

  const [TUBarData, setTUBarData] = useState([]);
  const [commonTU, setCommonTU] = useState([]);
  const [commonVehicles, setCommonVehicles] = useState([]);
  const [commonIssues, setCommonIssues] = useState([]);
  const [commonVehicleIssues, setCommonVehicleIssues] = useState([]);
  const [commonActions, setCommonActions] = useState([]);
  const [powerAlertNo, setPowerAlertNo] = useState(0);
  const [powerAlertYes, setPowerAlertYes] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: all } = await dashboardApi.allTickets();
        setAllTicketsStatus(all);
        if (all && all.length)
          all.forEach((item) => {
            if (item.status === 18) setAllOpen(item.count);
            if (item.status === 19) setAllClosed(item.count);
            if (item.status === 20) setAllInProgress(item.count);
          });

        const { data: allTypes } = await dashboardApi.allTypes();
        setAllTicketsTypes(allTypes);

        if (allTypes && allTypes.length)
          allTypes.forEach((item) => {
            if (item.name === 'maintenance') setAllMaintenance(item.count);
            if (item.name === 'systems') setAllSystems(item.count);
            if (item.name === 'servers') setAllServers(item.count);
          });

        const { data: tvDistribution } = await dashboardApi.trackingUnitsDistribution();
        setTUBarData(tvDistribution);

        const { data: commonTuData } = await dashboardApi.mostProblematicTrackingUnits();
        setCommonTU(commonTuData);

        const { data: commonVehiclesData } = await dashboardApi.mostProblematicVehicles();
        setCommonVehicles(commonVehiclesData);

        const { data: commonIssuesData } = await dashboardApi.mostCommonIssues();
        setCommonIssues(commonIssuesData);

        const { data: commonVehicleIssuesData } = await dashboardApi.mostCommonVehicleIssues();
        setCommonVehicleIssues(commonVehicleIssuesData);

        const { data: commonActionsData } = await dashboardApi.mostCommonActions();
        setCommonActions(commonActionsData);

        const { data: powerAlerts } = await dashboardApi.mainPowerAlertsCount();

        if (powerAlerts && powerAlerts.length) {
          setPowerAlertNo(powerAlerts.filter((x) => x.name === 'False')[0]?.count);
          setPowerAlertYes(powerAlerts.filter((x) => x.name === 'True')[0]?.count);
        }

        setLoading(false);
      } catch (e) {
        console.log('Error fetching dashboard data...', e);
        setLoadingError(true);
      }
    })();
  }, []);

  const header = (title) => {
    return <h3 className='dashboard-headers'>{title}</h3>;
  };

  const dashboardCard = (title, icon, content) => {
    return (
      <div className='card'>
        <div className='card-icon'>
          <Icon name={icon} />
        </div>
        <div className='card-title'>{title}</div>
        <div className='card-content'>{content}</div>
      </div>
    );
  };

  return (
    <div className='dashboard'>
      <h2>لوحة التحكم</h2>

      {loading ? (
        <Loader active className='workaround table-loader' size='large' inline='centered' />
      ) : (
        <>
          <div className='pies-container'>
            <div className='pie-container'>
              <div className='sub-container'>
                {header('توزيع التذاكر حسب الحالة')}
                {Boolean(allTicketsStatus) && Boolean(allTicketsStatus.length) ? (
                  <PieChart
                    labels={['مفتوحة', 'قيد التنفيذ', 'مغلقة']}
                    data={[allOpen, allClosed, allInProgress]}
                    colors={['#e99700', '#0067bf', '#009200']}
                    hoverColors={['#ffbc40', '#438ecf', '#3ead3e']}
                  />
                ) : (
                  <Message warning>لا توجد حالات تذاكر</Message>
                )}
              </div>
            </div>
            <div className='pie-container'>
              <div className='sub-container'>
                {header('توزيع التذاكر حسب النوع')}
                {Boolean(allTicketsTypes) && Boolean(allTicketsTypes.length) ? (
                  <PieChart
                    labels={['صيانة اجهزة تتبع', 'صيانة نظام', 'صيانة معدات وخوادم']}
                    data={[allMaintenance, allSystems, allServers]}
                    colors={['#028090', '#02c39a', '#05668d']}
                    hoverColors={['#67b2bc', '#67dbc2', '#69a3ba']}
                  />
                ) : (
                  <Message warning>لا توجد انواع تذاكر</Message>
                )}
              </div>
            </div>
          </div>

          <div className='sub-container'>
            {header('توزيع اجهزة التتبع لكل عميل')}
            {Boolean(TUBarData) && Boolean(TUBarData.length) ? (
              <StackedBarChart
                labels={TUBarData.map((x) => x.customerName)}
                data1={TUBarData.map((x) => x.total)}
                data1Label='الاجهزة المتبقية'
                data1Color='#77bfa3'
                data1HoverColor='rgb(158, 222, 197)'
                data2={TUBarData.map((x) => x.needMaintenance)}
                data2Label='بحاجة الى صيانة'
                data2Color='#f29e4c'
                data2HoverColor='rgb(255, 192, 130)'
              />
            ) : (
              <Message warning>لا توجد اجهزة تتبع لعملاء</Message>
            )}
          </div>

          <div className='sub-container'>
            {header('اكثر اجهزة التتبع تعطلاً')}
            {Boolean(commonTU) && Boolean(commonTU.length) ? (
              <TableSummary
                headers={['IMEI', 'SIM', 'نوع الجهاز', 'اسم العميل']}
                engHeaders={['imei', 'sim', 'model', 'customerName']}
                data={commonTU}
                counter={true}
              />
            ) : (
              <Message warning>لا توجد اجهزة تتبع</Message>
            )}
          </div>

          <div className='sub-container'>
            {header('اكثر المركبات تعطلاً')}
            {Boolean(commonVehicles) && Boolean(commonVehicles.length) ? (
              <TableSummary
                headers={['رقم اللوحة', 'اسم العميل']}
                engHeaders={['plateNumber', 'customerName']}
                data={commonVehicles}
                counter={true}
              />
            ) : (
              <Message warning>لا توجد مركبات</Message>
            )}
          </div>

          <div className='table-container-2c'>
            <div className='sub-container flex-1'>
              {header('اكثر اعطال اجهزة التتبع شيوعاً')}
              {Boolean(commonIssues) && Boolean(commonIssues.length) ? (
                <TableSummary
                  headers={['العطل']}
                  engHeaders={['name']}
                  data={commonIssues}
                  counter={true}
                  oneCountColor='teal'
                />
              ) : (
                <Message warning>لا توجد اعطال مركبات</Message>
              )}
            </div>

            <div className='sub-container flex-1'>
              {header('اكثر اعطال المركبات شيوعاً')}
              {Boolean(commonVehicleIssues) && Boolean(commonVehicleIssues.length) ? (
                <TableSummary
                  headers={['العطل']}
                  engHeaders={['name']}
                  data={commonVehicleIssues}
                  counter={true}
                  oneCountColor='teal'
                />
              ) : (
                <Message warning>لا توجد اعطال مركبات</Message>
              )}
            </div>
          </div>

          <div className='table-container-2c'>
            <div className='sub-container flex-1'>
              {header('اكثر الاجراءات شيوعاً')}
              {Boolean(commonActions) && Boolean(commonActions.length) ? (
                <TableSummary
                  headers={['الاجراء']}
                  engHeaders={['name']}
                  data={commonActions}
                  counter={true}
                  oneCountColor='teal'
                />
              ) : (
                <Message warning>لا توجد اجراءات</Message>
              )}
            </div>

            <div className='sub-container flex-1'>
              {header('توزيع فصل مزود الطاقة الرئيسي')}
              {Boolean(powerAlertYes) || Boolean(powerAlertNo) ? (
                <PieChart
                  labels={['نعم', 'لا']}
                  data={[powerAlertYes, powerAlertNo]}
                  colors={['#00a876', '#a60600']}
                  hoverColors={['#54bf9f', '#c74642']}
                />
              ) : (
                <Message warning>لا توجد بيانات عن فصل مزود الطاقة</Message>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

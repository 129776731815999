import Axios from "axios";
const addressingPath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ADDRESSING_PATH
    : `${window.location.protocol}//${window.location.hostname}/AddressingService`;

const checkSeparate = (field, separate) => {
  if (field) {
    if (separate) {
      return field + " - ";
    }
    return field;
  }
  return "";
};

const AddressingServiceApi = {
  getAddress: async (x, y) => {
    const res = await Axios.get(
      `${addressingPath}/GetAddress.ashx?x=${x}&y=${y}`
    );
    if (res && res.data) {
      return (
        checkSeparate(res.data["Governorate"], true) +
        checkSeparate(res.data["District"], true) +
        checkSeparate(res.data["SubDistrict"], true) +
        checkSeparate(res.data["Village"], true) +
        checkSeparate(res.data["Town"], true) +
        checkSeparate(res.data["Street"], true) +
        checkSeparate(res.data["Landmark"])
      );
    }
  },
};

export default AddressingServiceApi;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Transition, Modal, Dropdown, Form, Table, Message, Loader } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { departmentsApi, lookupsApi, usersApi, userTrackingAssetsApi } from '../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';

export default function UsersTrackingAssets() {
  const router = useHistory();
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const [deviceTypesOptions, setDeviceTypesOptions] = useState([]);
  const [deviceTypeArMap, setDeviceTypeArMap] = useState({});
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [departmentsArMap, setDepartmentsArMap] = useState({});
  const [usersOptions, setUsersOptions] = useState([]);
  const [usersArMap, setUsersArMap] = useState({});

  const [linkingPopupVisible, setLinkingPopupVisible] = useState(false);
  const [currentTrackingAsset, setCurrentTrackingAsset] = useState(null);
  const [loadingCurrentTrackingAssetInfo, setLoadingCurrentTrackingAssetInfo] = useState(false);
  const [popupUsersOptions, setPopupUsersOptions] = useState([]);
  const [selectedUserLinkingId, setSelectedUserLinkingId] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const deviceTypes = await lookupsApi.getGroup(77);
        let options1 = [];
        let options1ArMap = {};
        for (let item of deviceTypes.data) {
          options1.push({ key: item.id, text: item.name, value: item.id });
          options1ArMap[item.id] = item.name;
        }
        setDeviceTypesOptions(options1);
        setDeviceTypeArMap(options1ArMap);

        const users = await usersApi.getAllExceptCustomersDropdown();
        let options2 = [];
        let options2ArMap = {};
        for (let item of users.data) {
          options2.push({ key: item.id, text: item.name, value: item.id });
          options2ArMap[item.id] = item.name;
        }
        setUsersOptions(options2);
        setUsersArMap(options2ArMap);

        const departments = await departmentsApi.getDepartmentsDropDown();
        let options3 = [];
        let options3ArMap = {};
        for (let item of departments.data) {
          options3.push({ key: item.id, text: item.name, value: item.id });
          options3ArMap[item.id] = item.name;
        }
        setDepartmentsOptions(options3);
        setDepartmentsArMap(options3ArMap);
      } catch (err) {
        console.log('Error fetching page dropdowns data...', err?.response ?? err);
      }
    };

    fetchData();
  }, []);

  const handleLinkingTrackingAssetWithUser = async (id, row) => {
    setCurrentTrackingAsset(row);
    setLoadingCurrentTrackingAssetInfo(true);
    setLinkingPopupVisible(true);

    if (!row.userId) {
      try {
        const { data: departmentUsers } = await departmentsApi.getDepartmentUsersDropDown(row.departmentId);
        if (departmentUsers && departmentUsers.length) {
          const usersOptions = departmentUsers.map((item) => ({ key: item.id, text: item.name, value: item.id }));
          setPopupUsersOptions(usersOptions);
          setSelectedUserLinkingId(usersOptions[0].key);
        } else {
          setPopupUsersOptions([]);
          setSelectedUserLinkingId(null);
        }
      } catch (err) {
        console.log('Error fetching user tracking asset...', err?.response ?? err);
      }
    }

    setLoadingCurrentTrackingAssetInfo(false);
  };

  const deleteUserHandler = async (userTrackingAssetId) => {
    setIsLoading(true);
    try {
      await userTrackingAssetsApi.unlinkTrackingAssetFromUser(userTrackingAssetId);
      setRerenderFlag(!rerenderFlag);
      setLinkingPopupVisible(false);
    } catch (err) {
      console.log('Error unlinking tracking unit with vehicle...', err);
    }
    setIsLoading(false);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let tempErrors = '';
    setIsLoading(true);

    if (!selectedUserLinkingId) tempErrors = 'الرجاء اختيار المستخدم';

    setErrors(tempErrors);

    if (!tempErrors) {
      try {
        await userTrackingAssetsApi.linkTrackingAssetWithUser(currentTrackingAsset.id, {
          userId: selectedUserLinkingId,
        });
        setRerenderFlag(!rerenderFlag);
        setLinkingPopupVisible(false);
      } catch (err) {
        console.log('Error linking tracking unit with vehicle...', err);
      }
    }

    setIsLoading(false);
  };

  const tableHeaders = [
    {
      displayName: 'IMEI',
      fieldName: 'imei',
      dbFieldName: 'imei',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'SIM',
      fieldName: 'simNumber',
      dbFieldName: 'sim_number',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },

    {
      displayName: 'الإدارة',
      fieldName: 'department',
      dbFieldName: 'department',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: departmentsOptions,
      dropdownArMap: departmentsArMap,
    },
    {
      displayName: 'اسم المستخدم',
      fieldName: 'user',
      dbFieldName: 'user',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: usersOptions,
      dropdownArMap: usersArMap,
    },
    {
      displayName: 'نوع الجهاز',
      fieldName: 'deviceType',
      dbFieldName: 'device_type',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: deviceTypesOptions,
      dropdownArMap: deviceTypeArMap,
    },
  ];

  const tableActions = [
    ...(hasPermissions([permissions.LINK_TRACKING_ASSET_WITH_USER, permissions.UNLINK_TRACKING_ASSET_FROM_USER])
      ? [
          {
            actionType: 'custom',
            onClick: handleLinkingTrackingAssetWithUser,
            title: 'ربط جهاز بمستخدم',
            icon: 'linkify',
            color: 'green',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_USERS_TRACKING_ASSET)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/usersTrackingAssets/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_USERS_TRACKING_ASSET)
      ? [
          {
            actionType: 'delete',
            deleteActionApi: userTrackingAssetsApi.deleteUserTrackingAsset,
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>اجهزة تتبع المستخدمين</h3>
          <PermissionCheck permissions={permissions.CREATE_USERS_TRACKING_ASSET}>
            <Button
              icon
              labelPosition='right'
              className='blue-button'
              onClick={() => router.push('/usersTrackingAssets/add')}
            >
              <Icon name='plus' className='blue-buton-icon' />
              اضافة جهاز تتبع مستخدم جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='اجهزة تتبع المستخدمين'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={userTrackingAssetsApi.getAllUserTrackingAssets}
          optionalQsParams={{}}
          reloadTrigger={[rerenderFlag]}
        />
      </PageCardContainer>

      <Transition visible={linkingPopupVisible} animation='scale' duration={1000}>
        <Modal size='small' open={linkingPopupVisible}>
          <Modal.Header>ربط الجهاز بمستخدم</Modal.Header>
          <Modal.Content>
            {!loadingCurrentTrackingAssetInfo ? (
              currentTrackingAsset?.userId ? (
                <>
                  <div className='modal-contents'>
                    <Table basic='very' unstackable className='modal-table'>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <span style={{ marginLeft: '1rem' }}>
                              <Icon name='user' /> المستخدم:
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <b>{currentTrackingAsset?.user}</b>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ margin: '1rem' }} className='form-margin'>
                    <Form style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
                      <Form.Field>
                        <label>المستخدم</label>
                        <Dropdown
                          placeholder={
                            !popupUsersOptions || !popupUsersOptions.length
                              ? 'لا يوجد مستخدمين تابعين لإدارة هذا الجهاز'
                              : 'اختر المستخدم'
                          }
                          fluid
                          selection
                          search
                          name='userId'
                          value={selectedUserLinkingId}
                          options={popupUsersOptions}
                          onChange={(e, data) => setSelectedUserLinkingId(data.value)}
                          className='table-dropdown'
                          style={{ marginLeft: '0.5rem' }}
                          disabled={!popupUsersOptions || !popupUsersOptions.length}
                          error={errors}
                        />
                      </Form.Field>

                      {Boolean(errors) && <Message negative content={errors} />}
                    </Form>
                  </div>
                </div>
              )
            ) : (
              <Loader active className='workaround' size='large' inline='centered' />
            )}
          </Modal.Content>
          <Modal.Actions>
            {!Boolean(loadingCurrentTrackingAssetInfo) && Boolean(currentTrackingAsset?.userId) && (
              <PermissionCheck permissions={permissions.UNLINK_TRACKING_ASSET_FROM_USER}>
                <Button color='red' disabled={isLoading} onClick={() => deleteUserHandler(currentTrackingAsset?.id)}>
                  فك الارتباط
                </Button>
              </PermissionCheck>
            )}
            {!Boolean(loadingCurrentTrackingAssetInfo) &&
              !Boolean(currentTrackingAsset?.userId) &&
              Boolean(popupUsersOptions) &&
              Boolean(popupUsersOptions.length) && (
                <PermissionCheck permissions={permissions.LINK_TRACKING_ASSET_WITH_USER}>
                  <Button className='blue-button' disabled={isLoading} onClick={(e) => onSubmitHandler(e)}>
                    ربط جهاز التتبع بالمستخدم
                  </Button>
                </PermissionCheck>
              )}
            <Button onClick={() => setLinkingPopupVisible(false)}>اغلاق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

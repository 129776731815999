import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { customersApi, contractsApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import WarrantyEditList from './WarrantyEditList';
import moment from 'moment';

const AddContractsForm = () => {
  const currentUser = useSelector((st) => st.user).user;

  const [formData, setFormData] = useState({
    name: '',
    ownerName: '',
    customerGroupId: '',
    customerId: '',
    startDate: '',
    endDate: '',
    createdBy: Number(currentUser.id),
    invoiceDate: null,
  });

  const [warrantyList, setWarrantyList] = useState([]);

  const [validationErrors, setValidationErrors] = useState({
    name: null,
    ownerName: null,
    startDate: null,
    endDate: null,
  });

  const [touched, setTouched] = useState({
    name: false,
    ownerName: false,
    startDate: false,
    endDate: false,
  });

  const [customerGroupIdOptions, setCustomerGroupIdOptions] = useState([]);
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const router = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: groups } = await customersApi.getCustomerGroups();
        const options = groups.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        setCustomerGroupIdOptions(options);
        // setFormData({
        //   ...formData,
        // });

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching customer groups...', err);
        router.goBack();
      }
    };
    const fetchCustomers = async () => {
      try {
        const customers = await customersApi.getCustomersDropDown();
        const customersOptions = customers.data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));

        setCustomerIdOptions(customersOptions);
        // setFormData({
        //   ...formData,
        //   customerId: Boolean(customersOptions.length) ? customersOptions[0].key : '',
        // });

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching customer groups...', err);
        router.goBack();
      }
    };
    fetchData();
    fetchCustomers();
  }, []);

  const validation = () => {
    const errors = {
      name: null,
      ownerName: null,
      startDate: null,
      endDate: null,
    };

    if (!formData.name) {
      errors.name = 'الرجاء ادخال الاسم التعريفي';
    }
    if (!formData.ownerName) {
      errors.ownerName = 'الرجاء ادخال اسم المالك';
    }
    if (!formData.startDate) {
      errors.startDate = 'الرجاء ادخال تاريخ بداية العقد';
    }
    if (!formData.endDate) {
      errors.endDate = 'الرجاء ادخال تاريخ نهاية العقد';
    }

    setValidationErrors(errors);

    if (errors.name || errors.ownerName || errors.endDate || errors.startDate) return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e, v) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    if (v.name === 'customerGroupId') setFormData({ ...formData, ['customerId']: '', ['customerGroupId']: v.value });
    else if (v.name == 'customerId') setFormData({ ...formData, ['customerGroupId']: '', ['customerId']: v.value });
    else setFormData({ ...formData, [v.name]: v.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      name: true,
      endDate: true,
      ownerName: true,
      startDate: true,
    });

    if (validation()) {
      try {
        if (!formData.invoiceNumber) formData.invoiceNumber = null;
        await contractsApi.postContract({
          ...formData,
          customerId: formData.customerId === '' || formData.customerId === 0 ? null : formData.customerId,
          warranties: warrantyList.map((w) => ({
            startDate: moment(w.startDate).format('YYYY-MM-DD'),
            endDate: moment(w.endDate).format('YYYY-MM-DD'),
            note: w.note,
          })),
        });
        router.goBack();
      } catch (e) {
        console.log('Error posting contract...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const onDateChanged = (key, value) => {
    if (key === 'startDate' && (!formData.endDate || new Date(formData.endDate) < new Date(value))) {
      const next = new Date(value);
      next.setDate(next.getDate() + 1);
      setFormData({ ...formData, startDate: value, endDate: next });
    } else if (key === 'endDate' && !formData.startDate) {
      const prev = new Date(value);
      prev.setDate(prev.getDate() - 1);
      setFormData({ ...formData, endDate: value, startDate: prev });
    } else {
      setFormData({ ...formData, [key]: value || null });
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>اضافة عقد جديد</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={isLoading}>
          <Form.Field>
            <label>الاسم التعريفي</label>
            <Form.Input
              icon='edit'
              value={formData['name']}
              name='name'
              onChange={onChangeHandler}
              error={touched.name && validationErrors.name}
            />
          </Form.Field>
          <Form.Field>
            <label>اسم المالك</label>
            <Form.Input
              icon='user'
              value={formData['ownerName']}
              name='ownerName'
              onChange={onChangeHandler}
              error={touched.ownerName && validationErrors.ownerName}
            />
          </Form.Field>
          <div className='flex-between gap1'>
            <Form.Field width='5'>
              <label>تاريخ بداية العقد</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='من تاريخ'
                selected={formData.startDate}
                onChange={(date) => onDateChanged('startDate', date)}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.endDate}
              />
              <label style={{ color: '#9f3a38' }}>{touched.startDate && validationErrors.startDate}</label>
            </Form.Field>
            <Form.Field width='5'>
              <label>تاريخ نهاية العقد</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='إلى تاريخ'
                selected={formData.endDate}
                onChange={(date) => onDateChanged('endDate', date)}
                minDate={formData.startDate}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.endDate}
              />
              <label style={{ color: '#9f3a38' }}>{touched.endDate && validationErrors.endDate}</label>
            </Form.Field>
            <Form.Field width='6'>
              <label>تاريخ الفاتورة</label>
              <DatePicker
                popperPlacement='top-end'
                placeholderText='تاريخ الفاتورة'
                selected={formData.invoiceDate}
                onChange={(date) => onDateChanged('invoiceDate', date)}
              />
            </Form.Field>
          </div>
          <Form.Field>
            <label>مجموعة العملاء</label>
            <Dropdown
              fluid
              selection
              search
              name='customerGroupId'
              value={formData['customerGroupId']}
              options={customerGroupIdOptions}
              onChange={onChangeHandler}
              className='table-dropdown'
              noResultsMessage='لا يوجد عملاء'
              style={{ marginLeft: '0.5rem' }}
            />
          </Form.Field>
          <Form.Field>
            <label>العملاء</label>
            <Dropdown
              fluid
              selection
              search
              name='customerId'
              value={formData['customerId']}
              options={customerIdOptions}
              onChange={onChangeHandler}
              className='table-dropdown'
              noResultsMessage='لا يوجد عملاء'
              style={{ marginLeft: '0.5rem' }}
            />
          </Form.Field>
          <WarrantyEditList warrantyList={warrantyList} setWarrantyList={setWarrantyList} />

          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
};

export default AddContractsForm;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive } from 'semantic-ui-react';
import { customersApi, merchandiseDeliveryRequestsApi, filesApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import DropFileUpload from './../../Reusable/DropFileUpload';

export default function AddMerchandiseDeliveryRequest() {
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((st) => st.user).user;
  const [inputDate, setInputDate] = useState(new Date());

  const [formData, setFormData] = useState({
    salesRepresentativeName: '',
    customerId: '',
    location: '',
    personToCallName: '',
    personToCallPhone: '',
    customerDeliveryDate: '',
    deliverableMerchandise: '',
    description: '',
    createdBy: Number(currentUser.id),
  });

  const [validationErrors, setValidationErrors] = useState({
    salesRepresentativeName: null,
    customerId: null,
    location: null,
  });

  const [touched, setTouched] = useState({
    salesRepresentativeName: false,
    customerId: false,
    location: false,
  });

  const [customersIdOptions, setCustomersIdOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);

  const router = useHistory();

  const onDropFilesChanged = (files) => {
    setFiles(files);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: customers } = await customersApi.getCustomersDropDown();
        const options1 = customers.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCustomersIdOptions(options1);

        setFormData({
          ...formData,
          customerId: Boolean(options1.length) ? options1[0].key : '',
        });
      } catch (err) {
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching merchandise delivery requests form dropdowns...', err);
        router.goBack();
      }
      setLoading(false);
    })();
  }, []);

  const validation = () => {
    const errors = {
      salesRepresentativeName: null,
      customerId: null,
      location: null,
    };

    if (!formData.salesRepresentativeName) {
      errors.salesRepresentativeName = 'الرجاء ادخال اسم مندوب المبيعات';
    }
    if (!formData.location) {
      errors.location = 'الرجاء ادخال الموقع';
    }

    if (!formData.customerId) {
      errors.customerId = 'الرجاء اختيار العميل';
    }

    setValidationErrors(errors);

    if (validationErrors.salesRepresentativeName || validationErrors.location || validationErrors.customerId)
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, [data.name]: data.value });
  };

  const DateChangeHandler = (date) => {
    setInputDate(date);
    setFormData({
      ...formData,
      customerDeliveryDate: moment.utc(date.toLocaleDateString()).format('YYYY-MM-DD'),
    });
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const uploadFiles = async (merchandiseRequestId) => {
    let filesCount = 1;
    for (const file of files) {
      try {
        setUploadProgress(filesCount);
        const formData = new FormData();
        formData.append('file', file);
        await filesApi.uploadMerchandiseAttachments(merchandiseRequestId, formData);
        filesCount++;
      } catch (e) {
        console.log(e);
      }
    }
    if (filesCount < files.length) alert('لم يتم رفع جميع المرفقات، يرجى التأكد منها في شاشة التعديل');
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      salesRepresentativeName: true,
      customerId: true,
      location: true,
    });

    if (validation()) {
      try {
        const merchandiseRequestId = await merchandiseDeliveryRequestsApi.postMerchandiseDeliveryRequest(formData);
        if (files.length > 0) await uploadFiles(merchandiseRequestId.data);
        router.goBack();
      } catch (e) {
        console.log('Error posting merchandise delivery request...', e);
        setIsSubmitting(false);
        setErrors([{ key: 1, message: 'حدث خطأ اثناء الحفظ' }]);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin userForm'>
        <h2 style={{ marginBottom: '2rem' }}>اضافة طلب تسليم بضاعة جديد</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>اسم مندوب المبيعات</label>
              <Form.Input
                icon='user'
                value={formData['salesRepresentativeName']}
                name='salesRepresentativeName'
                onChange={onChangeHandler}
                error={touched.salesRepresentativeName && validationErrors.salesRepresentativeName}
              />
            </Form.Field>

            <Form.Field>
              <label>تاريخ انشاء الطلب</label>
              <Form.Input className='date-input-ltr' readOnly value={moment().format('YYYY/MM/DD hh:mm A')} fluid />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>العميل</label>
              <Dropdown
                placeholder='اختر العميل'
                fluid
                selection
                search
                value={formData['customerId']}
                name='customerId'
                options={customersIdOptions}
                onChange={selectionChangeHandler}
                error={touched.customerId && validationErrors.customerId}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>

            <Form.Field>
              <label>الموقع</label>
              <Form.Input
                icon='map pin'
                value={formData['location']}
                name='location'
                onChange={onChangeHandler}
                error={touched.location && validationErrors.location}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>اسم المستلم</label>
              <Form.Input
                icon='users'
                value={formData['personToCallName']}
                name='personToCallName'
                onChange={onChangeHandler}
                error={touched.personToCallName && validationErrors.personToCallName}
              />
            </Form.Field>

            <Form.Field>
              <label>هاتف المستلم</label>
              <Form.Input
                icon='call'
                value={formData['personToCallPhone']}
                name='personToCallPhone'
                onChange={onChangeHandler}
                error={touched.personToCallPhone && validationErrors.personToCallPhone}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>تاريخ التسليم المطلوب لدى العميل</label>
            <DatePicker
              selected={inputDate}
              onChange={(date) => DateChangeHandler(date)}
              minDate={moment().toDate()}
              onChangeRaw={handleDateChangeRaw}
            />
          </Form.Field>

          <Form.Field>
            <label>المواد المطلوب تسليمها</label>
            <Form.TextArea
              value={formData['deliverableMerchandise']}
              name='deliverableMerchandise'
              onChange={onChangeHandler}
            />
          </Form.Field>

          <Form.Field>
            <label>وصف المهمة المطلوبة</label>
            <Form.TextArea value={formData['description']} name='description' onChange={onChangeHandler} />
          </Form.Field>

          <h5>المرفقات</h5>
          <div className='attachments-container'>
            <DropFileUpload onFileChange={onDropFilesChanged} />
          </div>
          {uploadProgress > -1 && (
            <h4 style={{ textAlign: 'center' }}>
              جاري رفع المرفقات {uploadProgress}/{files.length}
            </h4>
          )}
          {errors.map((item) => {
            return <Message error key={item.key} content={item.message} />;
          })}

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}

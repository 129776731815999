export const userAuth = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGIN_START: 'LOGIN_START',
  LOGIN_FAILED: 'LOGIN_FAILED',
  UPDATE_DATA: 'UPDATE_DATA',
};

export const tickets = {
  SET_SELECTED_TICKET: 'SET_SELECTED_TICKET',
  CLEAR_SELECTED_TICKET: 'CLEAR_SELECTED_TICKET',
};

export const usersLocations = {
  FETCH_USERS_LOCATIONS_REQUEST: 'FETCH_USERS_LOCATIONS_REQUEST',
  FETCH_USERS_LOCATIONS_STOP: 'FETCH_USERS_LOCATIONS_STOP',
  FETCH_USERS_LOCATIONS_SUCCESS: 'FETCH_USERS_LOCATIONS_SUCCESS',
  FETCH_USERS_LOCATIONS_ERROR: 'FETCH_USERS_LOCATIONS_ERROR',
  SELECT_USER_LOCATION: 'SELECT_USER_LOCATION',
  CLEAR_ALL_SELECTED_USERS: 'CLEAR_ALL_SELECTED_USERS',
  SET_FIRST_LOAD: 'SET_LOCATIONS_FIRST_LOAD',
  LOAD_USERS_IMAGES: 'LOAD_USERS_IMAGES',
};

import { Picker } from 'emoji-mart';
import React from 'react';

const EmojiPicker = ({ onSelect }) => {
  return (
    <Picker
      native
      onSelect={onSelect}
      i18n={{
        search: 'بحث',
        categories: {
          search: 'بحث',
          recent: 'مستعمل مؤخراً',
          people: 'وجوه وتعابير',
          activity: 'نشاطات',
          custom: 'مخصص',
          foods: 'طعام',
          nature: 'طبيعة',
          objects: 'أشياء',
          places: 'اماكن وسفر',
        },
        notfound: 'غير موجود',
      }}
      exclude={['symbols', 'flags']}
      emojiSize={20}
      showSkinTones={false}
      //   title='اختر رمز'
      //   emoji='point_up'
      showPreview={false}
    />
  );
};

export default EmojiPicker;

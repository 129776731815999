import React, { useState, useEffect } from 'react';
import CustomerTicketsListItem from './CustomerTicketsListItem';
import './CustomerTicketsList.scss';
import { motion } from 'framer-motion';
import { Loader } from 'semantic-ui-react';
import { usersApi } from '../../../api/api';

const container = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const CustomerTicketsList = ({ data, onTicketSelected, loading }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);

  const [userImages, setUserImages] = useState({});
  useEffect(() => {
    (async () => {
      let temp = {};
      for (const ticket of data) {
        if (!temp.hasOwnProperty(ticket.teamMemberId) && !!ticket.teamMemberId) {
          let { data: res } = await usersApi.getImageById(ticket.teamMemberId);
          temp[ticket.teamMemberId] = res;
        }
      }
      setUserImages(temp);
    })();
  }, [data]);

  const onTicketSelectedLocal = (ticket) => {
    onTicketSelected(ticket);
    setSelectedTicket(ticket.id);
  };

  return !loading ? (
    data.length ? (
      <motion.div className='tickets-list' variants={container} initial='hidden' animate='show'>
        {data.map((ticket) => (
          <CustomerTicketsListItem
            key={ticket.id}
            ticket={ticket}
            onTicketSelected={onTicketSelectedLocal}
            selectedTicket={selectedTicket}
            assignedImage={userImages[ticket.teamMemberId]}
          />
        ))}
      </motion.div>
    ) : (
      <div className='center-container'>
        <h4>لا يوجد تذاكر</h4>
      </div>
    )
  ) : (
    <div className='center-container'>
      <Loader content='يرجى الانتظار ...' size='large' inline active={loading} />
    </div>
  );
};

export default CustomerTicketsList;

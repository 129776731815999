import { OSM, BingMaps, Stamen, XYZ } from 'ol/source';
import { Group as LayerGroup, Tile as TileLayer } from 'ol/layer';

// const gceMapUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.100.10' : 'https://moj.jonav.me';
const gceMapUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://192.168.100.10'
    : `${window.location.protocol}//${window.location.hostname}`;

export const baseLayers = () => [
  new LayerGroup({
    title: 'الطبقات الرئيسية',
    combine: false,
    visible: true,
    layers: [
      new TileLayer({
        title: 'GCE',
        source: new XYZ({
          url: `${gceMapUrl}/EMMAP/JordanTile.aspx?z={z}&x={x}&y={y}&lang=ar&clear=0`,
        }),
        type: 'base',
        visible: false,
      }),
      new TileLayer({
        title: 'OSM',
        type: 'base',
        visible: true,
        source: new OSM(),
      }),
      new TileLayer({
        title: 'Bing',
        source: new BingMaps({
          key: 'AsJihSDzsujaimLwbAvgi5cSJlxtXkpN0nOP7BGQCr7P-oO_TFboQ1AvZZ-85AUj',
          imagerySet: 'Road',
        }),
        type: 'base',
        visible: false,
      }),
      new TileLayer({
        title: 'Terrain',
        type: 'base',
        visible: false,
        source: new Stamen({
          layer: 'terrain',
        }),
      }),
      new TileLayer({
        title: 'Google',
        source: new XYZ({
          url: 'http://mt{1-3}.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}',
        }),
        type: 'base',
        visible: false,
      }),
      new TileLayer({
        title: 'Google Satellite',
        source: new XYZ({
          url: 'http://mt{1-3}.google.com/vt/lyrs=s@113&hl=en&&x={x}&y={y}&z={z}&s=Galileo',
        }),
        type: 'base',
        visible: false,
      }),
    ],
  }),
];

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Message, Button, Icon, Responsive } from 'semantic-ui-react';
import { usersApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import permissions, { hasPermission } from '../../../helpers/permissions';

export default function EditPasswordForm() {
  const router = useHistory();
  const location = useLocation();
  const { userId, id } = Boolean(location.state) ? location.state : {};

  useEffect(() => {
    if (Boolean(id) && !hasPermission(permissions.CHANGE_USER_PASSWORD)) router.replace('/home');
    else if (!Boolean(userId) && !Boolean(id)) router.replace('/home');
  }, [location.state]);

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    password: null,
    passwordConfirm: null,
  });

  const [touched, setTouched] = useState({
    password: false,
    passwordConfirm: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  const validation = () => {
    const errors = {
      password: null,
      passwordConfirm: null,
    };

    //PASSWORD VALIDATION
    if (!formData.password) {
      errors.password = 'الرجاء ادخال كلمة السر';
    } else if (formData.password.length < 3) {
      errors.password = 'كلمة السر يجب ان تكون 3 احرف على الاقل';
    }

    //PASSWORD CONFIRMATION VALIDATION
    if (!formData.passwordConfirm) {
      errors.passwordConfirm = 'الرجاء ادخال تأكيد كلمة السر';
    } else if (formData.password.length < 3 || formData.password !== formData.passwordConfirm) {
      errors.passwordConfirm = 'كلمة السر غير متطابقة';
    }

    setValidationErrors(errors);

    if (validationErrors.password || validationErrors.passwordConfirm) return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      password: true,
      passwordConfirm: true,
    });

    if (validation()) {
      try {
        const ID = Boolean(userId) ? userId : id;

        await usersApi.editPassword({
          ID: ID,
          Password: formData.password,
        });
        if (Boolean(userId)) router.replace('/home');
        else router.goBack();
      } catch (e) {
        console.log('Error editing password...', e);
        if (e.response.status === 400 || e.response.status === 401) {
          setErrors({ key: -1, message: e.response.data });
        } else {
          setErrors({ key: 1, message: 'حدث خطأ اثناء الحفظ' });
        }

        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل كلمة السر</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>كلمة السر</label>
              <Form.Input
                icon='lock'
                type='password'
                value={formData['password']}
                name='password'
                onChange={onChangeHandler}
                error={touched.password && validationErrors.password}
              />
            </Form.Field>

            <Form.Field>
              <label>تأكيد كلمة السر</label>
              <Form.Input
                icon='lock'
                type='password'
                value={formData['passwordConfirm']}
                name='passwordConfirm'
                onChange={onChangeHandler}
                error={touched.passwordConfirm && validationErrors.passwordConfirm}
              />
            </Form.Field>
          </Form.Group>

          <Message error key={errors.key} content={errors.message} />

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}

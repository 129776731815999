import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

const ErrorModal = () => {
  const router = useHistory();
  const [open, setOpen] = useState(true);
  return (
    <Modal
      open={open}
      size='mini'
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Header style={{ textAlign: 'center' }}>
        <Header icon>
          <Icon name='close' style={{ color: '#dc143c', margin: 0 }} />
          <h3>حدث خطأ يرجى المحاولة لاحقا</h3>
        </Header>
      </Modal.Header>

      <Modal.Content>
        <div className='flex-space'>
          <Button
            style={{ backgroundColor: '#dc143c', color: '#fff' }}
            onClick={() => {
              router.push('/home');
              setOpen(false);
            }}
          >
            الصفحة الرئيسية
          </Button>
          <Button
            style={{ backgroundColor: '#003A71', color: '#fff' }}
            onClick={() => {
              window.location.reload(false);
              setOpen(false);
            }}
          >
            اعادة تحميل الصفحة
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ErrorModal;

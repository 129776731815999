import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Transition } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { contractsApi, customersApi } from './../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission, hasPermissions } from '../../../helpers/permissions';

export default function Contracts() {
  const router = useHistory();
  const [currentContractId, setCurrentContractId] = useState(null);
  const [deleteContractPopup, setDeleteContractPopup] = useState(false);
  const [deleteContractErrPopup, setDeleteContractErrPopup] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState([]);
  const [rerenderFlag, setRerenderFlag] = useState(false);

  const [customerGroupIdOpts, setCustomerGroupIdOpts] = useState([]);
  const [customerGroupIdArMap, setCustomerGroupIdArMap] = useState([{}]);
  const [customerIdOpts, setCustomerIdOpts] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState([{}]);

  useEffect(() => {
    const fetch = async () => {
      const customers = await customersApi.getCustomerGroups();
      let customersOpts = [];
      let customersArMap = {};
      for (let item of customers.data) {
        customersOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
        customersArMap[item.id] = item.name;
      }
      setCustomerGroupIdOpts(customersOpts);
      setCustomerGroupIdArMap(customersArMap);
    };

    const fetchCustomers = async () => {
      const customers = await customersApi.getCustomersDropDown();
      let clientsOptionsAr = [];
      let clientsArMap = {};
      for (let item of customers.data) {
        clientsOptionsAr.push({ key: item.id, text: item.name, value: item.id });
        clientsArMap[item.id] = item.name;
      }
      setCustomerIdOpts(clientsOptionsAr);
      setCustomerIdArMap(clientsArMap);
    };
    fetch();
    fetchCustomers();
  }, []);

  const tableHeaders = [
    {
      displayName: 'رقم العقد',
      fieldName: 'id',
      dbFieldName: 'id',
      sortable: false,
      searchable: false,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'الاسم التعريفي',
      fieldName: 'name',
      dbFieldName: 'name',
      sortable: true,
      searchable: true,
      viewable: true,
      searchType: 'text',
    },
    {
      displayName: 'اسم المالك',
      fieldName: 'ownerName',
      dbFieldName: 'owner_name',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'مجموعة العملاء',
      fieldName: 'customerGroup',
      dbFieldName: 'customer_group',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerGroupIdOpts,
      dropdownArMap: customerGroupIdArMap,
    },
    {
      displayName: 'العميل',
      fieldName: 'customer',
      dbFieldName: 'customer',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerIdOpts,
      dropdownArMap: customerIdArMap,
    },
    {
      displayName: 'تاريخ البداية',
      fieldName: 'startDate',
      dbFieldName: 'start_date',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'تاريخ النهاية',
      fieldName: 'endDate',
      dbFieldName: 'end_date',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'تاريخ الفاتورة',
      fieldName: 'invoiceDate',
      dbFieldName: 'invoice_date',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
  ];

  const handleDeleteContract = async (id) => {
    setCurrentContractId(id);
    const { data: res } = await contractsApi.canDeleteContract(id);
    if (res) {
      setDeleteContractPopup(true);
    } else {
      // TODO: re-think this section
      // Current Behaviour: All associated asset will be deleted from contract_assets once the contract is deleted
      setDeleteMessage(['العقد مرتبط بآلات .... TODO: some validation when done']);
      setDeleteContractErrPopup(true);
    }
  };

  const handleDeleteContractConfirm = async () => {
    try {
      await contractsApi.deleteContract(currentContractId);
      setRerenderFlag(!rerenderFlag);
      setDeleteContractPopup(false);
    } catch (e) {
      console.log('Error deleting Contract...', e);
    }
  };

  const tableActions = [
    ...(hasPermissions([permissions.VIEW_CONTRACTS, permissions.VIEW_ALL_CONTRACTS])
      ? [
          {
            actionType: 'view',
            actionUrl: '/contracts/view',
          },
        ]
      : []),
    ...(hasPermission(permissions.EDIT_CONTRACT)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/contracts/edit',
          },
        ]
      : []),
    ...(hasPermissions([permissions.CREATE_CONTRACT, permissions.EDIT_CONTRACT])
      ? [
          {
            actionType: 'custom',
            onClick: (id, row) => router.push('/contracts/linkContractAssets', { row }),
            icon: 'boxes',
            title: 'ربط أجهزة بالعقد',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_CONTRACT)
      ? [
          {
            actionType: 'custom',
            onClick: handleDeleteContract,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  return (
    <div>
      <PageCardContainer>
        <div className='table-title-container'>
          <h3>العقود</h3>
          <PermissionCheck permissions={permissions.CREATE_CLIENT}>
            <Button icon labelPosition='right' className='blue-button' onClick={() => router.push('/contracts/add')}>
              <Icon name='plus' className='blue-buton-icon' />
              اضافة عقد جديد
            </Button>
          </PermissionCheck>
        </div>
        <DataTable
          tableTitle='العقود'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={contractsApi.getContracts}
          reloadTrigger={[rerenderFlag]}
          optionalQsParams={{}}
        />
      </PageCardContainer>

      <Transition visible={deleteContractPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteContractPopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <p>هل انت متأكد من الحذف؟</p>
            <p>في حال ارتباط العقد بآلات فسيتم حذف الارتباط بشكل كلي!</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => handleDeleteContractConfirm()}>
              نعم
            </Button>
            <Button color='green' onClick={() => setDeleteContractPopup(false)}>
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      {/* popup for error message */}
      <Transition visible={deleteContractErrPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteContractErrPopup}>
          <Modal.Header>حدث خطأ</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p>لا يمكن حذف العميل لأحد الاسباب التالية: </p>
              {deleteMessage.map((str, index) => (
                <p className='red-text' key={index}>
                  <Icon name='circle' size='tiny' />
                  {str}
                </p>
              ))}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setDeleteContractErrPopup(false)}>موافق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Icon, Button, Checkbox, Segment, Responsive } from 'semantic-ui-react';
import { groupBy } from 'lodash';

import { teamsApi, customersApi } from '../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import './team-members.scss';

const AssignTeamCustomers = () => {
  const location = useLocation();
  const router = useHistory();
  const currentUser = useSelector((st) => st.user).user;
  const { id, row } = location.state;

  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [customerIdOptions, setCustomerIdOptions] = useState([]);

  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const { data: currentCustomers } = await teamsApi.getCustomers(id);

        const { data: customers } = await customersApi.getCustomersDropDown();
        for (let result of customers) {
          if (currentCustomers.includes(result.id)) result.isChecked = true;
        }
        setCustomerIdOptions(customers);

        setLoading(false);
      } catch (error) {
        // TODO: Display an error somewhere
        console.log('error loading customers', error);
      }
    };
    if (id) {
      loadCustomers();
    } else {
      router.goBack();
    }
  }, [id]);

  const checkboxChangeHandler = (id) => {
    let newState = [...customerIdOptions];
    let customer = newState.find((x) => x.id === id);
    customer.isChecked = !customer.isChecked;
    setCustomerIdOptions(newState);
  };

  const groupCheckboxChangeHandler = (group) => {
    let newState = [...customerIdOptions];
    const alreadyChecked = newState.every(
      (customer) => customer.groupName !== group || (customer.groupName === group && customer.isChecked)
    );

    newState.forEach((customer) => {
      if (customer.groupName === group) customer.isChecked = !alreadyChecked;
    });

    setCustomerIdOptions(newState);
  };

  const onSave = async () => {
    try {
      setIsSubmitting(true);
      const data = { CustomerIds: customerIdOptions.filter((c) => c.isChecked).map((c) => c.id) };
      const res = (await teamsApi.updateTeamCustomers(id, data)).data;
      if (res === -1) {
        setIsSubmitting(false);
        setErrorMessage('يوجد عملاء بتذاكر غير مغلقة ولا يمكن ازالتهم');
        console.log('show validation for invalid team removal');
        // TODO: show validation error (customers being removed have open tickets assigned to this team)
      } else {
        router.goBack();
      }
    } catch (error) {
      setErrorMessage('حدث خطأ يرجى المحاولة مجددا');
      setIsSubmitting(false);
      console.log('error saving team customers', error);
    }
  };

  return (
    <PageCardContainer>
      <div className='form-margin m1 team-members-page'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل عملاء فريق</h2>
        <Table basic='very' size='large' collapsing unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Icon name='users' className='ml1' /> الفريق:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.name}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='building' className='ml1' /> الادارة:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.department}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='user plus' className='ml1' /> المدير:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.manager}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name='user' className='ml1' /> رئيس الفريق:
              </Table.Cell>
              <Table.Cell>
                <b className='mr1'>{row.teamLeader}</b>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Segment loading={loading}>
          <h3>العملاء</h3>
          <div className='checkbox-div'>
            {Boolean(customerIdOptions.length) ? (
              Object.entries(groupBy(customerIdOptions, (x) => x.groupName)).map((group, index) => (
                <div key={index}>
                  <div className='flex-center'>
                    {group[0] !== 'null' && (
                      <div className='one-checkbox'>
                        <Checkbox
                          name={group[0]}
                          checked={group[1].every((customer) => customer.isChecked)}
                          onClick={() => groupCheckboxChangeHandler(group[0])}
                        />
                      </div>
                    )}
                    <Icon name='group' className='ml1' />
                    <h5>{group[0] !== 'null' ? group[0] : 'بلا مجموعة (غير مصنف)'}</h5>
                  </div>
                  {group[1].map((customer) => (
                    <div key={customer.id} className='one-checkbox mr1'>
                      <Checkbox
                        label={customer.name}
                        name={customer.name}
                        checked={customer.isChecked}
                        onClick={() => checkboxChangeHandler(customer.id)}
                      />
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <h3 className='no-data'>لا يوجد عملاء</h3>
            )}
          </div>
        </Segment>
        <p style={{ color: 'crimson' }}>{errorMessage}</p>
        <Responsive minWidth={768}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              icon
              type='button'
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginLeft: '1rem' }}
              onClick={onSave}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              icon='chevron right'
              type='button'
              labelPosition='right'
              content='رجوع'
              onClick={() => {
                router.goBack();
              }}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={767}>
          <div style={{ marginTop: '2rem' }}>
            <Button
              type='button'
              icon
              fluid
              loading={isSubmitting}
              disabled={isSubmitting}
              className='blue-button'
              labelPosition='right'
              style={{ marginBottom: '1rem' }}
              onClick={onSave}
            >
              <Icon name='save' className='blue-buton-icon' />
              حفظ
            </Button>

            <Button
              type='button'
              fluid
              icon='chevron right'
              labelPosition='right'
              content='رجوع'
              onClick={() => {
                router.goBack();
              }}
            />
          </div>
        </Responsive>
      </div>
    </PageCardContainer>
  );
};

export default AssignTeamCustomers;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Transition } from 'semantic-ui-react';
import PageCardContainer from '../../Layout/PageCardContainer';
import DataTable from '../../Layout/Table/DataTable';
import { customersApi, lookupsApi, usersApi } from '../../../api/api';
import { maintenanceFormApi } from '../../../api/api';
import PermissionCheck from '../../Reusable/PermissionCheck';
import permissions, { hasPermission } from '../../../helpers/permissions';

export default function MaintenanceForm({ passedId = null, passedType = null }) {
  const router = useHistory();

  const [technicianUserIdOpts, setTechnicianUserIdOpts] = useState([]);
  const [techniciansArMap, setTechniciansArMap] = useState({});

  const [customerIdOpts, setCustomerIdOpts] = useState([]);
  const [customerIdArMap, setCustomerIdArMap] = useState({});

  const [problemsArMap, setProblemsArMap] = useState({});
  const [problemsOpts, setProblemsOpts] = useState({});

  const [vehicleProblemsOpts, setVehicleProblemsOpts] = useState([]);
  const [vehicleProblemsArMap, setVehicleProblemsArMap] = useState({});

  const [actionsOpts, setActionsOpts] = useState({});
  const [actionsArMap, setActionsArMap] = useState({});

  const [hourDropDown, setHourDropDown] = useState([]);
  const [hourDropDisplay, setHourDropDisplay] = useState({});

  const [deleteErrorPopup, setDeleteErrorPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);

  const [powerAlertsOpts, setPowerAlertsOpts] = useState([
    { key: 1, text: 'نعم', value: 1 },
    { key: 2, text: 'لا', value: 2 },
  ]);
  const [powerAlertsDisplay, setPowerAlertsDisplay] = useState({
    2: 'لا',
    1: 'نعم',
  });

  useEffect(() => {
    const fetchData = async () => {
      (() => {
        let arr = [];
        let arr2 = [];
        for (let i = 0; i <= 23; i++) {
          arr.push({ key: i, text: i, value: i });
          arr2[i] = i;
        }
        setHourDropDown(arr);
        setHourDropDisplay(arr2);
      })();
      // api calls

      //get technicians (all users with no customerId field)
      const technicians = await usersApi.getAllExceptCustomersDropdown();
      let techniciansOpts = [];
      let techniciansArMap = {};
      for (let item of technicians.data) {
        techniciansOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
        techniciansArMap[item.id] = item.name;
      }
      setTechniciansArMap(techniciansArMap);
      setTechnicianUserIdOpts(techniciansOpts);

      //get all customers
      const customers = await customersApi.getCustomersDropDown();
      let customersOpts = [];
      let customersArMap = {};
      for (let item of customers.data) {
        customersOpts.push({
          key: item.id,
          text: item.name,
          value: item.id,
        });
        customersArMap[item.id] = item.name;
      }
      setCustomerIdOpts(customersOpts);
      setCustomerIdArMap(customersArMap);

      //get lookup group where id = 1 (problems)
      const problems = await lookupsApi.getGroup(1);
      let problemsOpts = [];
      let problemsArMap = {};
      for (let item of problems.data) {
        problemsOpts.push({ key: item.id, text: item.name, value: item.id });
        problemsArMap[item.id] = item.name;
      }
      setProblemsOpts(problemsOpts);
      setProblemsArMap(problemsArMap);

      //get lookup where id = 42 (vehicle problems)
      const vehicleProblems = await lookupsApi.getGroup(42);
      let vehicleProbsOpts = [];
      let vehicleProbsArMap = {};
      for (let item of vehicleProblems.data) {
        vehicleProbsOpts.push({ key: item.id, text: item.name, value: item.id });
        vehicleProbsArMap[item.id] = item.name;
      }
      setVehicleProblemsOpts(vehicleProbsOpts);
      setVehicleProblemsArMap(vehicleProbsArMap);

      //get lookup group where id = 13 (actions)
      const actions = await lookupsApi.getGroup(13);
      let actionsOpts = [];
      let actionsArMap = {};
      for (let item of actions.data) {
        actionsOpts.push({ key: item.id, text: item.name, value: item.id });
        actionsArMap[item.id] = item.name;
      }
      setActionsOpts(actionsOpts);
      setActionsArMap(actionsArMap);
    };

    fetchData();
  }, []);

  const tableHeaders = [
    {
      displayName: 'اسم العميل',
      fieldName: 'customerId',
      dbFieldName: 'customer_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: customerIdOpts,
      dropdownArMap: customerIdArMap,
    },
    {
      displayName: 'اسم الفني',
      fieldName: 'technicianUserId',
      dbFieldName: 'technician_user_id',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: technicianUserIdOpts,
      dropdownArMap: techniciansArMap,
    },
    {
      displayName: 'حالة جهاز التتبع',
      fieldName: 'issueType',
      dbFieldName: 'issue_type',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: problemsOpts,
      dropdownArMap: problemsArMap,
    },
    {
      displayName: 'حالة المركبة',
      fieldName: 'vehicleIssueType',
      dbFieldName: 'vehicle_issue_type',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: vehicleProblemsOpts,
      dropdownArMap: vehicleProblemsArMap,
    },
    {
      displayName: 'الاجراء',
      fieldName: 'actionType',
      dbFieldName: 'action_type',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'dropdown',
      searchValues: actionsOpts,
      dropdownArMap: actionsArMap,
    },
    {
      displayName: 'جهاز التتبع',
      fieldName: 'imei',
      dbFieldName: 'imei',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'رقم المركبة',
      fieldName: 'plateNumber',
      dbFieldName: 'plate_number',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'تاريخ الصيانة',
      fieldName: 'maintenanceDate',
      dbFieldName: 'maintenance_date',
      viewable: true,
      sortable: true,
      searchable: true,
      searchType: 'date',
    },
    {
      displayName: 'ساعة الصيانة',
      fieldName: 'maintenanceHour',
      dbFieldName: 'maintenance_hour',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'dropdown',
      searchValues: hourDropDown,
      dropdownArMap: hourDropDisplay,
    },
    {
      displayName: 'فصل مزود الطاقة الرئيسي',
      fieldName: 'mainPowerAlerts',
      dbFieldName: 'main_power_alerts',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'dropdown',
      searchValues: powerAlertsOpts,
      dropdownArMap: powerAlertsDisplay,
    },
    {
      displayName: 'وصف المشكلة',
      fieldName: 'issueDesc',
      dbFieldName: 'issue_desc',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'وصف الاجراء',
      fieldName: 'actionDesc',
      dbFieldName: 'action_desc',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'مندوب العميل',
      fieldName: 'representativeName',
      dbFieldName: 'representative_name',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
    {
      displayName: 'ملاحظات',
      fieldName: 'note',
      dbFieldName: 'note',
      viewable: false,
      sortable: false,
      searchable: true,
      searchType: 'text',
    },
  ];

  const handleFormDelete = (id, row) => {
    setCurrentFormId(id);
    if (row.maintenanceTicketVehicleId) {
      setDeleteErrorPopup(true);
    } else setDeletePopup(true);
  };

  const deleteFormConfirmHandler = async () => {
    try {
      await maintenanceFormApi.deleteForm(currentFormId);
    } catch (err) {
      console.log('Error deleting form...', err);
    }
    setRerenderFlag(!rerenderFlag);
    setDeletePopup(false);
  };

  const tableActions = [
    {
      actionType: 'view',
      actionUrl: '/maintenanceForm/view',
    },
    ...(hasPermission(permissions.EDIT_MAINTENANCE_REPORTS)
      ? [
          {
            actionType: 'edit',
            actionUrl: '/maintenanceForm/edit',
          },
        ]
      : []),
    ...(hasPermission(permissions.DELETE_MAINTENANCE_REPORT)
      ? [
          {
            actionType: 'custom',
            onClick: handleFormDelete,
            title: 'حذف',
            icon: 'trash',
            color: 'red',
          },
        ]
      : []),
  ];

  const qsParams =
    passedId && passedType ? (passedType === 'vehicle' ? { vehicleId: passedId } : { trackingUnitId: passedId }) : {};

  return (
    <div>
      <PageCardContainer>
        {!passedId && !passedType && (
          <div className='table-title-container'>
            <h3>تقارير صيانة اجهزة التتبع</h3>
            <PermissionCheck permissions={permissions.CREATE_REPORT_WITHOUT_TICKET}>
              <Button
                icon
                labelPosition='right'
                className='blue-button'
                onClick={() => router.push('/maintenanceForm/add')}
              >
                <Icon name='plus' className='blue-buton-icon' />
                اضافة تقرير صيانة جديد
              </Button>
            </PermissionCheck>
          </div>
        )}
        <DataTable
          tableTitle='تقارير_الصيانة'
          headers={tableHeaders}
          actions={tableActions}
          apiFunction={maintenanceFormApi.getAllForms}
          optionalQsParams={qsParams}
          reloadTrigger={[passedType, rerenderFlag]}
        />
      </PageCardContainer>

      <Transition visible={deleteErrorPopup} animation='scale' duration={1000}>
        <Modal size='small' open={deleteErrorPopup}>
          <Modal.Header>خطأ في الحذف</Modal.Header>
          <Modal.Content>
            <div className='delete-ticket-errors-modal'>
              <p className='red-text'>لا يمكن الحذف لان التقرير مرتبط بتذكرة صيانة.</p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setDeleteErrorPopup(false)}>موافق</Button>
          </Modal.Actions>
        </Modal>
      </Transition>

      <Transition visible={deletePopup} animation='scale' duration={1000}>
        <Modal size='small' open={deletePopup}>
          <Modal.Header>تأكيد الحذف</Modal.Header>
          <Modal.Content>
            <p>هل انت متأكد من الحذف؟</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => deleteFormConfirmHandler()}>
              نعم
            </Button>
            <Button color='green' onClick={() => setDeletePopup(false)}>
              لا
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </div>
  );
}
